<template>
  <div class="tab-box">
    <div class="search">
      <div class="search-left">
        <div class="text">
          <span>日期</span>
        </div>
        <el-date-picker size="small" type="date" placeholder="选择日期">
        </el-date-picker>
        <el-button class="all">全部数据</el-button>
        <el-switch v-model="value" active-color="#30F05D" inactive-color="#30F05D" active-text="标准模式"
          inactive-text="标准模式">
        </el-switch>
      </div>
      <div class="search-right">
        <el-button size="mini">添加</el-button>
        <el-button size="mini">下载</el-button>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" height="251" :header-cell-style="{ background: '#024276' }">
        <el-table-column prop="name" label="日期"></el-table-column>
        <el-table-column label="时间"></el-table-column>
        <el-table-column label="收缩压(mmHg)">
          <!-- slot-scope="scope" -->
          <template>
            <div class="text">
              <span>33.3</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="舒张压(mmHg)">
          <!-- slot-scope="scope" -->
          <template>
            <div class="text">
              <span>33.3</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="心率(次/分)">
          <!-- slot-scope="scope" -->
          <template>
            <div class="text">
              <span>33.3</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="水平">
          <!-- slot-scope="scope" -->
          <template>
            <div class="text">
              <span>33.3</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="检测人"></el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <div class="chart-title">
      <span>血压检测数据分析</span>
      <div class="label-box">
        <div class="label">
          <div class="icon"></div>
          <span>高血</span>
        </div>
        <div class="label label1">
          <div class="icon yellow"></div>
          <span>低压</span>
        </div>
        <div class="label label2">
          <div class="icon green"></div>
          <span>心率</span>
        </div>
        <el-button>保存为图片</el-button>
      </div>
    </div>
    <div class="chart" id="chart1"></div>
    <div class="charts-box">
      <div class="left">
        <div class="title">
          <span>血压统计</span>
        </div>
        <div class="date">
          <div class="text">
            <span>日期</span>
          </div>
          <el-date-picker size="small" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="table">
          <el-table :data="tableData" height="150" :header-cell-style="{ background: '#024276' }">
            <el-table-column prop="name"></el-table-column>
            <el-table-column label="收缩压(mmHg)"></el-table-column>
            <el-table-column label="舒张压(mmHg)"></el-table-column>
            <el-table-column label="心率(次/分)"></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="right">
        <div class="title">
          <span>血压总览</span>
          <el-select v-model="value" placeholder="请选择" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="div" id="chart2"></div>
      </div>
    </div>
    <div class="title-box">
      <span>医嘱管理</span>
      <div class="right">
        <span>制订人</span>
        <el-select v-model="value" placeholder="请选择" size="small">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="switch">
      <div class="switch-tab">
        <span class="span" :class="{ span1: switchIndex === 0 }" @click="switchClick(0)">血糖检测计划</span>
        <span class="span" :class="{ span1: switchIndex === 1 }" @click="switchClick(1)">用药计划</span>
        <span class="span" :class="{ span1: switchIndex === 2 }" @click="switchClick(2)">管理级别</span>
      </div>
    </div>
    <div class="table" v-show="switchIndex === 0">
      <el-table :data="tableData" height="251" :header-cell-style="{ background: '#024276' }">
        <el-table-column prop="name" label="开始时间"></el-table-column>
        <el-table-column label="检测模式"></el-table-column>
        <el-table-column label="计划次数"></el-table-column>
        <el-table-column label="检测类型"></el-table-column>
        <el-table-column label="制定时间"></el-table-column>
        <el-table-column label="制定人"></el-table-column>
        <el-table-column label="执行状态"></el-table-column>
        <el-table-column label="随机"></el-table-column>
      </el-table>
    </div>
    <div class="table" v-show="switchIndex === 1">
      <el-table :data="tableData" height="251" :header-cell-style="{ background: '#024276' }">
        <el-table-column prop="name" label="开始时间"></el-table-column>
        <el-table-column label="药品类型"></el-table-column>
        <el-table-column label="药品名称"></el-table-column>
        <el-table-column label="每次剂量"></el-table-column>
        <el-table-column label="用药时段"></el-table-column>
        <el-table-column label="用药周期"></el-table-column>
        <el-table-column label="制定人"></el-table-column>
        <el-table-column label="执行状态"></el-table-column>
        <el-table-column label="状态">
          <template>
            <div>
              终止计划
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table" v-show="switchIndex === 2">
      <el-table :data="tableData" height="251" :header-cell-style="{ background: '#024276' }">
        <el-table-column prop="name" label="开始时间"></el-table-column>
        <el-table-column label="管理级别"></el-table-column>
        <el-table-column label="餐前"></el-table-column>
        <el-table-column label="餐后两小时"></el-table-column>
        <el-table-column label="睡前"></el-table-column>
        <el-table-column label="凌晨"></el-table-column>
        <el-table-column label="随机"></el-table-column>
        <el-table-column label="制定时间"></el-table-column>
        <el-table-column label="制定人"></el-table-column>
        <el-table-column label="执行状态"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: '',
      tableData: [{
        date: '2020-02-27 17:20',
        name: '2023-02-03 周五',
        type: '1',
        value: false
      }],
      myChart1: null,
      myChart2: null,
      options: [{
        value: '',
        label: ''
      }],
      switchIndex: 0
    }
  },
  mounted () {
    this.canvas1()
    this.canvas2()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      this.myChart1.resize()
      this.myChart2.resize()
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    canvas1 () {
      var chartDom = document.getElementById('chart1')
      this.myChart1 = this.$echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',
              fontSize: 15
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: [
          {
            type: 'value',
            name: 'mmol/L',
            nameTextStyle: {
              color: '#fff',
              nameLocation: 'start'
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: ['#fff']
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff',
                fontSize: 15
              }
            }
          },
          {
            type: 'value',
            name: '次/分',
            position: 'right',
            nameTextStyle: {
              color: '#fff',
              nameLocation: 'start'
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: ['#fff']
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff',
                fontSize: 15
              }
            }
          }
        ],
        grid: {
          left: '3%',
          right: '4%',
          top: '16%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            name: '体重',
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              // color: '#CE7500' // 改变折线点的颜色
              color: function (e) {
                if (e.data > 200) {
                  return '#FF2A2A'
                } else if (e.data < 200 && e.data > 50) {
                  return '#CE7500'
                } else {
                  return '#fff'
                }
              }
            },
            lineStyle: {
              color: '#30F05D' // 改变折线颜色
            }
          },
          {
            name: '次/分',
            type: 'line',
            yAxisIndex: 1,
            data: [15, 23, 22, 21, 135, 147, 260]
          }
        ]
      }
      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      var chartDom = document.getElementById('chart2')
      this.myChart2 = this.$echarts.init(chartDom)
      var option = {
        color: ['#30F05D', '#FF2B14', '#CE7500'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'right',
          orient: 'vertical',
          textStyle: {
            fontSize: 12,
            color: function (e) {

            }
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'bold'
              }
            },
            label: {
              show: true,
              color: function (e) {
                // console.log(e)
                const list = ['#30F05D', '#FF2B14', '#CE7500']
                return list[e.dataIndex]
              }
            },
            labelLine: {
              show: true
            },
            data: [
              { value: 37, name: '正常' },
              { value: 0, name: '偏低' },
              { value: 63, name: '偏高' }
            ].map((item, index) => {
              const colors = ['#30F05D', '#FF2B14', '#CE7500']
              item.label = {
                color: colors[index]
              }
              return item
            })
          }
        ]
      }
      option && this.myChart2.setOption(option)
    },
    switchClick (index) {
      this.switchIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 .3125rem;
  box-sizing: border-box;

  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: .6875rem;

    .search-left {
      display: flex;
      align-items: center;

      .text {
        margin-right: .125rem;
        color: #01EEFD;
        font-size: .2rem;
      }

      .all {
        height: .325rem;
        line-height: .325rem;
        margin: 0 .375rem;
        padding-top: 0;
        padding-bottom: 0;
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        border: none;
        color: #fff;
        font-size: .175rem;
      }

      //按钮文字显示
      .el-switch {
        height: .3125rem !important;
      }

      ::v-deep .el-switch__core {
        width: 1.375rem !important;
        height: .3rem;
        border-radius: 1.25rem;
        border: none;
      }

      ::v-deep .el-switch__core::after {
        width: .25rem;
        height: .25rem;
        top: .025rem;
      }

      ::v-deep .el-switch.is-checked .el-switch__core::after {
        margin-left: -0.2625rem;
      }

      /*关闭时文字位置设置*/
      ::v-deep .el-switch__label--right {
        position: absolute;
        z-index: 1;
        right: .075rem;
        margin-left: 0rem;
        color: rgba(255, 255, 255, 0.9019607843137255);

        span {
          font-size: .15rem;
        }
      }

      /* 激活时另一个文字消失 */
      ::v-deep .el-switch__label.is-active {
        display: none;
      }

      /*开启时文字位置设置*/
      ::v-deep .el-switch__label--left {
        position: absolute;
        z-index: 1;
        left: .0625rem;
        margin-right: 0rem;
        color: rgba(255, 255, 255, 0.9019607843137255);

        span {
          font-size: .15rem;
        }
      }
    }

    .search-right {
      .el-button {
        margin-right: .25rem;
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        color: #fff;
        border: none;
      }
    }
  }

  .charts-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.975rem;
    margin-bottom: .25rem;

    .left,
    .right {
      width: 58%;
      height: 100%;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: .6rem;
        line-height: .6rem;
        padding-left: .375rem;
        border-bottom: 1px solid #073F73;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: .25rem;

        .el-select {
          width: 1.25rem;
        }
      }

      .date {
        display: flex;
        align-items: center;
        color: #01EEFD;
        font-size: .2rem;
      }

      .div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: calc(100% - .6rem);
        border-bottom: 1px solid #073F73;
        box-sizing: border-box;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #CE7500;
          font-size: .25rem;

          >span {
            margin-top: .0625rem;
          }

          .green {
            color: #30F05D;
          }

          .red {
            color: #AF2731;
          }
        }
      }
    }

    .right {
      width: 40%;
    }
  }

  .table {
    width: 100%;
    margin-top: .125rem;

    .el-table {
      border: .05rem #073F73 solid;
    }

    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        // 整个滚动条
        width: 0rem; // 纵向滚动条的宽度
      }
    }

    ::v-deep .el-table th.gutter {
      display: none;
      width: 0;
    }

    ::v-deep .el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    ::v-deep .el-table__body {
      width: 100% !important;
    }

    /deep/.el-table__header-wrapper {
      thead {
        th {
          div {
            color: #01EEFD;
            font-size: .2125rem;
            font-weight: 400;
          }
        }
      }
    }

    /deep/.el-table__body tbody tr {
      color: #fff;
      font-size: .2rem;
      background-color: #092B50;
    }

    /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #092B50;
    }

    /deep/.el-table tbody tr:hover>td {
      background-color: transparent !important
    }

    .el-table--border,
    .el-table--group {
      border: .025rem solid #ddd !important;
    }

    .el-table--border td,
    .el-table--border th,
    .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      border-right: .0125rem solid #ddd !important;
    }

    /deep/.el-table td {
      border-bottom: .0125rem solid #01EEFD;
    }

    /deep/.el-table thead tr th {
      border-color: transparent;
    }

    /deep/ .el-table--border::after,
    /deep/ .el-table--group::after,
    /deep/ .el-table::before {
      background-color: #073F73;
    }

    /deep/.el-table--border {
      border: .0125rem solid #073F73 !important;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-table th.gutter {
      display: none;
      width: 0rem;
    }

    /deep/.el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    /deep/ .el-table__body-wrapper {
      background-color: #092B50;
    }

    .text {
      color: #01EEFD;
      color: #FF0000;
      font-size: .2rem;
    }

    .red {
      color: #FF0000;
    }

    .yellow {
      color: #CE7500;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: .5rem;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

  .chart-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #01EEFD;
    font-size: .25rem;

    .label-box {
      display: flex;
      align-items: center;
    }

    .label {
      display: flex;
      align-items: center;
      margin-right: .375rem;

      .icon {
        width: .175rem;
        height: .175rem;
        margin-right: .075rem;
        border-radius: 50%;
        background-color: #FF2A2A;
        color: #FF2A2A;
      }

      .yellow {
        background-color: #CE7500;
      }
      .green {
        background-color: #30F05D;
      }
    }

    .label1 {
      color: #CE7500;
    }

    .label2 {
      color: #30F05D;
    }

    .el-button {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      color: #fff;
      border: none;
    }
  }

  .chart {
    height: 4.9375rem;
    margin-top: .25rem;
    border-top: .05rem solid #073F73;
    border-bottom: .05rem solid #073F73;
    box-sizing: border-box;
  }

  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: .625rem;
    margin-top: .25rem;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: .25rem;

    .right {
      font-size: .2rem;

      .el-select {
        width: 1.25rem;
        margin-left: .125rem;
      }
    }
  }

  .switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: .875rem;

    .switch-tab {
      display: flex;
      align-items: center;
      height: 100%;

      .span {
        height: .5125rem;
        line-height: .5125rem;
        margin-right: .25rem;
        padding: 0 .25rem;
        background-color: #D2D2D2;
        color: #fff;
        font-size: .225rem;
        cursor: pointer;
      }

      .span1 {
        background-color: #01EEFD;
        color: #fff;
      }
    }
  }
}
</style>
