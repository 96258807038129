<template>
  <div class="item">
    <div class="item-title">
      <span>2022-08-03</span>
    </div>
    <div class="item-box">
      <div class="item-line">
        <span>09:32 血液 生化全套</span>
      </div>
      <div class="item-line">
        <span class="item-line-item">标本名称:血液</span>
        <span class="item-line-item">申请时间:2022-03-08</span>
        <span class="item-line-item">采集时间:2022-03-08</span>
      </div>
      <div class="item-line">
        <span class="item-line-item">申请医生:汤小蓉</span>
        <span class="item-line-item">报告医生:王艳</span>
        <span class="item-line-item">审核医生:王艳</span>
      </div>
      <div class="item-line">
        <span class="item-line-item">检验时间:2022-03-08</span>
        <span class="item-line-item">报告时间:2022-03-08 09:00:38</span>
        <span class="item-line-item">打印时间:2022-03-08</span>
      </div>
    </div>
    <div class="item-table">
      <div class="item-head">
        <span class="flex">项目名称</span>
        <span class="flex">结果</span>
        <span class="flex">单位</span>
        <span class="flex">参考值</span>
        <span class="flex">趋势</span>
      </div>
      <div class="item-scroll">
        <div class="item-list">
          <span class="item-flex">总胆红素</span>
          <span class="item-flex red">191.50 <span class="el-icon-top"></span></span>
          <span class="item-flex">ummol/L</span>
          <span class="item-flex">8.00-23.00</span>
          <div class="item-flex item-flex1">
            <div class="chart">
              <div class="text">
                <span>N</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-list">
          <span class="item-flex">直接胆红素</span>
          <span class="item-flex red">191.50 <span class="el-icon-top"></span></span>
          <span class="item-flex">ummol/L</span>
          <span class="item-flex">2.00-8.00</span>
          <div class="item-flex item-flex1">
            <div class="chart">
              <div class="text">
                <span>N</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-list">
          <span class="item-flex">谷丙转氨酶</span>
          <span class="item-flex red">191.50 <span class="el-icon-top"></span></span>
          <span class="item-flex">U/L</span>
          <span class="item-flex">9.00-50.00</span>
          <div class="item-flex item-flex1">
            <div class="chart">
              <div class="text">
                <span>N</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-list">
          <span class="item-flex">谷草转氨酶</span>
          <span class="item-flex red">191.50 <span class="el-icon-top"></span></span>
          <span class="item-flex">U/L</span>
          <span class="item-flex">15.00-45.00</span>
          <div class="item-flex item-flex1">
            <div class="chart">
              <div class="text">
                <span>N</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-list">
          <span class="item-flex">谷氨酰胺基转移酶</span>
          <span class="item-flex">35.00</span>
          <span class="item-flex">U/L</span>
          <span class="item-flex">10.00-60.00</span>
          <div class="item-flex item-flex1">
            <div class="chart">
              <div class="text">
                <span>N</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-list">
          <span class="item-flex">碱性磷酸酶</span>
          <span class="item-flex">60.00</span>
          <span class="item-flex">U/L</span>
          <span class="item-flex">45.00-125.00</span>
          <div class="item-flex item-flex1">
            <div class="chart">
              <div class="text">
                <span>N</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-list">
          <span class="item-flex">乳酸脱氢酶</span>
          <span class="item-flex">83.00</span>
          <span class="item-flex">U/L</span>
          <span class="item-flex">80.00-240.00</span>
          <div class="item-flex item-flex1">
            <div class="chart">
              <div class="text">
                <span>N</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-list">
          <span class="item-flex">总蛋白</span>
          <span class="item-flex">66.00</span>
          <span class="item-flex">g/L</span>
          <span class="item-flex">60.00-85.00</span>
          <div class="item-flex item-flex1">
            <div class="chart">
              <div class="text">
                <span>N</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-list">
          <span class="item-flex">白蛋白</span>
          <span class="item-flex">40.00</span>
          <span class="item-flex">g/L</span>
          <span class="item-flex">35.00-55.00</span>
          <div class="item-flex item-flex1">
            <div class="chart">
              <div class="text">
                <span>N</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-list">
          <span class="item-flex">胆碱酯酶</span>
          <span class="item-flex"></span>
          <span class="item-flex">mmol/L</span>
          <span class="item-flex"></span>
          <div class="item-flex item-flex1">
            <div class="chart">
              <div class="text">
                <span>N</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-list">
          <span class="item-flex">尿素</span>
          <span class="item-flex"></span>
          <span class="item-flex"></span>
          <span class="item-flex"></span>
          <div class="item-flex item-flex1">
            <div class="chart">
              <div class="text">
                <span>N</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () { },
  beforeDestroy () { },
  methods: {}
}
</script>

<style lang="less" scoped>
.item {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }

  .item-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 42px;
    border-bottom: 1px solid #00D5EA;
    box-sizing: border-box;
    color: #fff;
    font-size: 18px;
  }

  .item-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 160px;
    padding-left: 42px;
    border-bottom: 1px solid #00D5EA;
    box-sizing: border-box;

    .item-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #fff;
      font-size: 16px;

      .item-line-item {
        width: 280px;
        color: #fff;
        font-size: 16px;
      }
    }
  }

  .item-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 210px);
    margin-top: 10px;
    border: 4px #073F73 solid;
    box-sizing: border-box;

    .item-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 44px;
      background-color: #024276;

      .flex {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        height: 44px;
        line-height: 44px;
        text-align: center;
        color: #01EEFD;
        font-size: 17px;
      }
    }

    .item-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 44px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .item-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 42px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;

        .item-flex {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1;
          height: 42px;
          line-height: 42px;
          text-align: center;
          color: #01EEFD;
          font-size: 16px;

          .chart {
            width: 24px;
            height: 16px;
            line-height: 16px;
            border-left: 1px solid #01EEFD;
            border-bottom: 1px solid #01EEFD;
            color: #01EEFD;
            font-size: 14px;

            .text {
              transform: rotate(15deg);
            }
          }
        }

        .item-flex1 {
          display: flex;
          align-items: center;
          justify-content: center
        }

        .red {
          color: #FF0000;
        }
      }

      .item-list:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
