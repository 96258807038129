<template>
  <div class="page">
    <div class="switch">
      <span class="item item1">院外设备清单</span>
    </div>
    <div class="content border-none">
      <div class="line border-none">
        <div class="title">
          <span>患者总览</span>
        </div>
        <div class="count">
          <div class="count-item">
            <img src="../../img/ssjk/count1.png" alt="本周激活">
            <span>本周激活1人</span>
          </div>
          <div class="count-item">
            <img src="../../img/ssjk/count2.png" alt="本月激活">
            <span>本月激活0人</span>
          </div>
          <div class="count-item">
            <img src="../../img/ssjk/count3.png" alt="总激活">
            <span>总激活1人</span>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="search">
          <div class="search-left">
            <el-input class="width" placeholder="设备SN号" suffix-icon="el-icon-search"></el-input>
            <el-select v-model="value" placeholder="品牌">
              <el-option label="1" value="1">
              </el-option>
              <el-option label="2" value="2">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="类型">
              <el-option label="1" value="1">
              </el-option>
              <el-option label="2" value="2">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="型号">
              <el-option label="1" value="1">
              </el-option>
              <el-option label="2" value="2">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="科室">
              <el-option label="1" value="1">
              </el-option>
              <el-option label="2" value="2">
              </el-option>
            </el-select>
            <el-button class="margin-left" size="small" type="primary">搜索</el-button>
            <el-button size="small" type="info">重置</el-button>
            <el-switch v-model="isSwitch" active-color="#30F05D" inactive-color="#30F05D" active-text="在院设备"
              inactive-text="已激活设备" class="margin-left">
            </el-switch>
          </div>
          <div class="search-right" @click="openAddDevice">
            <span class="el-icon-plus"></span>
          </div>
        </div>
        <el-table v-if="!isSwitch" :data="tableData" height="400">
          <el-table-column prop="name" label="设备类型">
          </el-table-column>
          <el-table-column prop="date" label="型号">
          </el-table-column>
          <el-table-column prop="date" label="设备SN号">
          </el-table-column>
          <el-table-column prop="type" label="出厂日期">
          </el-table-column>
          <el-table-column prop="type1" label="入库日期科室">
          </el-table-column>
          <el-table-column prop="type2" label="所属科室">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex flex1">
                <span @click="openSeeData">查看数据</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table v-else-if="isSwitch" :data="tableData" height="300" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="设备类型">
          </el-table-column>
          <el-table-column prop="date" label="型号">
          </el-table-column>
          <el-table-column prop="date" label="设备SN号">
          </el-table-column>
          <el-table-column prop="type" label="出厂日期">
          </el-table-column>
          <el-table-column prop="type1" label="绑定日期">
          </el-table-column>
          <el-table-column prop="type2" label="激活日期">
          </el-table-column>
          <el-table-column prop="type2" label="所属科室">
          </el-table-column>
          <el-table-column prop="type2" label="使用者">
          </el-table-column>
          <el-table-column prop="type2" label="状态">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex flex1">
                <span @click="openSeeData">查看数据</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增设备 -->
    <AddDevice v-if="showAddDevice" @close="closeAddDevice"></AddDevice>
    <!-- 查看数据 -->
    <SeeData v-if="showSeeData" @close="closeSeeData"></SeeData>
  </div>
</template>

<script>
import AddDevice from './addDevice.vue'
import SeeData from './seeData.vue'
export default {
  components: {
    AddDevice,
    SeeData
  },
  data () {
    return {
      tableData: [
        { name: '111' },
        { name: '111' },
        { name: '111' },
        { name: '111' },
        { name: '111' },
        { name: '111' },
        { name: '111' },
        { name: '111' },
        { name: '111' },
        { name: '111' },
        { name: '111' },
        { name: '111' },
        { name: '111' },
        { name: '111' }
      ],
      value: '',
      isSwitch: false,
      options: [{
        value: '',
        label: ''
      }],
      showAddDevice: false,
      showSeeData: false
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openAddDevice () {
      this.showAddDevice = true
    },
    closeAddDevice () {
      this.showAddDevice = false
    },
    openSeeData () {
      this.showSeeData = true
    },
    closeSeeData () {
      this.showSeeData = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .switch {
    display: flex;
    width: 100%;
    height: 40px;

    .item {
      height: 40px;
      line-height: 40px;
      margin-right: 20px;
      padding: 0 10px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      background-color: rgba(115, 115, 115, .4);
      cursor: pointer;
    }

    .item1 {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  .content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 50px);
    margin-top: 10px;
    border-bottom: 4px solid #000;
    box-sizing: border-box;
    border-bottom: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    .line {
      width: 100%;
      padding: 20px;
      border: 4px solid #000;
      box-sizing: border-box;

      .title {
        color: #01EEFD;
        font-size: 20px;
      }

      .count {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        margin-top: 10px;

        .count-item {
          display: flex;
          align-items: center;
          width: 360px;
          height: 40px;
          margin-right: 20px;
          background: linear-gradient(79deg, #0A477B 0%, #0E67AD 100%);
          color: #fff;
          font-size: 18px;

          img {
            width: 30px;
            height: 30px;
            margin: 0 20px;
          }
        }
      }

      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;

        .width {
          width: 246px;
        }

        .el-select {
          width: 120px;
          margin-left: 10px;
        }

        .search-left {
          display: flex;
          align-items: center;

          .margin-left {
            margin-left: 10px;
          }

          //按钮文字显示
          .el-switch {
            // height: 25px !important;
          }

          ::v-deep .el-switch__core {
            // width: 130px !important;
            height: 24px;
            padding-left: 30px;
            padding-right: 90px;
            border-radius: 100px;
            border: none;
          }

          ::v-deep .el-switch__core::after {
            width: 20px;
            height: 20px;
            top: 2px;
          }

          ::v-deep .el-switch.is-checked .el-switch__core::after {
            margin-left: -21px;
          }

          /*关闭时文字位置设置*/
          ::v-deep .el-switch__label--right {
            position: absolute;
            z-index: 1;
            right: 6px;
            margin-left: 0px;
            color: rgba(255, 255, 255, 0.9);

            span {
              font-size: 16px;
            }
          }

          /* 激活时另一个文字消失 */
          ::v-deep .el-switch__label.is-active {
            display: none;
          }

          /*开启时文字位置设置*/
          ::v-deep .el-switch__label--left {
            position: absolute;
            z-index: 1;
            left: 5px;
            margin-right: 0px;
            color: rgba(255, 255, 255, 0.9);

            span {
              font-size: 16px;
            }
          }
        }

        .search-right {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    .border-none {
      border-bottom: none;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 40px;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      color: #0e75d4;
      font-size: 15px;

      span {
        cursor: pointer;
      }
    }

    .flex1 {
      justify-content: center;
    }

    .content-box {
      width: 100%;
      height: 100%;
      padding: 20px;
      border: 4px solid #073F73;
      box-sizing: border-box;

      .content-box-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 260px;
          }

          .el-select {
            width: 200px;
            margin-left: 10px;
          }

          .date {
            margin-left: 10px;
          }

          .blue,
          .gray {
            margin-left: 40px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            background-color: #284867;
          }
        }

        .right {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
