<template>
  <div class="info">
    <div class="info-box">
      <div class="info-content">
        <div class="info-close" @click="close">
          <span class="el-icon-close"></span>
        </div>
        <div class="info-tab">
          <span class="info-item" :class="{ 'info-active': tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">{{ item }}</span>
        </div>
        <div class="info-item-box">
          <!-- 首页 -->
          <Tab1 v-if="tabIndex === 0"></Tab1>
          <!-- 诊疗服务 -->
          <Tab2 v-else-if="tabIndex === 1"></Tab2>
          <!-- 公卫服务 -->
          <Tab3 v-else-if="tabIndex === 2"></Tab3>
          <!-- 家医签约 -->
          <Tab4 v-else-if="tabIndex === 3"></Tab4>
          <!-- 体检记录 -->
          <Tab5 v-else-if="tabIndex === 4"></Tab5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab1 from './tab1/tab1.vue'
import Tab2 from './tab2/tab2.vue'
import Tab3 from './tab3/tab3.vue'
import Tab4 from './tab4/tab4.vue'
import Tab5 from './tab5/tab5.vue'
export default {
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['首页', '诊疗服务', '公卫服务', '家医签约', '体检记录'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .92);
  // background-color: #fff;

  .info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 100px);
    height: 100%;
    border: 4px solid rgba(1, 124, 219, .27);
    // background-color: #F0F0F0;
    background-color: #000;
    box-sizing: border-box;

    .info-content {
      overflow: hidden;
      position: relative;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      padding: 0 16px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      .info-close {
        position: absolute;
        top: -20px;
        right: -30px;
        width: 118px;
        height: 55px;
        padding-left: 10px;
        line-height: 70px;
        text-align: center;
        transform: rotate(30deg);
        background: linear-gradient(117deg, #1E58A6 0%, #1C4EA1 53%, #2866B1 100%);
        box-shadow: inset 0px -13px 0px 1px #2661AB;
        color: #fff;
        font-size: 26px;
        box-sizing: border-box;

        span {
          transform: rotate(-30deg);
          cursor: pointer;
        }
      }

      .info-tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 80%;
        height: 50px;
        padding-left: 108px;
        border-bottom: 1px solid #0C4969;
        box-sizing: border-box;

        .info-item {
          margin-right: 89px;
          color: #fff;
          // color: #000;
          font-size: 20px;
          cursor: pointer;
        }

        .info-active {
          color: #01EEFD;
        }
      }

      .info-item-box {
        width: 100%;
        height: calc(100% - 80px);
        margin-top: 13px;
      }
    }
  }
}
</style>
