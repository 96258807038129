<template>
  <div class="tab">
    <div class="tab-scroll">
      <div class="tab-title">
        <span>心电图结果报告</span>
      </div>
      <div class="info">
        <div class="line border">
          <div class="item">
            <span>编号：{{ info.jktjid || '' }}</span>
          </div>
          <div class="item">
            <span>姓名：-</span>
          </div>
          <div class="item">
            <span>性别:-</span>
          </div>
          <div class="item">
            <span>身份证:-</span>
          </div>
          <div class="item">
            <span>电话:-</span>
          </div>
        </div>
        <img v-if="info.rpturl" :src="info.rpturl"  class="img">
        <div v-else class="img"></div>
        <div class="line3">
          <div class="text">
            <span>结果</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
          </el-radio-group>
          <div class="text text1">
            <span>异常原因：</span>
          </div>
          <el-input placeholder="请输入异常原因"></el-input>
          <div class="text text1">
            <span>报告者：-</span>
          </div>
        </div>
      </div>
      <div class="son-bottom">
        <el-button @click="noClick">编辑</el-button>
        <el-button @click="noClick">保存</el-button>
        <el-button @click="noClick">审核</el-button>
        <el-button @click="noClick">上传</el-button>
        <el-button @click="noClick">打印</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: '',
          jktjid: ''
        }
      }
    }
  },
  data () {
    return {
      checkList: [],
      radio: '',
      info: {
        jktjid: '',
        jktjts: '',
        tjms: '',
        rpturl: ''
      }
    }
  },
  created () {
    console.log('心电图参数：', this.params)
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591729
      try {
        const obj = {
          jktjid: this.params.tmcode || ''
        }
        const { data: res } = await this.$http.post('/ykdxdts/finds', obj)
        console.log('心电图报告--查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        this.info = res.data
      } catch (error) {
        console.log('心电图报告--查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    noClick () {
      this.$message.error('未开放')
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 0 50px;
  box-sizing: border-box;

  .tab-scroll {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tab-title {
      width: 100%;
      height: 54px;
      line-height: 54px;
      text-align: center;
      color: #475265;
      font-size: 22px;
    }

    .info {
      display: flex;
      flex-direction: column;
      width: 100%;

      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        padding: 0 26px;

        .text {
          color: #2A374D;
          font-size: 16px;
        }

        .item {
          flex: 1;
          text-align: center;
          color: #2A374D;
          font-size: 16px;
        }
      }

      .border {
        border-bottom: 1px solid #B1B1B1;
        box-sizing: border-box;
      }

      .line3 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        padding: 0 26px;
        border-top: 1px solid #B1B1B1;
        box-sizing: border-box;

        .text {
          color: #2A374D;
          font-size: 16px;
        }

        .text1 {
          margin-left: 20px;
        }

        .el-radio-group {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }

        /deep/.el-radio {
          display: flex;
          align-items: center;
        }

        /deep/.el-radio__label {
          color: #2A374D;
          font-size: 16px;
        }

        .el-input {
          width: 200px;
        }
      }

      .img {
        width: 600px;
        height: 420px;
        margin: 20px auto;
        border: 1px solid #000;
      }
    }

    .son-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
      border-bottom: 1px solid #475265;

      .el-button {
        min-width: 120px;
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 6px;
        padding-bottom: 6px;
        background-color: #3782F2;
        border: none;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .son-bottom1 {
      height: 60px;
      justify-content: flex-end;
    }

  }

}
</style>
