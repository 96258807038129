<template>
  <div class="exerciseDiet">
    <div class="run-search">
      <div class="run-item">
        <div class="key">
          <span>姓名</span>
        </div>
        <el-input placeholder="请输入" v-model="search.name"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>身份证</span>
        </div>
        <el-input placeholder="请输入" v-model="search.sfzh"></el-input>
      </div>
      <div class="run-item">
        <div class="key">
          <span>机构名称</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="run-item">
        <div class="key">
          <span>预警分类</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="run-item">
        <div class="key">
          <span>预警状态</span>
        </div>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary">搜索</el-button>
      <el-button type="primary" @click="clear">重置</el-button>
    </div>
    <div class="exerciseDiet-box">
      <div class="diet-left">
        <UserBasicInfo></UserBasicInfo>
      </div>
      <div class="diet-right">
        <div class="tab">
          <span :class="{ span: tabIndex === 0 }" @click="tabClick(0)">安防异常</span>
          <span :class="{ span: tabIndex === 1 }" @click="tabClick(1)">健康异常</span>
        </div>
        <div class="scroll">
          <div class="info">
            <div class="table">
              <!-- 序号 id  预警来源  lytype  预警分类 msgtype 预警模块  mkmc  房间号  床位号   姓名 name  身份证号 sfzh  服务内容 fwnr   预警信息 msg  机构名称  tjjgmc 上报人  usercode   上边时间 fwsj  处置人 czr   处置意见  czryj -->

              <el-table :data="tableData" height="100%" border>
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="name" label="编号"></el-table-column>
                <el-table-column label="房间号"></el-table-column>
                <el-table-column label="姓名"></el-table-column>
                <el-table-column label="预警内容"></el-table-column>
                <el-table-column label="预警级别"></el-table-column>
                <el-table-column label="健康数据">
                  <template>
                    <span class="red">异常</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template>
                    <!-- <div class="flex">
                      <span @click="openWarning">提醒</span>
                      <span>删除</span>
                    </div> -->
                    <el-button type="primary" size="mini">提醒</el-button>
                    <el-button type="danger" size="mini">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="pagination">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
                background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
                :total="400">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BasicInfoPopup v-if="showBasicInfoPopup" @close="closeBasicInfoPopup"></BasicInfoPopup>
    <Warning v-if="showWarning" @close="closeWarning"></Warning>
  </div>
</template>

<script>
import BasicInfoPopup from '../monitoring/basicInfoPopup.vue'
import Warning from './warning.vue'
import UserBasicInfo from '../../../components/user-basic-info/user-basic-info.vue'

export default {
  components: {
    BasicInfoPopup,
    Warning,
    UserBasicInfo
  },
  data () {
    return {
      showBasicInfoPopup: false,
      showWarning: false,
      tableData: [],
      tabIndex: 0,
      options: [],
      value: '',
      search: {
        name: '',
        sfzh: ''
      }
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    clear () {
      this.search.name = ''
      this.search.sfzh = ''
    },
    handleSizeChange () {

    },
    handleCurrentChange () {

    },
    openBasicInfoPopup () {
      this.showBasicInfoPopup = true
    },
    closeBasicInfoPopup () {
      this.showBasicInfoPopup = false
    },
    openWarning () {
      this.showWarning = true
    },
    closeWarning () {
      this.showWarning = false
    }
  }
}
</script>

<style lang="less" scoped>
.exerciseDiet {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .run-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    padding: 0 10px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .run-item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 18px;
      }
    }
  }

  .exerciseDiet-box {
    display: flex;
    width: 100%;
    height: calc(100% - 80px);
  }

  .diet-left {
    width: 270px;
    height: 99%;
    border-radius: 6px;

    .title {
      width: 100%;
      height: 35px;
      line-height: 35px;
      padding-left: 34px;
      padding-top: 5px;
      box-sizing: border-box;
      background: url('../img/dialogTitleBg.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }

    .search {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .el-input,
      ::v-deep .el-input__inner {
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        border: none;
        border-radius: 6px;
        color: #fff;

        &::-webkit-input-placeholder {
          color: #fff;
          font-size: 18px;
        }
      }

      ::v-deep .el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }

    .scroll {
      overflow: scroll;
      width: 100%;
      height: calc(100% - 100px);
      margin-top: 5px;
      border-radius: 6px;
      background-color: rgba(29, 64, 112, .33);

      .list {
        display: flex;
        align-items: center;
        width: 610px;
        height: 40px;
        color: #01EEFD;
        font-size: 16px;
        cursor: pointer;

        div {
          width: 60px;
          text-align: center;
        }

        .flex2 {
          width: 100px;
        }

        .flex3 {
          width: 150px;
        }

        .flex4 {
          width: 300px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .movetop {
        height: calc(100% - 100px);
      }

      .color {
        position: sticky;
        top: 0;
        left: 0;
        padding: 4px 0;
        z-index: 88;
        background-color: rgba(14, 40, 75, 1);
      }
    }
  }

  .diet-right {
    width: calc(100% - 280px);
    height: 100%;
    margin-left: 10px;

    .tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #000;

      span {
        height: 38px;
        line-height: 38px;
        margin-right: 16px;
        padding: 0 9px;
        color: #fff;
        font-size: 22px;
        background-color: #D5D5D5;
        cursor: pointer;
      }

      .span {
        background-color: #409EFF;
;
      }
    }

    .scroll {
      width: 100%;
      height: calc(100% - 70px);
      margin-top: 10px;

      .info {
        position: relative;
        width: 100%;
        height: 100%;
        min-width: 1100px;

        .query {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-left: 30px;
          box-sizing: border-box;

          .item {
            display: flex;
            align-items: center;
            width: 25%;
            height: 38px;
            margin-bottom: 20px;
            color: #04B4D0;
            font-size: 18px;

            .key {
              width: 82px;
              margin-right: 10px;
            }

            .el-input,
            .el-date-picker {
              width: 156px;
              height: 38px;
              line-height: 38px;
            }

            .margin {
              margin-right: 30px;
            }

            .el-button {
              width: 80px;
              height: 38px;
              line-height: 38px;
              text-align: center;
              margin-right: 50px;
              padding: 0;
              border: 0;
              border-radius: 0;
              color: #fff;
              font-size: 18px;
              background-color: #D5D5D5;
            }

            .blue {
              background-color: #01EEFD;
            }
          }

          .item1 {
            width: 50%;
          }
        }

        .add {
          display: flex;
          align-items: center;
          width: 100%;
          height: 46px;
          // padding-left: 30px;
          // box-sizing: border-box;

          .el-button {
            height: 26px;
            line-height: 26px;
            margin-right: 26px;
            padding: 0 21px;
            background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
            border-radius: 0;
            border: 0;
            color: #fff;
            font-size: 14px;
          }
        }

        .table {
          width: 100%;
          height: calc(100% - 100px);
        }

        /deep/.el-table .cell {
          text-align: center;
          color: #000;
        }

        .flex {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;
          font-size: 16px;
          color: #0077F9;

          span {
            cursor: pointer;
          }
        }

        .red {
          color: #FF2A2A;
        }

        .pagination {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
