var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"health"},[_c('div',{staticClass:"health-box"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"info"},[_c('div',{staticClass:"line"},[_vm._m(2),_vm._m(3),_c('el-input',{model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"line"},[_vm._m(4),_vm._m(5),_c('el-input',{model:{value:(_vm.xzz),callback:function ($$v) {_vm.xzz=$$v},expression:"xzz"}})],1),_c('div',{staticClass:"line"},[_vm._m(6),_c('el-input',{model:{value:(_vm.hjdz),callback:function ($$v) {_vm.hjdz=$$v},expression:"hjdz"}})],1),_c('div',{staticClass:"line"},[_vm._m(7),_c('el-input',{model:{value:(_vm.lxdh),callback:function ($$v) {_vm.lxdh=$$v},expression:"lxdh"}})],1),_c('div',{staticClass:"line"},[_vm._m(8),_c('el-input',{model:{value:(_vm.xzmc),callback:function ($$v) {_vm.xzmc=$$v},expression:"xzmc"}})],1),_c('div',{staticClass:"line"},[_vm._m(9),_c('el-input',{model:{value:(_vm.cmc),callback:function ($$v) {_vm.cmc=$$v},expression:"cmc"}})],1)]),_c('div',{staticClass:"info info1"},[_c('div',{staticClass:"line"},[_vm._m(10),_c('el-input',{model:{value:(_vm.jddw),callback:function ($$v) {_vm.jddw=$$v},expression:"jddw"}})],1),_c('div',{staticClass:"line"},[_vm._m(11),_vm._m(12),_c('el-input',{model:{value:(_vm.jdr),callback:function ($$v) {_vm.jdr=$$v},expression:"jdr"}})],1),_c('div',{staticClass:"line"},[_vm._m(13),_c('el-input',{model:{value:(_vm.zrys),callback:function ($$v) {_vm.zrys=$$v},expression:"zrys"}})],1),_c('div',{staticClass:"line"},[_vm._m(14),_c('el-input',{model:{value:(_vm.jdrqn),callback:function ($$v) {_vm.jdrqn=$$v},expression:"jdrqn"}}),_vm._m(15),_c('el-input',{model:{value:(_vm.jdrqy),callback:function ($$v) {_vm.jdrqy=$$v},expression:"jdrqy"}}),_vm._m(16),_c('el-input',{model:{value:(_vm.jdrqr),callback:function ($$v) {_vm.jdrqr=$$v},expression:"jdrqr"}}),_vm._m(17)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('span',[_vm._v("编号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("居民健康档案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key key1"},[_c('span',[_vm._v("姓名")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key key1"},[_c('span',[_vm._v("现住址")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("户籍地址：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("联系电话：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("乡镇（街道）名称：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("村（居）委会名称：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("建档单位：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key key1"},[_c('span',[_vm._v("建档人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("责任医生：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("建档日期：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("月")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key"},[_c('span',[_vm._v("日")])])
}]

export { render, staticRenderFns }