<template>
  <div class="tab">
    <div class="tab-scroll">
      <div class="tab-title">
        <span>心电图结果报告</span>
      </div>
      <div class="info">
        <div class="line border">
          <div class="item">
            <span>编号：304076981</span>
          </div>
          <div class="item">
            <span>姓名：李明明</span>
          </div>
          <div class="item">
            <span>性别:男</span>
          </div>
          <div class="item">
            <span>身份证:130429193205164852   </span>
          </div>
          <div class="item">
            <span>电话:17367759256</span>
          </div>
        </div>
        <div class="img"></div>
        <div class="line3">
          <div class="text">
            <span>结果</span>
          </div>
          <el-radio-group v-model="radio">
            <el-radio label="1">正常</el-radio>
            <el-radio label="2">异常</el-radio>
          </el-radio-group>
          <div class="text text1">
            <span>异常原因：</span>
          </div>
          <el-input placeholder="请输入异常原因"></el-input>
          <div class="text text1">
            <span>报告者：马依依</span>
          </div>
        </div>
      </div>
      <div class="son-bottom">
        <el-button>编辑</el-button>
        <el-button>保存</el-button>
        <el-button>审核</el-button>
        <el-button>上传</el-button>
        <el-button>打印</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      checkList: [],
      radio: ''
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 0 50px;
  box-sizing: border-box;

  .tab-scroll {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tab-title {
      width: 100%;
      height: 54px;
      line-height: 54px;
      text-align: center;
      color: #475265;
      font-size: 22px;
    }

    .info {
      display: flex;
      flex-direction: column;
      width: 100%;

      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        padding: 0 26px;

        .text {
          color: #2A374D;
          font-size: 16px;
        }

        .item {
          flex: 1;
          text-align: center;
          color: #2A374D;
          font-size: 16px;
        }
      }

      .border {
        border-bottom: 1px solid #B1B1B1;
        box-sizing: border-box;
      }

      .line3 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        padding: 0 26px;
        border-top: 1px solid #B1B1B1;
        box-sizing: border-box;

        .text {
          color: #2A374D;
          font-size: 16px;
        }

        .text1 {
          margin-left: 20px;
        }

        .el-radio-group {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }

        /deep/.el-radio {
          display: flex;
          align-items: center;
        }

        /deep/.el-radio__label {
          color: #2A374D;
          font-size: 16px;
        }

        .el-input {
          width: 200px;
        }
      }

      .img {
        width: 600px;
        height: 420px;
        margin: 20px auto;
        border: 1px solid #000;
      }
    }

    .son-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
      border-bottom: 1px solid #475265;

      .el-button {
        min-width: 120px;
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 6px;
        padding-bottom: 6px;
        background-color: #3782F2;
        border: none;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .son-bottom1 {
      height: 60px;
      justify-content: flex-end;
    }

  }

}
</style>
