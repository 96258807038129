<template>
  <div class="son-page">
    <div class="head">
      <el-button type="primary">体检记录</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="input input1">
      <div class="item">
        <div class="key">
          <span>管辖范围</span>
        </div>
        <el-select v-model="value" placeholder="我管辖的档案">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>个人档案编号</span>
        </div>
        <el-input></el-input>
        <el-select v-model="value" placeholder="医疗机构" class="margin">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-radio v-model="radio" label="1">只查本级</el-radio>
      <div class="item">
        <div class="key">
          <span>建档日期范围</span>
        </div>
        <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="item">
        <el-select v-model="value" placeholder="身份证">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div class="key">
          <span>:</span>
        </div>
        <el-input></el-input>
      </div>
      <div class="item">
        <el-button type="primary">查询</el-button>
        <el-button type="primary">清空</el-button>
      </div>
    </div>
    <div class="input">
      <el-button type="primary" size="small">新增</el-button>
    </div>
    <el-table :data="list1" border height="50%">
      <el-table-column type="selection" width="50">
      </el-table-column>
      <el-table-column prop="id" label="序号" :width="flexWidth('id', list1, '序号')">
      </el-table-column>
      <el-table-column prop="name" label="姓名" :width="flexWidth('name', list1, '姓名')">
      </el-table-column>
      <el-table-column prop="xb" label="性别" :width="flexWidth('xb', list1, '性别')">
        <template slot-scope="scope">
          <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="age" label="年龄" :width="flexWidth('age', list1, '年龄')">
      </el-table-column>
      <el-table-column prop="sfzh" label="身份证号" :width="flexWidth('sfzh', list1, '身份证号')">
      </el-table-column>
      <el-table-column prop="tjbh" label="体检编号" :width="flexWidth('tjbh', list1, '体检编号')">
      </el-table-column>
      <el-table-column prop="tjrq" label="体检日期" :width="flexWidth('tjrq', list1, '体检日期')">
      </el-table-column>
      <el-table-column prop="tjjgmc" label="体检乡镇/社区" :width="flexWidth('tjjgmc', list1, '体检乡镇/社区')">
      </el-table-column>
      <el-table-column prop="tjjgcmhmc" label="体检村/服务站" :width="flexWidth('tjjgcmhmc', list1, '体检村/服务站')">
      </el-table-column>
      <el-table-column prop="date" label="档案乡镇/社区" :width="flexWidth('date', list1, '档案乡镇/社区')">
      </el-table-column>
      <el-table-column prop="date" label="档案村/服务站" :width="flexWidth('date', list1, '档案村/服务站')">
      </el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <div class="operate">
            <span @click="openUpdate(scope.row)">查看</span>
            <span @click="openUpdate(scope.row)">编辑</span>
            <span>查体报告</span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum"
        background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total">
      </el-pagination>
    </div>

    <Services :params="params" v-if="showUpdate" @close="closeUpdate"></Services>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Services from './services.vue'
export default {
  props: {
    selectSfzh: {
      type: String,
      default: ''
    },
    xzjdmcid: {
      type: String,
      default: ''
    },
    cwhmcid: {
      type: String,
      default: ''
    }
  },
  components: {
    Services
  },
  data () {
    return {
      options: [],
      value: '',
      radio: '',
      value1: '',
      showUpdate: false,
      list1: [],
      showList1: [],
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        tmcode: '',
        sfzh: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.sfzh = this.selectSfzh
    this.getList1()
  },
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) {
        return title.length * 30 + 'px'
      }
      let flexWidth = 0
      let columnContent = ''
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) {
        columnContent = title
      } else {
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openUpdate (obj) {
      this.params.tmcode = obj.tmcode || ''
      this.params.sfzh = obj.sfzh || ''
      this.params.grjbxxid = obj.grjbxxid || ''
      this.params.jktjid = obj.jktjid || ''
      this.showUpdate = true
    },
    closeUpdate () {
      this.params.tmcode = ''
      this.params.sfzh = ''
      this.params.grjbxxid = ''
      this.showUpdate = false
    },
    handleSizeChange (val) {},
    handleCurrentChange (val) {
      this.query1.pageNum = val
      this.getList1()
    },
    // 体检记录
    async getList1 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97904037
      try {
        this.list1 = []
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: '10',
          ssjgbh: this.loginInfo.jgbh,
          xzjdmcid: this.xzjdmcid,
          cwhmcid: this.cwhmcid,
          flag: '',
          sfzh: this.sfzh
        }
        const { data: res } = await this.$http.get('/ykdjktjbs', { params: obj, headers: { token: this.loginInfo.token } })
        console.log('体检记录', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list1 = res.data
        this.query1.total = res.recordsTotal
      } catch (error) {
        console.log('体检记录请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.son-page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: calc(100% - 10px);
  height: 100%;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
      }
    }

    /deep/ .el-input__suffix {
      color: #000;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 10px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 16px;
      }

      .margin {
        margin-left: 20px;
      }

      .green {
        width: 90px;
        height: 38px;
        line-height: 38px;
        padding: 0;
        margin: 0;
        margin-right: 20px;
        text-align: center;
        background-color: #04B4D0;
        color: #fff;
        font-size: 16px;
      }
    }

    .search {
      width: 300px;
    }
  }

  .input1 {
    flex-wrap: wrap;
    height: 120px;
    border-bottom: 1px solid #000;
  }

  .el-table {
    margin-top: 20px;
  }

  .operate {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    span {
      cursor: pointer;
      color: #0077F9;
    }
  }

  /deep/.el-table thead {
    color: #000;
  }

  /deep/.el-table__cell {
    text-align: center;
    color: #000;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
  }
}
</style>
