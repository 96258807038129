<template>
  <div class="progress">
    <SonHead></SonHead>
    <div class="progress-box">
      <div class="progress-top">
        <div class="progress-item" @click="tagClick(0)">
          <img src="../../../../../assets/img/progress1.png" class="progress-img">
          <span class="progress-text">任务数</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(1)">
          <img src="../../../../../assets/img/progress1.png" class="progress-img">
          <span class="progress-text">已打码人数</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(2)">
          <img src="../../../../../assets/img/progress2.png" class="progress-img">
          <span class="progress-text">身高</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(3)">
          <img src="../../../../../assets/img/progress2.png" class="progress-img">
          <span class="progress-text">体重</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(4)">
          <img src="../../../../../assets/img/progress3.png" class="progress-img">
          <span class="progress-text">体温</span>
          <span class="progress-number red">0</span>
        </div>
        <div class="progress-item" @click="tagClick(5)">
          <img src="../../../../../assets/img/progress4.png" class="progress-img">
          <span class="progress-text">血压</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(6)">
          <img src="../../../../../assets/img/progress5.png" class="progress-img">
          <span class="progress-text">中医问诊</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(7)">
          <img src="../../../../../assets/img/progress6.png" class="progress-img">
          <span class="progress-text">采血</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(8)">
          <img src="../../../../../assets/img/progress7.png" class="progress-img">
          <span class="progress-text">B超检查</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(9)">
          <img src="../../../../../assets/img/progress8.png" class="progress-img">
          <span class="progress-text">心电检查</span>
          <span class="progress-number red">0</span>
        </div>
        <div class="progress-item" @click="tagClick(10)">
          <img src="../../../../../assets/img/progress4.png" class="progress-img">
          <span class="progress-text">血常规</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(11)">
          <img src="../../../../../assets/img/progress4.png" class="progress-img">
          <span class="progress-text">血生化</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(12)">
          <img src="../../../../../assets/img/progress9.png" class="progress-img">
          <span class="progress-text">尿常规</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(13)">
          <img src="../../../../../assets/img/progress10.png" class="progress-img">
          <span class="progress-text">报告人数</span>
          <span class="progress-number">0</span>
        </div>
        <div class="progress-item" @click="tagClick(14)">
          <span class="progress-icon el-icon-upload"></span>
          <span class="progress-text">报告人数</span>
          <span class="progress-number">0</span>
        </div>
      </div>
      <div class="progress-bottom">
        <div class="progress-table">
          <div class="progress-table-head">
            <span>体征</span>
            <img src="../../../../../assets/img/progress-table1.png" class="icon" @click="tabClick(0)">
          </div>
          <div class="progress-table-box">
            <div class="progress-table-list" v-for="(item, index) in 8" :key="index">
              <span class="span">张三</span>
              <span class="span">2022-12-10</span>
              <span class="span">类型:联网</span>
              <span class="span">测量人:张译</span>
              <span class="span">36.5°</span>
              <span class="span">175cm</span>
              <span class="span">65kg</span>
            </div>
          </div>
        </div>

        <div class="progress-table">
          <div class="progress-table-head">
            <span>血压</span>
            <img src="../../../../../assets/img/progress-table2.png" class="icon" @click="tabClick(1)">
          </div>
          <div class="progress-table-box">
            <div class="progress-table-list" v-for="(item, index) in 8" :key="index">
              <span class="span">张三</span>
              <span class="span">2022-12-10</span>
              <span class="span">类型:联网</span>
              <span class="span">测量人:张译</span>
              <span class="span">高压:120mmHg</span>
              <span class="span">低压:120mmHg</span>
            </div>
          </div>
        </div>

        <div class="progress-table">
          <div class="progress-table-head">
            <span>中医问诊</span>
            <img src="../../../../../assets/img/progress-table2.png" class="icon" @click="tabClick(2)">
          </div>
          <div class="progress-table-box">
            <div class="progress-table-list" v-for="(item, index) in 8" :key="index">
              <span class="span">张三</span>
              <span class="span">2022-12-10</span>
              <span class="span">类型:联网</span>
              <span class="span">问诊人:张译</span>
            </div>
          </div>
        </div>

        <div class="progress-table">
          <div class="progress-table-head">
            <span>采血</span>
            <img src="../../../../../assets/img/progress-table4.png" class="icon" @click="tabClick(3)">
          </div>
          <div class="progress-table-box">
            <div class="progress-table-list" v-for="(item, index) in 8" :key="index">
              <span class="span">张三</span>
              <span class="span">2022-12-10</span>
              <span class="span">类型:联网</span>
              <span class="span">采血人:张译</span>
            </div>
          </div>
        </div>

        <div class="progress-table">
          <div class="progress-table-head">
            <span>B超检查</span>
            <img src="../../../../../assets/img/progress-table3.png" class="icon" @click="tabClick(4)">
          </div>
          <div class="progress-table-box">
            <div class="progress-table-list" v-for="(item, index) in 8" :key="index">
              <span class="span">张三</span>
              <span class="span">2022-12-10</span>
              <span class="span">类型:联网</span>
              <span class="span">检查人:张译</span>
            </div>
          </div>
        </div>

        <div class="progress-table">
          <div class="progress-table-head">
            <span>心电检查</span>
            <img src="../../../../../assets/img/progress-table5.png" class="icon" @click="tabClick(5)">
          </div>
          <div class="progress-table-box">
            <div class="progress-table-list" v-for="(item, index) in 8" :key="index">
              <span class="span">张三</span>
              <span class="span">2022-12-10</span>
              <span class="span">类型:联网</span>
              <span class="span">检查人:张译</span>
            </div>
          </div>
        </div>

        <div class="progress-table">
          <div class="progress-table-head">
            <span>血常规</span>
            <img src="../../../../../assets/img/progress-table7.png" class="icon" @click="tabClick(6)">
          </div>
          <div class="progress-table-box">
            <div class="progress-table-list" v-for="(item, index) in 8" :key="index">
              <span class="span">张三</span>
              <span class="span">2022-12-10</span>
              <span class="span">类型:联网</span>
              <span class="span">测量人:张译</span>
            </div>
          </div>
        </div>

        <div class="progress-table">
          <div class="progress-table-head">
            <span>血生化</span>
            <img src="../../../../../assets/img/progress-table7.png" class="icon" @click="tabClick(7)">
          </div>
          <div class="progress-table-box">
            <div class="progress-table-list" v-for="(item, index) in 8" :key="index">
              <span class="span">张三</span>
              <span class="span">2022-12-10</span>
              <span class="span">类型:联网</span>
              <span class="span">测量人:张译</span>
              <span class="span">3.8mmol/L</span>
            </div>
          </div>
        </div>
        <div class="progress-table">
          <div class="progress-table-head">
            <span>尿常规</span>
            <img src="../../../../../assets/img/progress-table8.png" class="icon" @click="tabClick(8)">
          </div>
          <div class="progress-table-box">
            <div class="progress-table-list" v-for="(item, index) in 8" :key="index">
              <span class="span">张三</span>
              <span class="span">2022-12-10</span>
              <span class="span">类型:联网</span>
              <span class="span">测量人:张译</span>
              <span>2~6umol/L</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Message v-if="showMessage" :messageIndex="tagIndex" @close="closeMessage"></Message>
    <Message1 v-if="showMessage1" :messageIndex="tabIndex" @close="closeMessage1"></Message1>
  </div>
</template>

<script>
import SonHead from '../../../../../components/son-head/son-head.vue'
import Message from './message.vue'
import Message1 from './message1.vue'
export default {
  components: {
    SonHead,
    Message,
    Message1
  },
  data () {
    return {
      tagIndex: '',
      showMessage: false,
      tabIndex: '',
      showMessage1: false
    }
  },
  methods: {
    tagClick (index) {
      this.tagIndex = index + ''
      this.showMessage = true
    },
    closeMessage () {
      this.showMessage = false
    },
    tabClick (index) {
      this.tabIndex = index + ''
      this.showMessage1 = true
    },
    closeMessage1 () {
      this.showMessage1 = false
    }
  }
}
</script>

<style lang="less" scoped>
  .progress {
    width: 100%;
    height: 100%;
    background-color: #F0F0F0;

    .progress-box {
      width: 100%;
      height: calc(100% - 90px);
      padding: 0 15px;
      box-sizing: border-box;

      .progress-top {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 160px;
        border-radius: 18px;
        background-color: #fff;

        .progress-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          .progress-img {
            width: 40px;
            height: 40px;
          }

          .progress-text {
            margin: 6px 0;
            color: #68696A;
            font-size: 16px;
          }

          .progress-number {
            color: #3FA1FF;
            font-size: 24px;
          }

          .red {
            color: #FF6262;
          }

          .progress-icon {
            color: #66B4FF;
            font-size: 40px;
          }
        }
      }

      .progress-bottom {
        display: flex;
        align-content: space-evenly;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        height: calc(100% - 160px);

        .progress-table {
          overflow: hidden;
          width: 32%;
          height: 30%;
          border-radius: 25px;
          background-color: #fff;

          .progress-table-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 43px;
            padding: 0 40px;
            background-color: #006EFF;
            box-sizing: border-box;
            color: #fff;
            font-size: 18px;
            font-weight: 600;

            .icon {
              width: 30px;
              height: 30px;
              cursor: pointer;
            }
          }

          .progress-table-box {
            overflow-y: scroll;
            width: 100%;
            height: calc(100% - 43px);

            &::-webkit-scrollbar {
              width: 0;
            }

            .progress-table-list {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              width: 100%;
              margin: 10px 0;
              // padding: 0 10px;
              box-sizing: border-box;
              color: #68696A;
              font-size: 14px;

              .span {
                // margin: 0 5px;
              }
            }
          }
        }
      }
    }
  }
</style>
