<template>
  <div class="rule">
    <div class="title">
      <span>密码规则</span>
    </div>
    <div class="box">
      <div class="item">
        <span class="span">默认密码</span>
        <el-input class="width"></el-input>
        <el-button>修改</el-button>
      </div>
    </div>
    <div class="title">
      <span>血糖报警</span>
    </div>
    <div class="box">
      <div class="item">
        <span class="span">早餐前</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">早餐后</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">午餐前</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">午餐后</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">晚餐前</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">晚餐后</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">睡前</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">凌晨</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">随机</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">特殊</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item1">
        <div class="btns">
          <el-button>修改</el-button>
        </div>
      </div>
    </div>

    <div class="title">
      <span>血糖预警</span>
    </div>
    <div class="box">
      <div class="item">
        <span class="span">早餐前</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">早餐后</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">午餐前</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">午餐后</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">晚餐前</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">晚餐后</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">睡前</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">凌晨</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">随机</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item">
        <span class="span">特殊</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">mmol/L</span>
      </div>
      <div class="item1">
        <div class="btns">
          <el-button>修改</el-button>
        </div>
      </div>
    </div>

    <div class="title">
      <span>用餐时段</span>
    </div>
    <div class="box">
      <div class="item">
        <span class="span">早餐前</span>
        <el-time-picker class="width1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
        <el-time-picker class="width1 span1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
      </div>
      <div class="item">
        <span class="span">早餐后</span>
        <el-time-picker class="width1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
        <el-time-picker class="width1 span1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
      </div>
      <div class="item">
        <span class="span">午餐前</span>
        <el-time-picker class="width1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
        <el-time-picker class="width1 span1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
      </div>
      <div class="item">
        <span class="span">午餐后</span>
        <el-time-picker class="width1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
        <el-time-picker class="width1 span1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
      </div>
      <div class="item">
        <span class="span">晚餐前</span>
        <el-time-picker class="width1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
        <el-time-picker class="width1 span1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
      </div>
      <div class="item">
        <span class="span">晚餐后</span>
        <el-time-picker class="width1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
        <el-time-picker class="width1 span1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
      </div>
      <div class="item">
        <span class="span">睡前</span>
        <el-time-picker class="width1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
        <el-time-picker class="width1 span1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
      </div>
      <div class="item">
        <span class="span">凌晨</span>
        <el-time-picker class="width1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
        <el-time-picker class="width1 span1" :picker-options="{
          selectableRange: '00:00:00 - 23:59:59'
        }">
        </el-time-picker>
      </div>
      <div class="item1">
        <div class="btns">
          <el-button>修改</el-button>
        </div>
      </div>
    </div>

    <div class="title">
      <span>体温预警</span>
    </div>
    <div class="box">
      <div class="item">
        <span class="span">正常</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">℃</span>
      </div>
      <div class="item">
        <span class="span">低温</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">℃</span>
      </div>
      <div class="item">
        <span class="span">中等热</span>
        <el-input class="width1"></el-input>
        <el-input class="width1 span1"></el-input>
        <span class="span span1">℃</span>
      </div>
      <div class="item1">
        <div class="item1">
          <div class="btns">
            <el-button>修改</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: ''
    }
  }
}
</script>

<style lang="less" scpoed>
.rule {
  width: 100%;
  height: 100%;
  border-top: 1px solid #000;
  box-sizing: border-box;

  .title {
    height: 50px;
    line-height: 50px;
    margin-bottom: 20px;
    padding-left: 10px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    padding-left: 10px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 48%;
      margin-bottom: 20px;
      color: #000;
      font-size: 18px;
    }

    .item1 {
      justify-content: center;
      width: 100%;

      .btns {
        width: 20%;
        margin: 0 auto;
      }
    }

    .span {
      width: 100px;
      white-space: nowrap;
    }

    .span1 {
      margin-left: 10px;
    }

    .width {
      width: 550px;
    }

    .width1 {
      width: 120px;
    }
  }

  .el-button {
    margin-left: 20px;
    background-color: #04B4D0;
    border: none;
    border-radius: 0;
    color: #fff;
  }
}
</style>
