<template>
  <div class="page">
    <div class="switch">
      <span class="item" :class="{ item1: switchIndex === index }" v-for="(item, index) in switchs" :key="index"
        @click="switchClick(index)">{{ item }}</span>
    </div>
    <div class="content">
      <!-- 机构管理 -->
      <OrganRun v-if="(switchIndex === 0)"></OrganRun>
      <!-- 医护管理 -->
      <DoctorRun v-else-if="(switchIndex === 1)" @closePopup="closePopup"></DoctorRun>
      <!-- 规则管理 -->
      <RuleRun v-else-if="(switchIndex === 2)"></RuleRun>
    </div>
  </div>
</template>

<script>
import OrganRun from './organRun.vue'
import DoctorRun from './doctorRun.vue'
import RuleRun from './ruleRun.vue'
export default {
  components: {
    OrganRun,
    DoctorRun,
    RuleRun
  },
  data () {
    return {
      switchIndex: 0,
      switchs: Object.freeze(['机构管理', '医护管理', '医护管理'])
    }
  },
  methods: {
    switchClick (index) {
      if (index === this.switchIndex) return
      this.switchIndex = index
    },
    closePopup (index) {
      // console.log(index)
      this.switchIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: .25rem;
  box-sizing: border-box;

  .switch {
    display: flex;
    width: 100%;
    height: .5rem;

    .item {
      height: .5rem;
      line-height: .5rem;
      margin-right: .25rem;
      padding: 0 .125rem;
      text-align: center;
      color: #fff;
      font-size: .175rem;
      background-color: rgba(115, 115, 115, .4);
      cursor: pointer;
    }

    .item1 {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  .content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - .625rem);
    margin-top: .125rem;

    &::-webkit-scrollbar {
      width: 0;
    }

  }
}
</style>
