<template>
  <div class="tab">
    <div class="tab-scroll">
      <div class="tab-head">
        <div class="item">
          <div class="label">
            <span>生化</span>
          </div>
          <div class="icon">
            <span class="el-icon-check"></span>
          </div>
        </div>
        <div class="item">
          <div class="label label1">
            <span>血常规</span>
          </div>
          <div class="icon red">
            <span class="el-icon-close"></span>
          </div>
        </div>
        <div class="item">
          <div class="label">
            <span>b超</span>
          </div>
          <div class="icon">
            <span class="el-icon-check"></span>
          </div>
        </div>
        <div class="item">
          <div class="label">
            <span>尿常规</span>
          </div>
          <div class="icon">
            <span class="el-icon-check"></span>
          </div>
        </div>
        <div class="item">
          <div class="label">
            <span>心电图</span>
          </div>
          <div class="icon">
            <span class="el-icon-check"></span>
          </div>
        </div>
        <div class="item">
          <div class="label">
            <span>中医药</span>
          </div>
          <div class="icon">
            <span class="el-icon-check"></span>
          </div>
        </div>
      </div>
      <div class="tab-title">
        <span>体检结果评价报告及健康指导</span>
      </div>
      <div class="info">
        <div class="line border">
          <div class="item">
            <span>编号：304076981</span>
          </div>
          <div class="item">
            <span>姓名：李明明</span>
          </div>
          <div class="item">
            <span>性别:男</span>
          </div>
          <div class="item">
            <span>身份证:130429193205164852</span>
          </div>
          <div class="item">
            <span>电话:17367759256</span>
          </div>
        </div>
      </div>
      <div class="table">
        <div class="tr">
          <div class="list">
            <span>一、体检异常结果：</span>
          </div>
          <div class="list list1">
            <span>1、血红蛋白偏高：162 g/L;</span>
            <span>2、高密度胆固醇偏低：0.70mmol/L</span>
          </div>
        </div>
        <div class="tr">
          <div class="list">
            <span>二、体检小结（评价）：</span>
          </div>
          <div class="list list1">
          </div>
        </div>
        <div class="tr">
          <div class="list">
            <span>三、健康管理：</span>
          </div>
          <div class="list list1">
          </div>
        </div>
        <div class="tr">
          <div class="list">
            <span>四、采取的健康指导措施</span>
          </div>
          <div class="list list1">
          </div>
        </div>
        <div class="tr tr1">
          <div class="left">
            <div class="text">
              <span>健康指导:</span>
            </div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="1">1、纳入慢性病患者健康管理</el-checkbox>
              <el-checkbox label="2">2、建议复查</el-checkbox>
              <el-checkbox label="3">3、建议转诊</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="right">
            <div class="text">
              <span>危险因素控制：:</span>
            </div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="1">戒烟</el-checkbox>
              <el-checkbox label="2">健康饮酒</el-checkbox>
              <el-checkbox label="3">饮食</el-checkbox>
              <el-checkbox label="4">锻炼</el-checkbox>
              <el-checkbox label="5">
                <span>减体重（目标</span>
                <el-input class="input" value="88.0"></el-input>
                <span>kg）</span>
              </el-checkbox>
              <el-checkbox label="6">建议接种疫苗 </el-checkbox>
              <el-checkbox label="7">
                <span>其他</span>
                <el-input class="input1"></el-input>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="son-bottom">
        <el-button @click="noClick">编辑</el-button>
        <el-button @click="noClick">保存</el-button>
        <el-button @click="noClick">审核</el-button>
        <el-button @click="noClick">上传</el-button>
        <el-button @click="noClick">打印</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      checkList: [],
      radio: ''
    }
  },
  methods: {
    noClick () {
      this.$message.error('未开放')
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 0 50px;
  box-sizing: border-box;

  .tab-scroll {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tab-head {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      .item {
        display: flex;
        align-items: center;
        margin: 0 20px;

        .label {
          height: 30px;
          line-height: 30px;
          padding: 0 16px;
          background-color: #3782F2;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
        }

        .label1 {
          background-color: #FF0000;
        }

        .icon {
          margin-left: 10px;
          color: #3782F2;
          font-size: 26px;
        }

        .red {
          color: #FF0000;
          font-weight: 600;
        }
      }
    }

    .tab-title {
      width: 100%;
      height: 54px;
      line-height: 54px;
      text-align: center;
      color: #475265;
      font-size: 22px;
    }

    .info {
      display: flex;
      flex-direction: column;
      width: 100%;

      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        padding: 0 26px;

        .text {
          color: #2A374D;
          font-size: 16px;
        }

        .item {
          flex: 1;
          text-align: center;
          color: #2A374D;
          font-size: 16px;
        }
      }

      .border {
        border-bottom: 1px solid #B1B1B1;
        box-sizing: border-box;
      }
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 1px solid #475265;
      box-sizing: border-box;

      .tr {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        height: 130px;
        padding: 10px 18px;
        border-bottom: 1px solid #475265;
        box-sizing: border-box;

        .list {
          width: 100%;
          color: #475265;
          font-size: 20px;
          font-weight: 600;
        }

        .list1 {
          font-size: 18px;
          font-weight: 400;
        }

        .left {
          width: 347px;
          height: 100%;
          padding: 10px 18px;
          box-sizing: border-box;

          /deep/.el-checkbox__label {
            color: #475265;
            font-size: 14px;
          }

          .text {
            color: #475265;
            font-size: 18px;
          }

          /deep/.el-checkbox-group {
            display: flex;
            flex-direction: column;
          }

          /deep/.el-checkbox {
            margin-top: 8px;
          }
        }

        .right {
          width: calc(100% - 347px);
          height: 100%;
          padding: 10px 18px;
          box-sizing: border-box;

          .text {
            color: #475265;
            font-size: 18px;
          }

          .input {
            width: 80px;
          }

          .input1 {
            width: 154px;
          }
        }
      }

      .tr1 {
        flex-direction: row;
        padding: 0;
      }
    }

    .son-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
      border-bottom: 1px solid #475265;

      .el-button {
        min-width: 120px;
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 6px;
        padding-bottom: 6px;
        background-color: #3782F2;
        border: none;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .son-bottom1 {
      height: 60px;
      justify-content: flex-end;
    }

  }

}
</style>
