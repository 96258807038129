<template>
  <div class="page">
    <div class="scroll">
      <div class="title">
        <span class="span2" @click="closePopup(0)">系统管理</span>
        <span>/</span>
        <span class="span2" @click="closePopup(1)">医护管理</span>
        <span>/</span>
        <span>添加医护</span>
      </div>
      <div class="title">
        <span>个人信息</span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">用户名</span>
          <el-input class="width"></el-input>
        </div>
        <div class="item">
          <span class="span">姓名</span>
          <el-input class="width"></el-input>
        </div>
        <div class="item">
          <span class="span">手机号</span>
          <el-input class="width"></el-input>
        </div>
        <div class="item">
          <span class="span">身份证号</span>
          <el-input class="width"></el-input>
        </div>
        <div class="item">
          <span class="span">性别</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item item1">
          <span class="span">地址</span>
          <el-select class="width1" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span class="span span1">省</span>
          <el-select class="width1" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span class="span span1">市</span>
          <el-select class="width1" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span class="span span1">区/县</span>
          <el-input class="width" placeholder="请输入地址"></el-input>
        </div>
        <div class="item">
          <span class="span">组织</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">职业</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">状态</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">职称</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="title">
        <span>资格认证</span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">职业认证</span>
          <el-input class="width"></el-input>
        </div>
        <div class="item">
          <span class="span">发证日期</span>
          <el-date-picker class="width" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <div class="title">
        <span>资格认证</span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">职业认证</span>
          <el-input class="width1"></el-input>
          <el-input class="width1 span1"></el-input>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button @click="close">返回</el-button>
      <el-button class="color" @click="close">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      data: [{
        label: '宝安中心医院',
        children: [{
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    closePopup (index) {
      this.$emit('closePopup', index)
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: #08223E;

  .scroll {
    height: calc(100% - .5rem);

    .title {
      height: .625rem;
      line-height: .625rem;
      color: #04B4D0;
      font-size: .225rem;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      .item {
        display: flex;
        align-items: center;
        width: 48%;
        margin-bottom: .25rem;
        color: #04B4D0;
        font-size: .225rem;
      }

      .item1 {
        width: 100%;
      }

      .span {
        width: 1.25rem;
        white-space: nowrap;
      }

      .span1 {
        margin-left: .125rem;
      }

      .span2 {
        cursor: pointer;
      }

      .width {
        width: 6.875rem;
      }

      .width1 {
        width: 1.5rem;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: .5rem;

    .el-button {
      width: .875rem;
      height: .325rem;
      line-height: .325rem;
      margin-right: .625rem;
      text-align: center;
      background-color: #33465C;
      color: #fff;
      font-size: .175rem;
      border: 0;
      padding: 0;
      border-radius: 0;
    }

    .color {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
