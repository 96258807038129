var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cover"},[_c('div',{staticClass:"cover-box"},[_c('div',{staticClass:"conve-center"},[_vm._m(0),_c('div',{staticClass:"conve-line"},[_vm._m(1),_c('el-input',{staticClass:"input"})],1),_c('div',{staticClass:"conve-line"},[_vm._m(2),_c('el-input',{staticClass:"input"})],1),_c('div',{staticClass:"conve-line"},[_vm._m(3),_c('el-input',{staticClass:"input"})],1),_c('div',{staticClass:"conve-line"},[_vm._m(4),_c('el-input',{staticClass:"input"})],1),_c('div',{staticClass:"conve-line"},[_vm._m(5),_c('el-input',{staticClass:"input"})],1),_c('div',{staticClass:"conve-line"},[_vm._m(6),_c('el-input',{staticClass:"input"})],1),_c('div',{staticClass:"conve-line conve-line1"},[_vm._m(7),_c('el-input',{staticClass:"input"})],1),_c('div',{staticClass:"conve-line"},[_vm._m(8),_c('el-input',{staticClass:"input"})],1),_c('div',{staticClass:"conve-line"},[_vm._m(9),_c('el-input',{staticClass:"input"})],1),_c('div',{staticClass:"conve-line"},[_vm._m(10),_c('el-input',{staticClass:"input input1"}),_vm._m(11),_c('el-input',{staticClass:"input input1"}),_vm._m(12),_c('el-input',{staticClass:"input input1"}),_vm._m(13)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-title"},[_c('span',[_vm._v("居民健康档案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-key"},[_c('span',[_vm._v("姓名：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-key"},[_c('span',[_vm._v("现住址：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-key"},[_c('span',[_vm._v("户籍地址：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-key"},[_c('span',[_vm._v("联系电话：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-key"},[_c('span',[_vm._v("乡镇（街道）名称：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-key"},[_c('span',[_vm._v("村（居）委员名称：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-key"},[_c('span',[_vm._v("建档单位：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-key"},[_c('span',[_vm._v("建档人：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-key"},[_c('span',[_vm._v("责任医生：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-key"},[_c('span',[_vm._v("建档日期：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-date"},[_c('span',[_vm._v("年")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-date"},[_c('span',[_vm._v("月")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conve-date"},[_c('span',[_vm._v("日")])])
}]

export { render, staticRenderFns }