<template>
  <div class="son">
    <div class="son-left">
      <div class="son-title">
        <span>医疗服务</span>
      </div>
      <div class="son-line">
        <span>门诊</span>
      </div>
      <div class="son-line">
        <span>住院</span>
      </div>
      <div class="son-title">
        <span>居民体检</span>
      </div>
      <div class="son-line">
        <span>公卫体检（30）</span>
      </div>
      <div class="son-line">
        <span>健康体检（30）</span>
      </div>
      <div class="son-title">
        <span>公共卫生</span>
      </div>
      <div class="son-line son-line-active">
        <span>基本档案管理</span>
      </div>
      <div class="son-line">
        <span>计划免疫（30）</span>
      </div>
      <div class="son-line">
        <span>儿童保健（30）</span>
      </div>
      <div class="son-line">
        <span>孕产妇保健（30）</span>
      </div>
      <div class="son-line">
        <span>高血压管理（30）</span>
      </div>
      <div class="son-line">
        <span>糖尿病管理（30）</span>
      </div>
      <div class="son-line">
        <span>肿瘤管理（30）</span>
      </div>
      <div class="son-line">
        <span>脑卒中管理（30）</span>
      </div>
      <div class="son-line">
        <span>冠心病管理（30）</span>
      </div>
      <div class="son-line">
        <span>精神病管理（30）</span>
      </div>
      <div class="son-line">
        <span>肺结核管理（30）</span>
      </div>
      <div class="son-line">
        <span>生命统计（30）</span>
      </div>
    </div>
    <div class="son-right">
      <div class="son-tab">
        <span class="son-item" :class="{ 'item-active': listIndex === index }" v-for="(item, index) in labels"
          :key="index">{{ item }}</span>
      </div>
      <div class="son-scroll">
        <div class="scroll-title">
          <span>个人基本信息</span>
        </div>
        <div class="scroll-info">
          <span>姓名：李明明</span>
          <span>档案编号:12908477</span>
        </div>
        <div class="scroll-table">
          <div class="scroll-tr">
            <div class="scroll-td flex1">
              <span>性别</span>
            </div>
            <div class="scroll-td flex1">
              <span>男</span>
            </div>
            <div class="scroll-td flex1">
              <span>出生日期</span>
            </div>
            <div class="scroll-td flex1">
              <span>1998-08-03</span>
            </div>
          </div>
          <div class="scroll-tr">
            <div class="scroll-td flex1">
              <span>身份证号</span>
            </div>
            <div class="scroll-td flex1">
              <span>130730199808080836</span>
            </div>
            <div class="scroll-td flex1">
              <span>工作单位</span>
            </div>
            <div class="scroll-td flex1">
              <span>石家庄市医药有限公司</span>
            </div>
          </div>
          <div class="scroll-tr">
            <div class="scroll-td flex1">
              <span>本人电话</span>
            </div>
            <div class="scroll-td flex1">
              <span>15803338644</span>
            </div>
            <div class="scroll-td flex1">
              <span>民族</span>
            </div>
            <div class="scroll-td flex1">
              <span>汉族</span>
            </div>
          </div>
          <div class="scroll-tr">
            <div class="scroll-td flex1">
              <span>联系人姓名</span>
            </div>
            <div class="scroll-td flex1">
              <span>李明明</span>
            </div>
            <div class="scroll-td flex1">
              <span>联系人电话</span>
            </div>
            <div class="scroll-td flex1">
              <span>15803338644</span>
            </div>
          </div>
          <div class="scroll-tr">
            <div class="scroll-td flex1">
              <span>常住类型</span>
            </div>
            <div class="scroll-td flex1">
              <span>户籍</span>
            </div>
            <div class="scroll-td flex1">
              <span>血型</span>
            </div>
            <div class="scroll-td flex1">
              <span>A型</span>
            </div>
          </div>
          <div class="scroll-tr">
            <div class="scroll-td flex1">
              <span>文化程度</span>
            </div>
            <div class="scroll-td flex1">
              <span>研究生</span>
            </div>
            <div class="scroll-td flex1">
              <span>职业</span>
            </div>
            <div class="scroll-td flex1">
              <span>策划</span>
            </div>
          </div>
          <div class="scroll-tr">
            <div class="scroll-td flex1">
              <span>婚姻状况</span>
            </div>
            <div class="scroll-td flex1">
              <span>未婚</span>
            </div>
            <div class="scroll-td flex1">
              <span>医疗费用支付方式</span>
            </div>
            <div class="scroll-td flex1">
              <span>城镇居民基本医疗保险</span>
            </div>
          </div>
          <div class="scroll-tr">
            <div class="scroll-td flex1">
              <span>药物过敏史</span>
            </div>
            <div class="scroll-td flex1">
              <span>青霉素</span>
            </div>
            <div class="scroll-td flex1">
              <span>暴露史</span>
            </div>
            <div class="scroll-td flex1">
              <span>无</span>
            </div>
          </div>
          <div class="scroll-tr scroll-tr1">
            <div class="scroll-td scroll-td1 flex1">
              <div class="tr-left border-right-bottom">
                <span>既往史</span>
              </div>
              <div class="tr-right border-right">
                <div class="tr-item">
                  <span>疾病</span>
                </div>
                <div class="tr-item">
                  <span>疾病</span>
                </div>
                <div class="tr-item">
                  <span>手术</span>
                </div>
                <div class="tr-item">
                  <span>外伤</span>
                </div>
                <div class="tr-item">
                  <span>输血</span>
                </div>
              </div>
            </div>
            <div class="scroll-td scroll-td1 flex3">
              <div class="td-list">
                <div class="list-td border-right-bottom">
                  <span>无</span>
                </div>
                <div class="list-td border-right-bottom">
                  <span>确诊时间</span>
                </div>
                <div class="list-td border-right-bottom">
                  <span></span>
                </div>
              </div>
              <div class="td-list">
                <div class="list-td border-right-bottom">
                  <span>无</span>
                </div>
                <div class="list-td border-right-bottom">
                  <span>确诊时间</span>
                </div>
                <div class="list-td">
                  <span></span>
                </div>
              </div>
              <div class="td-list">
                <div class="list-td border-right-bottom">
                  <span>刨宫产手术</span>
                </div>
                <div class="list-td border-right-bottom">
                  <span>时间</span>
                </div>
                <div class="list-td">
                  <span></span>
                </div>
              </div>
              <div class="td-list">
                <div class="list-td border-right-bottom">
                  <span>无</span>
                </div>
                <div class="list-td border-right-bottom">
                  <span>时间</span>
                </div>
                <div class="list-td">
                  <span></span>
                </div>
              </div>
              <div class="td-list">
                <div class="list-td border-right-bottom">
                  <span>无</span>
                </div>
                <div class="list-td border-right-bottom">
                  <span>时间</span>
                </div>
                <div class="list-td">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-tr scroll-tr1 scroll-tr2">
            <div class="scroll-td scroll-td1 flex1">
              <div class="tr-left border-right-bottom">
                <span>家族史</span>
              </div>
              <div class="tr-right border-right">
                <div class="tr-item">
                  <span>父亲</span>
                </div>
                <div class="tr-item">
                  <span>兄弟姐妹</span>
                </div>
              </div>
            </div>
            <div class="scroll-td scroll-td1 flex3">
              <div class="td-list">
                <div class="list-td border-right-bottom">
                  <span>高血压</span>
                </div>
                <div class="list-td border-right-bottom">
                  <span>母亲</span>
                </div>
                <div class="list-td border-right-bottom">
                  <span>高血压</span>
                </div>
              </div>
              <div class="td-list">
                <div class="list-td border-right-bottom">
                  <span>无</span>
                </div>
                <div class="list-td border-right-bottom">
                  <span>子女</span>
                </div>
                <div class="list-td border-right-bottom">
                  <span>无</span>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-tr">
            <div class="scroll-td flex1">
              <span>遗传病史</span>
            </div>
            <div class="scroll-td flex3 td-flex3">
              <span>无</span>
            </div>
          </div>
          <div class="scroll-tr">
            <div class="scroll-td flex1">
              <span>残疾情况</span>
            </div>
            <div class="scroll-td flex3 td-flex3">
              <span>无</span>
            </div>
          </div>
          <div class="scroll-tr scroll-tr1 scroll-tr3">
            <div class="scroll-td scroll-td1 flex1">
              <div class="tr-left border-right-bottom">
                <span>生活环境</span>
              </div>
              <div class="tr-right border-right">
                <div class="tr-item">
                  <span>厨房排风设施</span>
                </div>
                <div class="tr-item">
                  <span>燃料类型</span>
                </div>
                <div class="tr-item">
                  <span>饮水</span>
                </div>
              </div>
            </div>
            <div class="scroll-td scroll-td1 flex3">
              <div class="td-list">
                <div class="list-td border-right-bottom">
                  <span>油烟机</span>
                </div>
              </div>
              <div class="td-list">
                <div class="list-td border-right-bottom">
                  <span>天然气</span>
                </div>
              </div>
              <div class="td-list">
                <div class="list-td border-right-bottom">
                  <span>自来水</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '1',
      listIndex: 0,
      labels: Object.freeze(['基本信息', '健康体检表', '档案封面']),
      checks: []
    }
  },
  methods: {
    listClick (index) {
      this.listIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }

  .son-left {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    height: 100%;
    padding: 6px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-title {
      width: 100%;
      height: 56px;
      line-height: 56px;
      margin-top: 10px;
      padding-left: 10px;
      background-color: #041929;
      box-sizing: border-box;
      color: #fff;
      font-size: 18px;
    }

    .son-title:first-child {
      margin-top: 0;
    }

    .son-line {
      width: 100%;
      margin-top: 10px;
      padding-left: 10px;
      box-sizing: border-box;
      color: #fff;
      font-size: 16px;
    }

    .son-line-active {
      color: #01EEFD;
    }
  }

  .son-right {
    overflow-y: scroll;
    width: calc(100% - 380px);
    height: 100%;
    padding: 12px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-tab {
      display: flex;
      flex-direction: row;
      height: 50px;

      .son-item {
        height: 40px;
        line-height: 40px;
        margin-right: 20px;
        padding: 0 12px;
        background-color: #737373;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }

      .item-active {
        background: linear-gradient(102deg, #0172C6 0%, #02467C 100%);
      }
    }

    .son-scroll {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 50px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .scroll-title {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }

      .scroll-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        padding: 0 8px;
        box-sizing: border-box;
        color: #fff;
        font-size: 16px;
      }

      .scroll-table {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #01EEFD;
        border-left: 1px solid #01EEFD;
        box-sizing: border-box;

        .scroll-tr {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 60px;

          .scroll-td {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border-bottom: 1px solid #01EEFD;
            border-right: 1px solid #01EEFD;
            box-sizing: border-box;
            color: #01EEFD;
            font-size: 14px;
          }

          .scroll-td1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            border: none;
          }

          .flex1 {
            flex: 1;
          }

          .td-flex3 {
            border-left: 3px solid transparent;
          }

          .flex3 {
            display: flex;
            flex-direction: column;
            flex: 3;
            // border: 3px solid transparent;
            // border-bottom: 1px solid #01EEFD;
            // border-right: 1px solid #01EEFD;
            box-sizing: border-box;

            .td-list {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              height: 50px;

              .list-td {
                flex: 1;
                height: 50px;
                line-height: 50px;
                text-align: center;
                border-right: 1px solid #01EEFD;
                border-bottom: 1px solid #01EEFD;
                box-sizing: border-box;
                color: #01EEFD;
                font-size: 14px;
              }
            }
          }
        }

        .border-right-bottom {
          border-right: 1px solid #01EEFD;
          border-bottom: 1px solid #01EEFD;
          box-sizing: border-box;
        }

        .scroll-tr1 {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 250px;

          .tr-left {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90px;
            height: 100%;
            box-sizing: border-box;
            color: #01EEFD;
            font-size: 14px;
          }

          .border-right {
            border-right: 1px solid #01EEFD;
            box-sizing: border-box;
          }

          .tr-right {
            width: calc(100% - 87px);
            height: 100%;

            .tr-item {
              width: 100%;
              height: 50px;
              line-height: 50px;
              text-align: center;
              border-bottom: 1px solid #01EEFD;
              box-sizing: border-box;
              color: #01EEFD;
              font-size: 14px;
            }
          }
        }

        .scroll-tr2 {
          height: 100px;
        }

        .scroll-tr3 {
          height: 150px;
        }

        .border-right {
          border-right: 1px solid #01EEFD;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
