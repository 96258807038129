<template>
  <div class="data">
    <div class="head">
      <el-button type="primary">健康干预</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="input">
      <el-button type="primary" size="mini" @click="openAddIntervene">新增</el-button>
    </div>
    <el-table :data="tableData" border height="50%">
      <el-table-column prop="jkgymc" label="干预名称">
      </el-table-column>
      <el-table-column prop="jkgysj" label="干预时间">
      </el-table-column>
      <el-table-column label="操作" width="240">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="openSeeIntervene(scope.row)">查看</el-button>
          <el-button size="small" type="primary" @click="openUpdateIntervene(scope.row)">编辑</el-button>
          <el-button size="small" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 干预详情 -->
    <SeeIntervene v-if="showSeeIntervene" @closePopup="closeSeeIntervene"></SeeIntervene>
    <!-- 新增干预 -->
    <AddIntervene v-if="showAddIntervene" @closePopup="closeAddIntervene"></AddIntervene>
    <UpdateIntervene v-if="showUpdateIntervene"></UpdateIntervene>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SeeIntervene from './seeIntervene.vue'
import AddIntervene from './addIntervene.vue'
import UpdateIntervene from './updateIntervene.vue'
export default {
  components: {
    SeeIntervene,
    AddIntervene,
    UpdateIntervene
  },
  data () {
    return {
      tableData: [],
      showSeeIntervene: false,
      showAddIntervene: false,
      showUpdateIntervene: false,
      searchInfo: {
        ssjgbh: '',
        sfzh: '130525200011015220',
        jgbh: '',
        start: '',
        length: ''
      },
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      selectInfo: {
        ssjgbh: '',
        sfzh: '',
        jkgyid: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  methods: {
    async getInfo () {
      try {
        this.tableData = []
        this.searchInfo.start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + 1 + ''
        this.searchInfo.length = this.query1.pageSize + ''
        this.searchInfo.ssjgbh = this.loginInfo.jgbh
        const { data: res } = await this.$http.get('/ykdjkgys', { params: this.searchInfo })
        console.log('健康干预列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
      } catch (error) {
        console.log('健康干预列表请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    openSeeIntervene (info) {
      this.showSeeIntervene = true
      for (const key in this.selectInfo) {
        this.selectInfo[key] = info[key]
      }
    },
    closeSeeIntervene () {
      this.showSeeIntervene = false
      for (const key in this.selectInfo) {
        this.selectInfo[key] = ''
      }
    },
    openAddIntervene () {
      this.showAddIntervene = true
    },
    closeAddIntervene () {
      this.showAddIntervene = false
      this.getInfo()
    },
    openUpdateIntervene (info) {
      this.showUpdateIntervene = true
      for (const key in this.selectInfo) {
        this.selectInfo[key] = info[key]
      }
    },
    beforeDestroy () {}
  }
}
</script>

<style lang="less" scoped>
.data {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
    }
  }

  .input {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .operate {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #01EEFD;
    font-size: 14px;

    span {
      cursor: pointer;
    }

    .red {
      color: #FF0000;
    }
  }

  /deep/.el-table__cell {
    text-align: center;
    color: #000;
  }
}
</style>
