<template>
  <div class="item">
    <div class="item-line">
      <div class="item-title">
        <span>诊疗服务</span>
      </div>
      <div class="item-table">
        <div class="item-head">
          <span class="flex">就诊时间</span>
          <span class="flex">就诊类型</span>
          <span class="flex">就诊机构</span>
          <span class="flex">机构级别</span>
          <span class="flex">诊断</span>
        </div>
        <div class="item-scroll">
          <div class="item-list">
            <span class="item-flex">2020-08-03</span>
            <span class="item-flex">门诊</span>
            <span class="item-flex">FED</span>
            <span class="item-flex">二级医院</span>
            <span class="item-flex">上呼吸道恶性肿瘤</span>
          </div>
          <div class="item-list">
            <span class="item-flex">2020-08-03</span>
            <span class="item-flex">门诊</span>
            <span class="item-flex">FED</span>
            <span class="item-flex">二级医院</span>
            <span class="item-flex">上呼吸道恶性肿瘤</span>
          </div>
          <div class="item-list">
            <span class="item-flex">2020-08-03</span>
            <span class="item-flex">门诊</span>
            <span class="item-flex">FED</span>
            <span class="item-flex">二级医院</span>
            <span class="item-flex">上呼吸道恶性肿瘤</span>
          </div>
          <div class="item-list">
            <span class="item-flex">2020-08-03</span>
            <span class="item-flex">门诊</span>
            <span class="item-flex">FED</span>
            <span class="item-flex">二级医院</span>
            <span class="item-flex">上呼吸道恶性肿瘤</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item-line item-line1">
      <div class="item-sort">
        <div class="item-title">
          <span>检查记录</span>
        </div>
        <div class="item-table">
          <div class="item-head">
            <span class="flex">报告时间</span>
            <span class="flex">项目名称</span>
          </div>
          <div class="item-scroll">
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
          </div>
        </div>
      </div>
      <div class="item-sort">
        <div class="item-title">
          <span>检查记录</span>
        </div>
        <div class="item-table">
          <div class="item-head">
            <span class="flex">报告时间</span>
            <span class="flex">项目名称</span>
          </div>
          <div class="item-scroll">
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
          </div>
        </div>
      </div>
      <div class="item-sort">
        <div class="item-title">
          <span>检查记录</span>
        </div>
        <div class="item-table">
          <div class="item-head">
            <span class="flex">报告时间</span>
            <span class="flex">药物名称</span>
          </div>
          <div class="item-scroll">
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020-08-03</span>
              <span class="item-flex">超声常规检查</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-line item-line1">
      <div class="item-sort">
        <div class="item-title">
          <span>高血压管理（随访29次，评估0次）</span>
        </div>
        <div class="item-table">
          <div class="item-head">
            <span class="flex">服务日期</span>
            <span class="flex">服务名称</span>
          </div>
          <div class="item-scroll">
            <div class="item-list">
              <span class="item-flex">2020（3次）</span>
              <span class="item-flex">随访登记</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020（3次）</span>
              <span class="item-flex">随访登记</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020（3次）</span>
              <span class="item-flex">随访登记</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020（3次）</span>
              <span class="item-flex">随访登记</span>
            </div>
          </div>
        </div>
      </div>
      <div class="item-sort">
        <div class="item-title">
          <span>糖尿病管理（随访29次，评估0次）</span>
        </div>
        <div class="item-table">
          <div class="item-head">
            <span class="flex">服务日期</span>
            <span class="flex">服务名称</span>
          </div>
          <div class="item-scroll">
            <div class="item-list">
              <span class="item-flex">2020（3次）</span>
              <span class="item-flex">随访登记</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020（3次）</span>
              <span class="item-flex">随访登记</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020（3次）</span>
              <span class="item-flex">随访登记</span>
            </div>
            <div class="item-list">
              <span class="item-flex">2020（3次）</span>
              <span class="item-flex">随访登记</span>
            </div>
          </div>
        </div>
      </div>
      <div class="item-sort">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () { },
  beforeDestroy () { },
  methods: {}
}
</script>

<style lang="less" scoped>
.item {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }

  .item-line {
    flex: 1;
    width: 100%;

    .item-sort {
      width: 32%;
      height: 100%;
    }

    .item-title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #00D5EA;
      box-sizing: border-box;
      color: #fff;
      font-size: 18px;
    }

    .item-table {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 50px);
      margin-top: 10px;
      border: 4px #073F73 solid;
      box-sizing: border-box;

      .item-head {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 44px;
        background-color: #024276;

        .flex {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1;
          height: 44px;
          line-height: 44px;
          text-align: center;
          color: #01EEFD;
          font-size: 17px;
        }
      }

      .item-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 44px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .item-list {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          height: 40px;
          border-bottom: 1px solid #01EEFD;
          box-sizing: border-box;

          .item-flex {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #01EEFD;
            font-size: 16px;
          }
        }

        .item-list:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .item-line1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
