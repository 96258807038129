var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab"},[_c('div',{staticClass:"tab-scroll"},[_vm._m(0),_c('div',{staticClass:"info"},[_c('div',{staticClass:"line border"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("编号："+_vm._s(_vm.info.jktjid || ''))])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]),(_vm.info.rpturl)?_c('img',{staticClass:"img",attrs:{"src":_vm.info.rpturl}}):_c('div',{staticClass:"img"}),_c('div',{staticClass:"line3"},[_vm._m(5),_c('el-radio-group',{model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_c('el-radio',{attrs:{"label":"1"}},[_vm._v("正常")]),_c('el-radio',{attrs:{"label":"2"}},[_vm._v("异常")])],1),_vm._m(6),_c('el-input',{attrs:{"placeholder":"请输入异常原因"}}),_vm._m(7)],1)]),_c('div',{staticClass:"son-bottom"},[_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("编辑")]),_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("审核")]),_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("上传")]),_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("打印")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-title"},[_c('span',[_vm._v("心电图结果报告")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("姓名：-")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("性别:-")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("身份证:-")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("电话:-")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('span',[_vm._v("结果")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text text1"},[_c('span',[_vm._v("异常原因：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text text1"},[_c('span',[_vm._v("报告者：-")])])
}]

export { render, staticRenderFns }