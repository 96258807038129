<template>
  <div class="son">
    <div class="son-left">
      <el-input placeholder="机构名称/诊断名称/就诊名称" suffix-icon="el-icon-search">
      </el-input>
      <div class="son-title">
        <span>随访登记</span>
      </div>
      <div class="scroll">
        <div class="list" :class="{ active: listIndex === index }" v-for="(item, index) in 8" :key="index"
          @click="listClick(index)">
          <span>2017年</span>
          <span>随机登记2次</span>
        </div>
      </div>
    </div>
    <div class="son-right">
      <div class="scroll">
        <div class="title">
          <span>高血压患者随访服务记录表</span>
        </div>
        <div class="info">
          <span>姓名：测试</span>
          <span>档案编号:-</span>
        </div>
        <div class="son-table">
          <div class="table-flxed">
            <div class="table-box border-right-bottom">
              <span>随访日期</span>
            </div>
            <div class="table-box border-right-bottom">
              <span>随访方式</span>
            </div>
            <div class="table-box1">
              <div class="table-box-left border-right-bottom">
                <span>症状</span>
              </div>
              <div class="table-box-right border-right-bottom"></div>
            </div>
            <div class="table-box3">
              <div class="table-box-left border-right-bottom">
                <span>体征</span>
              </div>
              <div class="table-box-right border-right-bottom">
                <div class="table-box border-bottom">
                  <span>血压（mmHg）</span>
                </div>
                <div class="table-box border-bottom">
                  <span>身高（cm)</span>
                </div>
                <div class="table-box border-bottom">
                  <span>体重（kg)</span>
                </div>
                <div class="table-box border-bottom">
                  <span>体质指数(BMI)(kg/m2)</span>
                </div>
                <div class="table-box border-bottom">
                  <span>心率（次/分钟 ）</span>
                </div>
                <div class="table-box">
                  <span>其他</span>
                </div>
              </div>
            </div>
            <div class="table-box3">
              <div class="table-box-left border-right-bottom">
                <span>生活方式指导</span>
              </div>
              <div class="table-box-right border-right-bottom">
                <div class="table-box border-bottom">
                  <span>日吸烟量（支）</span>
                </div>
                <div class="table-box border-bottom">
                  <span>日饮酒量（两）</span>
                </div>
                <div class="table-box border-bottom">
                  <span>运动</span>
                </div>
                <div class="table-box border-bottom">
                  <span>摄盐情况（咸淡）</span>
                </div>
                <div class="table-box border-bottom">
                  <span>心理调整</span>
                </div>
                <div class="table-box">
                  <span>遵医行为</span>
                </div>
              </div>
            </div>
            <div class="table-box border-right-bottom">
              <span>辅助检查</span>
            </div>
            <div class="table-box border-right-bottom">
              <span>服药依从性</span>
            </div>
            <div class="table-box border-right-bottom">
              <span>药物不良反应</span>
            </div>
            <div class="table-box border-right-bottom">
              <span>此次随访分类</span>
            </div>
            <div class="table-box4">
              <div class="table-box-left border-right-bottom">
                <span>用药情况</span>
              </div>
              <div class="table-box-right border-right-bottom">
                <div class="table-box border-bottom">
                  <span>药物名称1</span>
                </div>
                <div class="table-box border-bottom">
                  <span>用法用量</span>
                </div>
                <div class="table-box border-bottom">
                  <span>药物名称2</span>
                </div>
                <div class="table-box">
                  <span>用法用量</span>
                </div>
              </div>
            </div>
            <div class="table-box5">
              <div class="table-box-left border-right-bottom">
                <span>转诊</span>
              </div>
              <div class="table-box-right border-right-bottom">
                <div class="table-box border-bottom">
                  <span>原因</span>
                </div>
                <div class="table-box">
                  <span>机构及科别</span>
                </div>
              </div>
            </div>
            <div class="table-box border-right-bottom">
              <span>下次随访时间：</span>
            </div>
            <div class="table-box border-right">
              <span>随访医生签名：</span>
            </div>
          </div>
          <div class="table-scroll">
            <div class="scroll-item">
              <div class="scroll-box border-right-bottom">
                <el-date-picker value-format='yyyy-MM-dd' type="date" placeholder="选择日期" v-model="params.followupDate">
                </el-date-picker>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-radio-group v-model="params.followupMode">
                  <el-radio label="1">门诊</el-radio>
                  <el-radio label="2">家庭</el-radio>
                  <el-radio label="3">电话</el-radio>
                </el-radio-group>
              </div>
              <div class="scroll-box1 border-right-bottom">
                <el-checkbox-group v-model="params.symptom">
                  <el-checkbox label="0">没有症状</el-checkbox>
                  <el-checkbox label="1">头痛头晕</el-checkbox>
                  <el-checkbox label="2">恶心呕吐</el-checkbox>
                  <el-checkbox label="3">眼花耳鸣</el-checkbox>
                  <el-checkbox label="4">呼吸困难</el-checkbox>
                  <el-checkbox label="5">心悸胸闷</el-checkbox>
                  <el-checkbox label="6">鼻衄出血不止</el-checkbox>
                  <el-checkbox label="7">四肢发麻</el-checkbox>
                  <el-checkbox label="8">下肢水肿</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input-number v-model="params.bloodLeft" controls-position="right" :min="0" :max="400" ></el-input-number>
                <el-input-number v-model="params.bloodRight" controls-position="right" :min="0" :max="400" ></el-input-number>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input-number v-model="params.height" controls-position="right" :min="0"  :step="0.1" ></el-input-number>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input-number v-model="params.weight" controls-position="right" :min="0"  :step="0.1" ></el-input-number>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input-number v-model="params.bmi" controls-position="right" :min="0"  :step="0.1" ></el-input-number>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input-number v-model="params.heartRate" controls-position="right" :step="0.1" :min="0" ></el-input-number>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input placeholder="" v-model="params.other"></el-input>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input-number v-model="params.smoking" controls-position="right" :min="0" ></el-input-number>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input-number v-model="params.drinking" controls-position="right" :min="0" ></el-input-number>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input-number v-model="params.motionWeek" controls-position="right" :min="0" ></el-input-number>
                <span>次/周</span>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-radio-group v-model="params.salt">
                  <el-radio label="1">轻</el-radio>
                  <el-radio label="2">中</el-radio>
                  <el-radio label="3">重</el-radio>
                </el-radio-group>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-radio-group v-model="params.psy">
                  <el-radio label="1">良好</el-radio>
                  <el-radio label="2">一般</el-radio>
                  <el-radio label="3">差</el-radio>
                </el-radio-group>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-radio-group v-model="params.respect">
                  <el-radio label="1">良好</el-radio>
                  <el-radio label="2">一般</el-radio>
                  <el-radio label="3">差</el-radio>
                </el-radio-group>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input placeholder="" v-model="params.fzjc"></el-input>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-radio-group v-model="params.medicine">
                  <el-radio label="1">规律</el-radio>
                  <el-radio label="2">间断</el-radio>
                  <el-radio label="3">不服药</el-radio>
                </el-radio-group>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-radio-group v-model="params.adverseFlag">
                  <el-radio label="1">无</el-radio>
                  <el-radio label="2">有</el-radio>
                </el-radio-group>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-radio-group v-model="params.followupClassify">
                  <el-radio label="1" class="box-label">控制满意</el-radio>
                  <el-radio label="2" class="box-label">控制不满意</el-radio>
                  <el-radio label="3" class="box-label">不良反应</el-radio>
                  <el-radio label="4" class="box-label">并发症</el-radio>
                </el-radio-group>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input placeholder="" v-model="yys[0].name"></el-input>
              </div>
              <div class="scroll-box border-right-bottom">
                <div class="box-item border-right">
                  <span>每日</span>
                  <el-input-number v-model="yys[0].forUse" controls-position="right" :min="0" class="input-number" size="small"></el-input-number>
                  <span>次</span>
                </div>
                <div class="box-item">
                  <span>每</span>
                  <el-input-number v-model="yys[0].phr" controls-position="right" :min="0" class="input-number" size="small"></el-input-number>
                  <span>次</span>
                </div>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input placeholder="" v-model="yys[1].name"></el-input>
              </div>
              <div class="scroll-box border-right-bottom">
                <div class="box-item border-right">
                  <span>每日</span>
                  <el-input-number v-model="yys[1].forUse" controls-position="right" :min="0" class="input-number" size="small"></el-input-number>
                  <span>次</span>
                </div>
                <div class="box-item">
                  <span>每</span>
                  <el-input-number v-model="yys[1].phr" controls-position="right" :min="0" class="input-number" size="small"></el-input-number>
                  <span>次</span>
                </div>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input placeholder="" v-model="params.referralReason"></el-input>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-input placeholder="" v-model="params.referralDept"></el-input>
              </div>
              <div class="scroll-box border-right-bottom">
                <el-date-picker value-format='yyyy-MM-dd' type="date" placeholder="选择日期" v-model="params.nextFollowDate">
                </el-date-picker>
              </div>
              <div class="scroll-box border-right">
                <el-select v-model="params.sfysqm" placeholder="">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '1',
      listIndex: 0,
      labels: Object.freeze(['门诊摘要', '门诊摘要', '检验记录', '门诊记录', '收费记录']),
      checks: [],
      options: [],
      value: '',
      params: {
        followupDate: '', // 随访时间
        followupMode: '', // 随访方式
        loss: '', // 是否失访
        lossReason: '', // 失访原因
        followupType: '', // 随访类型
        symptom: [], // 症状
        bloodLeft: '', // 血压
        bloodRight: '', // 血压目标
        height: '', // 身高
        weight: '', // 体重
        aimWeight: '', // 体重目标
        bmi: '', // 体重指数
        aimBmi: '', // 体重指数目标
        heartRate: '', // 心率
        other: '', // 其他
        smoking: '', // 日吸烟量
        aimSmoking: '', // 日吸烟量目标
        drinking: '', // 日饮酒量
        aimDrinking: '', // 日饮酒量目标
        motionWeek: '', // 运动一周
        aimMotionWeek: '', // 运动一周目标
        motionMinute: '', // 运动每分钟
        aimMotionMinute: '', // 运动每分钟目标
        salt: '', // 摄盐情况
        aimSalt: '', // 摄盐情况目标
        psy: '', // 心里调整
        respect: '', // 遵医行为
        fzjc: '', // 辅助检查
        medicine: '', // 服药依从性
        adverseFlag: '', // 药物不良反应
        adverseReason: '', // 不良反应
        followupClassify: '', // 次次随访分类
        referralReason: '', // 转诊原因
        referralDept: '', // 转诊机构及科别
        nextFollowDate: '', // 下次随访日期
        sfysqm: '', // 随访医生签名
        lrsj: '', // 录入时间
        lrjg: '', // 录入机构
        lrr: '', // 录入人
        gxsj: '', // 更新时间
        gxjg: '', // 更新机构
        gxr: ''// 更新人
      },
      yys: [
        {
          name: '', // 药物名称
          forUse: '', // 次/日
          phr: '', // 用量
          unit: ''// 单位
        },
        {
          name: '', // 药物名称
          forUse: '', // 次/日
          phr: '', // 用量
          unit: ''// 单位
        }
      ]
    }
  },
  methods: {
    listClick (index) {
      this.listIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }

  .son-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    height: 100%;
    padding: 0 7px;
    padding-top: 30px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    .el-input {
      width: 300px;
    }

    .son-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;
      color: #fff;
      font-size: 18px;
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 110px);
      padding: 0 10px;
      padding-top: 10px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .list {
        display: flex;
        flex-direction: column;
        height: 84px;
        padding-top: 16px;
        border-bottom: 1px solid #fff;
        box-sizing: border-box;
        color: #fff;
        font-size: 18px;
      }

      .active {
        color: #01EEFD;
      }
    }
  }

  .son-right {
    width: calc(100% - 380px);
    height: 100%;
    padding: 12px 9px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      padding: 0 20px;
      border-top: 1px solid #fff;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }

      .info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 9px;
        color: #fff;
        font-size: 16px;
      }

      .son-table {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #01EEFD;
        box-sizing: border-box;

        .table-flxed {
          display: flex;
          flex-direction: column;
          width: 282px;

          .table-box {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            color: #01EEFD;
            font-size: 14px;
          }

          .table-box1 {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 317px;
          }

          .table-box-left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 100%;
            writing-mode: vertical-lr;
            color: #01EEFD;
            font-size: 14px;
          }

          .table-box-right {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(100% - 70px);
            height: 100%;

            .el-checkbox-group {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-evenly;
              width: 90%;
            }

            .el-checkbox {
              width: 100%;
              margin-top: 10px;
              margin-right: 0;
            }

            /deep/.el-checkbox__label {
              color: #FFFFFF;
            }

            /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
              background-color: #01EEFD;
            }

            /deep/.is-checked .el-checkbox__label {
              color: #01EEFD;
            }
          }

          .border-right-bottom {
            border-right: 1px solid #01EEFD;
            border-bottom: 1px solid #01EEFD;
          }

          .border-right {
            border-right: 1px solid #01EEFD;
          }

          .border-bottom {
            border-bottom: 1px solid #01EEFD;
          }

          .table-box2 {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 250px;
          }

          .table-box3 {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 300px;
          }

          .table-box4 {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 200px;
          }

          .table-box5 {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100px;
          }
        }

        .table-scroll {
          overflow-x: scroll;
          white-space: nowrap;
          width: calc(100% - 282px);

          .el-input {
            width: 60%;
          }

          .input-number {
            width: 60%;
          }

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          .scroll-item {
            display: inline-block;
            width: 300px;
            min-width: 300px;

            .scroll-box {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 50px;
              box-sizing: border-box;
              color: #01EEFD;
              font-size: 14px;

              .box-item {
                flex: 1;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: #01EEFD;
                font-size: 14px;
              }
            }

            .border-right-bottom {
              border-right: 1px solid #01EEFD;
              border-bottom: 1px solid #01EEFD;
            }

            .border-right {
              border-right: 1px solid #01EEFD;
            }

            .border-bottom {
              border-bottom: 1px solid #01EEFD;
            }

            .el-radio-group {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              flex-wrap: wrap;
              align-content: space-around;
              width: 100%;
            }

            /deep/.el-radio__label {
              color: #FFFFFF;
            }

            .el-radio {
              margin-right: 0;
            }

            .box-label {
              width: 50%;
              padding-left: 4px;
              box-sizing: border-box;
            }

            /deep/.el-radio__input.is-checked .el-radio__inner {
              background-color: #01EEFD;
            }

            /deep/.is-checked .el-radio__label {
              color: #01EEFD;
            }

            .scroll-box1 {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
              height: 317px;

              .el-checkbox-group {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
              }

              .el-checkbox {
                width: 100%;
                margin-top: 10px;
                margin-right: 0;
              }

              /deep/.el-checkbox__label {
                color: #FFFFFF;
              }

              /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
                background-color: #01EEFD;
              }

              /deep/.is-checked .el-checkbox__label {
                color: #01EEFD;
              }
            }
          }
        }
      }
    }
  }
}
</style>
