<template>
  <div class="access">
    <div class="access-head">
      <div class="access-text">
        <span>李明敏</span>
      </div>
      <div class="access-text">
        <span>女</span>
      </div>
      <div class="access-text">
        <span>38岁</span>
      </div>
      <div class="access-text">
        <span>电话：15888888888</span>
      </div>
      <div class="access-text">
        <span>地址：河北省石家庄市桥西区友谊街道258号</span>
      </div>
      <div class="access-label yellow">
        <span>高</span>
      </div>
      <div class="access-label green">
        <span>糖</span>
      </div>
      <div class="access-label blue">
        <span>冠</span>
      </div>
      <div class="access-label purple">
        <span>肿</span>
      </div>
      <div class="access-label red">
        <span>精</span>
      </div>
    </div>
    <div class="access-box">
      <div class="access-left">
        <div class="access-left-title">
          <span>患者摘要</span>
        </div>
        <div class="access-left-scroll">
          <div class="access-scroll-title access-scroll-title1">
            <span class="icon"></span>
            <span>健康视图</span>
          </div>
          <div class="access-scroll-label">
            <span>高血压视图</span>
          </div>
          <div class="access-scroll-label">
            <span>糖尿病视图</span>
          </div>
          <div class="access-scroll-label">
            <span>儿童视图</span>
          </div>

          <div class="access-scroll-title">
            <span class="icon"></span>
            <span>诊断</span>
          </div>
          <div class="access-scroll-label">
            <span>便秘：2次</span>
          </div>
          <div class="access-scroll-label">
            <span>带状疱症：3次</span>
          </div>

          <div class="access-scroll-title">
            <span class="icon"></span>
            <span>体征</span>
          </div>
          <div class="access-scroll-label">
            <span>血压：122/83mmHg</span>
          </div>
          <div class="access-scroll-label">
            <span>血糖：3.2mmol</span>
          </div>
          <div class="access-scroll-label">
            <span>BMI：24.46</span>
          </div>

          <div class="access-scroll-title">
            <span class="icon"></span>
            <span>生活习惯</span>
          </div>
          <div class="access-scroll-label">
            <span>吸烟情况：无</span>
          </div>
          <div class="access-scroll-label">
            <span>饮食情况：嗜糖</span>
          </div>
          <div class="access-scroll-label">
            <span>饮酒情况：从不</span>
          </div>
          <div class="access-scroll-label">
            <span>运动情况：0次/周，0分钟/次</span>
          </div>

          <div class="access-scroll-title">
            <span class="icon"></span>
            <span>门诊用药</span>
          </div>
          <div class="access-scroll-label">
            <span>2022-08-03：合欢花</span>
          </div>
          <div class="access-scroll-label">
            <span>2022-08-03：阿斯匹林胶囊</span>
          </div>

          <div class="access-scroll-title">
            <span class="icon"></span>
            <span>异常检测项目</span>
          </div>
          <div class="access-scroll-label">
            <span>2022-08-03：CT常规检查</span>
          </div>

          <div class="access-scroll-title">
            <span class="icon"></span>
            <span>异常检验</span>
          </div>
          <div class="access-scroll-label">
            <span>2022-08-03：生化全套</span>
          </div>
        </div>
      </div>
      <div class="access-right">
        <div class="access-table access-table1">
          <div class="access-table-head">
            <span>慢病管理</span>
          </div>
          <div class="access-table-box">
            <el-table :data="tableData" border height="90%">
              <el-table-column prop="date" label="就诊时间">
              </el-table-column>
              <el-table-column prop="date" label="就诊类型">
              </el-table-column>
              <el-table-column prop="date" label="就诊机构">
              </el-table-column>
              <el-table-column prop="date" label="机构级别">
              </el-table-column>
              <el-table-column prop="date" label="诊断机构">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="access-table">
          <div class="access-table-head">
            <span>检查记录</span>
          </div>
          <div class="access-table-box">
            <el-table :data="tableData" border height="90%">
              <el-table-column prop="date" label="报告时间">
              </el-table-column>
              <el-table-column prop="date" label="项目名称">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="access-table">
          <div class="access-table-head">
            <span>检验记录</span>
          </div>
          <div class="access-table-box">
            <el-table :data="tableData" border height="90%">
              <el-table-column prop="date" label="报告时间">
              </el-table-column>
              <el-table-column prop="date" label="项目名称">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="access-table">
          <div class="access-table-head">
            <span>用药记录</span>
          </div>
          <div class="access-table-box">
            <el-table :data="tableData" border height="90%">
              <el-table-column prop="date" label="报告时间">
              </el-table-column>
              <el-table-column prop="date" label="项目名称">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="access-table">
          <div class="access-table-head">
            <span>高血压管理（随访29次，评估0次）</span>
          </div>
          <div class="access-table-box">
            <el-table :data="tableData" border height="90%">
              <el-table-column prop="date" label="服务日期">
              </el-table-column>
              <el-table-column prop="date" label="服务名称">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="access-table">
          <div class="access-table-head">
            <span>糖尿病管理（随访29次，评估0次）</span>
          </div>
          <div class="access-table-box">
            <el-table :data="tableData" border height="90%">
              <el-table-column prop="date" label="服务日期">
              </el-table-column>
              <el-table-column prop="date" label="服务名称">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: []
    }
  }
}
</script>

<style lang="less" scoped>
.access {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 10px;
  // background-color: #081C38;
  background-color: #fff;
  box-sizing: border-box;

  .access-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    padding-left: 57px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    .access-text {
      margin-right: 10px;
      color: #fff;
      font-size: 16px;
    }

    .access-label {
      width: 32px;
      height: 32px;
      line-height: 32px;
      margin-left: 20px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      border-radius: 50%;
    }

    .yellow {
      background-color: #E34D04;
    }

    .green {
      background-color: #109607;
    }

    .blue {
      background-color: #075896;
    }

    .purple {
      background-color: #790796;
    }

    .red {
      background-color: #FA2323;
    }
  }

  .access-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 10px;

    .access-left {
      width: 256px;
      height: 100%;
      padding: 0 3px;
      background-color: #0C4969;
      border-radius: 6px;
      box-sizing: border-box;

      .access-left-title {
        height: 36px;
        padding-top: 10px;
        padding-left: 7px;
        border-bottom: 1px solid #fff;
        color: #fff;
        font-size: 14px;
        box-sizing: border-box;
      }

      .access-left-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 60px);
        margin-top: 10px;

        &::-webkit-scrollbar {
          width: 0;
        }

        .access-scroll-title {
          display: flex;
          align-items: center;
          margin-top: 14px;
          color: #fff;
          font-size: 16px;

          .icon {
            width: 3px;
            height: 17px;
            margin-right: 3px;
            background-color: #01EEFD;
          }
        }

        .access-scroll-title1 {
          margin-top: 0;
        }

        .access-scroll-label {
          margin-top: 10px;
          padding-left: 6px;
          color: #fff;
          font-size: 14px;
          box-sizing: border-box;
        }
      }
    }

    .access-right {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      width: calc(100% - 266px);
      height: calc(100% - 6px);

      .access-table {
        display: flex;
        flex-direction: column;
        width: calc(33% - 10px);
        height: 33%;
        margin-right: 12px;

        .access-table-head {
          width: 100%;
          height: 38px;
          line-height: 38px;
          padding-left: 21px;
          background-color: #01EEFD;
          color: #fff;
          font-size: 18px;
          box-sizing: border-box;
        }

        .access-table-box {
          width: calc(100% - 4px);
          height: calc(100% - 40px);
          padding-left: 4px;
          box-sizing: border-box;
        }
      }

      .access-table1 {
        width: 100%;
      }
    }
  }

  .el-table {
    margin-top: 10px;
  }

  /deep/.el-table__cell {
    text-align: center;
    color: #000;
    font-size: 16px;
  }
}
</style>
