<template>
  <div class="summary">
    <div class="summary-scroll">
      <div class="summary-title">
        <span>健康体检表</span>
      </div>
      <div class="summary-info">
        <div class="summary-info-left">
          <span class="span">姓名: 王玉方</span>
          <span class="span">身份证号: 132124195307010319</span>
          <span class="span">性别: 男</span>
          <span class="span">年龄: 70</span>
          <span class="span">现住址:</span>
          <span>联系电话: </span>
        </div>
        <div class="summary-info-right">
          <span>编号: 603308832</span>
        </div>
      </div>
      <table class="table" border="1">
        <tr>
          <td class="td" colspan="8">
            <span class="text">考核项: 54</span>
            <span class="text">缺项: 0</span>
            <span class="text">完整率: 100%</span>
          </td>
        </tr>
        <tr>
          <td class="td" colspan="2">
            <span class="tad-blue">体检日期</span>
          </td>
          <td class="td" colspan="2">
            <el-date-picker v-model="value1" type="date" placeholder="">
            </el-date-picker>
          </td>
          <td class="td" colspan="2">
            <span>责任医生</span>
          </td>
          <td class="td" colspan="2">
            <el-select v-model="value" placeholder="">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="td td-title" colspan="2">
            <span>内容</span>
          </td>
          <td class="td" colspan="6">
            <span>检查项目</span>
          </td>
        </tr>
        <tr></tr>
        <!-- 症状 -->
        <tr>
          <td class="td td-title" colspan="2">
            <span class="tad-blue">症状</span>
          </td>
          <td class="td" colspan="6">
            <el-checkbox-group v-model="list">
              <el-checkbox v-for="(item, index) in checks" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
              <el-input class="input"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <!-- 一般状况 -->
        <tr>
          <td class="td td-title" rowspan="9" colspan="2">
            <p>一般状况</p>
            <p class="record">测量记录</p>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">体温</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>℃</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">脉率</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>次/分钟</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="2">
            <span class="tad-blue">呼吸频率</span>
          </td>
          <td class="td" colspan="2" rowspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>次/分钟</span>
          </td>
          <td class="td td-title1" rowspan="2">
            <span>血压</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">左侧</span>
          </td>
          <td class="td">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span> / </span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>mmhg</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title">
            <span class="tad-blue">右侧</span>
          </td>
          <td class="td">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span> / </span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>mmhg</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">身高</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>cm</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">体重</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>kg</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">腰围</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>cm</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">体脂指数</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>kg/㎡</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人健康状态自我评估*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group class="line-paddint-left" v-model="radio">
              <el-radio v-for="(item, index) in radios" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人生活自理能力自我评估*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group class="line-paddint-left" v-model="radio">
              <el-radio v-for="(item, index) in radios1" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人认知功能*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group class="line-paddint-left" v-model="radio">
              <el-radio v-for="(item, index) in radios2" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人情感状态*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group class="line-paddint-left" v-model="radio">
              <el-radio v-for="(item, index) in radios3" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <!-- 生活方式 -->
        <tr>
          <td class="td td-title" rowspan="10" colspan="2">
            <spn>生活方式</spn>
          </td>
          <td class="td td-title1" rowspan="3">
            <span>体育锻炼</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">锻炼频率</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group class="line-paddint-left" v-model="radio">
              <el-radio v-for="(item, index) in radios4" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>每次锻炼时间</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>分钟</span>
          </td>
          <td class="td td-title1">
            <span>坚持锻炼时间</span>
          </td>
          <td class="td" colspan="3">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>年</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>锻炼方式</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>饮食习惯</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="list">
              <el-checkbox v-for="(item, index) in checks1" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      value1: '',
      options: [],
      value: '',
      list: [],
      num: null,
      radio: '',
      checks: Object.freeze(['无症状', '头疼', '心悸', '胸闷', '胸痛', '慢性咳嗽', '咳痰', '呼吸苦难', '多饮', '多尿', '体重下降', '乳房胀痛', '乏力', '关节肿痛', '视力模糊', '手脚麻木', '尿急', '尿痛', '便秘', '腹泻', '恶心呕吐', '眼花', '耳鸣', '其他']),
      checks1: Object.freeze(['荤素均衡', '荤食为主', '素食为主', '嗜盐', '嗜油', '嗜糖']),
      radios: Object.freeze(['满意', '基本满意', '说不清楚', '不太满意', '不满意']),
      radios1: Object.freeze(['可自理（0-3分）', '轻度依赖（4-8分） ', '中度依赖（9-18分） ', '不能（9-18分） ']),
      radios2: Object.freeze(['粗筛阴性', '粗筛阳性，老年人智力检查']),
      radios3: Object.freeze(['粗筛阴性', '粗筛阳性，老年人抑郁检查']),
      radios4: Object.freeze(['每天', '每周一次以上', '偶尔', '不锻炼'])
    }
  }
}
</script>

<style lang="less" scoped>
.summary {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .summary-scroll {
    overflow-y: scroll;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .summary-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }

  .summary-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;

    .summary-info-left {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 18px;

      .span {
        margin-right: 20px;
      }
    }

    .summary-info-right {
      color: #000;
      font-size: 18px;
    }
  }

  .table {
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;

    .td {
      padding: 10px;
      font-size: 16px;

      .text {
        margin-right: 30px;
      }
    }

    .td-title {
      min-width: 150px;
    }

    .td-title1 {
      width: 120px;
    }

    .tad-blue {
      color: #1f21fd;
    }

    .record {
      color: #409eff;
      text-decoration: underline;
      cursor: pointer;
    }

    /deep/.el-checkbox {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    /deep/.el-checkbox__label {
      color: #000;
      font-size: 16px;
    }

    .input {
      width: 200px;
    }

    /deep/.el-input__inner {
      text-align: center;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #929394;
      color: #000;
      font-size: 16px;
    }

    /deep/.el-radio__label {
      color: #000;
      font-size: 16px;
    }
  }
}
</style>
