import { render, staticRenderFns } from "./son1.vue?vue&type=template&id=9f64c3ec&scoped=true&"
import script from "./son1.vue?vue&type=script&lang=js&"
export * from "./son1.vue?vue&type=script&lang=js&"
import style0 from "./son1.vue?vue&type=style&index=0&id=9f64c3ec&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f64c3ec",
  null
  
)

export default component.exports