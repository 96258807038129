<template>
  <div class="services">
    <div class="services-tab">
      <div class="services-item" :class="{ 'services-item-active': index === tabIndex }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="services-tab-box">
      <Tab1 v-if="tabIndex === 0" :params="params" @close="close"></Tab1>
      <Tab2 v-else-if="tabIndex === 1" :params="params" @close="close"></Tab2>
      <Tab3 v-else-if="tabIndex === 2" :params="params" @close="close"></Tab3>
      <Tab4 v-else-if="tabIndex === 3" :params="params" @close="close"></Tab4>
    </div>
  </div>
</template>

<script>
import Tab1 from './tab1.vue'
import Tab2 from './tab2.vue'
import Tab3 from './tab3.vue'
import Tab4 from './tab4.vue'
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: ''
        }
      }
    }
  },
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['体检表', '中医体质辨识', '老年人评估', '中医健康指导'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.services {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .services-tab {
    display: flex;
    width: 100%;
    height: 50px;

    .services-item {
      height: 40px;
      line-height: 40px;
      margin-right: 20px;
      padding: 0 20px;
      background-color: #D5D5D5;
      color: #fff;
      font-size: 22px;
      cursor: pointer;
    }

    .services-item-active {
      background-color: #01EEFD;
    }
  }

  .services-tab-box {
    width: 100%;
    height: calc(100% - 50px);
  }
}
</style>
