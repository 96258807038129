<template>
  <div class="children">
    <div class="children-title">
      <span>老年人生活能力自我评估表</span>
    </div>
    <!-- <div class="children-title1">
      <span>该表为自评表，根据下表中5个方面进行评估，将各方面判断评分汇总后，0～3分者为可自理；4～8分者为轻度依赖；9～18分者为中度依赖； 19分者为不能自理。</span>
    </div> -->
    <div class="children-info">
      <div class="item">
        <span>姓名：程霞</span>
      </div>
      <div class="item">
        <span>编号：</span>
      </div>
    </div>
    <div class="table">
      <div class="tr">
        <div class="td td-left">
          <span>评估事项、内容与评分</span>
        </div>
        <div class="td5">
          <div class="td-item">
            <span>程度等级</span>
          </div>
          <div class="td-item">
            <div class="tr-td">
              <span>可自理</span>
            </div>
            <div class="tr-td">
              <span>轻度依赖</span>
            </div>
            <div class="tr-td">
              <span>中度依赖</span>
            </div>
            <div class="tr-td">
              <span>不能自理</span>
            </div>
            <div class="tr-td">
              <span>判断评分</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>进餐：使用餐具将饭菜送入口、咀嚼、吞咽等活动</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <span>独立完成</span>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
          <div class="td td-left">
            <span>需要协助，如切碎、搅拌食物等</span>
          </div>
          <div class="td td-left">
            <span>完全需要帮助</span>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tr tr1">
        <div class="td td-left">
          <span>评分</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <el-radio label="0" v-model="info.lnrpgjincan">0</el-radio>
          </div>
          <div class="td td-left">
            <!-- <el-radio label="0" v-model="info.lnrpgjincan">0</el-radio> -->
          </div>
          <div class="td td-left">
            <el-radio label="3" v-model="info.lnrpgjincan">3</el-radio>
          </div>
          <div class="td td-left">
            <el-radio label="5" v-model="info.lnrpgjincan">5</el-radio>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>梳洗：梳头、洗脸、刷牙、剃须、洗澡等活动</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <span>独立完成</span>
          </div>
          <div class="td td-left">
            <span>能独立地洗头、梳头、洗脸、刷牙、剃须等；洗澡需要协助</span>
          </div>
          <div class="td td-left">
            <span>在协助下和适当的时间内，能完成部分梳洗活动</span>
          </div>
          <div class="td td-left">
            <span>完全需要帮助</span>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tr tr1">
        <div class="td td-left">
          <span>评分</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <el-radio label="0" v-model="info.lnrpgshufa">0</el-radio>
          </div>
          <div class="td td-left">
            <el-radio label="1" v-model="info.lnrpgshufa">1</el-radio>
          </div>
          <div class="td td-left">
            <el-radio label="3" v-model="info.lnrpgshufa">3</el-radio>
          </div>
          <div class="td td-left">
            <el-radio label="7" v-model="info.lnrpgshufa">7</el-radio>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
        </div>
      </div>

      <div class="tr">
        <div class="td td-left">
          <span>穿衣：穿衣裤、袜子、鞋子等活动</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <span>独立完成</span>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
          <div class="td td-left">
            <span>需要协助，在适当的时间内完成部分穿衣</span>
          </div>
          <div class="td td-left">
            <span>完全需要帮助</span>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tr tr1">
        <div class="td td-left">
          <span>评分</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <el-radio label="0" v-model="info.lnrpgchuanyi">0</el-radio>
          </div>
          <div class="td td-left">
            <!-- <el-radio label="0" v-model="info.lnrpgchuanyi">0</el-radio> -->
          </div>
          <div class="td td-left">
            <el-radio label="3" v-model="info.lnrpgchuanyi">3</el-radio>
          </div>
          <div class="td td-left">
            <el-radio label="5" v-model="info.lnrpgchuanyi">5</el-radio>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
        </div>
      </div>

      <div class="tr">
        <div class="td td-left">
          <span>如厕：小便、大便等活动及自控</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <span>不需协助，可自控</span>
          </div>
          <div class="td td-left">
            <span>偶尔失禁，但基本上能如厕或使用便具</span>
          </div>
          <div class="td td-left">
            <span>经常失禁，在很多提示和协助下尚能如厕或使用便具</span>
          </div>
          <div class="td td-left">
            <span>完全需要帮助</span>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tr tr1">
        <div class="td td-left">
          <span>评分</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <el-radio label="0" v-model="info.lnrpgruce">0</el-radio>
          </div>
          <div class="td td-left">
            <el-radio label="1" v-model="info.lnrpgruce">1</el-radio>
          </div>
          <div class="td td-left">
            <el-radio label="5" v-model="info.lnrpgruce">5</el-radio>
          </div>
          <div class="td td-left">
            <el-radio label="10" v-model="info.lnrpgruce">10</el-radio>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
        </div>
      </div>

      <div class="tr">
        <div class="td td-left">
          <span>活动：站立、室内行走、上下楼梯、户外活动</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <span>独立完成所有活动</span>
          </div>
          <div class="td td-left">
            <span>借助较小的外力或辅助装置能完成站立、行走、上下楼梯等</span>
          </div>
          <div class="td td-left">
            <span>借助较大的外力才能完成站立、行走，不能上下楼梯</span>
          </div>
          <div class="td td-left">
            <span>完全需要帮助</span>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tr tr1">
        <div class="td td-left">
          <span>评分</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <el-radio label="0" v-model="info.lnrpghuodong">0</el-radio>
          </div>
          <div class="td td-left">
            <el-radio label="1" v-model="info.lnrpghuodong">1</el-radio>
          </div>
          <div class="td td-left">
            <el-radio label="5" v-model="info.lnrpghuodong">5</el-radio>
          </div>
          <div class="td td-left">
            <el-radio label="10" v-model="info.lnrpghuodong">10</el-radio>
          </div>
          <div class="td td-left">
            <span></span>
          </div>
        </div>
      </div>

      <div class="tr tr1">
        <div class="td td-left">
          <span>总得分：{{ info.zdfhj || '' }}</span>
        </div>
      </div>
    </div>
    <div class="services-btns">
        <el-button>编辑</el-button>
        <el-button>保存</el-button>
        <el-button>审核</el-button>
        <el-button>上传</el-button>
        <el-button>打印</el-button>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: ''
        }
      }
    }
  },
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: [],
      radio: '1',
      info: {
        id: 0,
        createTime: '',
        updateTime: '',
        jktjid: '',
        grjbxxid: '',
        tjbh: '',
        name: '',
        lnrpgjincan: '',
        lnrpgshufa: '',
        lnrpgchuanyi: '',
        lnrpgruce: '',
        lnrpghuodong: '',
        zdfhj: '',
        jkdnid: '',
        flag: '',
        sfzh: '',
        lrsj: '',
        age: '',
        xb: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591859
      try {
        const obj = {
          grjbxxid: this.params.grjbxxid || '',
          jktjid: this.params.tmcode || ''
        }
        const { data: res } = await this.$http.post('/ykdlnrshzlpgbs/finds', obj)
        console.log('老年人生活自理评估--查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data[0]
        // const arr = ['tzlxqxzzd', 'tzlxyxzzd', 'tzlxyinxzzd', 'tzlxtszzd', 'tzlxsrzzd', 'tzlxxyzzd', 'tzlxqyzzd', 'tzlxtbzzd', 'tzlxphzzd']
        // for (var i = 0; i < arr.length; i++) {
        //   this.updateData(arr[i])
        // }
      } catch (error) {
        console.log('老年人生活自理评估--查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    submit () {
      console.log(this.info)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54px;
    text-align: center;
    color: #475265;
    font-size: 22px;
  }

  .children-title1 {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 300px;
    box-sizing: border-box;
    text-decoration: left;
    color: #475265;
    font-size: 20px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 0 100px;
    box-sizing: border-box;

    .item {
      min-width: 206px;
      color: #475265;
      font-size: 22px;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #475265;
    box-sizing: border-box;

    .tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100px;
      border-bottom: 1px solid #475265;
      box-sizing: border-box;

      .td {
        flex: 1;
        height: 100%;
        color: #475265;
        font-size: 14px;
      }

      .td-left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 10px;
        border-right: 1px solid #475265;
        box-sizing: border-box;
      }

      .td5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 5;
        height: 100%;

        .td-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 50px;
          color: #475265;
          font-size: 14px;
        }

        .td-item:first-child {
          border-bottom: 1px solid #475265;
          box-sizing: border-box;
        }

        .tr-td {
          flex: 1;
          height: 100%;
          line-height: 50px;
          text-align: center;
          border-left: 1px solid #475265;
          box-sizing: border-box;
          color: #fff;
          font-size: 14px;
        }

        .tr-td:first-child {
          border-left: none;
        }
      }

      .row {
        flex-direction: row;
      }

      .el-radio {
        color: #fff;
      }

      /deep/.el-radio__label {
        font-size: 18px;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #475265;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        background-color: #475265;
        border-color: #475265;
      }

      /deep/.el-radio__inner {
        width: 18px;
        height: 18px;
        background-color: transparent;
      }
    }

    .tr:last-child {
      border-bottom: none;
    }

    .tr1 {
      height: 50px;
    }
  }

  .services-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #475265;

    .el-button {
      min-width: 120px;
      margin-left: 15px;
      margin-right: 15px;
      padding-top: 6px;
      padding-bottom: 6px;
      border: none;
      background-color: #3782F2;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }
  }

}
</style>
