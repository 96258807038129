<template>
  <div class="summary">
    <div class="summary-scroll">
      <div class="summary-title">
        <span>健康体检表</span>
      </div>
      <div class="summary-info">
        <div class="summary-info-left">
          <span class="span">姓名: {{ info.name || '-' }}</span>
          <span class="span">身份证号: {{ info.sfzh || '-' }}</span>
          <span class="span">性别: {{ info.xb === '1' ? '男' : '女' }}</span>
          <span class="span">年龄: {{ info.age || '-' }}</span>
          <span class="span">人群分类：{{ info.rqfl || '-' }}</span>
          <span class="span">现住址：{{ info.jkdnxzz || '-' }}</span>
          <span>联系电话: {{ info.lxdh || '-' }}</span>
        </div>
        <div class="summary-info-right">
          <span>编号: {{ info.tjbh || '-' }}</span>
        </div>
      </div>
      <table class="table" border="1">
        <tr>
          <td class="td" colspan="8">
            <span class="text">考核项: -</span>
            <span class="text">缺项: -</span>
            <span class="text">完整率: -</span>
          </td>
        </tr>
        <tr>
          <td class="td" colspan="2">
            <span class="tad-blue">体检日期</span>
          </td>
          <td class="td" colspan="2">
            <el-date-picker v-model="info.tjrq" type="date" placeholder="">
            </el-date-picker>
          </td>
          <td class="td">
            <span>责任医生</span>
          </td>
          <td class="td" colspan="2">
            <el-select v-model="info.zrys" placeholder="">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="td td-title">
            <span>内容</span>
          </td>
          <td class="td" colspan="6">
            <span>检查项目</span>
          </td>
        </tr>
        <tr></tr>
        <!-- 症状 -->
        <tr>
          <td class="td td-title">
            <p class="tad-blue">症状</p>
            <p class="record" @click="openZzHistory">历史记录</p>
          </td>
          <td class="td" colspan="6">
            <el-checkbox-group v-model="info.jcxmzz" @change="zzChange">
              <el-checkbox v-for="(item, index) in checks" :key="index" :label="index + 1 + ''" :disabled="index > 0 && info.jcxmzz.indexOf('1')> -1">{{ item }}</el-checkbox>
              <el-input class="zz-input" v-if="info.jcxmzz.indexOf('24')> -1" v-model="info.jcxmzzDes"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <!-- 一般状况 -->
        <tr>
          <td class="td td-title" rowspan="9">
            <p>一般状况</p>
            <p class="record" @click="openHistory1">历史记录</p>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">体温</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="info.ybzktw" controls-position="right" :min="0"></el-input-number>
            <span>℃</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">脉率</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="info.ybzkml" controls-position="right" :min="0"></el-input-number>
            <span>次/分钟</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="2">
            <span class="tad-blue">呼吸频率</span>
          </td>
          <td class="td" colspan="2" rowspan="2">
            <el-input-number size="small" v-model="info.ybzkhxpl" controls-position="right" :min="0"></el-input-number>
            <span>次/分钟</span>
          </td>
          <td class="td td-title1" rowspan="2">
            <span>血压</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">左侧</span>
          </td>
          <td class="td">
            <el-input-number size="small" v-model="info.ybzkxycc" controls-position="right" :min="0"></el-input-number>
            <span> / </span>
            <el-input-number size="small" v-model="info.ybzksxycc" controls-position="right" :min="0"></el-input-number>
            <span>mmhg</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title">
            <span class="tad-blue">右侧</span>
          </td>
          <td class="td">
            <el-input-number size="small" v-model="info.ybzkxyyc" controls-position="right" :min="0"></el-input-number>
            <span> / </span>
            <el-input-number size="small" v-model="info.ybzksxyyc" controls-position="right" :min="0"></el-input-number>
            <span>mmhg</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">身高</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="info.ybzksg" controls-position="right" :min="0"></el-input-number>
            <span>cm</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">体重</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="info.ybzktz" controls-position="right" :min="0"></el-input-number>
            <span>kg</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">腰围</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="info.ybzkyw" controls-position="right" :min="0"></el-input-number>
            <span>cm</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">体质指数</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="info.ybzktzzs" controls-position="right" :min="0"></el-input-number>
            <span>kg/㎡</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人健康状态自我评估*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.lnrjkztzwpg">
              <el-radio v-for="(item, index) in radios" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人生活自理能力自我评估*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.lnrshzlnlzwpg">
              <el-radio v-for="(item, index) in radios1" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人认知功能*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.lnrrzgn">
              <el-radio v-for="(item, index) in radios2" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人情感状态*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.lnrqgzt">
              <el-radio v-for="(item, index) in radios3" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <!-- 生活方式 -->
        <tr>
          <td class="td td-title" rowspan="13">
            <p>生活方式</p>
            <p class="record" @click="openHistory2">历史记录</p>
          </td>
          <td class="td td-title1" rowspan="3">
            <span>体育锻炼</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">锻炼频率</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.shfstydldlpl">
              <el-radio v-for="(item, index) in radios4" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>每次锻炼时间</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="info.shfstydlmcdlsj" controls-position="right" :min="0"></el-input-number>
            <span>分钟</span>
          </td>
          <td class="td td-title1">
            <span>坚持锻炼时间</span>
          </td>
          <td class="td" colspan="3">
            <el-input-number size="small" v-model="info.shfstydljcdlsj" controls-position="right" :min="0"></el-input-number>
            <span>年</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>锻炼方式</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input" v-model="info.shfstydldlfs"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>饮食习惯</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="info.shfsysxg">
              <el-checkbox v-for="(item, index) in checks1" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="3">
            <span>吸烟情况</span>
          </td>
          <td class="td td-title1">
            <span>吸烟情况</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.shfsxyqkxyzk">
              <el-radio v-for="(item, index) in radios5" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>日吸烟量</span>
          </td>
          <td class="td" colspan="7">
            <span>平均</span>
            <el-input class="input" v-model="info.shfsxyqkrxyl"></el-input>
            <span>支</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>开始吸烟年龄</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="info.shfsxyqkksxynl" controls-position="right" :min="0"></el-input-number>
            <span>岁</span>
          </td>
          <td class="td td-title1">
            <span>戒烟年龄</span>
          </td>
          <td class="td" colspan="3">
            <el-input-number size="small" v-model="info.shfsxyqkjynl" controls-position="right" :min="0"></el-input-number>
            <span>岁</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="5">
            <span>饮酒情况</span>
          </td>
          <td class="td td-title1">
            <span>饮酒频率</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.shfsyjqkyjpl">
              <el-radio v-for="(item, index) in radios6" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>日饮酒量</span>
          </td>
          <td class="td" colspan="7">
            <span>平均</span>
            <el-input-number size="small" v-model="info.shfsyjqkryjl" controls-position="right" :min="0"></el-input-number>
            <span>两</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>是否戒酒</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.shfsyjqksfjj">
              <el-radio v-for="(item, index) in radios7" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <span class="span-left">戒酒年龄</span>
            <el-input-number size="small" v-model="info.shfsyjqkjjnl" controls-position="right" :min="0"></el-input-number>
            <span>岁</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>开始饮酒年龄</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="info.shfsyjqkksyjnl" controls-position="right" :min="0"></el-input-number>
            <span>岁</span>
          </td>
          <td class="td td-title1">
            <span>近一年是否醉酒</span>
          </td>
          <td class="td" colspan="3">
            <el-radio-group v-model="info.shfsyjqksfzj">
              <el-radio v-for="(item, index) in radios8" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>饮酒种类</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.shfsyjqkyjzl">
              <el-radio v-for="(item, index) in checks2" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">职业病危害因素接触史</span>
          </td>
          <td class="td" colspan="7">
            <div class="list">
              <el-radio-group v-model="info.zybwhysjcs">
                <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
              <span>（工种</span>
              <el-input class="input2" :disabled="info.zybwhysjcs !== '2'" v-model="info.zybgz"></el-input>
              <span>从业</span>
              <el-input class="input2" :disabled="info.zybwhysjcs !== '2'" v-model="info.zybcysjn"></el-input>
              <span>年）</span>
            </div>
            <div class="list">
              <span>毒粉种类-粉尘</span>
              <el-input class="input2" v-model="info.dwzlfc"></el-input>
              <span class="span-left2">防护措施</span>
              <el-radio-group class="span-left2" v-model="info.dwzlfcfhcs">
                <el-radio v-for="(item, index) in radios28" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
              <el-input class="input2" :disabled="info.dwzlfcfhcs !== '2'" v-model="info.dwzlfcbz"></el-input>
            </div>
            <div class="list">
              <span>毒粉种类-放射物质</span>
              <el-input class="input2" v-model="info.dwzlfswz"></el-input>
              <span class="span-left2">防护措施</span>
              <el-radio-group class="span-left2" v-model="info.dwzlfswzfhcs">
                <el-radio v-for="(item, index) in radios28" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
              <el-input class="input2" :disabled="info.dwzlfswzfhcs !== '2'" v-model="info.dwzlfswzbz"></el-input>
            </div>
            <div class="list">
              <span>毒粉种类-物理因素</span>
              <el-input class="input2" v-model="info.dwzlwlys"></el-input>
              <span class="span-left2">防护措施</span>
              <el-radio-group class="span-left2" v-model="info.dwzlwlysfhcs">
                <el-radio v-for="(item, index) in radios28" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
              <el-input class="input2" :disabled="info.dwzlwlysfhcs !== '2'" v-model="info.dwzlwlysbz"></el-input>
            </div>
            <div class="list">
              <span>毒粉种类-化学物质</span>
              <el-input class="input2" v-model="info.dwzlhxwz"></el-input>
              <span class="span-left2">防护措施</span>
              <el-radio-group class="span-left2" v-model="info.dwzkhxwzfhcs">
                <el-radio v-for="(item, index) in radios28" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
              <el-input class="input2" :disabled="info.dwzkhxwzfhcs !== '2'" v-model="info.dwzlhxwzbz"></el-input>
            </div>
            <div class="list">
              <span>毒粉种类-其他</span>
              <el-input class="input2" v-model="info.dwzlfhcsqt"></el-input>
              <span class="span-left2">防护措施</span>
              <el-radio-group class="span-left2" v-model="info.dwzlqtfhcs">
                <el-radio v-for="(item, index) in radios28" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
              <el-input class="input2" :disabled="info.dwzlqtfhcs !== '2'" v-model="info.dwzlfhcsqtbz"></el-input>
            </div>
          </td>
        </tr>
        <!-- 脏器功能 -->
        <tr>
          <td class="td td-title" rowspan="9">
            <p>脏器功能</p>
            <p class="record" @click="openHistory3">历史记录</p>
          </td>
          <td class="td td-title1" rowspan="6">
            <span>口腔</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">口唇</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.zqgnkqkc">
              <el-radio v-for="(item, index) in radios10" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="4">
            <span class="tad-blue">齿列</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox label="1" v-model="info.kqcl">正常</el-checkbox>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <el-checkbox label="1" :disabled="info.kqcl.indexOf('1') > -1" v-model="info.isLosetTeeth">缺齿</el-checkbox>
          </td>
          <td class="td" colspan="7">
            <el-checkbox label="1" :disabled="info.isLosetTeeth.indexOf('1') === -1" v-model="info.allLoseTeeth">全口</el-checkbox>
            <span class="span-left2">左上</span>
            <el-input-number size="small" v-model="info.kqqcscs" controls-position="right" :min="0" :disabled="info.allLoseTeeth.indexOf('1') !== -1"></el-input-number>
            <span>左下</span>
            <el-input-number size="small" v-model="info.kqqcscx" controls-position="right" :min="0" :disabled="info.allLoseTeeth.indexOf('1') !== -1"></el-input-number>
            <span>右上</span>
            <el-input-number size="small" v-model="info.kqqcsyx" controls-position="right" :min="0" :disabled="info.allLoseTeeth.indexOf('1') !== -1"></el-input-number>
            <span>右下</span>
            <el-input-number size="small" v-model="info.kqqcsys" controls-position="right" :min="0" :disabled="info.allLoseTeeth.indexOf('1') !== -1"></el-input-number>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <el-checkbox label="1" :disabled="info.kqcl.indexOf('1') > -1" v-model="info.isDecayedTooth">龋齿</el-checkbox>
          </td>
          <td class="td" colspan="7">
            <el-checkbox label="1" :disabled="info.isDecayedTooth.indexOf('1') === -1" v-model="info.allDecayedTeeth">全口</el-checkbox>
            <span class="span-left2">左上</span>
            <el-input-number size="small" v-model="info.kqsfquczs" controls-position="right" :min="0" :disabled="info.allDecayedTeeth.indexOf('1') !== -1"></el-input-number>
            <span>左下</span>
            <el-input-number size="small" v-model="info.kqsfquczx" controls-position="right" :min="0" :disabled="info.allDecayedTeeth.indexOf('1') !== -1"></el-input-number>
            <span>右上</span>
            <el-input-number size="small" v-model="info.kqsfqucys" controls-position="right" :min="0" :disabled="info.allDecayedTeeth.indexOf('1') !== -1"></el-input-number>
            <span>右下</span>
            <el-input-number size="small" v-model="info.kqsfqucyx" controls-position="right" :min="0" :disabled="info.allDecayedTeeth.indexOf('1') !== -1"></el-input-number>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <el-checkbox label="1" :disabled="info.kqcl.indexOf('1') > -1" v-model="info.isFalseTeeth">义齿</el-checkbox>
          </td>
          <td class="td" colspan="7">
            <el-checkbox label="1" :disabled="info.isFalseTeeth.indexOf('1') === -1" v-model="info.allFalseTeeth">全口</el-checkbox>
            <span class="span-left2">左上</span>
            <el-input-number size="small" v-model="info.kqyccs" controls-position="right" :min="0" :disabled="info.allFalseTeeth.indexOf('1') !== -1"></el-input-number>
            <span>左下</span>
            <el-input-number size="small" v-model="info.kqyccx" controls-position="right" :min="0" :disabled="info.allFalseTeeth.indexOf('1') !== -1"></el-input-number>
            <span>右上</span>
            <el-input-number size="small" v-model="info.kqycys" controls-position="right" :min="0" :disabled="info.allFalseTeeth.indexOf('1') !== -1"></el-input-number>
            <span>右下</span>
            <el-input-number size="small" v-model="info.kqycyx" controls-position="right" :min="0" :disabled="info.allFalseTeeth.indexOf('1') !== -1"></el-input-number>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">咽部</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.kqyb">
              <el-radio v-for="(item, index) in radios11" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">视力</span>
          </td>
          <td class="td" colspan="7">
            <span>左眼</span>
            <el-input-number size="small" v-model="info.shililyzy" controls-position="right" :min="0"></el-input-number>
            <span>右眼</span>
            <el-input-number size="small" v-model="info.shililyyy" controls-position="right" :min="0"></el-input-number>
            <span>（矫正视力：左眼</span>
            <el-input-number size="small" v-model="info.shilijzslcy" controls-position="right" :min="0"></el-input-number>
            <span>右眼</span>
            <el-input-number size="small" v-model="info.shilijzslyy" controls-position="right" :min="0"></el-input-number>
            <span>）</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">听力</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.tingli">
              <el-radio v-for="(item, index) in radios12" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">运动功能</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.ydgn">
              <el-radio v-for="(item, index) in radios13" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <!-- 查体 -->
        <tr>
          <td class="td td-title1" rowspan="24">
            <p>查体</p>
            <p class="record" @click="openHistory4">历史记录</p>
          </td>
          <td class="td td-title1">
            <span>眼底*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatiyandi">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatiyandi !== '2'" v-model="info.chatiyandiDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">皮肤</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatipf">
              <el-radio v-for="(item, index) in radios15" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatipf !== '7'" v-model="info.chatipfDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">巩膜</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatigm">
              <el-radio v-for="(item, index) in radios16" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatigm !== '4'" v-model="info.chatigmDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">淋巴结</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatilbj">
              <el-radio v-for="(item, index) in radios17" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatilbj !== '4'" v-model="info.chatilbjDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="3">
            <span>肺</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">桶状胸</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifeitzx">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">呼吸音</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifeihxy">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifeihxy !== '2'" v-model="info.chatifeihxyDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">啰音</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifeily">
              <el-radio v-for="(item, index) in radios19" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifeily !== '4'" v-model="info.chatifeilyDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="2">
            <span>心</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">心率</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="info.chatixzxlv" controls-position="right" :min="0"></el-input-number>
            <span>次/分钟 </span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">心律</span>
          </td>
          <td class="td" colspan="3">
            <el-radio-group v-model="info.chatixzxlu">
              <el-radio v-for="(item, index) in radios20" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">杂音</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatixzx">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatixzx !== '2'" v-model="info.chatixzxyDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="5">
            <span>腹部</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">腹部压痛</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifbyt">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifbyt !== '2'" v-model="info.chatifbytDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">腹部包块</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifbbk">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifbbk !== '2'" v-model="info.chatifbbkDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">腹部肝大</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifbgd">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifbgd !== '2'" v-model="info.chatifbgdDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">腹部脾大</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifbpd">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifbpd !== '2'" v-model="info.chatifbpdDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">移动性浊音</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifbydxzy">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifbydxzy !== '2'" v-model="info.chatifbydxzyDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">下肢水肿</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatixzsz">
              <el-radio v-for="(item, index) in radios21" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>足背动脉搏动*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatizbdmbd">
              <el-radio v-for="(item, index) in radios22" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>肛门指诊*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatigmzz">
              <el-radio v-for="(item, index) in radios23" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatigmzz !== '5'" v-model="info.chatigmzzDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>乳腺*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatiruxian">
              <el-radio v-for="(item, index) in radios24" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input v-model="info.chatiruxianDes" :disabled="info.chatiruxian !== '5'" class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="5">
            <span>妇科*</span>
          </td>
          <td class="td td-title1">
            <span>外阴</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifkwy">
              <el-radio v-for="(item, index) in radios30" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifkwy !== '2'" v-model="info.chatifkwyDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>阴道</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifkyd">
              <el-radio v-for="(item, index) in radios30" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifkyd !== '2'" v-model="info.chatifkydDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>宫颈</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifkgj">
              <el-radio v-for="(item, index) in radios30" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifkgj !== '2'" v-model="info.chatifkgjDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>宫体</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifkgt">
              <el-radio v-for="(item, index) in radios30" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifkgt !== '2'" v-model="info.chatifkgtDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>附件</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.chatifkfj">
              <el-radio v-for="(item, index) in radios30" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.chatifkfj !== '2'" v-model="info.chatifkfjDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>其他*</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input1" v-model="info.chatiqtjc"></el-input>
          </td>
        </tr>
        <!-- 辅助检查 -->
        <tr>
          <td class="td td-title1" rowspan="16">
            <p>辅助检查</p>
            <p class="record" @click="openHistory5">历史记录</p>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">血常规*</span>
          </td>
          <td class="td" colspan="7">
            <span class="tad-blue">血红蛋白*</span>
            <el-input class="input2" v-model="info.chatixcgxhdb"></el-input>
            <span>g/L</span>
            <span class="span-left1 tad-blue">白细胞*</span>
            <el-input class="input2" v-model="info.chatixcgbxb"></el-input>
            <span>×10^9/L</span>
            <span class="span-left1 tad-blue">血小板*</span>
            <el-input class="input2" v-model="info.chatixcgxxb"></el-input>
            <span>×10^9/L</span>
            <span class="span-left1 tad-blue">其他*</span>
            <el-input class="input2" v-model="info.chatixcgqt"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">尿常规*</span>
          </td>
          <td class="td" colspan="7">
            <span class="tad-blue">尿蛋白*</span>
            <el-input class="input2" v-model="info.chatincgndb"></el-input>
            <span class="tad-blue">尿糖*</span>
            <el-input class="input2" v-model="info.chatincgnt"></el-input>
            <span class="tad-blue">尿酮体*</span>
            <el-input class="input2" v-model="info.chatincgntt"></el-input>
            <span class="tad-blue">尿潜血*</span>
            <el-input class="input2" v-model="info.chatincgnqx"></el-input>
            <span class="tad-blue">其他*</span>
            <el-input class="input2" v-model="info.chatincgqt"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">空腹血糖*</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input2" v-model="info.kfxtmool"></el-input>
            <span>mmol/L或</span>
            <el-input class="input2" v-model="info.kfxtmg"></el-input>
            <span>mg/dL</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">心电图*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.xdtzt">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input" :disabled="info.xdtzt !== '2'" v-model="info.xdtztDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>尿微量白蛋白*</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input2" v-model="info.nwlbdb"></el-input>
            <span>mg/dL</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>大便潜血*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.dbqxzt">
              <el-radio v-for="(item, index) in radios25" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>糖化血红蛋白*</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input2" v-model="info.thxhdb"></el-input>
            <span>%</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>乙型肝炎表面抗原*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.yxgybmky">
              <el-radio v-for="(item, index) in radios25" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">肝功能*</span>
          </td>
          <td class="td" colspan="7">
            <span class="tad-blue">血清谷丙转氨酶*</span>
            <el-input class="input2" v-model="info.gnnxqgnzam"></el-input>
            <span>U/L</span>
            <span class="span-left1 tad-blue">血清谷草转氨酶*</span>
            <el-input class="input2" v-model="info.gnnxqgczam"></el-input>
            <span>U/L</span>
            <span class="span-left1 tad-blue">白蛋白*</span>
            <el-input class="input2" v-model="info.gnnbdb"></el-input>
            <span>g/L</span>
            <span class="span-left1 tad-blue">总胆红素*</span>
            <el-input class="input2" v-model="info.gnnzdhs"></el-input>
            <span>μmol/L</span>
            <span class="span-left1 tad-blue">结合胆红素*</span>
            <el-input class="input2" v-model="info.gnnjhdhs"></el-input>
            <span>μmol/L</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">肾功能*</span>
          </td>
          <td class="td" colspan="7">
            <span class="tad-blue">肾功能血清肌酐*</span>
            <el-input class="input2" v-model="info.sgnxqjg"></el-input>
            <span>μmol/L</span>
            <span class="span-left1 tad-blue">肾功能血尿素*</span>
            <el-input class="input2" v-model="info.sgnxns"></el-input>
            <span>mmol/L</span>
            <span class="span-left1 tad-blue">肾功能血钾浓度*</span>
            <el-input class="input2" v-model="info.sgnxjnd"></el-input>
            <span>mmol/L</span>
            <span class="span-left1 tad-blue">肾功能血钠浓度*</span>
            <el-input class="input2" v-model="info.sgnxnnd"></el-input>
            <span>mmol/L</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">血脂*</span>
          </td>
          <td class="td" colspan="7">
            <span class="tad-blue">总胆固醇*</span>
            <el-input class="input2" v-model="info.xzhizdgc"></el-input>
            <span>μmol/L</span>
            <span class="span-left1 tad-blue">甘油三酯*</span>
            <el-input class="input2" v-model="info.xzhigysz"></el-input>
            <span>mmol/L</span>
            <span class="span-left1 tad-blue">血清低密度脂蛋白胆固醇*</span>
            <el-input class="input2" v-model="info.xzhixqdmdzdbdgc"></el-input>
            <span>mmol/L</span>
            <span class="span-left1 tad-blue">血清高密度脂蛋白胆固醇*</span>
            <el-input class="input2" v-model="info.xzhixqgmdzdbdgc"></el-input>
            <span>mmol/L</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>胸部X线片*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.xbxxp">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input1" :disabled="info.xbxxp !== '2'" v-model="info.xbxxpDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="2">
            <span>B超</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">腹部B超*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.bchaofbb">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input1" :disabled="info.bchaofbb !== '2'" v-model="info.bchaofbbcDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>其他*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.bchaoqtbbc">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input1" :disabled="info.bchaoqtbbc !== '2'" v-model="info.bchaoqtbbcDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>宫颈涂片*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.gjgp">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input1" :disabled="info.gjgp !== '2'" v-model="info.gjgpDes"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>其他*</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input1" v-model="info.fzjcqtjc"></el-input>
          </td>
        </tr>
        <!-- 现存主要健康问题 -->
        <tr>
          <td class="td td-title1" rowspan="7">
            <p>现存主要健康问题</p>
            <p class="record" @click="openHistory6">历史记录</p>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">脑血管疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="info.xczyjkwtnxgjb">
              <el-checkbox v-for="(item, index) in checks3" :key="index" :label="index + 1 + ''" :disabled="index > 0 && info.xczyjkwtnxgjb.indexOf('1') > -1" >{{ item }}</el-checkbox>
              <el-input class="input" :disabled="info.xczyjkwtnxgjb.indexOf('6') === -1" v-model="info.xczyjkwtnxgjbDes"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">肾脏疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="info.xczyjkwtszjb">
              <el-checkbox v-for="(item, index) in checks4" :key="index" :label="index + 1 + ''" :disabled="index > 0 && info.xczyjkwtszjb.indexOf('1') > -1">{{ item }}</el-checkbox>
              <el-input class="input" :disabled="info.xczyjkwtszjb.indexOf('6') === -1" v-model="info.xczyjkwtszjbDes"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">心脏疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="info.xczyjkwtxzjb">
              <el-checkbox v-for="(item, index) in checks5" :key="index" :label="index + 1 + ''" :disabled="index > 0 && info.xczyjkwtxzjb.indexOf('1') > -1">{{ item }}</el-checkbox>
              <el-input class="input" :disabled="info.xczyjkwtxzjb.indexOf('7') === -1" v-model="info.xczyjkwtxzjbDes"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">血管疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="info.xczyjkwtxgjb">
              <el-checkbox v-for="(item, index) in checks6" :key="index" :label="index + 1 + ''" :disabled="index > 0 && info.xczyjkwtxgjb.indexOf('1') > -1">{{ item }}</el-checkbox>
              <el-input class="input" :disabled="info.xczyjkwtxgjb.indexOf('4') === -1" v-model="info.xczyjkwtxgjbDes"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">眼部疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="info.xczyjkwtybjb">
              <el-checkbox v-for="(item, index) in checks7" :key="index" :label="index + 1 + ''" :disabled="index > 0 && info.xczyjkwtybjb.indexOf('1') > -1">{{ item }}</el-checkbox>
              <el-input class="input" :disabled="info.xczyjkwtybjb.indexOf('5') === -1" v-model="info.xczyjkwtybjbDes"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>神经系统疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.xczyjkwtsjxtjb">
              <el-radio v-for="(item, index) in radios26" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              <el-input class="input" :disabled="info.xczyjkwtsjxtjb.indexOf('2') === -1" v-model="info.xczyjkwtsjxtjbDes"></el-input>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">其他疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.xczyjkwtqtxtjb">
              <el-radio v-for="(item, index) in radios26" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              <el-input class="input" :disabled="info.xczyjkwtqtxtjb.indexOf('2') === -1" v-model="info.xczyjkwtqtxtjbDes"></el-input>
            </el-radio-group>
          </td>
        </tr>
        <!-- 住院治疗情况--住院史 -->
        <tr>
          <td class="td td-title1" rowspan="2">
            <p>住院治疗情况--住院史</p>
            <p class="record" @click="openHistory7">历史记录</p>
          </td>
          <td class="td td-title1">
            <span>住院史</span>
          </td>
          <td class="td" colspan="4">
            <el-table :data="zys" height="300" border>
              <el-table-column label="操作">
              </el-table-column>
              <el-table-column prop="zyzlqkrq" label="入/出院日期">
              </el-table-column>
              <el-table-column prop="zyzlqkyy" label="原因">
              </el-table-column>
              <el-table-column prop="zyzlqkyljgmc" label="医疗机构名称">
              </el-table-column>
              <el-table-column prop="zyzlqkbah" label="病案号">
              </el-table-column>
            </el-table>
          </td>
          <td class="td td-box">
            <div class="line">
              <span>病案号 </span>
              <el-input v-model="zyInfo.zyzlqkbah" class="input"></el-input>
            </div>
            <div class="line">
              <span>入院日期</span>
              <el-date-picker v-model="zyInfo.start" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span>出院日期</span>
              <el-date-picker v-model="zyInfo.end" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span>医疗机构名称</span>
              <el-input v-model="zyInfo.zyzlqkyljgmc" class="input"></el-input>
            </div>
            <div class="line">
              <span>原因</span>
              <el-input v-model="zyInfo.zyzlqkyy" class="input"></el-input>
            </div>
            <el-button type="success" @click="noClick">提交</el-button>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>家庭病床史</span>
          </td>
          <td class="td" colspan="4">
            <el-table :data="jtbcs" height="300" border>
              <el-table-column label="操作">
              </el-table-column>
              <el-table-column prop="zyzlqkrq" label="入/出院日期">
              </el-table-column>
              <el-table-column prop="zyzlqkyy" label="原因">
              </el-table-column>
              <el-table-column prop="zyzlqkyljgmc" label="医疗机构名称">
              </el-table-column>
              <el-table-column prop="zyzlqkbah" label="病案号">
              </el-table-column>
            </el-table>
          </td>
          <td class="td td-box">
            <div class="line">
              <span>病案号 </span>
              <el-input v-model="zyInfo1.zyzlqkbah" class="input"></el-input>
            </div>
            <div class="line">
              <span>入院日期</span>
              <el-date-picker v-model="zyInfo1.start" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span>出院日期</span>
              <el-date-picker v-model="zyInfo1.end" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span>医疗机构名称</span>
              <el-input v-model="zyInfo1.zyzlqkyljgmc" class="input"></el-input>
            </div>
            <div class="line">
              <span>原因</span>
              <el-input v-model="zyInfo1.zyzlqkyy" class="input"></el-input>
            </div>
            <el-button type="success" @click="noClick">提交</el-button>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <p>主要用药情况 </p>
            <p class="record" @click="openHistory8">历史记录</p>
          </td>
          <td class="td" colspan="5">
            <el-table :data="yyqks" height="300" border>
              <el-table-column label="操作">
                <template>
                  <span class="el-icon-edit icon-red"></span>
                  <span class="el-icon-delete icon-blue"></span>
                </template>
              </el-table-column>
              <el-table-column prop="zyyyqkywmc" label="药物名称">
              </el-table-column>
              <el-table-column prop="zyyyqkyf" label="用法">
              </el-table-column>
              <el-table-column prop="zyyyqkyl" label="用量">
              </el-table-column>
              <el-table-column prop="zyyyqkyysj" label="用药时间">
              </el-table-column>
              <el-table-column prop="zyyyqkfyycx" label="服药依从性">
              </el-table-column>
            </el-table>
          </td>
          <td class="td td-box">
            <div class="line">
              <span>用药名称</span>
              <el-select v-model="yyInfo.zyyyqkywmc" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="line">
              <span>用法</span>
              <el-select v-model="yyInfo.zyyyqkyf" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="line">
              <span>用量</span>
              <el-input v-model="yyInfo.zyyyqkyl" class="input"></el-input>
            </div>
            <div class="line">
              <span>用药时间</span>
              <el-input v-model="yyInfo.zyyyqkyysj" class="input"></el-input>
            </div>
            <div class="line">
              <span>服药依从性</span>
              <el-radio-group v-model="yyInfo.zyyyqkfyycx">
                <el-radio v-for="(item, index) in radios27" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
            </div>
            <el-button type="success" @click="noClick">提交</el-button>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <p>非免疫规划预防接种史</p>
            <p class="record" @click="openHistory9">历史记录</p>
          </td>
          <td class="td" colspan="5">
            <el-table :data="jzss" height="300" border>
              <el-table-column label="操作">
              </el-table-column>
              <el-table-column prop="fmyghyfjzsmc" label="名称">
              </el-table-column>
              <el-table-column prop="fmyghyfjzsrq" label="接种日期">
              </el-table-column>
              <el-table-column prop="fmyghyfyljgmc" label="接种机构">
              </el-table-column>
            </el-table>
          </td>
          <td class="td td-box">
            <div class="line">
              <span>名称 </span>
              <el-select v-model="jzInfo.fmyghyfjzsmc" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="line">
              <span>接种日期 </span>
              <el-date-picker
                v-model="jzInfo.fmyghyfjzsrq"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span>接种机构 </span>
              <el-input v-model="jzInfo.fmyghyfyljgmc" class="input"></el-input>
            </div>
            <el-button type="success" @click="noClick">提交</el-button>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <p class="tad-blue">健康评价</p>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="info.jkpjnr">
              <el-radio v-for="(item, index) in radios29" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <div class="line">
              <span>异常1</span>
              <el-input class="input1" v-model="info.jkpjnrDes1" :disabled="info.jkpjnr !== '2'"></el-input>
            </div>
            <div class="line">
              <span>异常2</span>
              <el-input class="input1" v-model="info.jkpjnrDes2" :disabled="info.jkpjnr !== '2'"></el-input>
            </div>
            <div class="line">
              <span>异常3</span>
              <el-input class="input1" v-model="info.jkpjnrDes3" :disabled="info.jkpjnr !== '2'"></el-input>
            </div>
            <div class="line">
              <span>异常4</span>
              <el-input class="input1" v-model="info.jkpjnrDes4" :disabled="info.jkpjnr !== '2'"></el-input>
            </div>
            <div class="line">
              <span>异常5</span>
              <el-input class="input1" v-model="info.jkpjnrDes5" :disabled="info.jkpjnr !== '2'"></el-input>
            </div>
            <div class="line">
              <span>异常6</span>
              <el-input class="input1" v-model="info.jkpjnrDes6" :disabled="info.jkpjnr !== '2'"></el-input>
            </div>
          </td>
        </tr>
        <!-- 健康指导 -->
        <tr>
          <td class="td td-title1">
            <p>健康指导</p>
          </td>
          <td class="td" colspan="3">
            <el-checkbox-group v-model="info.jkzdnrff">
              <el-checkbox v-for="(item, index) in checks8" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </td>
          <td class="td td-title1">
            <span>危险因素控制</span>
          </td>
          <td class="td" colspan="4">
            <el-checkbox-group v-model="info.jkzdwxyskzff">
              <el-checkbox v-for="(item, index) in checks9" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
            <div class="line">
              <span>减体重目标</span>
              <el-input class="input" :disabled="info.jkzdwxyskzff.indexOf('5') === -1" v-model="info.wxyskzjtzmb"></el-input>
              <span>kg</span>
            </div>
            <div class="line">
              <span>接种疫苗</span>
              <el-input class="input" :disabled="info.jkzdwxyskzff.indexOf('6') === -1" v-model="info.wxyskzjyjzym"></el-input>
            </div>
            <div class="line">
              <span>其他</span>
              <el-input class="input" :disabled="info.jkzdwxyskzff.indexOf('7') === -1" v-model="info.wxyskzDes"></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>录入机构</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input1" v-model="info.tjjgmc"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>录入人</span>
          </td>
          <td class="td" colspan="3">
            <el-input class="input" v-model="info.creator"></el-input>
          </td>
          <td class="td td-title1">
            <span>录入时间</span>
          </td>
          <td class="td" colspan="4">
            <el-date-picker
              v-model="info.createTime"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>更新机构</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input1" v-model="info.upjgbh"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>更新人</span>
          </td>
          <td class="td" colspan="3">
            <el-input class="input" v-model="info.updateuser"></el-input>
          </td>
          <td class="td td-title1">
            <span>更新时间</span>
          </td>
          <td class="td" colspan="4">
            <el-date-picker
              v-model="info.updateTime"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </td>
        </tr>
      </table>
    </div>
    <div class="summary-button">
      <el-button @click="noClick">提交</el-button>
      <el-button @click="noClick">打印预览</el-button>
      <el-button @click="noClick">上传</el-button>
      <el-button @click="close">返回</el-button>
    </div>
    <!-- 症状 -->
    <History v-if="showZzHistory" @close="closeZzHistory"></History>
    <!-- 一般情况 -->
    <History1 v-if="showHistory1" @close="closeHistory1"></History1>
    <!-- 生活方式 -->
    <History2 v-if="showHistory2" @close="closeHistory2"></History2>
    <!-- 脏器功能 -->
    <History3 v-if="showHistory3" @close="closeHistory3"></History3>
    <!-- 查体 -->
    <History4 v-if="showHistory4" @close="closeHistory4"></History4>
    <!-- 辅助检查 -->
    <History5 v-if="showHistory5" @close="closeHistory5"></History5>
    <!-- 现存主要健康问题 -->
    <History6 v-if="showHistory6" @close="closeHistory6"></History6>
    <!-- 住院治疗情况--住院史 -->
    <History7 v-if="showHistory7" @close="closeHistory7"></History7>
    <!-- 主要用药情况 -->
    <History8 v-if="showHistory8" @close="closeHistory8"></History8>
    <!-- 非免疫规划预防接种史 -->
    <History9 v-if="showHistory9" @close="closeHistory9"></History9>
  </div>
</template>

<script>
import History from './history.vue'
import History1 from './history1.vue'
import History2 from './history2.vue'
import History3 from './history3.vue'
import History4 from './history4.vue'
import History5 from './history5.vue'
import History6 from './history6.vue'
import History7 from './history7.vue'
import History8 from './history8.vue'
import History9 from './history9.vue'
export default {
  components: {
    History,
    History1,
    History2,
    History3,
    History4,
    History5,
    History6,
    History7,
    History8,
    History9
  },
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: '',
          jktjid: ''
        }
      }
    }
  },
  data () {
    return {
      value1: '',
      value: '',
      num: '',
      list: [],
      checked: [],
      options: [],
      tableData: [],
      tabs: Object.freeze(['体检表', '中医体质辨识', '老年人评估', '中医健康指导']),
      checkList: ['0'],
      checks: Object.freeze(['无症状', '头疼', '心悸', '胸闷', '胸痛', '慢性咳嗽', '咳痰', '呼吸苦难', '多饮', '多尿', '体重下降', '乳房胀痛', '乏力', '关节肿痛', '视力模糊', '手脚麻木', '尿急', '尿痛', '便秘', '腹泻', '恶心呕吐', '眼花', '耳鸣', '其他']),
      checks1: Object.freeze(['荤素均衡', '荤食为主', '素食为主', '嗜盐', '嗜油', '嗜糖']),
      checks2: Object.freeze(['白酒', '红酒', '啤酒', '黄酒', '其他']),
      checks3: Object.freeze(['未发现', '缺血性卒中', '脑出血', '蛛网膜下腔出血', '短暂性脑缺血发作', '其他']),
      checks4: Object.freeze(['未发现', '糖尿病肾病', '肾功能衰竭', '急性肾炎', '慢性肾炎', '其他']),
      checks5: Object.freeze(['未发现', '心肌梗死', '心绞痛', '冠状动脉血运重建 ', '充血性心力', '心前区疼痛', '其他']),
      checks6: Object.freeze(['未发现', '夹层动脉瘤', '动脉闭塞性疾病', '其他']),
      checks7: Object.freeze(['未发现', '视网膜出血或渗出', '视乳头水肿', '白内障', '其他']),
      checks8: Object.freeze(['纳入慢性病患者健康管理', '建议复查', '建议转诊']),
      checks9: Object.freeze(['戒烟', '健康饮酒', '饮食', '锻炼', '减体重', '建议接种疫苗', '其他']),
      radio: '0',
      radios: Object.freeze(['满意', '基本满意', '说不清楚', '不太满意', '不满意']),
      radios1: Object.freeze(['可自理（0-3分）', '轻度依赖（4-8分） ', '中度依赖（9-18分） ', '不能（9-18分） ']),
      radios2: Object.freeze(['粗筛阴性', '粗筛阳性，老年人智力检查']),
      radios3: Object.freeze(['粗筛阴性', '粗筛阳性，老年人抑郁检查']),
      radios4: Object.freeze(['每天', '每周一次以上', '偶尔', '不锻炼']),
      radios5: Object.freeze(['从不吸烟', '已戒烟', '吸烟']),
      radios6: Object.freeze(['从不', '偶尔', '经常', '每天']),
      radios7: Object.freeze(['未戒酒', '已戒酒']),
      radios8: Object.freeze(['是', '否']),
      radios9: Object.freeze(['无', '有']),
      radios10: Object.freeze(['红润', '苍白', '发敢', '皲裂', '疱诊']),
      radios11: Object.freeze(['无充血', '充血', '淋巴滤泡增长']),
      radios12: Object.freeze(['听见', '听不清或无法听见']),
      radios13: Object.freeze(['可顺利完成', '无法独立完成任意一个动作']),
      radios14: Object.freeze(['正常', '异常']),
      radios15: Object.freeze(['正常', '潮红', '苍白', '发绀', '黄染', '色素沉着', '其他']),
      radios16: Object.freeze(['正常', '黄染', '充血', '其他']),
      radios17: Object.freeze(['未触及', '锁骨上', '腋窝', '其他']),
      radios18: Object.freeze(['否', '是']),
      radios19: Object.freeze(['无', '干罗音', '湿罗音', '其他']),
      radios20: Object.freeze(['齐', '不齐', '绝对不齐']),
      radios21: Object.freeze(['无', '单侧', '双侧不对称', '双侧对称']),
      radios22: Object.freeze(['未触及', '触及双侧堆成', '触及左侧弱或消失', '触及右侧弱或消失']),
      radios23: Object.freeze(['未见异常', '触痛', '包块', '前列腺异常', '其他']),
      radios24: Object.freeze(['未见异常', '乳房切除', '异常泌乳', '乳腺包块', '其他']),
      radios25: Object.freeze(['阴性', '阳性']),
      radios26: Object.freeze(['未发现', '有']),
      radios27: Object.freeze(['规律', '间断', '不服药']),
      radios28: Object.freeze(['无', '有']),
      radios29: Object.freeze(['体检无异常', '体检有异常']),
      radios30: Object.freeze(['未见异常', '异常']),
      info: {
        zybgz: '',
        zybcysjn: '',
        id: 0,
        createTime: '',
        updateTime: '',
        jktjid: '',
        grjbxxid: '',
        dwzlwlysfhcs: '',
        tjbh: '',
        name: '',
        zrys: '',
        jcxmzz: [],
        ybzktw: '',
        ybzkml: '',
        ybzkhxpl: '',
        ybzkxycc: '',
        ybzkxyyc: '',
        ybzksg: '',
        ybzktz: '',
        ybzkyw: '',
        ybzktzzs: '',
        lnrjkztzwpg: '',
        lnrshzlnlzwpg: '',
        lnrrzgn: '',
        lnrqgzt: '',
        shfstydldlpl: '',
        shfstydlmcdlsj: '',
        shfstydljcdlsj: '',
        shfstydldlfs: '',
        shfsysxg: [],
        shfsxyqkxyzk: '',
        shfsxyqkrxyl: '',
        shfsxyqkksxynl: '',
        shfsxyqkjynl: '',
        shfsyjqkyjpl: '',
        shfsyjqkryjl: '',
        shfsyjqksfjj: '',
        shfsyjqkjjnl: '',
        shfsyjqkksyjnl: '',
        shfsyjqksfzj: '',
        shfsyjqkyjzl: [],
        zybwhysjcs: '',
        dwzlfc: '',
        dwzlfcfhcs: '',
        dwzlfswzfhcs: '',
        dwzkhxwzfhcs: '',
        tjrq: '',
        tjrqnew: '',
        dwzlfswz: '',
        dwzlwlys: '',
        dwzlhxwz: '',
        dwzlqtfhcs: '',
        dwzlfhcsqt: '',
        dwzlfcbz: '',
        dwzlfswzbz: '',
        dwzlwlysbz: '',
        dwzlhxwzbz: '',
        dwzlfhcsqtbz: '',
        zqgnkqkc: '',
        kqcl: [],
        kqsfqc: '',
        kqqcscs: '',
        kqqcscx: '',
        kqqcsyx: '',
        kqqcsys: '',
        kqsfquc: '',
        kqquccs: '',
        kqquccx: '',
        kqqucys: '',
        kqqucyx: '',
        kqsfyc: '',
        kqyccs: '',
        kqyccx: '',
        kqycys: '',
        kqycyx: '',
        kqsfqcyx: '',
        kqsfqczs: '',
        kqsfqczx: '',
        kqsfqcys: '',
        kqsfqucys: '',
        kqsfqucyx: '',
        kqsfquczs: '',
        kqsfquczx: '',
        allLoseTeeth: [],
        allDecayedTeeth: [],
        allFalseTeeth: [],
        isLosetTeeth: [],
        isDecayedTooth: [],
        isFalseTeeth: [],
        kqyb: '',
        shililyzy: '',
        shililyyy: '',
        shilijzslcy: '',
        shilijzslyy: '',
        tingli: '',
        ydgn: '',
        chatiyandi: '',
        chatipf: '',
        chatigm: '',
        chatilbj: '',
        chatifeitzx: '',
        chatifeihxy: '',
        chatifeily: '',
        chatixzxlv: '',
        chatixzxlu: '',
        chatixzxy: '',
        chatifbyt: '',
        chatifbbk: '',
        chatifbgd: '',
        chatifbpd: '',
        chatifbydxzy: '',
        chatixzsz: '',
        chatizbdmbd: '',
        chatigmzz: '',
        chatiruxian: '',
        chatifkwy: '',
        chatifkyd: '',
        chatifkgj: '',
        chatifkgt: '',
        chatifkfj: '',
        chatiqtjc: '',
        chatixcgxhdb: '',
        chatixcgbxb: '',
        chatixcgxxb: '',
        chatixcgqt: '',
        chatincgndb: '',
        chatincgnt: '',
        chatincgntt: '',
        chatincgnqx: '',
        chatincgqt: '',
        kfxtmool: '',
        kfxtmg: '',
        xdtzt: '',
        nwlbdb: '',
        dbqxzt: '',
        thxhdb: '',
        yxgybmky: '',
        ygbmkt: '',
        ygbmky: '',
        ygeky: '',
        ygekt: '',
        yghxkt: '',
        ygbdqs1: '',
        gnnxqgnzam: '',
        gnnxqgczam: '',
        gnnbdb: '',
        gnnzdhs: '',
        gnnjhdhs: '',
        sgnxqjg: '',
        sgnxns: '',
        sgnxjnd: '',
        sgnxnnd: '',
        xzhizdgc: '',
        xzhigysz: '',
        xzhixqdmdzdbdgc: '',
        xzhixqgmdzdbdgc: '',
        xbxxp: '',
        bchaofbbc: '',
        bchaoqtbbc: '',
        gjgp: '',
        fzjcqtjc: '',
        xczyjkwtnxgjb: [],
        xczyjkwtszjb: [],
        xczyjkwtxzjb: [],
        xczyjkwtxgjb: [],
        xczyjkwtybjb: [],
        xczyjkwtsjxtjb: '',
        xczyjkwtqtxtjb: '',
        zyzlqkzysryrq: '',
        zyzlqkzyscyrq: '',
        zyzlqkzysyy: '',
        zyzlqkzysyljg: '',
        zyzlqkzysybah: '',
        zyzlqkjtbcsjcrq: '',
        zyzlqkjtbcsccrq: '',
        zyzlqkjtbcsyy: '',
        zyzlqkjtbcsyljgmc: '',
        zyzlqkjtbcsbah: '',
        zyyyqkywmc: '',
        zyyyqkyf: '',
        zyyyqkyl: '',
        zyyyqkyysj: '',
        zyyyqkfyycx: '',
        fmyghyfjzsmc: '',
        fmyghyfjzsrq: '',
        fmyghyfjzsjg: '',
        jkpjnr: '',
        jkzdnrff: [],
        jkzdwxyskzff: [],
        jcxmzzDes: '',
        chatiyandiDes: '',
        chatipfDes: '',
        chatigmDes: '',
        chatilbjDes: '',
        chatifeihxyDes: '',
        chatifeilyDes: '',
        chatixzxyDes: '',
        chatifbytDes: '',
        chatifbbkDes: '',
        chatifbgdDes: '',
        chatifbpdDes: '',
        chatifbydxzyDes: '',
        chatigmzzDes: '',
        chatiruxianDes: '',
        chatifkwyDes: '',
        chatifkydDes: '',
        chatifkgjDes: '',
        chatifkgtDes: '',
        chatifkfjDes: '',
        xbxxpDes: '',
        bchaofbbcDes: '',
        bchaoqtbbcDes: '',
        gjgpDes: '',
        xczyjkwtnxgjbDes: '',
        xczyjkwtszjbDes: '',
        xczyjkwtxzjbDes: '',
        xczyjkwtxgjbDes: '',
        xczyjkwtybjbDes: '',
        xczyjkwtsjxtjbDes: '',
        xczyjkwtqtxtjbDes: '',
        jkpjnrDes: '',
        wxyskzjtzmb: '',
        wxyskzjywmb: '',
        wxyskzjyjzym: '',
        wxyskzDes: '',
        xdtztDes: '',
        ybzksxycc: '',
        ybzksxyyc: '',
        sfzh: '',
        tmcode: '',
        jgbh: '',
        age: '',
        hjdz: '',
        jkpjnrDes1: '',
        jkpjnrDes2: '',
        jkpjnrDes3: '',
        jkpjnrDes4: '',
        jkpjnrDes5: '',
        jkpjnrDes6: '',
        jkpjnrycjg: '',
        jkpjnrtjxj: '',
        ytjbz: '',
        bcbz: '',
        lisbz: '',
        sgtzbz: '',
        shbz: '',
        xqbz: '',
        xb: '',
        scflag: '',
        uploadFlag: '',
        uploadflagtj: '',
        uploadflagzyzd: '',
        uploadflagzyy: '',
        uploadflagsf: '',
        uploadflagpgb: '',
        uploadflagyjjh: '',
        uploadflaglnr: '',
        person: '',
        healthExam: '',
        elderInfo: '',
        cmgOperation: '',
        elderSelfcareAbility: '',
        elderTcmHealth: '',
        picId: '',
        personInfoId: '',
        manageOrgId: '',
        sfztp: '',
        zppic: '',
        dnbh: '',
        pttbflag: '',
        jkdnid: '',
        bcflag: '',
        tpflag: '',
        bctbflag: '',
        bctbflagnew: '',
        sfzhm: '',
        jddw: '',
        sftbflag: '',
        cwhmcid: '',
        lxdh: '',
        jdrq: '',
        jkdnxzz: '',
        xdtbz: '',
        tjjgbh: '',
        tjjgbhnew: '',
        tjjgmc: '',
        tjjgmcnew: '',
        tjjgcmhmc: '',
        tjjgcmhmcnew: '',
        tjjgcmhid: '',
        tjjgcmhidnew: '',
        jkpjnrybzk: '',
        downflag: '',
        zrysid: '',
        czy: '',
        czyid: '',
        fileData: '',
        ocrtpurl: '',
        tptype: '',
        printflag: '',
        yctype: '',
        xcgyzflag: '',
        ncgyzflag: '',
        shyzflag: '',
        bcyzflag: '',
        xdtyzflag: '',
        sfyzflag: '',
        nametage: '',
        zyyzflag: '',
        printdesc: '',
        xcgyzdesc: '',
        ncgyzdesc: '',
        shyzdesc: '',
        bcyzdesc: '',
        xdtyzdesc: '',
        sfyzdesc: '',
        zyyzdesc: '',
        tjlx: '',
        deflag: '',
        deuserid: '',
        xdtflag: '',
        minzu: '',
        ocrtpbase64: '',
        xfflag: '',
        shryqm: '',
        xcgryqm: '',
        ncgryqm: '',
        bchaoryqm: '',
        xdtryqm: '',
        zeysqm: '',
        province: '',
        city: '',
        country: '',
        town: '',
        village: '',
        jgjb: '',
        tbtype: '',
        healthCheck: '',
        provinceid: '',
        cityid: '',
        countryid: '',
        townid: '',
        villageid: '',
        tjjgxzjdmc: '',
        tjjgxzjdid: '',
        jddwid: '',
        updateuser: '',
        updateuserid: '',
        updatejg: '',
        updatejgid: '',
        lrr: '',
        ycflagnew: '',
        lrrid: '',
        xzjdmc: '',
        cwhmc: '',
        xzjdmcid: '',
        empiid: '',
        beginsj: '',
        endsj: '',
        limit: '',
        tjjgbhs: '',
        jkdnhjdz: '',
        tjssjgmc: '',
        uploadflagnlpgb: '',
        tjjlsum: '',
        uptjflag: '',
        rqfl: '',
        sxtj: '',
        type: '',
        tbtypes: '',
        entime: '',
        betime: '',
        username: '',
        openid: '',
        miniapppenid: '',
        mpopenid: '',
        lytype: '',
        ptcode: '',
        ryfl: '',
        unionid: '',
        respdoctorname: '',
        respdoctorId: '',
        yztype: '',
        orgName: '',
        newsfzh: '',
        updateflag: '',
        healthExamno: '',
        createOrgId: '',
        creatorId: '',
        creator: '',
        pdfurl: '',
        drflag: '',
        pttjflag: '',
        sctype: '',
        sclytype: '',
        scday: '',
        offset: '',
        sjtype: '',
        retmsg: '',
        upflagnew: '',
        uploadflagtjsc: '',
        uploadflagtjssc: '',
        xztzcode: '',
        xztzdesc: '',
        fbtzcode: '',
        fbtzdesc: '',
        wkjcszcode: '',
        wkjcszdesc: '',
        wkjzxcode: '',
        wkjzxdesc: '',
        wklbcode: '',
        wklbdesc: '',
        wkjzhucode: '',
        wkjzhudesc: '',
        ybzkchatnrc: '',
        districtid: '',
        czsj: '',
        czry: '',
        czyw: '',
        czzt: '',
        czjg: '',
        flag: '',
        czrc: '',
        czcc: '',
        ssmk: '',
        phone: '',
        pwd: '',
        delrs: '',
        ycfj: '',
        wxstrs: '',
        tzjg: '',
        tzjgcode: '',
        assessmentCount: '',
        intactRate: '',
        missingCount: '',
        jkpjnrycjgxcg: '',
        jkpjnrycjgpj: '',
        jkpjnrycjgxy: '',
        jkpjnrycjgshggn: '',
        jkpjnrycjgshsgn: '',
        jkpjnrycjgncg: '',
        jkpjnrycjgsh: '',
        jkpjnrycjgbc: '',
        jkpjnrqtyc: '',
        jkpjnrycjgxdt: '',
        jkpjnrycjgjkzd: '',
        jkpjnrycjgtingli: '',
        jkpjnrycjgyjqk: '',
        jkpjnrycjgydgn: '',
        jkpjnrycjglnrshzlnlpg: '',
        jkpjnrycjgxyqk: '',
        jkpjnrycjgshxt: '',
        jkpjnrycjgshxz: '',
        jkpjnrycjglnrjkztpg: '',
        jkpjnrycjglnrzyyjkglfw: '',
        jkpjnrycjgbcdjs: '',
        jkpjnrycjgtzzs: '',
        jkpjnrycjgbczfg: '',
        archivesNum: ''
      },
      zys: [],
      jtbcs: [],
      yyqks: [],
      zyInfo: {
        start: '',
        end: '',
        zyzlqkbah: '',
        zyzlqkrq: '',
        zyzlqkyljgmc: '',
        zyzlqkyy: ''
      },
      zyInfo1: {
        start: '',
        end: '',
        zyzlqkbah: '',
        zyzlqkrq: '',
        zyzlqkyljgmc: '',
        zyzlqkyy: ''
      },
      yyInfo: {
        zyyyqkywmc: '',
        zyyyqkyf: '',
        zyyyqkyl: '',
        zyyyqkyysj: '',
        zyyyqkfyycx: ''
      },
      jzss: [],
      jzInfo: {
        fmyghyfjzsmc: '',
        fmyghyfjzsrq: '',
        fmyghyfyljgmc: ''
      },
      showZzHistory: false,
      showHistory1: false,
      showHistory2: false,
      showHistory3: false,
      showHistory4: false,
      showHistory5: false,
      showHistory6: false,
      showHistory7: false,
      showHistory8: false,
      showHistory9: false
    }
  },
  computed: {
    zzSelect () {
      return this.info.jcxmzz
    },
    xdtzt () {
      return this.info.xdtzt
    },
    ybzksg () {
      return parseFloat(this.info.ybzksg)
    },
    ybzktz () {
      return parseFloat(this.info.ybzktz)
    },
    zybwhysjcs () {
      return this.info.zybwhysjcs
    },
    dwzlfcfhcs () {
      return this.info.dwzlfcfhcs
    },
    dwzlfswzfhcs () {
      return this.info.dwzlfswzfhcs
    },
    dwzlwlysfhcs () {
      return this.info.dwzlwlysfhcs
    },
    dwzkhxwzfhcs () {
      return this.info.dwzkhxwzfhcs
    },
    dwzlqtfhcs () {
      return this.info.dwzlqtfhcs
    },
    kqcl () {
      return this.info.kqcl
    },
    isLosetTeeth () {
      return this.info.isLosetTeeth
    },
    allLoseTeeth () {
      return this.info.allLoseTeeth
    },
    isDecayedTooth () {
      return this.info.isDecayedTooth
    },
    allDecayedTeeth () {
      return this.info.allDecayedTeeth
    },
    isFalseTeeth () {
      return this.info.isFalseTeeth
    },
    allFalseTeeth () {
      return this.info.allFalseTeeth
    },
    chatiyandi () {
      return this.info.chatiyandi
    },
    chatipf () {
      return this.info.chatipf
    },
    chatigm () {
      return this.info.chatigm
    },
    chatilbj () {
      return this.info.chatilbj
    },
    chatifeihxy () {
      return this.info.chatifeihxy
    },
    chatifeily () {
      return this.info.chatifeily
    },
    chatixzx () {
      return this.info.chatixzx
    },
    chatifbyt () {
      return this.info.chatifbyt
    },
    chatifbbk () {
      return this.info.chatifbbk
    },
    chatifbgd () {
      return this.info.chatifbgd
    },
    chatifbpd () {
      return this.info.chatifbpd
    },
    chatifbydxzy () {
      return this.info.chatifbydxzy
    },
    chatigmzz () {
      return this.info.chatigmzz
    },
    chatiruxian () {
      return this.info.chatiruxian
    },
    chatifkwy () {
      return this.info.chatifkwy
    },
    chatifkyd () {
      return this.info.chatifkyd
    },
    chatifkgj () {
      return this.info.chatifkgj
    },
    chatifkgt () {
      return this.info.chatifkgt
    },
    chatifkfj () {
      return this.info.chatifkfj
    },
    xbxxp () {
      return this.info.xbxxp
    },
    bchaofbb () {
      return this.info.bchaofbb
    },
    bchaoqtbbc () {
      return this.info.bchaoqtbbc
    },
    gjgp () {
      return this.info.gjgp
    },
    xczyjkwtnxgjb () {
      return this.info.xczyjkwtnxgjb
    },
    xczyjkwtszjb () {
      return this.info.xczyjkwtszjb
    },
    xczyjkwtxzjb () {
      return this.info.xczyjkwtxzjb
    },
    xczyjkwtxgjb () {
      return this.info.xczyjkwtxgjb
    },
    xczyjkwtybjb () {
      return this.info.xczyjkwtybjb
    },
    xczyjkwtsjxtjb () {
      return this.info.xczyjkwtsjxtjb
    },
    xczyjkwtqtxtjb () {
      return this.info.xczyjkwtqtxtjb
    },
    jkpjnr () {
      return this.info.jkpjnr
    },
    jkzdwxyskzff () {
      return this.info.jkzdwxyskzff
    }
  },
  watch: {
    zzSelect (val) {
      if (val.indexOf('24') === -1) {
        this.info.jcxmzzDes = ''
      }
    },
    xdtzt (val) {
      if (val !== '2') {
        this.info.xdtztDes = ''
      }
    },
    ybzksg (val) {
      const bmi = this.ybzktz / (val ** 2) * 10000
      this.info.ybzktzzs = bmi.toFixed(2)
      console.log(this.info.ybzktzzs)
    },
    ybzktz (val) {
      const bmi = val / (this.ybzksg ** 2) * 10000
      this.info.ybzktzzs = bmi.toFixed(2)
    },
    zybwhysjcs (val) {
      if (val !== '2') {
        this.info.zybgz = ''
        this.info.zybcysjn = ''
      }
    },
    dwzlfcfhcs (val) {
      if (val !== '2') {
        this.info.dwzlfcbz = ''
      }
    },
    dwzlfswzfhcs (val) {
      if (val !== '2') {
        this.info.dwzlfswzbz = ''
      }
    },
    dwzlwlysfhcs (val) {
      if (val !== '2') {
        this.info.dwzlwlysbz = ''
      }
    },
    dwzkhxwzfhcs (val) {
      if (val !== '2') {
        this.info.dwzlhxwzbz = ''
      }
    },
    dwzlqtfhcs (val) {
      if (val !== '2') {
        this.info.dwzlfhcsqtbz = ''
      }
    },
    kqcl (val) {
      if (val.indexOf('1') > -1) {
        this.info.isLosetTeeth = []
        this.info.allLoseTeeth = []
        this.info.kqqcscs = ''
        this.info.kqqcscx = ''
        this.info.kqqcsyx = ''
        this.info.kqqcsys = ''
        this.info.isDecayedTooth = []
        this.info.allDecayedTeeth = []
        this.info.kqsfquczs = ''
        this.info.kqsfquczx = ''
        this.info.kqsfqucys = ''
        this.info.kqsfqucyx = ''
        this.info.isFalseTeeth = []
        this.info.allFalseTeet = []
        this.info.kqyccs = ''
        this.info.kqyccx = ''
        this.info.kqycys = ''
        this.info.kqycyx = ''
      }
    },
    isLosetTeeth (val) {
      if (val.indexOf('1') === -1) {
        this.info.allLoseTeeth = []
        this.info.kqqcscs = ''
        this.info.kqqcscx = ''
        this.info.kqqcsyx = ''
        this.info.kqqcsys = ''
      }
    },
    allLoseTeeth (val) {
      if (val.indexOf('1') !== -1) {
        this.info.kqqcscs = ''
        this.info.kqqcscx = ''
        this.info.kqqcsyx = ''
        this.info.kqqcsys = ''
      }
    },
    isDecayedTooth (val) {
      if (val.indexOf('1') === -1) {
        this.info.allDecayedTeeth = []
        this.info.kqsfquczs = ''
        this.info.kqsfquczx = ''
        this.info.kqsfqucys = ''
        this.info.kqsfqucyx = ''
      }
    },
    allDecayedTeeth (val) {
      if (val.indexOf('1') !== -1) {
        this.info.kqsfquczs = ''
        this.info.kqsfquczx = ''
        this.info.kqsfqucys = ''
        this.info.kqsfqucyx = ''
      }
    },
    isFalseTeeth (val) {
      if (val.indexOf('1') === -1) {
        this.info.allFalseTeeth = []
        this.info.kqyccs = ''
        this.info.kqyccx = ''
        this.info.kqycys = ''
        this.info.kqycyx = ''
      }
    },
    allFalseTeeth (val) {
      if (val.indexOf('1') !== -1) {
        this.info.kqyccs = ''
        this.info.kqyccx = ''
        this.info.kqycys = ''
        this.info.kqycyx = ''
      }
    },
    chatiyandi (val) {
      if (val !== '2') {
        this.info.chatiyandiDes = ''
      }
    },
    chatipf (val) {
      if (val !== '7') {
        this.info.chatipfDes = ''
      }
    },
    chatigm (val) {
      if (val !== '4') {
        this.info.chatigmDes = ''
      }
    },
    chatilbj (val) {
      if (val !== '4') {
        this.info.chatilbjDes = ''
      }
    },
    chatifeihxy (val) {
      if (val !== '2') {
        this.info.chatifeihxyDes = ''
      }
    },
    chatifeily (val) {
      if (val !== '4') {
        this.info.chatifeilyDes = ''
      }
    },
    chatixzx (val) {
      if (val !== '2') {
        this.info.chatixzxyDes = ''
      }
    },
    chatifbyt (val) {
      if (val !== '2') {
        this.info.chatifbytDes = ''
      }
    },
    chatifbbk (val) {
      if (val !== '2') {
        this.info.chatifbbkDes = ''
      }
    },
    chatifbgd (val) {
      if (val !== '2') {
        this.info.chatifbgdDes = ''
      }
    },
    chatifbpd (val) {
      if (val !== '2') {
        this.info.chatifbpdDes = ''
      }
    },
    chatifbydxzy (val) {
      if (val !== '2') {
        this.info.chatifbydxzyDes = ''
      }
    },
    chatigmzz (val) {
      if (val !== '5') {
        this.info.chatigmzzDes = ''
      }
    },
    chatiruxian (val) {
      if (val !== '5') {
        this.info.chatiruxianDes = ''
      }
    },
    chatifkwy (val) {
      if (val !== '2') {
        this.info.chatifkwyDes = ''
      }
    },
    chatifkyd (val) {
      if (val !== '2') {
        this.info.chatifkydDes = ''
      }
    },
    chatifkgj (val) {
      if (val !== '2') {
        this.info.chatifkgjDes = ''
      }
    },
    chatifkgt (val) {
      if (val !== '2') {
        this.info.chatifkgtDes = ''
      }
    },
    chatifkfj (val) {
      if (val !== '2') {
        this.info.chatifkfjDes = ''
      }
    },
    xbxxp (val) {
      if (val !== '2') {
        this.info.xbxxpDes = ''
      }
    },
    bchaofbb (val) {
      if (val !== '2') {
        this.info.bchaofbbcDes = ''
      }
    },
    bchaoqtbbc (val) {
      if (val !== '2') {
        this.info.bchaoqtbbcDes = ''
      }
    },
    gjgp (val) {
      if (val !== '2') {
        this.info.gjgpDes = ''
      }
    },
    xczyjkwtnxgjb (val) {
      if (val.indexOf('1') > -1 && val.length > 1) {
        this.info.xczyjkwtnxgjb = ['1']
        this.info.xczyjkwtnxgjbDes = ''
      } else if (val.indexOf('6') === -1) {
        this.info.xczyjkwtnxgjbDes = ''
      }
    },
    xczyjkwtszjb (val) {
      if (val.indexOf('1') > -1 && val.length > 1) {
        this.info.xczyjkwtszjb = ['1']
        this.info.xczyjkwtszjbDes = ''
      } else if (val.indexOf('6') === -1) {
        this.info.xczyjkwtszjbDes = ''
      }
    },
    xczyjkwtxzjb (val) {
      if (val.indexOf('1') > -1 && val.length > 1) {
        this.info.xczyjkwtxzjb = ['1']
        this.info.xczyjkwtxzjbDes = ''
      } else if (val.indexOf('7') === -1) {
        this.info.xczyjkwtxzjbDes = ''
      }
    },
    xczyjkwtxgjb (val) {
      if (val.indexOf('1') > -1 && val.length > 1) {
        this.info.xczyjkwtxgjb = ['1']
        this.info.xczyjkwtxgjbDes = ''
      } else if (val.indexOf('4') === -1) {
        this.info.xczyjkwtxgjbDes = ''
      }
    },
    xczyjkwtybjb (val) {
      if (val.indexOf('1') > -1 && val.length > 1) {
        this.info.xczyjkwtybjb = ['1']
        this.info.xczyjkwtybjbDes = ''
      } else if (val.indexOf('5') === -1) {
        this.info.xczyjkwtybjbDes = ''
      }
    },
    xczyjkwtsjxtjb (val) {
      if (val !== '2') {
        this.info.xczyjkwtsjxtjbDes = ''
      }
    },
    xczyjkwtqtxtjb (val) {
      if (val !== '2') {
        this.info.xczyjkwtqtxtjbDes = ''
      }
    },
    jkpjnr (val) {
      if (val !== '2') {
        this.info.jkpjnrDes1 = ''
        this.info.jkpjnrDes2 = ''
        this.info.jkpjnrDes3 = ''
        this.info.jkpjnrDes4 = ''
        this.info.jkpjnrDes5 = ''
        this.info.jkpjnrDes6 = ''
      }
    },
    jkzdwxyskzff (val) {
      if (val.indexOf('5') === -1) {
        this.info.wxyskzjtzmb = ''
      }
      if (val.indexOf('6') === -1) {
        this.info.wxyskzjyjzym = ''
      }
      if (val.indexOf('7') === -1) {
        this.info.wxyskzDes = ''
      }
    }
  },
  created () {
    const obj = {
      tmcode: this.params.tmcode,
      sfzh: this.params.sfzh
    }
    this.getInfo(obj)
  },
  methods: {
    // 体检记录--查询单条信息
    async getInfo (obj) {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97905103
      try {
        const { data: res } = await this.$http.post('/api/jkct/app/findtjxx', obj)
        console.log('体检记录--查询单条信息', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        for (const key in res.data) {
          if (res.data[key] === null) {
            res.data[key] = ''
          }
        }
        this.info = res.data
        if (res.data.jcxmzz) {
          this.info.jcxmzz = res.data.jcxmzz.split(',')
        } else {
          this.info.jcxmzz = []
        }

        if (res.data.shfsysxg) {
          this.info.shfsysxg = res.data.shfsysxg.split(',')
        } else {
          this.info.shfsysxg = []
        }

        if (res.data.shfsyjqkyjzl) {
          this.info.shfsyjqkyjzl = res.data.shfsyjqkyjzl.split(',')
        } else {
          this.info.shfsyjqkyjzl = []
        }

        if (res.data.kqcl) {
          this.info.kqcl = res.data.kqcl.split(',')
        } else {
          this.info.kqcl = []
        }

        if (res.data.xczyjkwtnxgjb) {
          this.info.xczyjkwtnxgjb = res.data.xczyjkwtnxgjb.split(',')
        } else {
          this.info.xczyjkwtnxgjb = []
        }

        if (res.data.xczyjkwtszjb) {
          this.info.xczyjkwtszjb = res.data.xczyjkwtszjb.split(',')
        } else {
          this.info.xczyjkwtszjb = []
        }

        if (res.data.xczyjkwtxzjb) {
          this.info.xczyjkwtxzjb = res.data.xczyjkwtxzjb.split(',')
        } else {
          this.info.xczyjkwtxzjb = []
        }

        if (res.data.xczyjkwtxgjb) {
          this.info.xczyjkwtxgjb = res.data.xczyjkwtxgjb.split(',')
        } else {
          this.info.xczyjkwtxgjb = []
        }

        if (res.data.xczyjkwtybjb) {
          this.info.xczyjkwtybjb = res.data.xczyjkwtybjb.split(',')
        } else {
          this.info.xczyjkwtybjb = []
        }

        if (res.data.jkzdnrff) {
          this.info.jkzdnrff = res.data.jkzdnrff.split(',')
        } else {
          this.info.jkzdnrff = []
        }

        if (res.data.jkzdwxyskzff) {
          this.info.jkzdwxyskzff = res.data.jkzdwxyskzff.split(',')
        } else {
          this.info.jkzdwxyskzff = []
        }

        if (res.data.isLosetTeeth) {
          this.info.isLosetTeeth = res.data.isLosetTeeth.split('')
        } else {
          this.info.isLosetTeeth = []
        }

        if (res.data.allLoseTeeth) {
          this.info.allLoseTeeth = res.data.allLoseTeeth.split('')
        } else {
          this.info.allLoseTeeth = []
        }

        if (res.data.isDecayedTooth) {
          this.info.isDecayedTooth = res.data.isDecayedTooth.split('')
        } else {
          this.info.isDecayedTooth = []
        }
        if (res.data.allDecayedTeeth) {
          this.info.allDecayedTeeth = res.data.allDecayedTeeth.split('')
        } else {
          this.info.allDecayedTeeth = []
        }
        if (res.data.isFalseTeeth) {
          this.info.isFalseTeeth = res.data.isFalseTeeth.split('')
        } else {
          this.info.isFalseTeeth = []
        }
        if (res.data.allFalseTeeth) {
          this.info.allFalseTeeth = res.data.allFalseTeeth.split('')
        } else {
          this.info.allFalseTeeth = []
        }
        this.getZyqk()
        this.getYyqk()
        this.getYymcs()
        this.getJzs()
      } catch (error) {
        console.log('体检记录--查询单条信息请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getZyqk () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591855
      try {
        const obj = {
          start: 0,
          length: 100,
          jktjid: this.info.jktjid || '',
          grjbxxid: this.info.grjbxxid || ''
        }
        const { data: res } = await this.$http.get('/ykdzyzlqkzyss', { params: obj })
        console.log('住院情况--查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        // res.data = [
        //   { zyzlqklx: '1', zyzlqkrq: '2020/10/10-2020/10/10', zyzlqkyy: '1111', zyzlqkyljgmc: '11111', zyzlqkbah: '1111111' },
        //   { zyzlqklx: '2', zyzlqkrq: '2020/10/10-2020/10/10', zyzlqkyy: '22', zyzlqkyljgmc: '222', zyzlqkbah: '2222' }
        // ]
        this.zys = res.data.filter(item => item.zyzlqklx === '1')
        this.jtbcs = res.data.filter(item => item.zyzlqklx === '2')
      } catch (error) {
        console.log('住院情况--查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getYyqk () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591848
      try {
        const obj = {
          start: 0,
          length: 100,
          jktjid: this.info.jktjid || ''
        }
        const { data: res } = await this.$http.get('/ykdzyyyqks', { params: obj })
        console.log('主要用药--列表查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        this.yyqks = res.data
      } catch (error) {
        console.log('主要用药--列表查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getYymcs () {
      // 接口地址
      try {
        const { data: res } = await this.$http.post('/ykdjktjypmls/find')
        console.log('获取药品目录', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('获取药品目录请求失败', error)
      }
    },
    async getJzs () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591863
      try {
        const obj = {
          start: 0,
          length: 100,
          jktjid: this.info.jktjid || ''
        }
        const { data: res } = await this.$http.get('/ykdfmyghjzss', { params: obj })
        console.log('接种史--查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        this.jzss = res.data
      } catch (error) {
        console.log('接种史--查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    zzChange (e) {
      console.log(e)
      if (e.indexOf('1') > -1) {
        this.info.jcxmzz = ['1']
      }
    },
    openZzHistory () {
      this.showZzHistory = true
    },
    closeZzHistory () {
      this.showZzHistory = false
    },
    openHistory1 () {
      this.showHistory1 = true
    },
    closeHistory1 () {
      this.showHistory1 = false
    },
    openHistory2 () {
      this.showHistory2 = true
    },
    closeHistory2 () {
      this.showHistory2 = false
    },
    openHistory3 () {
      this.showHistory3 = true
    },
    closeHistory3 () {
      this.showHistory3 = false
    },
    openHistory4 () {
      this.showHistory4 = true
    },
    closeHistory4 () {
      this.showHistory4 = false
    },
    openHistory5 () {
      this.showHistory5 = true
    },
    closeHistory5 () {
      this.showHistory5 = false
    },
    openHistory6 () {
      this.showHistory6 = true
    },
    closeHistory6 () {
      this.showHistory6 = false
    },
    openHistory7 () {
      this.showHistory7 = true
    },
    closeHistory7 () {
      this.showHistory7 = false
    },
    openHistory8 () {
      this.showHistory8 = true
    },
    closeHistory8 () {
      this.showHistory8 = false
    },
    openHistory9 () {
      this.showHistory9 = true
    },
    closeHistory9 () {
      this.showHistory9 = false
    },
    noClick () {
      this.$message.error('未开放')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.summary {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;

  .summary-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 60px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .summary-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }

  .summary-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;

    .summary-info-left {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 18px;

      .span {
        margin-right: 20px;
      }
    }

    .summary-info-right {
      color: #000;
      font-size: 18px;
    }
  }

  .table {
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;

    .td {
      padding: 10px;
      font-size: 16px;

      .zz-input {
        width: 100px;

        /deep/.el-input__inner {
          height: 30px;
          background-color: #fff;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #000;
          color: #000;
        }
      }

      .text {
        margin-right: 30px;
      }
    }

    .td-title {
      min-width: 150px;
    }

    .td-title1 {
      width: 110px;
    }

    .tad-blue {
      color: #1f21fd;
    }

    .record {
      color: #409eff;
      text-decoration: underline;
      cursor: pointer;
    }

    /deep/.el-checkbox {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    /deep/.el-checkbox__label {
      color: #000;
      font-size: 16px;
    }

    .list {
      display: flex;
      align-items: center;
    }

    .input {
      width: 200px;
    }

    .input1 {
      width: 400px;
    }

    .input2 {
      width: 100px;
    }

    /deep/.el-input__inner {
      text-align: center;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #929394;
      color: #000;
      font-size: 16px;
    }

    /deep/.el-radio__label {
      color: #000;
      font-size: 16px;
    }

    .span-left {
      margin-left: 30px;
    }

    .span-left1 {
      margin-left: 5px;
    }

    .span-left2 {
      margin-left: 10px;
    }

    /deep/.el-radio__inner {
      border: 1px solid #000;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner {
      border-color: #409EFF;
    }

    .flex {
      display: flex;
      width: 100%;
      height: 100%;
    }

    .td-box {
      width: 400px;
      height: 100%;
    }

    .line {
      display: flex;
      align-items: center;
      margin-top: 4px;
      margin-bottom: 4px;

      /deep/.el-radio-group {
        display: flex;
        align-items: center;
      }

      /deep/.el-radio {
        display: flex;
        align-items: center;
      }
    }
    .el-table {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      margin: 0 auto;
    }
    /deep/.el-table th {
      border-right: 1px solid #000 !important;
      border-bottom: 1px solid #000 !important;
    }

    /deep/.el-table td {
      border-right: 1px solid #000 !important;
      border-bottom: 1px solid #000 !important;
    }

    // /deep/.el-table__empty-block {
    //   border-top: 1px solid #000 !important;
    //   border-left: 1px solid #000 !important;
    // }

    .icon-red {
      margin: 0 5px;
      color: rgb(245, 108, 108);
      font-size: 16px;
      cursor: pointer;
    }

    .icon-blue {
      margin: 0 5px;
      color: rgb(102, 177, 255);
      font-size: 16px;
      cursor: pointer;
    }
  }

  .summary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;

    .el-button {
      width: 180px;
      height: 40px;
      line-height: 40px;
      margin: 0 10px;
      padding: 0;
      background-color: rgba(103,194,58,.6);
      text-align: center;
      color: #4d94ff;
      font-size: 16px;
      font-weight: 600;

      &::hover {
        background-color: #67c23a;
      }
    }
  }
}
</style>
