<template>
  <div class="seeInfo">
    <div class="seeInfo-left">
      <div class="title">
        <span>患者住院信息</span>
      </div>
      <el-select size="small" v-model="value" placeholder="一病区">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select size="small" v-model="value" placeholder="全部责任医师">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select size="small" v-model="value" placeholder="姓名">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select size="small" v-model="value" placeholder="床号">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <div class="btns">
        <el-button size="mini">搜索</el-button>
        <el-button size="mini">重置</el-button>
      </div>
      <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
    </div>
    <div class="seeInfo-right">
      <div class="title">
        <span>查看详情</span>
      </div>
      <div class="scroll">
        <div class="title1">
          <span>基本信息</span>
          <div class="bts">
            <el-button size="small" @click="openBindDevice">设备绑定</el-button>
            <el-button size="small" @click="openUpdateInfo">编辑信息</el-button>
            <el-button size="small" @click="openHandlingDischarge">办理出院</el-button>
          </div>
        </div>
        <div class="box">
          <div class="item">
            <span class="key">姓名</span>
            <span class="value">李明明</span>
          </div>
          <div class="item">
            <span class="key">性别</span>
            <span class="value">男</span>
          </div>
          <div class="item">
            <span class="key">年龄</span>
            <span class="value">40</span>
          </div>
          <div class="item">
            <span class="key">联系电话</span>
            <span class="value">15803339264</span>
          </div>
          <div class="item">
            <span class="key">护理等级</span>
            <span class="value">一级护理</span>
          </div>
          <div class="item">
            <span class="key">地址</span>
            <span class="value">河北石家庄桥西区友谊街道202号</span>
          </div>
          <div class="item">
            <span class="key">床号</span>
            <span class="value">1</span>
          </div>
          <div class="item">
            <span class="key">住院号</span>
            <span class="value"></span>
          </div>
          <div class="item">
            <span class="key">主治医生</span>
            <span class="value">李明明</span>
          </div>
          <div class="item">
            <span class="key">责任护士</span>
            <span class="value">王晓宏</span>
          </div>
          <div class="item">
            <span class="key">分组</span>
            <span class="value">2型糖尿病</span>
          </div>
          <div class="item">
            <span class="key">护理等级</span>
            <span class="value">一级护理</span>
          </div>
          <div class="item">
            <span class="key">入院时间</span>
            <span class="value">2020-07-09</span>
          </div>
          <div class="item">
            <span class="key">科室</span>
            <span class="value">内分泌 > 三病区</span>
          </div>
          <div class="item">
            <span class="key">身高</span>
            <span class="value">173</span>
          </div>
          <div class="item">
            <span class="key">体重</span>
            <span class="value">80</span>
          </div>

          <div class="item">
            <span class="key">BMI值</span>
            <span class="value">26.70</span>
          </div>
          <div class="item">
            <span class="key">并发症</span>
            <span class="value">白内障</span>
          </div>
          <div class="item">
            <span class="key">家族史</span>
            <span class="value">无白内障</span>
          </div>
          <div class="item">
            <span class="margin">药物过去治疗方式</span>
            <span class="value value1">一直服用二甲双瓜</span>
          </div>
          <div class="item">
            <span class="key">过敏史</span>
            <span class="value">无</span>
          </div>
          <div class="item item1">
            <span class="key">入院评估</span>
            <span class="value">可能有糖尿病足的风险</span>
          </div>
        </div>
        <div class="tabs">
          <span class="item" :class="{ item1: tabsIndex === index }" v-for="(item, index) in tabs" :key="index"
            @click="tabClick(index)">{{ item }}</span>
        </div>
        <div class="tab-box">
          <InfoTab1 v-if="(tabsIndex === 0)" @openAddXt="openAddXt" @openAdd="openAdd"></InfoTab1>
          <InfoTab2 v-else-if="(tabsIndex === 1)"></InfoTab2>
          <InfoTab3 v-else-if="(tabsIndex === 2)"></InfoTab3>
          <InfoTab4 v-else-if="(tabsIndex === 3)"></InfoTab4>
        </div>
      </div>
      <div class="bottom">
        <el-button @click="close">返回</el-button>
      </div>
    </div>
    <!-- 设备绑定 -->
    <BindDevice v-if="showBindDevice" @close="closeBindDevice"></BindDevice>
    <!-- 编辑信息 -->
    <UpdateInfo v-if="showUpdateInfo" @close="closeUpdateInfo"></UpdateInfo>
    <!-- 办理出院 -->
    <HandlingDischarge v-if="showHandlingDischarge" @close="closeHandlingDischarge"></HandlingDischarge>
    <!-- 新增血糖 -->
    <AddXt v-if="showAddXt" @close="closeAddXt"></AddXt>
    <!-- 添加计划 -->
    <AddPlan v-if="showAddPlan" @close="closeAddPlan"></AddPlan>
    <!-- 新增药品 -->
    <AddDrugs v-if="showAddDrugs" @close="closeAddDrugs"></AddDrugs>
    <!-- 添加级别 -->
    <AddLevel v-if="showAddLevel" @close="closeAddLevel"></AddLevel>
  </div>
</template>

<script>
import InfoTab1 from './seeInfoTab1.vue'
import InfoTab2 from './seeInfoTab2.vue'
import InfoTab3 from './seeInfoTab3.vue'
import InfoTab4 from './seeInfoTab4.vue'
import BindDevice from './bindDevice.vue'
import UpdateInfo from './updateInfo.vue'
import HandlingDischarge from './handlingDischarge.vue'
import AddXt from './addXt.vue'
import AddPlan from './addPlan.vue'
import AddDrugs from './addDrugs.vue'
import AddLevel from './addLevel.vue'
export default {
  components: {
    InfoTab1,
    InfoTab2,
    InfoTab3,
    InfoTab4,
    BindDevice,
    UpdateInfo,
    HandlingDischarge,
    AddXt,
    AddPlan,
    AddDrugs,
    AddLevel
  },
  data () {
    return {
      tabsIndex: 0,
      tabs: Object.freeze(['血糖数据', '血压数据', '动态血压', '体温数据']),
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      data: [{
        label: '一病区',
        children: [{
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      showBindDevice: false,
      showUpdateInfo: false,
      showHandlingDischarge: false,
      showAddXt: false,
      showAddPlan: false,
      showAddDrugs: false,
      showAddLevel: false
    }
  },
  mounted () {
    // setTimeout(() => {
    //   debugger
    // }, 3000)
  },
  methods: {
    tabClick (index) {
      this.tabsIndex = index
    },
    handleNodeClick (data) {
      // console.log(data)
    },
    openBindDevice () {
      this.showBindDevice = true
    },
    closeBindDevice () {
      this.showBindDevice = false
    },
    openUpdateInfo () {
      this.showUpdateInfo = true
    },
    closeUpdateInfo () {
      this.showUpdateInfo = false
    },
    openHandlingDischarge () {
      this.showHandlingDischarge = true
    },
    closeHandlingDischarge () {
      this.showHandlingDischarge = false
    },
    openAddXt () {
      this.showAddXt = true
    },
    closeAddXt () {
      this.showAddXt = false
    },
    openAdd (index) {
      if (index === 0) {
        this.showAddPlan = true
      } else if (index === 1) {
        this.showAddDrugs = true
      } else if (index === 2) {
        this.showAddLevel = true
      }
    },
    closeAddPlan () {
      this.showAddPlan = false
    },
    closeAddDrugs () {
      this.showAddDrugs = false
    },
    closeAddLevel () {
      this.showAddLevel = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.seeInfo {
  position: relative;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: #071224;

  .seeInfo-left {
    width: 3.375rem;
    height: 10.3125rem;
    padding: .125rem;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      width: 100%;
      height: .4375rem;
      line-height: .52rem;
      text-align: center;
      background-image: url('../../img/ssjk/title.png');
      background-size: 100% 100%;
      color: #fff;
      font-size: .25rem;
      font-weight: bold;
    }

    .el-select {
      margin-top: .25rem;
    }

    .btns {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: .125rem auto;

      .el-button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        border-radius: 0;
        color: #fff;
        border: none;

        &::after {
          border: none;
        }
      }
    }

    .el-tree {
      background-color: #081C38;
      color: #fff;

      ::v-deep .el-tree-node > .el-tree-node__content:hover {
        background-color: transparent;
      }
      ::v-deep.is-current {
        background-color: transparent;
        color: #01EEFD;
        background-color: #081C38;
      }

      ::v-deep.el-tree-node.is-current > .el-tree-node__content {
        background-color: transparent !important;
      }
    }
  }

  .seeInfo-right {
    width: calc(100% - 3.625rem);
    height: 100%;
    padding: .125rem;
    border-left: .05rem solid rgba(1, 124, 219, .27);
    box-sizing: border-box;
    background-color: #08284B;

    .title {
      display: flex;
      align-items: center;
      color: #01EEFD;
      font-size: .25rem;
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 1rem);

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .title1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 97%;
      height: .625rem;
      margin: 0 auto;
      padding: 0 .225rem;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: .225rem;

      .bts {}

      .el-button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        border: none;
        border-radius: 0;
        color: #fff;
      }
    }

    .box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 97%;
      margin: 0 auto;
      padding: .25rem;
      box-sizing: border-box;
      border-bottom: 1px solid #01EEFD;

      .item {
        display: flex;
        align-items: center;
        width: 25%;
        padding: .1rem 0;
        color: #01EEFD;
        font-size: .2rem;

        .key {
          width: .875rem;
          margin-right: .225rem;
          white-space: nowrap;
          text-align-last: justify;
        }

        .margin {
          margin-right: .225rem;
        }

        .value {
          width: calc(100% - 1.1rem);
          white-space: wrap;
        }

        .value1 {
          width: calc(100% - 2.5rem);
        }
      }

      .item1 {
        width: 75%;
      }
    }

    .tabs {
      display: flex;
      align-items: center;
      width: 97%;
      height: .875rem;
      margin: 0 auto;

      .item {
        height: .5125rem;
        line-height: .5125rem;
        margin-right: .25rem;
        padding: 0 .35rem;
        color: #fff;
        font-size: .225rem;
        background-color: #D2D2D2;
        cursor: pointer;
      }

      .item1 {
        background-color: #01EEFD;
      }
    }

    .tab-box {}

    .bottom {
      display: flex;
      align-items: center;
      height: .6rem;

      .el-button {
        width: .875rem;
        height: .475rem;
        line-height: .475rem;
        margin: 0;
        padding: 0;
        background-color: #33465C;
        color: #FFFFFF;
        font-size: .225rem;
        border: none;
        border-radius: 0;
      }
    }
  }

}
</style>
