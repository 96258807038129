<template>
  <div class="summary">
    <div class="summary-scroll">
      <div class="summary-title">
        <span>健康体检表</span>
      </div>
      <div class="summary-info">
        <div class="summary-info-left">
          <span class="span">姓名: 王玉方</span>
          <span class="span">身份证号: 132124195307010319</span>
          <span class="span">性别: 男</span>
          <span class="span">年龄: 70</span>
          <span class="span">现住址:</span>
          <span>联系电话: </span>
        </div>
        <div class="summary-info-right">
          <span>编号: 603308832</span>
        </div>
      </div>
      <table class="table" border="1">
        <tr>
          <td class="td" colspan="8">
            <span class="text">考核项: 54</span>
            <span class="text">缺项: 0</span>
            <span class="text">完整率: 100%</span>
          </td>
        </tr>
        <tr>
          <td class="td" colspan="2">
            <span class="tad-blue">体检日期</span>
          </td>
          <td class="td" colspan="2">
            <el-date-picker v-model="value1" type="date" placeholder="">
            </el-date-picker>
          </td>
          <td class="td">
            <span>责任医生</span>
          </td>
          <td class="td" colspan="2">
            <el-select v-model="value" placeholder="">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="td td-title">
            <span>内容</span>
          </td>
          <td class="td" colspan="6">
            <span>检查项目</span>
          </td>
        </tr>
        <tr></tr>
        <!-- 症状 -->
        <tr>
          <td class="td td-title">
            <span class="tad-blue">症状</span>
          </td>
          <td class="td" colspan="6">
            <el-checkbox-group v-model="list">
              <el-checkbox v-for="(item, index) in checks" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
              <el-input class="input"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <!-- 一般状况 -->
        <tr>
          <td class="td td-title" rowspan="9">
            <p>一般状况</p>
            <p class="record">测量记录</p>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">体温</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>℃</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">脉率</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>次/分钟</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="2">
            <span class="tad-blue">呼吸频率</span>
          </td>
          <td class="td" colspan="2" rowspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>次/分钟</span>
          </td>
          <td class="td td-title1" rowspan="2">
            <span>血压</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">左侧</span>
          </td>
          <td class="td">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span> / </span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>mmhg</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title">
            <span class="tad-blue">右侧</span>
          </td>
          <td class="td">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span> / </span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>mmhg</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">身高</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>cm</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">体重</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>kg</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">腰围</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>cm</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">体脂指数</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>kg/㎡</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人健康状态自我评估*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人生活自理能力自我评估*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios1" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人认知功能*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios2" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>老人情感状态*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios3" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <!-- 生活方式 -->
        <tr>
          <td class="td td-title" rowspan="13">
            <span>生活方式</span>
          </td>
          <td class="td td-title1" rowspan="3">
            <span>体育锻炼</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">锻炼频率</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios4" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>每次锻炼时间</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>分钟</span>
          </td>
          <td class="td td-title1">
            <span>坚持锻炼时间</span>
          </td>
          <td class="td" colspan="3">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>年</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>锻炼方式</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>饮食习惯</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="list">
              <el-checkbox v-for="(item, index) in checks1" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="3">
            <span>吸烟情况</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">吸烟情况</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios5" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>日吸烟量</span>
          </td>
          <td class="td" colspan="7">
            <span>平均</span>
            <el-input class="input"></el-input>
            <span>支</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>开始吸烟年龄</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>岁</span>
          </td>
          <td class="td td-title1">
            <span>戒烟年龄</span>
          </td>
          <td class="td" colspan="3">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>岁</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="5">
            <span>饮酒情况</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">饮酒频率</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios6" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>日饮酒量</span>
          </td>
          <td class="td" colspan="7">
            <span>平均</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>两</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>是否戒酒</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios7" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <span class="span-left">戒酒年龄</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>岁</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>开始饮酒年龄</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>岁</span>
          </td>
          <td class="td td-title1">
            <span>近一年是否醉酒</span>
          </td>
          <td class="td" colspan="3">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios8" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>饮酒种类</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in checks2" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">职业病危害因素接触史</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <!-- 脏器功能 -->
        <tr>
          <td class="td td-title" rowspan="9">
            <span>脏器功能</span>
          </td>
          <td class="td td-title1" rowspan="6">
            <span class="tad-blue">口腔</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">口唇</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios10" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="4">
            <span class="tad-blue">齿列</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox v-model="checked">正常</el-checkbox>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <el-checkbox v-model="checked">缺齿</el-checkbox>
          </td>
          <td class="td" colspan="7">
            <el-checkbox v-model="checked">全口</el-checkbox>
            <span class="span-left">左上</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>左下</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span class="span-left">右上</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>右下</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <el-checkbox v-model="checked">龋齿</el-checkbox>
          </td>
          <td class="td" colspan="7">
            <el-checkbox v-model="checked">全口</el-checkbox>
            <span class="span-left">左上</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>左下</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>右上</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>右下</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <el-checkbox v-model="checked">义齿</el-checkbox>
          </td>
          <td class="td" colspan="7">
            <el-checkbox v-model="checked">全口</el-checkbox>
            <span class="span-left">左上</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>左下</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>右上</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>右下</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">咽部</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios11" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">视力</span>
          </td>
          <td class="td" colspan="7">
            <span>左眼</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>右眼</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>（矫正视力：左眼</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>右眼</span>
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>）</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">听力</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios12" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">运动功能</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios13" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <!-- 查体 -->
        <tr>
          <td class="td td-title1" rowspan="18">
            <span>查体</span>
          </td>
          <td class="td td-title1">
            <span>眼底*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">皮肤</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios15" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">巩膜</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios16" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">淋巴结</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios17" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="3">
            <span>肺</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">桶状胸</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">呼吸音</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">啰音</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios19" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="2">
            <span>心</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">心率</span>
          </td>
          <td class="td" colspan="2">
            <el-input-number size="small" v-model="num" controls-position="right" :min="0"></el-input-number>
            <span>次/分钟 </span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">心律</span>
          </td>
          <td class="td" colspan="3">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios20" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">杂音</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="5">
            <span>腹部</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">腹部压痛</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">腹部包块</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">腹部肝大</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">腹部脾大</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">移动性浊音</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">下肢水肿</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios21" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>足背动脉搏动*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios22" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>肛门指诊*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios23" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>其他*</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input1"></el-input>
          </td>
        </tr>
        <!-- 辅助检查 -->
        <tr>
          <td class="td td-title1" rowspan="16">
            <p>辅助检查</p>
            <p class="record">监测记录</p>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">血常规*</span>
          </td>
          <td class="td" colspan="7">
            <span>血红蛋白*</span>
            <el-input class="input2"></el-input>
            <span>g/L</span>
            <span class="span-left1">白细胞*</span>
            <el-input class="input2"></el-input>
            <span>×10^9/L</span>
            <span class="span-left1">血小板*</span>
            <el-input class="input2"></el-input>
            <span>×10^9/L</span>
            <span class="span-left1">其他*</span>
            <el-input class="input2"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">尿常规</span>
          </td>
          <td class="td" colspan="7">
            <span>尿蛋白*</span>
            <el-input class="input2"></el-input>
            <span>尿糖*</span>
            <el-input class="input2"></el-input>
            <span>尿酮体*</span>
            <el-input class="input2"></el-input>
            <span>尿潜血*</span>
            <el-input class="input2"></el-input>
            <span>其他*</span>
            <el-input class="input2"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">空腹血糖</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input2"></el-input>
            <span>mmol/L或</span>
            <el-input class="input2"></el-input>
            <span>mg/dL</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">心电图</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>尿微量白蛋白</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input2"></el-input>
            <span>mg/dL</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>大便潜血*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios25" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>糖化血红蛋白*</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input2"></el-input>
            <span>%</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>乙型肝炎表面抗原*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios25" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">肝功能</span>
          </td>
          <td class="td" colspan="7">
            <span>血清谷丙转氨酶*</span>
            <el-input class="input2"></el-input>
            <span>U/L</span>
            <span class="span-left1">血清谷草转氨酶*</span>
            <el-input class="input2"></el-input>
            <span>U/L</span>
            <span class="span-left1">白蛋白*</span>
            <el-input class="input2"></el-input>
            <span>g/L</span>
            <span class="span-left1">总胆红素*</span>
            <el-input class="input2"></el-input>
            <span>μmol/L</span>
            <span class="span-left1">结合胆红素*</span>
            <el-input class="input2"></el-input>
            <span>μmol/L</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">肾功能</span>
          </td>
          <td class="td" colspan="7">
            <span>肾功能血清肌酐*</span>
            <el-input class="input2"></el-input>
            <span>μmol/L</span>
            <span class="span-left1">肾功能血尿素*</span>
            <el-input class="input2"></el-input>
            <span>mmol/L</span>
            <span class="span-left1">肾功能血钾浓度*</span>
            <el-input class="input2"></el-input>
            <span>mmol/L</span>
            <span class="span-left1">肾功能血钠浓度*</span>
            <el-input class="input2"></el-input>
            <span>mmol/L</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">血脂</span>
          </td>
          <td class="td" colspan="7">
            <span>总胆固醇*</span>
            <el-input class="input2"></el-input>
            <span>μmol/L</span>
            <span class="span-left1">甘油三酯*</span>
            <el-input class="input2"></el-input>
            <span>mmol/L</span>
            <span class="span-left1">血清低密度脂蛋白胆固醇*</span>
            <el-input class="input2"></el-input>
            <span>mmol/L</span>
            <span class="span-left1">血清高密度脂蛋白胆固醇*</span>
            <el-input class="input2"></el-input>
            <span>mmol/L</span>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>胸部X线片*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input1"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1" rowspan="2">
            <span>B超</span>
          </td>
          <td class="td td-title1">
            <span>腹部B超*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input1"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>其他*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input1"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>宫颈涂片*</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <el-input class="input1"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>其他*</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input1"></el-input>
          </td>
        </tr>
        <!-- 现存主要健康问题 -->
        <tr>
          <td class="td td-title1" rowspan="7">
            <span>现存主要健康问题</span>
          </td>
          <td class="td td-title1">
            <span class="tad-blue">脑血管疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="list">
              <el-checkbox v-for="(item, index) in checks3" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
              <el-input class="input"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">肾脏疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="list">
              <el-checkbox v-for="(item, index) in checks4" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
              <el-input class="input"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">心脏疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="list">
              <el-checkbox v-for="(item, index) in checks5" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
              <el-input class="input"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">血管疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="list">
              <el-checkbox v-for="(item, index) in checks6" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
              <el-input class="input"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">眼部疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-checkbox-group v-model="list">
              <el-checkbox v-for="(item, index) in checks7" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
              <el-input class="input"></el-input>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">神经系统疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios26" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              <el-input class="input"></el-input>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>其他疾病</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios26" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              <el-input class="input"></el-input>
            </el-radio-group>
          </td>
        </tr>
        <!-- 住院治疗情况--住院史 -->
        <tr>
          <td class="td td-title1" rowspan="2">
            <span>住院治疗情况--住院史</span>
          </td>
          <td class="td td-title1">
            <span>住院史</span>
          </td>
          <td class="td" colspan="4">
            <el-table :data="tableData" height="300" border>
              <el-table-column prop="date" label="操作">
              </el-table-column>
              <el-table-column prop="name" label="入/出院日期">
              </el-table-column>
              <el-table-column prop="name" label="原因">
              </el-table-column>
              <el-table-column prop="name" label="医疗机构名称">
              </el-table-column>
              <el-table-column prop="name" label="病案号">
              </el-table-column>
            </el-table>
          </td>
          <td class="td td-box">
            <div class="line">
              <span>病案号 </span>
              <el-input class="input"></el-input>
            </div>
            <div class="line">
              <span>入院日期</span>
              <el-date-picker v-model="value1" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span>出院日期</span>
              <el-date-picker v-model="value1" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span>医疗机构名称</span>
              <el-input class="input"></el-input>
            </div>
            <div class="line">
              <span>原因</span>
              <el-input class="input"></el-input>
            </div>
            <el-button type="success">提交</el-button>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>家庭病床史</span>
          </td>
          <td class="td" colspan="4">
            <el-table :data="tableData" height="300" border>
              <el-table-column prop="date" label="操作">
              </el-table-column>
              <el-table-column prop="name" label="入/出院日期">
              </el-table-column>
              <el-table-column prop="name" label="原因">
              </el-table-column>
              <el-table-column prop="name" label="医疗机构名称">
              </el-table-column>
              <el-table-column prop="name" label="病案号">
              </el-table-column>
            </el-table>
          </td>
          <td class="td td-box">
            <div class="line">
              <span>病案号 </span>
              <el-input class="input"></el-input>
            </div>
            <div class="line">
              <span>建床日期</span>
              <el-date-picker v-model="value1" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span>撤床日期</span>
              <el-date-picker v-model="value1" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span>医疗机构名称</span>
              <el-input class="input"></el-input>
            </div>
            <div class="line">
              <span>原因</span>
              <el-input class="input"></el-input>
            </div>
            <el-button type="success">提交</el-button>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <p>主要用药情况 </p>
            <p class="record">获取上一次用药情况</p>
          </td>
          <td class="td" colspan="5">
            <el-table :data="tableData" height="300" border>
              <el-table-column prop="date" label="操作">
              </el-table-column>
              <el-table-column prop="name" label="药物名称">
              </el-table-column>
              <el-table-column prop="name" label="用法">
              </el-table-column>
              <el-table-column prop="name" label="用量">
              </el-table-column>
              <el-table-column prop="name" label="用药时间">
              </el-table-column>
              <el-table-column prop="name" label="服药依从性">
              </el-table-column>
            </el-table>
          </td>
          <td class="td td-box">
            <div class="line">
              <span>用药名称</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="line">
              <span>用法</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="line">
              <span>用量</span>
              <el-input class="input"></el-input>
            </div>
            <div class="line">
              <span>用药时间</span>
              <el-input class="input"></el-input>
            </div>
            <div class="line">
              <span>服药依从性</span>
              <el-radio-group v-model="radio">
                <el-radio v-for="(item, index) in radios27" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
            </div>
            <el-button type="success">提交</el-button>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>非免疫规划预防接种史</span>
          </td>
          <td class="td" colspan="5">
            <el-table :data="tableData" height="300" border>
              <el-table-column prop="date" label="操作">
              </el-table-column>
              <el-table-column prop="name" label="名称">
              </el-table-column>
              <el-table-column prop="name" label="接种日期">
              </el-table-column>
              <el-table-column prop="name" label="接种机构">
              </el-table-column>
            </el-table>
          </td>
          <td class="td td-box">
            <div class="line">
              <span>名称 </span>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="line">
              <span>接种日期 </span>
              <el-date-picker
                v-model="value1"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="line">
              <span>接种机构 </span>
              <el-input class="input"></el-input>
            </div>
            <el-button type="success">提交</el-button>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span class="tad-blue">健康评价</span>
          </td>
          <td class="td" colspan="7">
            <el-radio-group v-model="radio">
              <el-radio v-for="(item, index) in radios29" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
            </el-radio-group>
            <div class="line">
              <span>异常1</span>
              <el-input class="input1"></el-input>
            </div>
            <div class="line">
              <span>异常2</span>
              <el-input class="input1"></el-input>
            </div>
            <div class="line">
              <span>异常3</span>
              <el-input class="input1"></el-input>
            </div>
            <div class="line">
              <span>异常4</span>
              <el-input class="input1"></el-input>
            </div>
            <div class="line">
              <span>异常5</span>
              <el-input class="input1"></el-input>
            </div>
            <div class="line">
              <span>异常6</span>
              <el-input class="input1"></el-input>
            </div>
          </td>
        </tr>
        <!-- 健康指导 -->
        <tr>
          <td class="td td-title1">
            <span>健康评价</span>
          </td>
          <td class="td" colspan="3">
            <el-checkbox-group v-model="list">
              <el-checkbox v-for="(item, index) in checks8" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </td>
          <td class="td td-title1">
            <span>危险因素控制</span>
          </td>
          <td class="td" colspan="4">
            <el-checkbox-group v-model="list">
              <el-checkbox v-for="(item, index) in checks9" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
            <div class="line">
              <span>减体重目标</span>
              <el-input class="input"></el-input>
              <span>kg</span>
            </div>
            <div class="line">
              <span>接种疫苗</span>
              <el-input class="input"></el-input>
            </div>
            <div class="line">
              <span>其他</span>
              <el-input class="input"></el-input>
            </div>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>录入机构</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input1"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>录入人</span>
          </td>
          <td class="td" colspan="3">
            <el-input class="input"></el-input>
          </td>
          <td class="td td-title1">
            <span>录入时间</span>
          </td>
          <td class="td" colspan="4">
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>更新机构</span>
          </td>
          <td class="td" colspan="7">
            <el-input class="input1"></el-input>
          </td>
        </tr>
        <tr>
          <td class="td td-title1">
            <span>更新人</span>
          </td>
          <td class="td" colspan="3">
            <el-input class="input"></el-input>
          </td>
          <td class="td td-title1">
            <span>更新时间</span>
          </td>
          <td class="td" colspan="4">
            <el-date-picker
              v-model="value1"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </td>
        </tr>
      </table>
    </div>
    <div class="summary-button">
      <el-button>提交</el-button>
      <el-button>打印预览</el-button>
      <el-button>上传</el-button>
      <el-button @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showSummary: true,
      value1: '',
      options: [],
      value: '',
      list: [],
      num: null,
      checked: false,
      radio: '',
      checks: Object.freeze(['无症状', '头疼', '心悸', '胸闷', '胸痛', '慢性咳嗽', '咳痰', '呼吸苦难', '多饮', '多尿', '体重下降', '乳房胀痛', '乏力', '关节肿痛', '视力模糊', '手脚麻木', '尿急', '尿痛', '便秘', '腹泻', '恶心呕吐', '眼花', '耳鸣', '其他']),
      checks1: Object.freeze(['荤素均衡', '荤食为主', '素食为主', '嗜盐', '嗜油', '嗜糖']),
      checks2: Object.freeze(['白酒', '红酒', '啤酒', '黄酒', '其他']),
      checks3: Object.freeze(['未发现', '缺血性卒中', '脑出血', '蛛网膜下腔出血', '短暂性脑缺血发作', '其他']),
      checks4: Object.freeze(['未发现', '糖尿病肾病', '肾功能衰竭', '急性肾炎', '慢性肾炎', '其他']),
      checks5: Object.freeze(['未发现', '心肌梗死', '心绞痛', '冠状动脉血运重建 ', '充血性心力', '心前区疼痛', '其他']),
      checks6: Object.freeze(['未发现', '夹层动脉瘤', '动脉闭塞性疾病', '其他']),
      checks7: Object.freeze(['未发现', '视网膜出血或渗出', '视乳头水肿', '白内障', '其他']),
      checks8: Object.freeze(['纳入慢性病患者健康管理', '建议复查', '建议转诊']),
      checks9: Object.freeze(['戒烟', '健康饮酒', '饮食', '锻炼', '减体重', '建议接种疫苗', '其他']),
      radios: Object.freeze(['满意', '基本满意', '说不清楚', '不太满意', '不满意']),
      radios1: Object.freeze(['可自理（0-3分）', '轻度依赖（4-8分） ', '中度依赖（9-18分） ', '不能（9-18分） ']),
      radios2: Object.freeze(['粗筛阴性', '粗筛阳性，老年人智力检查']),
      radios3: Object.freeze(['粗筛阴性', '粗筛阳性，老年人抑郁检查']),
      radios4: Object.freeze(['每天', '每周一次以上', '偶尔', '不锻炼']),
      radios5: Object.freeze(['从不吸烟', '已戒烟', '吸烟']),
      radios6: Object.freeze(['从不', '偶尔', '经常', '每天']),
      radios7: Object.freeze(['未戒酒', '已戒酒', '经常']),
      radios8: Object.freeze(['是', '否']),
      radios9: Object.freeze(['有', '无']),
      radios10: Object.freeze(['红润', '苍白', '发敢', '皲裂', '疱诊']),
      radios11: Object.freeze(['无充血', '充血', '淋巴滤泡增长']),
      radios12: Object.freeze(['听见', '听不清或无法听见']),
      radios13: Object.freeze(['可顺利完成', '无法独立完成任意一个动作']),
      radios14: Object.freeze(['正常', '异常']),
      radios15: Object.freeze(['正常', '潮红', '苍白', '发绀', '黄染', '色素沉着', '其他']),
      radios16: Object.freeze(['正常', '黄染', '充血', '其他']),
      radios17: Object.freeze(['未触及', '锁骨上', '腋窝', '其他']),
      radios18: Object.freeze(['否', '是']),
      radios19: Object.freeze(['无', '干罗音', '湿罗音', '其他']),
      radios20: Object.freeze(['齐', '不齐', '绝对不齐']),
      radios21: Object.freeze(['无', '单侧', '双侧不对称', '双侧对称']),
      radios22: Object.freeze(['未触及', '触及双侧堆成', '触及左侧弱或消失', '触及右侧弱或消失']),
      radios23: Object.freeze(['未见异常', '触痛', '包块', '前列腺异常', '其他']),
      radios24: Object.freeze(['未见异常', '乳房切除', '异常泌乳', '乳腺包块', '其他']),
      radios25: Object.freeze(['阴性', '阳性']),
      radios26: Object.freeze(['未发现', '有']),
      radios27: Object.freeze(['规律', '间断', '不服药']),
      radios28: Object.freeze(['无', '有']),
      radios29: Object.freeze(['体检无异常', '体检有异常']),
      tableData: []
    }
  },
  created () {
    setTimeout(() => {
      this.showSummary = false
    }, 1500)
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.summary {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .summary-scroll {
    overflow-y: scroll;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .summary-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }

  .summary-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;

    .summary-info-left {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 18px;

      .span {
        margin-right: 20px;
      }
    }

    .summary-info-right {
      color: #000;
      font-size: 18px;
    }
  }

  .table {
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;

    .td {
      padding: 10px;
      font-size: 16px;

      .text {
        margin-right: 30px;
      }
    }

    .td-title {
      min-width: 150px;
    }

    .td-title1 {
      width: 120px;
    }

    .tad-blue {
      color: #1f21fd;
    }

    .record {
      color: #409eff;
      text-decoration: underline;
    }

    /deep/.el-checkbox {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    /deep/.el-checkbox__label {
      color: #000;
      font-size: 16px;
    }

    .input {
      width: 200px;
    }

    .input1 {
      width: 400px;
    }

    .input2 {
      width: 100px;
    }

    /deep/.el-input__inner {
      text-align: center;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #929394;
      color: #000;
      font-size: 16px;
    }

    /deep/.el-radio__label {
      color: #000;
      font-size: 16px;
    }

    .span-left {
      margin-left: 30px;
    }

    .span-left1 {
      margin-left: 5px;
    }

    /deep/.el-radio__inner {
      border: 1px solid #000;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner {
      border-color: #409EFF;
    }

    .flex {
      display: flex;
      width: 100%;
      height: 100%;
    }

    .td-box {
      width: 400px;
      height: 100%;
    }

    .line {
      display: flex;
      align-items: center;
      margin-top: 4px;
      margin-bottom: 4px;

      /deep/.el-radio-group {
        display: flex;
        align-items: center;
      }

      /deep/.el-radio {
        display: flex;
        align-items: center;
      }
    }
    .el-table {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      margin: 0 auto;
    }
    /deep/.el-table th {
      border-right: 1px solid #000 !important;
      border-bottom: 1px solid #000 !important;
    }

    /deep/.el-table td {
      border-right: 1px solid #000 !important;
      border-bottom: 1px solid #000 !important;
    }

    // /deep/.el-table__empty-block {
    //   border-top: 1px solid #000 !important;
    //   border-left: 1px solid #000 !important;
    // }
  }

  .summary-button {
    position: absolute;
    left: 0;
    bottom: 20px;
    z-index: 8;
    display: flex;
    justify-content: center;
    width: 100%;

    .el-button {
      width: 180px;
      height: 40px;
      line-height: 40px;
      margin: 0 10px;
      padding: 0;
      background-color: rgba(103,194,58,.6);
      text-align: center;
      color: #4d94ff;
      font-size: 16px;
      font-weight: 600;

      &::hover {
        background-color: #67c23a;
      }
    }
  }
}
</style>
