<template>
  <div class="write">
    <div class="title">
      <span>新增</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">姓名</span>
          <el-input placeholder="" v-model="params.name"></el-input>
        </div>
        <div class="item">
          <span class="span">性别</span>
          <el-radio-group v-model="params.xb">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <span class="span">年龄</span>
          <el-input placeholder="" maxlength="3" v-model="params.age"></el-input>
        </div>
        <div class="item">
          <span class="span">身份证号</span>
          <el-input placeholder="" maxlength="18" v-model="params.sfzh"></el-input>
        </div>
        <div class="item">
          <span class="span">与家属关系</span>
          <el-select placeholder="" v-model="params.yjsgx">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="btns">
        <el-button @click="close">取消</el-button>
        <el-button @click="submit">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      options: [{
        value: '1',
        label: '父母'
      }, {
        value: '2',
        label: '配偶'
      }, {
        value: '3',
        label: '子女'
      }],
      value: '',
      params: {
        name: '',
        xb: '',
        sfzh: '',
        age: '',
        yjsgx: '',
        jrsfzh: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.params.jrsfzh = this.$parent.selectSfzh
  },
  methods: {
    async submit () {
      // 接口文档 https://app.apifox.com/link/project/2726972/apis/api-212326120
      try {
        const res = await this.$http.post('/ykdjtcylbs/savejtcy', this.params)
        console.log('家庭成员新增', res)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.$parent.showAddMember = false
        this.$parent.getList()
      } catch (error) {
        console.log('家庭成员新增请求失败', error)
      }
    },
    close () {
      this.$parent.showAddMember = false
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 100px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 167px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 431px;
    padding: 50px 65px;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 14px;
      right: 20px;
      color: #fff;
      font-size: 28px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      position: relative;
      display: flex;
      align-items: center;
      width: 25%;
      margin-top: 30px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        margin-right: 10px;
        white-space: nowrap;
      }

      .span1 {
        width: 108px;
      }

      .el-input,
      .el-select {
        width: 167px;
        height: 38px;
      }
    }

    .btns {
      position: absolute;
      bottom: 70px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 400px;
      height: 46px;
      margin: 0 auto;

      .el-button {
        width: 150px;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #1E58A6;
        border-radius: 8px;
        border: none;
      }
    }
  }

  .el-radio-group {
    margin-top: 6px;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio {
    color: #fff;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }
}
</style>
