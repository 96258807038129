<template>
  <div class="update">
    <div class="sub-left">
      <div class="info-title">
        <div class="info-top"></div>
        <div class="info-bottom">
          <div class="info-bottom-text" @click="selectClick(-1)">
            <span>个人档案</span>
          </div>
        </div>
      </div>
      <div class="info-box">
        <div class="icon"></div>
        <div class="info-user">
          <img src="../../img/emr/user.png" class="img">
          <div class="info">
            <span>{{ info.name || '-' }}</span>
            <span>{{ info.age }} 岁</span>
          </div>
        </div>
        <div class="list-title list-title1">
          <span>基本信息</span>
          <span class="icon-open el-icon-caret-bottom"></span>
        </div>
        <!-- <div class="list-cell">
          <span>档案摘要</span>
        </div> -->
        <!-- <div class="list-cell" @click="selectClick(1)">
          <span :class="{ 'list-active': selectIndex === 1 }">档案封面</span>
        </div> -->
        <div class="list-cell" @click="selectClick(2)">
          <span :class="{ 'list-active': selectIndex === 2 }">居民健康档案信息卡</span>
        </div>
        <div class="list-cell" @click="selectClick(3)">
          <span :class="{ 'list-active': selectIndex === 3 }">基本信息</span>
        </div>
        <div class="list-cell" @click="selectClick(4)">
          <span :class="{ 'list-active': selectIndex === 4 }">体检记录</span>
        </div>
        <!-- <div class="list-cell">
          <span>服务优惠券</span>
        </div> -->
        <div class="list-cell" @click="selectClick(5)">
          <span :class="{ 'list-active': selectIndex === 5 }">随访记录</span>
        </div>
        <div class="list-cell" @click="selectClick(6)">
          <span :class="{ 'list-active': selectIndex === 6 }">就诊记录</span>
        </div>

        <div class="list-title">
          <span>健康评估</span>
          <span class="icon-open el-icon-caret-bottom"></span>
        </div>
        <div class="list-cell" @click="selectClick(8)">
          <span :class="{ 'list-active': selectIndex === 8 }">综合健康评估</span>
        </div>
        <!-- <div class="list-cell" @click="openVisit">
          <span :class="{ 'list-active': showAccess }">综合健康评估</span>
        </div> -->

        <div class="list-title">
          <span>健康跟踪</span>
          <span class="icon-open el-icon-caret-bottom"></span>
        </div>
        <div class="list-cell" @click="selectClick(9)">
          <span :class="{ 'list-active': selectIndex === 9 }">健康干预</span>
        </div>
        <div class="list-cell" @click="selectClick(10)">
          <span :class="{ 'list-active': selectIndex === 10 }">处方管理</span>
        </div>
        <div class="list-cell" @click="selectClick(11)">
          <span :class="{ 'list-active': selectIndex === 11 }">居民居家自测</span>
        </div>
        <div class="list-title">
          <span>区级健康档案浏览</span>
        </div>
        <div class="list-cell" @click="selectClick(12)">
          <span class="list-cell-span" :class="{ 'list-active': selectIndex === 12 }">档案调阅</span>
        </div>
      </div>
    </div>
    <div class="sub-right">
      <div class="sub-scroll">
        <div class="sub-scroll-title">
          <span class="color">基本信息</span>
        </div>
        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>新增报告</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>姓名</span>
              </div>
              <el-input class="input" v-model="info.name"></el-input>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key1">
                <span>个人档案编号</span>
              </div>
              <el-input class="input1" v-model="info.jkdnid"></el-input>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>性别</span>
              </div>
              <el-select v-model="info.xb" placeholder="" class="select">
                <el-option v-for="item in sexs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>出生日期</span>
              </div>
              <el-date-picker v-model="info.csrq" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>

            <div class="sub-scroll-list-item">
              <div class="key">
                <span>身份证号</span>
              </div>
              <el-input class="input3" v-model="info.sfzhm"></el-input>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>工作单位</span>
              </div>
              <el-input class="input1" v-model="info.gzdw"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item5">
              <div class="key">
                <span>本人电话</span>
              </div>
              <el-input class="input1" placeholder="请输入" v-model="info.lxdh"></el-input>
            </div>

            <div class="sub-scroll-list-item">
              <div class="key1">
                <span>联系人姓名</span>
              </div>
              <el-input class="input" v-model="info.lxrxm"></el-input>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key1">
                <span>联系人电话</span>
              </div>
              <el-input class="input1" v-model="info.lxrdh"></el-input>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>常住类型</span>
              </div>
              <el-select v-model="info.chzlx" placeholder="" class="select">
                <el-option v-for="item in czlxs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>民族</span>
              </div>
              <el-select v-model="info.minzu" placeholder="" class="select1">
                <el-option v-for="item in mzs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>

            <div class="sub-scroll-list-item">
              <div class="key">
                <span>血型</span>
              </div>
              <el-select v-model="info.xuexing" placeholder="" class="select">
                <el-option v-for="item in xxs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>RH</span>
              </div>
              <el-radio-group v-model="info.rh">
                <el-radio label="1">阴性</el-radio>
                <el-radio label="2">阳性</el-radio>
                <el-radio label="3">不详</el-radio>
              </el-radio-group>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>文化程度</span>
              </div>
              <el-select v-model="info.whcd" placeholder="" class="select">
                <el-option v-for="item in whcds" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>职业</span>
              </div>
              <el-select v-model="info.zhiye" placeholder="" class="select1">
                <el-option v-for="item in zys" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item5">
              <div class="key">
                <span>婚姻状况</span>
              </div>
              <el-radio-group v-model="info.hyqk">
                <el-radio label="1">未婚</el-radio>
                <el-radio label="2">已婚</el-radio>
                <el-radio label="3">丧偶</el-radio>
                <el-radio label="4">离婚</el-radio>
                <el-radio label="5">未说明的婚姻状况</el-radio>
              </el-radio-group>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item5">
              <div class="key1">
                <span>是否重点人群</span>
              </div>
              <el-radio-group v-model="info.isImportent">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key1">
                <span>医疗费用支付方式</span>
              </div>
              <el-radio-group v-model="info.ylfy">
                <el-radio label="1">城镇职工基本医疗保险</el-radio>
                <el-radio label="2">城镇居民基本医疗保险</el-radio>
                <el-radio label="3">新型农村合作医疗</el-radio>
                <el-radio label="4">贫困救助</el-radio>
                <el-radio label="5">商业医疗保险</el-radio>
                <el-radio label="6">全公费</el-radio>
                <el-radio label="7">其他</el-radio>
                <el-input class="input1" v-show="info.ylfy === '7'" v-model="info.ylfyqt"></el-input>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>过敏史</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key2">
                <span>药物过敏史</span>
              </div>
              <el-radio-group v-model="info.allergyHistory">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input class="input1" v-show="info.allergyHistory === '2'" v-model="info.alqt"></el-input>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>暴露史</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key2">
                <span>暴露史</span>
              </div>
              <el-radio-group v-model="info.exposureHistory">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input class="input1" v-show="info.exposureHistory === '2'" v-model="info.exqt"></el-input>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>疾病史</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key2">
                <span>有无疾病史</span>
              </div>
              <el-radio-group v-model="info.medicalHistory">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input class="input1" v-show="info.medicalHistory === '2'" v-model="info.meqt"></el-input>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>手术史</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key2">
                <span>有无手术史</span>
              </div>
              <el-radio-group v-model="info.surgicalHistory">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input class="input1" v-show="info.surgicalHistory === '2'" v-model="info.suqt"></el-input>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>外伤史</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key2">
                <span>有无外伤史</span>
              </div>
              <el-radio-group v-model="info.traumaHistory">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input class="input1" v-show="info.traumaHistory === '2'" v-model="info.trqt"></el-input>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>输血史</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key2">
                <span>有无输血史</span>
              </div>
              <el-radio-group v-model="info.bloodHistory">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input class="input1" v-show="info.bloodHistory === '2'" v-model="info.blqt"></el-input>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>家族史</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key2">
                <span>有无家族史</span>
              </div>
              <el-radio-group v-model="info.familyHistory">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input class="input1" v-show="info.familyHistory === '2'" v-model="info.faqt"></el-input>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>遗传病史</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key2">
                <span>有无遗传病史</span>
              </div>
              <el-radio-group v-model="info.geneticHistory">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input class="input1" v-show="info.geneticHistory === '2'" v-model="info.geqt"></el-input>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>残疾情况</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key2">
                <span>有无残疾</span>
              </div>
              <el-radio-group v-model="info.disabilitySituation">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input class="input1" v-show="info.disabilitySituation === '2'" v-model="info.diqt"></el-input>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>生活环境</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item5">
              <div class="key1">
                <span>厨房排风设施</span>
              </div>
              <el-radio-group v-model="info.cfpfss">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">油烟机</el-radio>
                <el-radio label="3">换气窗</el-radio>
                <el-radio label="4">烟囱</el-radio>
              </el-radio-group>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item5">
              <div class="key1">
                <span>燃料类型</span>
              </div>
              <el-radio-group v-model="info.rllx">
                <el-radio label="1">液化气</el-radio>
                <el-radio label="2">煤</el-radio>
                <el-radio label="3">天然气</el-radio>
                <el-radio label="4">沼气</el-radio>
                <el-radio label="5">柴火</el-radio>
                <el-radio label="6">其他</el-radio>
                <el-input class="input1" v-show="info.rllx === '6'" v-model="info.shhjrllxqt"></el-input>
              </el-radio-group>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item5">
              <div class="key1">
                <span>厕所</span>
              </div>
              <el-radio-group v-model="info.cslx">
                <el-radio label="1">卫生厕所</el-radio>
                <el-radio label="2">一格或二格粪池氏</el-radio>
                <el-radio label="3">马桶</el-radio>
                <el-radio label="4">露天粪坑</el-radio>
                <el-radio label="5">简易棚厕</el-radio>
              </el-radio-group>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item5">
              <div class="key1">
                <span>禽兽栏</span>
              </div>
              <el-radio-group v-model="info.qsl">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">单设</el-radio>
                <el-radio label="3">室内</el-radio>
                <el-radio label="4">室外</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>家庭基本信息</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>居住地址</span>
              </div>
              <el-input v-model="info.jkdnxzz" class="input2"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>详细地址</span>
              </div>
              <el-input v-model="info.jkdnhjdz" class="input1"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>户主姓名</span>
              </div>
              <el-input v-model="info.hzxm" class="input1"></el-input>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>户主身份证号</span>
              </div>
              <el-input v-model="info.hzsfzh" class="input1"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>邮政编码</span>
              </div>
              <el-input v-model="info.yzbm" class="input1" placeholder="请输入"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>住宅电话</span>
              </div>
              <el-input v-model="info.zzdh" class="input1"></el-input>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>与户主关系</span>
              </div>
              <el-input v-model="info.yhzgx" class="input1"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>家庭联系人</span>
              </div>
              <el-input v-model="info.jtlxr" class="input1" placeholder="请输入"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>家庭联系人身份证号</span>
              </div>
              <el-input v-model="info.jtlxrsfzh" class="input2"></el-input>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key1">
                <span>户属性</span>
              </div>
              <el-radio-group v-model="info.hlx">
                <el-radio label="0">一般户</el-radio>
                <el-radio label="1">低保户</el-radio>
                <el-radio label="2">五保户</el-radio>
                <el-radio label="3">贫困户</el-radio>
                <el-radio label="4">特困户</el-radio>
                <el-radio label="5">烈军属</el-radio>
                <el-radio label="6">其他</el-radio>
                <el-radio label="7">失独户</el-radio>
                <el-radio label="8">计划生育特殊户</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>其他信息</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>其他证件</span>
              </div>
              <!-- qtzjmc -->
              <el-select v-model="info.qtzjbh" placeholder="">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>工作单位电话</span>
              </div>
              <el-input v-model="info.gzdwdh" class="input1"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>户籍地址</span>
              </div>
              <el-select v-model="info.hjAdress" placeholder="">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>户籍详细地址</span>
              </div>
              <el-input v-model="info.hjxxdz" class="input3"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item6">
              <div class="key1">
                <span>籍贯</span>
              </div>
              <el-input v-model="info.jig" class="input1" placeholder="请输入"></el-input>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key1">
                <span>备注</span>
              </div>
              <el-input v-model="info.bz" class="input1"></el-input>
            </div>
          </div>

        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>登记信息</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>是否签约</span>
              </div>
              <el-radio-group v-model="info.isSign">
                <el-radio label="0">是</el-radio>
                <el-radio label="1">否</el-radio>
              </el-radio-group>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>建档机构</span>
              </div>
              <el-input v-model="info.jdjg" class="input1"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>责任医生</span>
              </div>
              <el-input v-model="info.zeys" class="input"></el-input>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>建档日期</span>
              </div>
              <el-date-picker v-model="info.jdrq" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>建档医生</span>
              </div>
              <el-input v-model="info.jdys" class="input"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>现管机构</span>
              </div>
              <!-- xgjgmc -->
              <el-select v-model="info.xgjgbh" placeholder="">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item3">
              <div class="key1">
                <span>录入日期</span>
              </div>
              <el-date-picker type="date" placeholder="选择日期" v-model="info.createTime">
              </el-date-picker>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item6">
              <div class="key1">
                <span>录入人</span>
              </div>
              <el-input v-model="info.jdr" class="input"></el-input>
            </div>
          </div>

        </div>

      </div>
      <div class="sub-btns">
        <el-button type="primary" size="small" @click="submit">保存</el-button>
        <el-button size="small" @click="close">返回</el-button>
      </div>
      <!-- 档案封面 -->
      <Cover v-if="selectIndex === 1"></Cover>
      <!-- 居民健康档案信息卡 -->
      <HealthRecordInfo v-else-if="selectIndex === 2" :selectSfzh="selectSfzh"></HealthRecordInfo>
      <!-- 基本信息 -->
      <BasicInfo v-else-if="selectIndex === 3" :selectSfzh="selectSfzh"></BasicInfo>
      <!-- 体检记录 -->
      <TestRecords v-else-if="selectIndex === 4" :selectSfzh="selectSfzh" :xzjdmcid="xzjdmcid" :cwhmcid="cwhmcid">
      </TestRecords>
      <!-- 随访记录 -->
      <VisitRecords v-else-if="selectIndex === 5" :selectSfzh="selectSfzh"></VisitRecords>
      <!-- 就诊记录 -->
      <VisitRecord v-else-if="selectIndex === 6"></VisitRecord>
      <!-- 综合健康评估 -->
      <HealthAssess v-else-if="selectIndex === 8"></HealthAssess>
      <!-- 健康干预 -->
      <HealthInterventions v-else-if="selectIndex === 9"></HealthInterventions>
      <!-- 处方管理 -->
      <prescriptionRun v-else-if="selectIndex === 10"></prescriptionRun>
      <!-- 自测数据 -->
      <SelfTestData v-else-if="selectIndex === 11"></SelfTestData>
      <!-- 档案调阅 -->
      <Access v-else-if="selectIndex === 12" @close="closeAccess"></Access>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Cover from './cover.vue'
import HealthRecordInfo from './healthRecordInfo/healthRecordInfo.vue'
import BasicInfo from './basicInfo.vue'
import TestRecords from './testRecords/testRecords.vue'
import VisitRecords from './visitRecords/visitRecords.vue'
import VisitRecord from './visitRecord/visitRecord.vue'
import SelfTestData from './selfTestData/selfTestData.vue'
import HealthAssess from './healthAssess/healthAssess.vue'
import HealthInterventions from './healthInterventions/healthInterventions.vue'
import prescriptionRun from './prescriptionRun/prescriptionRun.vue'
import Access from './access.vue'
export default {
  props: {
    selectSfzh: {
      type: String,
      default: ''
    },
    xzjdmcid: {
      type: String,
      default: ''
    },
    cwhmcid: {
      type: String,
      default: ''
    }
  },
  components: {
    Cover,
    HealthRecordInfo,
    BasicInfo,
    TestRecords,
    VisitRecords,
    VisitRecord,
    SelfTestData,
    HealthAssess,
    HealthInterventions,
    prescriptionRun,
    Access

  },
  data () {
    return {
      radio: '1',
      options: [],
      sexs: Object.freeze([
        { label: '男', value: '1' },
        { label: '女', value: '2' }
      ]),
      czlxs: Object.freeze([
        { label: '户籍', value: '1' },
        { label: '非户籍', value: '2' }
      ]),
      mzs: Object.freeze([
        { label: '汉族', value: '01' },
        { label: '少数民族', value: '99' }
      ]),
      xxs: Object.freeze([
        { label: 'A型', value: '1' },
        { label: 'B型', value: '2' },
        { label: 'O型', value: '3' },
        { label: 'AB型', value: '4' },
        { label: '不详', value: '5' }
      ]),
      zys: Object.freeze([
        { label: '国家机关、党群组织、企、事业单位负责人', value: '1' },
        { label: '专业技术人员', value: '2' },
        { label: '办事人员和有关人员', value: '3' },
        { label: '商业、服务业人员', value: '4' },
        { label: '农、林、牧、渔、水利业生产人员', value: '5' },
        { label: '生产、运输设备操作人员及有关人员', value: '6' },
        { label: '军人', value: '7' },
        { label: '不便分类的其他从业人员', value: '8' },
        { label: '无职业', value: '9' }
      ]),
      whcds: Object.freeze([
        { label: '研究生', value: '1' },
        { label: '大学本科', value: '2' },
        { label: '大学专科和专科学校', value: '3' },
        { label: '中等专业校', value: '4' },
        { label: '技工学校', value: '5' },
        { label: '高中', value: '6' },
        { label: '初中', value: '7' },
        { label: '小学', value: '8' },
        { label: '文盲及半文盲', value: '9' },
        { label: '不详', value: '10' }
      ]),
      value: '',
      checkList: [''],
      showCover: false,
      showAccess: false,
      selectIndex: -1,
      info: {
        id: 0,
        createTime: '',
        updateTime: '',
        jkdnid: '',
        name: '',
        lxdh: '',
        jdrq: '',
        sfzhm: '',
        grjbxxid: '',
        tmcode: '',
        jgbh: '',
        cwhmcid: '',
        age: '',
        personInfoId: '',
        isHypertension: '',
        isDiabetes: '',
        isSmi: '',
        isTuberculosis: '',
        isChd: '',
        isCa: '',
        isaged: '',
        isWomen: '',
        isOld: '',
        isChildren: '',
        isMaternal: '',
        isPhychosis: '',
        personInfoNo: '',
        manageOrgId: '',
        xb: '',
        sfztp: '',
        xzjdmcid: '',
        jddwid: '',
        tjlx: '',
        nametage: '',
        deflag: '',
        csrq: '',
        jkdnxzz: '',
        jkdnhjdz: '',
        xzjdmc: '',
        cwhmc: '',
        jdr: '',
        jddw: '',
        zeys: '',
        dnbh: '',
        whcd: '',
        hyqk: '',
        zhiye: '',
        lxrxm: '',
        lxrdh: '',
        czlx: '',
        ylfy: '',
        ddyldw: '',
        ybzksg: '',
        yzbktz: '',
        xuexing: '',
        rh: '',
        minzu: '1',
        sfzdrq: '',
        allergyHistory: '',
        exposureHistory: '',
        medicalHistory: '',
        surgicalHistory: '',
        traumaHistory: '',
        bloodHistory: '',
        familyHistory: '',
        geneticHistory: '',
        disabilitySituation: '',
        cfpfss: '',
        rllx: '',
        cslx: '',
        qsl: '',
        hzxm: '',
        hzsfzh: '',
        yzbm: '',
        zzdh: '',
        yhzgx: '',
        jtlxr: '',
        jtlxrsfzh: '',
        qtzj: '',
        gzdwdh: '',
        hjxxdz: '',
        jig: '',
        bz: '',
        isSign: '',
        jdys: '',
        xgjg: '',
        gzdw: '',
        chzlx: '',
        isImportent: '',
        hlx: [],
        hjAdress: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo']),
    ylfy () {
      return this.info.ylfy
    },
    rllx () {
      return this.info.rllx
    },
    allergyHistory () {
      return this.info.allergyHistory
    },
    exposureHistory () {
      return this.info.exposureHistory
    },
    medicalHistory () {
      return this.info.medicalHistory
    },
    surgicalHistory () {
      return this.info.surgicalHistory
    },
    traumaHistory () {
      return this.info.traumaHistory
    },
    bloodHistory () {
      return this.info.bloodHistory
    },
    familyHistory () {
      return this.info.familyHistory
    },
    disabilitySituation () {
      return this.info.disabilitySituation
    }
  },
  watch: {
    ylfy (newVal) {
      if (newVal !== '7') {
        this.info.ylfyqt = ''
      }
    },
    rllx (newVal) {
      if (newVal !== '6') {
        this.info.shhjrllxqt = ''
      }
    },
    allergyHistory (newVal) {
      if (newVal !== '2') {
        this.info.alqt = ''
      }
    },
    exposureHistory (newVal) {
      if (newVal !== '2') {
        this.info.exqt = ''
      }
    },
    medicalHistory (newVal) {
      if (newVal !== '2') {
        this.info.meqt = ''
      }
    },
    surgicalHistory (newVal) {
      if (newVal !== '2') {
        this.info.suqt = ''
      }
    },
    traumaHistory (newVal) {
      if (newVal !== '2') {
        this.info.trqt = ''
      }
    },
    bloodHistory (newVal) {
      if (newVal !== '2') {
        this.info.blqt = ''
      }
    },
    familyHistory (newVal) {
      if (newVal !== '2') {
        this.info.faqt = ''
      }
    },
    geneticHistory (newVal) {
      if (newVal !== '2') {
        this.info.geqt = ''
      }
    },
    disabilitySituation (newVal) {
      if (newVal !== '2') {
        this.info.diqt = ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-124388850
      try {
        const { data: res } = await this.$http.post('/api/jkct/app/findinfo', {
          sfzh: this.selectSfzh,
          type: 'jkdn',
          ssjgbh: this.loginInfo.jgbh || ''
        })
        console.log('根据参数获取档案信息', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
        if (!this.info.hlx) {
          this.info.hlx = []
        } else {
          this.info.hlx = this.info.hlx.split(',')
        }
      } catch (error) {
        console.log('根据参数获取档案信息请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-209538077
      try {
        const { data: res } = await this.$http.post('/api/jkct/app/updateinfo', {
          ...this.info,
          // ylfy: this.info.ylfy.join(','),
          hlx: this.info.hlx.join(',')
        })
        console.log('修改档案', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.$emit('close', 'load')
      } catch (error) {
        console.log('修改档案请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    selectClick (index) {
      if (index === this.selectIndex) {
        this.selectIndex = -1
      } else {
        this.selectIndex = index
        if (index === 12) {
          this.$emit('showAccess', { info: {} })
        }
      }
    },
    openCover () {
      if (this.showCover) {
        this.showCover = false
        return
      }
      this.showCover = true
    },
    closeCover () {
      this.showCover = false
    },
    openAccess () {
      if (this.showAccess) {
        this.showAccess = false
        return
      }
      this.showAccess = true
    },
    closeAccess () {
      this.showAccess = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;
  box-sizing: border-box;

  .sub-left {
    width: 270px;
    height: 100%;
    margin: 0 10px;
    padding: 0 10px;
    background-color: #fff;
    box-sizing: border-box;

    .info-title {
      display: flex;
      flex-direction: column;
      background-color: rgb(100,197,255);

      .info-top {
        position: relative;
        width: 87px;
        height: 6px;
        transform: skew(30deg);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: -6px;
          width: 10px;
          height: 6px;
          transform: skew(-50deg);
        }
      }

      .info-bottom {
        overflow: hidden;
        position: relative;
        width: 252px;
        height: 30px;
        transform: skew(30deg);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: -30px;
          width: 20px;
          height: 30px;
          transform: skew(-55deg);
        }

        .info-bottom-text {
          height: 30px;
          line-height: 30px;
          padding-left: 20px;
          box-sizing: border-box;
          transform: skew(-30deg);
          color: #fff;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .info-box {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 752px;
      margin-top: 8px;
      padding: 0 4px;
      padding-top: 33px;
      background-color: #fff;
      border-radius: 6px;
      box-sizing: border-box;

      .icon {
        position: absolute;
        top: 0;
        left: 7px;
        z-index: 88;
        width: 60px;
        height: 2px;
        background-color: #44D7B6;
        border-radius: 2px;
      }

      .info-user {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 10px;
        box-sizing: border-box;

        .img {
          width: 50px;
          height: 50px;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 10px;
          color: #000;
          font-size: 16px;
        }
      }

      .list-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding: 0 4px;
        background-color: rgb(100,197,255);
        border-radius: 8px;
        box-sizing: border-box;
        color: #fff;
        font-size: 16px;
        font-weight: bold;

        .icon-open {
          color: #fff;
          font-size: 18px;
        }
      }

      .list-title1 {
        margin-top: 20px;
      }

      .list-cell {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 35px;
        line-height: 35px;
        padding-left: 70px;
        text-align: center;
        color: #000;
        font-size: 14px;
        box-sizing: border-box;
        cursor: pointer;
      }

      .list-active {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        padding-left: 67px;
        border-left: 3px solid #01EEFD;
        color: #01EEFD;
        font-size: 16px;
        font-weight: bold;
        box-sizing: border-box;
      }
    }
  }

  .sub-right {
    position: relative;
    width: calc(100% - 290px);
    height: 100%;
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .sub-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 80px);

      /deep/.el-radio__input.is-checked .el-radio__inner {
        background-color: #000;
        border-color: #000;
      }

      /deep/.el-radio__label,
      /deep/.el-checkbox__label {
        font-size: 18px;
      }

      .el-radio,
      .el-checkbox {
        margin-right: 20px;
        color: #000;
        font-size: 18px;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label,
      /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
        color: #000;
        border-color: #000;
      }

      &::-webkit-scrollbar {
        width: 0;
      }

      .sub-scroll-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        line-height: 48px;
        padding: 0 18px;
        border-bottom: 1px solid #000;
        box-sizing: border-box;
        color: #000;
        font-size: 20px;
        font-weight: bold;

        .size {
          font-size: 18px;
          font-weight: 400;
        }

        .color {
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          background-color: rgb(100,197,255);
          color: #fff;
        }
      }

      .sub-scroll-list {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #000;
        box-sizing: border-box;

        .sub-scroll-list-title {
          height: 45px;
          line-height: 45px;
          padding-left: 10px;
          box-sizing: border-box;
          color: #000;
          font-size: 18px;
        }

        .sub-scroll-list-box {
          display: flex;
          flex-wrap: wrap;
          padding: 0 20px;
          box-sizing: border-box;

          .sub-scroll-list-item {
            display: flex;
            align-items: center;
            width: 25%;
            margin-bottom: 18px;

            .key {
              width: 80px;
              margin-right: 10px;
              white-space: nowrap;
              text-align-last: justify;
              color: #000;
              font-size: 18px;
            }

            .key1 {
              margin-right: 10px;
              white-space: nowrap;
              color: #000;
              font-size: 18px;
            }

            .key2 {
              width: 140px;
              white-space: nowrap;
              color: #000;
              font-size: 18px;
            }

            .input {
              width: 100px;
            }

            .input1 {
              width: 160px;
            }

            .input2 {
              width: 300px;
            }

            .input3 {
              width: 240px;
            }

            .select {
              width: 100px;
            }

            .select1 {
              width: 150px;
            }

            .radio-width {
              width: 150px;
            }

            .unit {
              margin-left: 10px;
              color: #000;
              font-size: 18px;
              white-space: nowrap;
            }
          }

          .sub-scroll-list-item1 {
            width: 40%;
          }

          .sub-scroll-list-item2 {
            width: 100%;
          }

          .sub-scroll-list-item3 {
            width: 33%;
          }

          .sub-scroll-list-item4 {
            width: 70%;
          }

          .sub-scroll-list-item5 {
            width: 50%;
          }

          .sub-scroll-list-item6 {
            width: 67%;
          }
        }
      }

      .sub-scroll-list:last-child {
        border: none;
      }
    }

    .sub-btns {
      display: flex;
      align-items: center;
      height: 80px;
    }
  }

}
</style>
