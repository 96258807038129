<template>
  <div class="added">
    <div class="added-box">
      <div class="added-head">
        <span>体检结果异常</span>
        <span class="el-icon-close close" @click="close"></span>
      </div>
      <div class="added-search">
        <div class="added-item">
          <div class="added-key">
            <span>体检日期</span>
          </div>
          <el-date-picker
            v-model="value1"
            type="date"
            placeholder="体检日期">
          </el-date-picker>
        </div>
        <div class="added-item">
          <div class="added-key">
            <span>姓名</span>
          </div>
          <el-input v-model="name" placeholder="姓名"></el-input>
        </div>
        <div class="added-item">
          <div class="added-key">
            <span>身份证号</span>
          </div>
          <el-input v-model="sfzh" placeholder="身份证号"></el-input>
        </div>
        <div class="added-item">
          <div class="added-key">
            <span>人群分类</span>
          </div>
          <el-select v-model="rqfl" placeholder="人群分类">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="added-item">
          <el-button type="primary" @click="noClick">
            <span class="el-icon-search"></span>
            <span>搜索</span>
          </el-button>
        </div>
        <div class="added-item">
          <el-button @click="clear">
            <span class="el-icon-delete"></span>
            <span>清空</span>
          </el-button>
        </div>
        <div class="added-item" @click="noClick">
          <el-button type="primary">
            <span class="el-icon-download"></span>
            <span>导出</span>
          </el-button>
        </div>
      </div>
      <div class="added-table">
        <el-table :data="tableData" border height="100%">
          <el-table-column type="index" label="序号" width="100" fixed="left">
          </el-table-column>
          <el-table-column prop="date" label="体检日期" :width="flexWidth('date', tableData, '体检日期')">
          </el-table-column>
          <el-table-column prop="name" label="姓名" :width="flexWidth('name', tableData, '姓名')">
          </el-table-column>
          <el-table-column prop="age" label="年龄" :width="flexWidth('age', tableData, '年龄')">
          </el-table-column>
          <el-table-column label="性别" :width="flexWidth('sex', tableData, '性别')">
            <template>
              <!-- <el-tag type="success">男</el-tag> -->
              <el-tag type="danger">女</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="sfzh" label="身份证号" :width="flexWidth('sfzh', tableData, '身份证号')">
          </el-table-column>
          <el-table-column prop="rqfl" label="人群分类" :width="flexWidth('rqfl', tableData, '人群分类')">
          </el-table-column>
          <el-table-column prop="rqfl" label="生化" :width="flexWidth('rqfl', tableData, '生化')">
          </el-table-column>
          <el-table-column prop="rqfl" label="血常规" :width="flexWidth('rqfl', tableData, '血常规')">
          </el-table-column>
          <el-table-column prop="rqfl" label="尿常规" :width="flexWidth('rqfl', tableData, '尿常规')">
          </el-table-column>
          <el-table-column prop="rqfl" label="心电图" :width="flexWidth('rqfl', tableData, '心电图')">
          </el-table-column>
          <el-table-column prop="rqfl" label="B超" :width="flexWidth('rqfl', tableData, 'B超')">
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="200">
          </el-table-column>
        </el-table>
      </div>
      <div class="added-bottom">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
          :total="400">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '',
      options: [],
      name: '',
      sfzh: '',
      rqfl: '',
      value: '',
      value1: '',
      currentPage4: 4,
      tableData: []
    }
  },
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    clear () {
      this.value1 = ''
      this.name = ''
      this.sfzh = ''
      this.rqfl = ''
    },
    noClick () {
      this.$message.error('未开放')
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.added {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);

  .added-box {
    width: 80%;
    height: 90%;
    padding: 0 20px;
    border-radius: 12px;
    background-color: #fff;
    box-sizing: border-box;

    .added-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      color: #000;
      font-size: 20px;

      .close {
        font-size: 24px;
        cursor: pointer;
      }
    }

    .added-search {
      display: flex;
      flex-wrap: wrap;
      align-content: space-evenly;
      width: 100%;
      height: 120px;

      .added-item {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .added-key {
          margin-right: 10px;
          white-space: nowrap;
          font-size: 18px;
        }
      }
    }

    .added-table {
      width: 100%;
      height: calc(100% - 280px);
      background-color: red;
    }

    .added-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100px;
    }
  }
}
</style>
