<template>
  <div class="write">
    <div class="title">
      <span>查看数据</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="box-title">
          <span>用户信息</span>
        </div>
        <div class="line">
          <span>姓名：殷鹏</span>
          <span>性别：男</span>
          <span>手机号：138****8857</span>
          <span>身份证号：5108**********4119</span>
        </div>
        <div class="box-title">
          <span>健康数据</span>
        </div>
        <el-table :data="tableData" height="200">
          <el-table-column prop="name" label="设备类型">
          </el-table-column>
          <el-table-column prop="date" label="型号">
          </el-table-column>
          <el-table-column prop="date" label="设备SN号">
          </el-table-column>
          <el-table-column prop="type" label="出厂日期">
          </el-table-column>
          <el-table-column prop="type1" label="绑定日期">
          </el-table-column>
          <el-table-column prop="type2" label="激活日期">
          </el-table-column>
          <el-table-column prop="type2" label="所属科室">
          </el-table-column>
          <el-table-column prop="type2" label="使用者">
          </el-table-column>
          <el-table-column prop="type2" label="状态">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex flex1">
                <span>查看数据</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
        <div class="box-title">
          <span>趋势分析</span>
        </div>
        <div class="charts" id="chart1"></div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        name: '111'
      }],
      myChart1: null
    }
  },
  mounted () {
    this.canvas()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      this.myChart1.resize()
    },
    canvas () {
      this.canvas1()
      window.addEventListener('resize', this.getresize)
    },
    canvas1 () {
      var chartDom = document.getElementById('chart1')
      this.myChart1 = this.$echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',
              fontSize: 15
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: [
          {
            type: 'value',
            name: 'mmol/L',
            nameTextStyle: {
              color: '#fff',
              nameLocation: 'start'
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: ['#fff']
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff',
                fontSize: 15
              }
            }
          }
        ],
        grid: {
          left: '3%',
          right: '4%',
          top: '16%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            name: '体重',
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              // color: '#CE7500' // 改变折线点的颜色
              color: function (e) {
                if (e.data > 200) {
                  return '#FF2A2A'
                } else if (e.data < 200 && e.data > 50) {
                  return '#CE7500'
                } else {
                  return '#fff'
                }
              }
            },
            lineStyle: {
              color: '#30F05D' // 改变折线颜色
            }
          }
        ]
      }
      option && this.myChart1.setOption(option)
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 50px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: calc(100% - 53px);
    margin-top: 10px;
    padding: 50px;
    padding-right: 100px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 35px;
      right: 30px;
      color: #fff;
      font-size: 30px;
      cursor: pointer;
    }

    .box {
      overflow-y: scroll;
      width: 100%;
      height: 100%;

      &::-webkit-scrollbar {
        width: 0;
      }

      .box-title {
        height: 44px;
        line-height: 44px;
        border-bottom: .0125rem solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 18px;
      }
    }

    .line {
      display: flex;
      align-items: center;
      padding: 10px 0;
      color: #01EEFD;
      font-size: 16px;

      span {
        margin-right: 40px;
      }
    }

    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0px;
      }
    }

    ::v-deep .el-table th.gutter {
      display: none;
      width: 0;
    }

    ::v-deep .el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    ::v-deep .el-table__body {
      width: 100% !important;
    }

    .el-table {
      margin-top: 10px;
      background-color: #092B50;
      border: 4px #073F73 solid;
    }

    // 表头文字颜色
    /deep/.el-table__header-wrapper {
      thead {
        th {
          div {
            color: #01EEFD;
            font-size: 17px;
            font-weight: 400;
          }
        }
      }
    }

    //表格内容颜色
    /deep/.el-table__body tbody tr {
      color: #fff;
      background-color: #092B50;
    }

    /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #092B50;
    }

    /deep/.el-table tbody tr:hover>td {
      background-color: transparent !important
    }

    // 改变边框颜色
    .el-table--border,
    .el-table--group {
      border: 2px solid #ddd !important;
    }

    /**
  改变表格内竖线颜色
   */
    .el-table--border td,
    .el-table--border th,
    .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      border-right: 1px solid #ddd !important;
    }

    /deep/.el-table td {
      border-bottom: 1px solid #01EEFD;
    }

    /deep/.el-table thead tr th {
      border-color: transparent;
    }

    /deep/ .el-table--border::after,
    /deep/ .el-table--group::after,
    /deep/ .el-table::before {
      background-color: #073F73;
    }

    /deep/.el-table--border {
      border: 1px solid #073F73 !important;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    ::v-deep .el-table__empty-text {
      color: #01EEFD;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 40px;

      ::v-deep.el-pagination__total,
      ::v-deep.el-pagination__jump {
        color: #01EEFD;

        .el-input__inner {
          color: #fff;
          background-color: transparent;
        }
      }

      ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #01EEFD;
      }
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #0e75d4;
      font-size: 15px;

      span {
        cursor: pointer;
      }
    }

    .flex1 {
      justify-content: center;
    }

    .charts {
      width: 100%;
      height: 500px;
    }
  }
}
</style>
