<template>
  <div class="son">
    <div class="son-box">
      <div class="son-title">
        <div class="font">
          <span>健康体检条码</span>
          <span class="el-icon-printer print" :class="{ red: !isConnect }" v-loading="connecting" @click="connect"></span>
        </div>
        <span class="el-icon-close icon" @click="close"></span>
      </div>
      <div class="tmcode">
        <div class="code">
          <VueBarcode :value='printInfo.tmcode' :displayValue="false"></VueBarcode>
          <div class="text">
            <span class="span">{{ printInfo.name || '-' }}</span>
            <span class="span">{{ printInfo.xb === '1' ? '男' : '女' }}</span>
            <span class="span">{{ printInfo.age || '-' }}</span>
            <span class="span">{{ printInfo.tmcode || '-' }}</span>
          </div>
        </div>
      </div>
      <el-button type="primary" :loading="printing" @click="print">{{ printing ? '打印中' : '打印' }}</el-button>
    </div>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'
import { createNew } from '../../../../../utils/print/tsc.js'
export default {
  props: {
    printInfo: {
      typeof: Object,
      default () {
        return {
          name: '',
          xb: '',
          age: '',
          tmcode: ''
        }
      }
    }
  },
  data () {
    return {
      isConnect: false,
      connecting: false,
      currentTime: 2,
      // 打印计数
      printCount: 1,
      // 当前打印计数
      currentCount: 1,
      printing: false,
      timer: null
    }
  },
  components: {
    VueBarcode
  },
  mounted () {
    this.connect()
  },
  beforeDestroy () {
    this.disconnect()
  },
  methods: {
    async connect () {
      if ('bluetooth' in navigator) {
        try {
          this.device = await navigator.bluetooth.requestDevice({
            filters: [{ services: ['e7810a71-73ae-499d-8c15-faa9aef0c3f2'] }]
          })
          this.connecting = true
          this.device.gatt.connect().then(async server => {
            this.connecting = false
            const service = await server.getPrimaryService('e7810a71-73ae-499d-8c15-faa9aef0c3f2')
            this.characteristic = await service.getCharacteristic('bef8d6c9-9c21-4c9e-b632-bd58c1009f9f')
            this.$message({ message: '连接成功', type: 'success' })
            this.isConnect = true
            this.device.addEventListener('gattserverdisconnected', this.onDisconnected)
          }).catch(err => {
            console.log('连接失败', err)
            this.connecting = false
            this.isConnect = false
            this.$message({
              message: '连接失败',
              type: 'warning'
            })
          })
        } catch (error) {
          console.error('连接蓝牙打印机失败', error)
          this.isConnect = false
          this.$message({
            message: '连接失败',
            type: 'warning'
          })
        }
      } else {
        console.log('浏览器不支持Web Bluetooth API')
        this.isConnect = false
        this.$message({
          message: '浏览器不支持Web Bluetooth API',
          type: 'warning'
        })
      }
    },
    // 监听蓝牙断开事件
    onDisconnected (event) {
      console.log(event)
      const device = event.target
      this.isConnect = false
      this.$message({
        message: '设备 ' + (device.name || '') + ' 断开连接',
        type: 'warning'
      })
      // 取消监听蓝牙设备的连接状态
      device.removeEventListener('gattserverdisconnected', this.onDisconnected)
    },
    print () {
      this.printing = true
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.printing = false
        console.log('强制取消打印')
      }, this.printCount * 6000)
      const command = createNew()
      command.setCls()
      setTimeout(() => {
        command.setSize('40', '120')
        command.setGap(1)
        command.setCls()
        command.setText(50, 38, 'TSS24.BF2', 0, 1, 1, '健康体检条码')
        command.setBarCode(10, 72, '128', 60, 0, 0, 2, 2, '3023645644545')
        command.setText(50, 140, 'TSS24.BF2', 0, 1, 1, '李四 女 23 ')
        command.setText(50, 170, 'TSS24.BF2', 0, 1, 1, '3023645644545')
        // command.setBox(-70, 50, 400, 940, 1)
        // command.setText(70, 80, 'TSS24.BF2', 0, 2, 2, '详情信息')
        // command.setBar(-70, 150, 470, 1)

        // command.setText(-60, 170, 'TSS24.BF2', 0, 1, 1, '编号:33384528899')
        // command.setText(200, 170, 'TSS24.BF2', 0, 1, 1, '关注级别:')
        // command.setBar(-70, 220, 470, 1)

        // command.setText(-60, 240, 'TSS24.BF2', 0, 1, 1, '李明明  男  25岁  130133199909090909')
        // command.setText(-60, 290, 'TSS24.BF2', 0, 1, 1, '楼层')
        // command.setText(100, 290, 'TSS24.BF2', 0, 1, 1, '房间')
        // command.setText(250, 290, 'TSS24.BF2', 0, 1, 1, '床位')
        // command.setBar(-70, 330, 470, 1)

        // command.setText(-60, 350, 'TSS24.BF2', 0, 1, 1, '入院诊断:')
        // command.setText(-60, 410, 'TSS24.BF2', 0, 1, 1, '入院时间')
        // command.setBar(-70, 450, 470, 1)

        // command.setText(-60, 470, 'TSS24.BF2', 0, 1, 1, '主管医生:')
        // command.setText(150, 470, 'TSS24.BF2', 0, 1, 1, '主管护士:')
        // command.setText(-60, 520, 'TSS24.BF2', 0, 1, 1, '护理级别:')
        // command.setText(150, 520, 'TSS24.BF2', 0, 1, 1, '主管护工:')
        // command.setBar(-70, 560, 470, 1)

        // command.setQrcode(70, 640, 'L', '10', 'M', '18464156456')
        command.setPagePrint()
        this.Send(command.getData())
      }, 1500)
    },
    // 发送指令
    async Send (buff) {
      let currentTime = this.currentTime
      const loopTime = parseInt(buff.length / 20) + 1
      const lastData = parseInt(buff.length % 20)
      let currentCount = this.currentCount
      let buf = ''
      let dataView = ''
      if (currentTime < loopTime) {
        buf = new ArrayBuffer(20)
        dataView = new DataView(buf)
        for (var i = 0; i < 20; ++i) {
          dataView.setUint8(i, buff[(currentTime - 1) * 20 + i])
        }
      } else {
        buf = new ArrayBuffer(lastData)
        dataView = new DataView(buf)
        for (var j = 0; j < lastData; ++j) {
          dataView.setUint8(j, buff[(currentTime - 1) * 20 + j])
        }
      }
      const date = new Uint8Array(buf)
      await this.characteristic.writeValue(date)
      currentTime++
      if (currentTime <= loopTime) {
        this.currentTime = currentTime
        this.Send(buff)
      } else {
        if (currentCount === this.printCount) {
          this.currentTime = 1
          this.currentCount = 1
          setTimeout(() => {
            this.printing = false
            if (this.timer) {
              clearTimeout(this.timer)
            }
            this.$message({ message: '打印完毕', type: 'success' })
          }, 1000)
        } else {
          currentCount++
          this.currentCount = currentCount
          this.currentTime = 1
          this.Send(buff)
        }
      }
    },
    // 断开连接
    disconnect () {
      if (this.device && this.device.gatt) {
        this.device.gatt.disconnect()
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .69);

  .son-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;
    height: 50%;
    background-color: #fff;
    border-radius: 18px;

    .son-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      padding: 0 20px;
      box-sizing: border-box;

      .font {
        font-size: 20px;
        font-weight: 600;
      }

      .icon {
        font-size: 26px;
        cursor: pointer;
      }

      .print {
        margin-left: 10px;
        color: #409EFF;
        cursor: pointer;
      }

      .red {
        color: red;
      }
    }

    .tmcode {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(100% - 160px);

      .code {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 50%;

        .text {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 16px;

          .span {
            margin: 0 10px;
          }
        }
      }
    }

    .el-button {
      margin-top: 20px;
    }
  }
}
</style>
