<template>
  <div class="page">
    <div class="head">
      <el-button type="primary">处方管理</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <el-button size="mini" type="primary" @click="openAddRrescription">新增</el-button>
    <div class="box">
      <el-table :data="tableData" height="100%" border>
        <el-table-column prop="cfmc" label="处方名称">
        </el-table-column>
        <el-table-column prop="pgsj" label="评估时间">
        </el-table-column>
        <el-table-column prop="name" label="操作" width="230">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="openRrescriptionInfo(scope.row.id)">查看</el-button>
            <el-button type="primary" size="small" @click="openUpdateRrescription(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="small" @click="clear(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <RrescriptionInfo v-if="showRrescriptionInfo" @closePopup="closeRrescriptionInfo"></RrescriptionInfo>
    <AddRrescription v-if="showAddRrescription" @closePopup="closeAddRrescription"></AddRrescription>
    <UpdateRrescription v-if="showUpdateRrescription" @closePopup="closeUpdateRrescription"></UpdateRrescription>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RrescriptionInfo from './prescriptionInfo.vue'
import AddRrescription from './addRrescription.vue'
import UpdateRrescription from './updateRrescription.vue'
export default {
  components: {
    RrescriptionInfo,
    AddRrescription,
    UpdateRrescription
  },
  data () {
    return {
      tableData: [],
      selectId: null,
      showRrescriptionInfo: false,
      showAddRrescription: false,
      showUpdateRrescription: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-208862418
      try {
        const { data: res } = await this.$http.post('/ydkjkcfbtlbs/getlist', {
          ssjgbh: this.loginInfo.jgbh || '',
          jgbh: this.loginInfo.jgbh || ''
        })
        console.log('处方居民信息列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
      } catch (error) {
        console.log('处方居民信息列表请求失败', error)
        // this.$message.error('系统异常，请稍后重试')
      }
    },
    openRrescriptionInfo (id) {
      this.selectId = id
      this.showRrescriptionInfo = true
    },
    closeRrescriptionInfo () {
      this.showRrescriptionInfo = false
    },
    openAddRrescription () {
      this.showAddRrescription = true
    },
    closeAddRrescription (type) {
      if (type === 'load') {
        this.getList()
      }
      this.showAddRrescription = false
    },
    openUpdateRrescription (id) {
      this.selectId = id
      this.showUpdateRrescription = true
    },
    closeUpdateRrescription (type) {
      if (type === 'load') {
        this.getList()
      }
      this.selectId = null
      this.showUpdateRrescription = false
    },
    clear (id) {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-208862418
      try {
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const { data: res } = await this.$http.post('/ydkjkcfbtlbs/deleteone', { id })
          console.log('处方居民信息删除', res)
          if (res.code !== 0) return this.$message.error(res.msg || res.message)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getList()
        }).catch(() => {})
      } catch (error) {
        console.log('处方居民信息删除请求失败', error)
        // this.$message.error('系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 20px;
  // background-color: #062f57;
  background-color: #fff;
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
    }
  }

  .box {
    width: 100%;
    height: calc(100% - 200px);
    margin-top: 10px;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #000;
      font-size: 14px;

      .item {
        flex: 1;
        text-align: center;
      }

      .item1 {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          color: #000;
          font-size: 14px;
          cursor: pointer;
        }

        .btn1 {
          margin-left: 30px;
          margin-right: 30px;
        }

        .red {
          color: #FF0000;
        }
      }
    }

    .line1 {
      height: 40px;
      line-height: 40px;
      background-color: #024276;

      .item {
        color: #fff;
      }
    }

    .line2 {
      margin-top: 10px;
    }
  }

  .return {
    position: absolute;
    bottom: 30px;
  }
}</style>
