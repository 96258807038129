<template>
  <div class="tab">
    <div class="tab-scroll">
      <div class="tab-title">
        <span>尿常规体检报告单</span>
      </div>
      <div class="info">
        <div class="line border">
          <div class="item">
            <span>编号：-</span>
          </div>
          <div class="item">
            <span>姓名：-</span>
          </div>
          <div class="item">
            <span>性别:-</span>
          </div>
          <div class="item">
            <span>身份证:-</span>
          </div>
          <div class="item">
            <span>电话:-</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>项目名称</span>
          </div>
          <div class="item">
            <span>结果</span>
          </div>
          <div class="item">
            <span>提示</span>
          </div>
          <div class="item">
            <span>单位</span>
          </div>
          <div class="item">
            <span>参考范围</span>
          </div>
        </div>
        <div class="line" v-for="(item, index) in info" :key="index">
          <div class="item">
            <span>{{ item.jktjxmmc || '-' }}</span>
          </div>
          <div class="item">
            <span>{{ item.jktjjgsz || '-' }}</span>
          </div>
          <div class="item">
            <span>{{ item.jktjts || '-' }}</span>
          </div>
          <div class="item">
            <span>{{ item.jktjdw || '-' }}</span>
          </div>
          <div class="item">
            <span>{{ item.jktjckfw || '-' }}</span>
          </div>
        </div>
        <!-- <div class="line">
          <div class="item">
            <span>红细胞压积</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-bottom"></span>
          </div>
          <div class="item">
            <span>%</span>
          </div>
          <div class="item">
            <span>39--48</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>血红蛋白</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>g/L</span>
          </div>
          <div class="item">
            <span>110--160</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>淋巴细胞比率</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>%</span>
          </div>
          <div class="item">
            <span>20--40</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>平均血红蛋白量</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>pg</span>
          </div>
          <div class="item">
            <span>26--32</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>平均血红蛋白浓度</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>g/L</span>
          </div>
          <div class="item">
            <span>320--450</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>红细胞平均体积</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>fL</span>
          </div>
          <div class="item">
            <span>80--99</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>平均血小板体积</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>fL</span>
          </div>
          <div class="item">
            <span>7.4--10.4</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>血小板压积</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>fL</span>
          </div>
          <div class="item">
            <span>0.1--0.28</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>血小板分布宽度</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>fL</span>
          </div>
          <div class="item">
            <span>10--17</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>P-LCR</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>fL</span>
          </div>
          <div class="item">
            <span>10--17</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>血小板</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>10^9/L</span>
          </div>
          <div class="item">
            <span>100--300</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>红细胞</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>10^12/L</span>
          </div>
          <div class="item">
            <span>3.5--5.5</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>红细胞分布宽度CV</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>10^12/L</span>
          </div>
          <div class="item">
            <span>3.5--5.5</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>红细胞分布宽度SD</span>
          </div>
          <div class="item">
            <span></span>
          </div>
          <div class="item">
            <span></span>
          </div>
          <div class="item">
            <span></span>
          </div>
          <div class="item">
            <span></span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>白细胞</span>
          </div>
          <div class="item">
            <span>37.6</span>
          </div>
          <div class="item">
            <span class="el-icon-top"></span>
          </div>
          <div class="item">
            <span>10^9/L</span>
          </div>
          <div class="item">
            <span>4--10</span>
          </div>
        </div> -->
        <div class="line3">
          <div class="text">
            <span>检查时间：2022.2.22</span>
          </div>
          <div class="text">
            <span>检验人员：马依依</span>
          </div>
          <div class="text">
            <span>机构：古城营镇卫生院</span>
          </div>
        </div>
      </div>
      <div class="son-bottom">
        <el-button @click="noClick">编辑</el-button>
        <el-button @click="noClick">保存</el-button>
        <el-button @click="noClick">审核</el-button>
        <el-button @click="noClick">上传</el-button>
        <el-button @click="noClick">打印</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: '',
          jktjid: ''
        }
      }
    }
  },
  data () {
    return {
      checkList: [],
      info: []
    }
  },
  created () {
    console.log('尿常规报告参数：', this.params)
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591728
      try {
        const obj = {
          jktjid: this.params.tmcode || ''
        }
        const { data: res } = await this.$http.post('/ykdncgjcbgjgs/findncg', obj)
        console.log('尿常规报告--查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null || res.data.length < 1) return
        this.info = res.data
      } catch (error) {
        console.log('尿常规报告--查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    noClick () {
      this.$message.error('未开放')
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 0 50px;
  box-sizing: border-box;

  .tab-scroll {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tab-title {
      width: 100%;
      height: 54px;
      line-height: 54px;
      text-align: center;
      color: #475265;
      font-size: 22px;
    }

    .info {
      display: flex;
      flex-direction: column;
      width: 100%;

      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        padding: 0 26px;

        .text {
          color: #2A374D;
          font-size: 16px;
        }

        .item {
          flex: 1;
          text-align: center;
          color: #2A374D;
          font-size: 16px;
        }
      }

      .border {
        border-bottom: 1px solid #B1B1B1;
        box-sizing: border-box;
      }

      .line3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        padding: 0 26px;
        border-top: 1px solid #B1B1B1;
        box-sizing: border-box;

        .text {
          color: #2A374D;
          font-size: 16px;
        }
      }

      .tip {
        width: 100%;
        text-align: center;
        color: #999999;
        font-size: 13px;
      }
    }

    .son-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
      border-bottom: 1px solid #475265;

      .el-button {
        min-width: 120px;
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 6px;
        padding-bottom: 6px;
        background-color: #3782F2;
        border: none;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .son-bottom1 {
      height: 60px;
      justify-content: flex-end;
    }

  }

}
</style>
