<template>
  <div class="assess">
    <div class="scroll">
      <div class="flex">
        <div class="line">
          <div class="left">
            <span class="left-title">您的全身性肥胖评估结果为：</span>
            <div class="box">
              <div class="item"></div>
              <div class="item"></div>
              <div class="item"></div>
              <div class="item"></div>
              <img class="img img3" src="../../../img/ssjk/man.png" alt="人物">
            </div>
            <div class="text">
              <span>体重过低</span>
              <span>体重正常</span>
              <span>超重</span>
              <span>肥胖</span>
            </div>
          </div>
          <div class="right">
            <span class="left-title">您的中心性肥胖评估结果：</span>
            <div class="box">
              <div class="item"></div>
              <div class="item"></div>
              <div class="item"></div>
              <img class="img" src="../../../img/ssjk/man.png" alt="人物">
            </div>
            <div class="text text1">
              <span>腰围正常</span>
              <span>中心性肥胖（1级）</span>
              <span>中心性肥胖（2级）</span>
            </div>
          </div>
        </div>
        <div class="line-title">中国成人超重和肥胖的体重指数和腰围界限值与相关疾病危险的关系</div>
        <div class="tables">
          <div class="tr tr1">
            <span>分类</span>
            <span>体重指数（kg/平方米）</span>
            <span>体重过低&lt;80</span>
            <span>体重过低80-90</span>
            <span>体重过低≥90</span>
          </div>
          <div class="tr">
            <span>体重过低</span>
            <span>&lt;18.5</span>
            <span>...</span>
            <span>...</span>
            <span>...</span>
          </div>
          <div class="tr">
            <span>体重正常</span>
            <span>&lt;18.5</span>
            <span>...</span>
            <span>...</span>
            <span>...</span>
          </div>
          <div class="tr">
            <span>超重</span>
            <span>&lt;18.5</span>
            <span>...</span>
            <span>...</span>
            <span>...</span>
          </div>
          <div class="tr">
            <span>肥胖</span>
            <span>&lt;18.5</span>
            <span>...</span>
            <span>...</span>
            <span>...</span>
          </div>
        </div>
        <div class="tip">* 相关疾病指高血压、糖尿病、血脂异常和危险聚集</div>
        <div class="tip">** 体重过低可能预示有其他健康问题</div>
        <div class="line-title1">指导建议</div>
        <div class="div">多吃蔬菜水果</div>
        <!-- <div class="line-title1">运动建议</div>
        <div class="div">每天坚持30分钟有氧运动</div>
        <div class="line-title1">生活建议</div>
        <div class="div">作息规律，保持积极乐观的心态</div> -->
      </div>
    </div>

    <div class="bts">
      <span class="update" @click="closePopup">保存</span>
      <span @click="closePopup">返回</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.assess {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0A2D52;

  .scroll {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 40px);
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .flex {
    display: flex;
    flex-direction: column;
  }

  div {
    color: #01EEFD;
  }

  .line {
    display: flex;
    align-items: center;
    width: 100%;
    height: 352px;
    margin-top: 20px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .left,
    .right {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      position: relative;
      width: 50%;
      height: 340px;

      .left-title {
        position: absolute;
        left: 60px;
        top: 20px;
        color: #01EEFD;
        font-size: 18px;
      }

      .box {
        display: flex;
        align-items: flex-end;
        position: relative;
        height: 160px;

        .img {
          position: absolute;
          bottom: -10px;
          left: 75px;
          transform: translateX(-50%);
          width: 60px;
          height: 100px;
        }

        .img1 {
          position: absolute;
          bottom: 40px;
          left: 225px;
        }

        .img2 {
          position: absolute;
          bottom: 90px;
          left: 375px;
        }

        .img3 {
          position: absolute;
          bottom: 140px;
          left: 530px;
        }

        .item {
          width: 150px;
          height: 10px;
          background-color: #00DB00;

          &:nth-child(2) {
            height: 60px;
            background-color: #97CBFF;
          }

          &:nth-child(3) {
            height: 110px;
            background-color: #FFDC35;
          }

          &:nth-child(4) {
            height: 160px;
            background-color: #FF9224;
          }
        }
      }

      .text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 600px;
        margin-top: 7px;

        span {
          flex: 1;
          text-align: center;
          color: #01EEFD;
          font-size: 16px;
        }
      }

      .text1 {
        width: 450px;
      }
    }

    .left {
      box-sizing: border-box;
      border-right: 1px solid #01EEFD;

    }

    .right {
      padding: 0 19px;
      box-sizing: border-box;
    }
  }

  .line-title {
    display: flex;
    align-items: center;
    margin-top: 28px;
    margin-left: 44px;
    font-size: 18px;
  }

  .tables {
    width: 98%;
    height: 187px;
    margin-top: 20px;
    margin-left: 44px;
    border-left: 1px solid #01EEFD;
    border-top: 1px solid #01EEFD;
    box-sizing: border-box;

    .tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 31px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      font-size: 14px;

      span {
        flex: 1;
        height: 31px;
        line-height: 31px;
        text-align: center;
        box-sizing: border-box;
        border-right: 1px solid #01EEFD;
      }
    }

    .tr1 {
      height: 61px;

      span {
        height: 61px;
        line-height: 61px;
      }
    }
  }

  .tip {
    margin-top: 10px;
    margin-left: 50px;
    color: #FF0000;
    font-size: 14px;
  }

  .line-title1 {
    margin-top: 28px;
    margin-left: 54px;
    font-size: 18px;
  }

  .div {
    width: 90%;
    height: 80px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 9px 8px;
    box-sizing: border-box;
    border: 1px solid #01EEFD;
    font-size: 14px;
  }
}

.bts {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 26px;
  margin-left: 18px;

  span {
    height: 26px;
    line-height: 26px;
    padding: 0 18px;
    color: #fff;
    font-size: 14px;
    background-color: #D5D5D5;
    cursor: pointer;
  }

  .update {
    background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
  }
}
</style>
