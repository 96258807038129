<template>
  <div class="history">
    <div class="history-box">
      <div class="history-head">
        <span>选择历史记录</span>
        <span class="el-icon-close close" @click="close"></span>
      </div>
      <div class="history-add">
        <el-button icon="el-icon-menu" circle></el-button>
        <el-button icon="el-icon-refresh" circle></el-button>
      </div>
      <div class="history-table">
        <el-table :data="tableData" border height="100%">
          <el-table-column prop="date" label="序号">
          </el-table-column>
          <el-table-column prop="date" label="体检日期">
          </el-table-column>
          <el-table-column prop="name" label="姓名" :width="flexWidth('sfzh', tableData, '体检日期')">
          </el-table-column>
          <el-table-column prop="name" label="身份证号">
          </el-table-column>
          <el-table-column prop="name" label="症状">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template>
              <el-button type="primary">
                <span class="el-icon-edit"></span>
                <span>使用</span>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: []
    }
  },
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.history {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .69);

  .history-box {
    width: 90%;
    height: 70%;
    padding: 0 30px;
    border-radius: 12px;
    background-color: #fff;
    box-sizing: border-box;

    .history-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 80px;
      color: #000;
      font-size: 20px;

      .close {
        font-size: 24px;
        cursor: pointer;
      }
    }

    .history-add {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 60px;

      .el-button {
        margin: 0 5px;
      }
    }

    .history-table {
      width: 100%;
      height: calc(100% - 160px);
    }
  }
}
</style>
