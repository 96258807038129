<template>
  <div class="children">
    <div class="children-scroll">
      <div class="children-title">
        <span>老年人生活自理能力评估表</span>
      </div>
      <div class="children-title1">
        <span>该表为自评表，根据下表中5个方面进行评估，将各方面判断评分汇总后，0～3分者为可自理；4～8分者为轻度依赖；9～18分者为中度依赖； 19分者为不能自理。</span>
      </div>
      <div class="children-info">
        <div class="item">
          <span>姓名：</span>
        </div>
        <div class="item">
          <span>编号：</span>
        </div>
      </div>
      <div class="table">
        <div class="tr">
          <div class="td td-left">
            <span>评估事项、内容与评分</span>
          </div>
          <div class="td5">
            <div class="td-item">
              <span>程度等级</span>
            </div>
            <div class="td-item">
              <div class="tr-td">
                <span>可自理</span>
              </div>
              <div class="tr-td">
                <span>轻度依赖</span>
              </div>
              <div class="tr-td">
                <span>中度依赖</span>
              </div>
              <div class="tr-td">
                <span>不能自理</span>
              </div>
              <div class="tr-td">
                <span>判断评分</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tr">
          <div class="td td-left">
            <span>进餐：使用餐具将饭菜送入口、咀嚼、吞咽等活动</span>
          </div>
          <div class="td5 row">
            <div class="td td-left">
              <span>独立完成</span>
            </div>
            <div class="td td-left">
              <span></span>
            </div>
            <div class="td td-left">
              <span>需要协助，如切碎、搅拌食物等</span>
            </div>
            <div class="td td-left">
              <span>完全需要帮助</span>
            </div>
            <div class="td td-left">
              <span></span>
            </div>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td td-left">
            <span>评分</span>
          </div>
          <div class="td5 row">
            <div class="td td-left">
              <el-radio label="0" v-model="info.lnrpgjincan">0</el-radio>
            </div>
            <div class="td td-left">
              <!-- <el-radio label="2" v-model="info.lnrpgjincan">0</el-radio> -->
            </div>
            <div class="td td-left">
              <el-radio label="3" v-model="info.lnrpgjincan">3</el-radio>
            </div>
            <div class="td td-left">
              <el-radio label="5" v-model="info.lnrpgjincan">5</el-radio>
            </div>
            <div class="td td-left">
              <span>{{ info.lnrpgjincan }}</span>
            </div>
          </div>
        </div>
        <div class="tr">
          <div class="td td-left">
            <span>梳洗：梳头、洗脸、刷牙、剃须、洗澡等活动</span>
          </div>
          <div class="td5 row">
            <div class="td td-left">
              <span>独立完成</span>
            </div>
            <div class="td td-left">
              <span>能独立地洗头、梳头、洗脸、刷牙、剃须等；洗澡需要协助</span>
            </div>
            <div class="td td-left">
              <span>在协助下和适当的时间内，能完成部分梳洗活动</span>
            </div>
            <div class="td td-left">
              <span>完全需要帮助</span>
            </div>
            <div class="td td-left">
              <span></span>
            </div>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td td-left">
            <span>评分</span>
          </div>
          <div class="td5 row">
            <div class="td td-left">
              <el-radio label="0" v-model="info.lnrpgshufa">0</el-radio>
            </div>
            <div class="td td-left">
              <el-radio label="1" v-model="info.lnrpgshufa">1</el-radio>
            </div>
            <div class="td td-left">
              <el-radio label="3" v-model="info.lnrpgshufa">3</el-radio>
            </div>
            <div class="td td-left">
              <el-radio label="7" v-model="info.lnrpgshufa">7</el-radio>
            </div>
            <div class="td td-left">
              <span>{{ info.lnrpgshufa }}</span>
            </div>
          </div>
        </div>

        <div class="tr">
          <div class="td td-left">
            <span>穿衣：穿衣裤、袜子、鞋子等活动</span>
          </div>
          <div class="td5 row">
            <div class="td td-left">
              <span>独立完成</span>
            </div>
            <div class="td td-left">
              <span></span>
            </div>
            <div class="td td-left">
              <span>需要协助，在适当的时间内完成部分穿衣</span>
            </div>
            <div class="td td-left">
              <span>完全需要帮助</span>
            </div>
            <div class="td td-left">
              <span>{{ score }}</span>
            </div>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td td-left">
            <span>评分</span>
          </div>
          <div class="td5 row">
            <div class="td td-left">
              <el-radio label="0" v-model="info.lnrpgchuanyi">0</el-radio>
            </div>
            <div class="td td-left">
              <!-- <el-radio label="2" v-model="radio">0</el-radio> -->
            </div>
            <div class="td td-left">
              <el-radio label="3" v-model="info.lnrpgchuanyi">3</el-radio>
            </div>
            <div class="td td-left">
              <el-radio label="5" v-model="info.lnrpgchuanyi">5</el-radio>
            </div>
            <div class="td td-left">
              <span>{{ info.lnrpgchuanyi }}</span>
            </div>
          </div>
        </div>

        <div class="tr">
          <div class="td td-left">
            <span>如厕：小便、大便等活动及自控</span>
          </div>
          <div class="td5 row">
            <div class="td td-left">
              <span>不需协助，可自控</span>
            </div>
            <div class="td td-left">
              <span>偶尔失禁，但基本上能如厕或使用便具</span>
            </div>
            <div class="td td-left">
              <span>经常失禁，在很多提示和协助下尚能如厕或使用便具</span>
            </div>
            <div class="td td-left">
              <span>完全需要帮助</span>
            </div>
            <div class="td td-left">
              <span></span>
            </div>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td td-left">
            <span>评分</span>
          </div>
          <div class="td5 row">
            <div class="td td-left">
              <el-radio label="0" v-model="info.lnrpgruce">0</el-radio>
            </div>
            <div class="td td-left">
              <el-radio label="1" v-model="info.lnrpgruce">1</el-radio>
            </div>
            <div class="td td-left">
              <el-radio label="5" v-model="info.lnrpgruce">5</el-radio>
            </div>
            <div class="td td-left">
              <el-radio label="10" v-model="info.lnrpgruce">10</el-radio>
            </div>
            <div class="td td-left">
              <span>{{ info.lnrpgruce }}</span>
            </div>
          </div>
        </div>

        <div class="tr">
          <div class="td td-left">
            <span>活动：站立、室内行走、
              上下楼梯、户外活动</span>
          </div>
          <div class="td5 row">
            <div class="td td-left">
              <span>独立完成所有活动</span>
            </div>
            <div class="td td-left">
              <span>借助较小的外力或辅助装置能完成站立、行走、上下楼梯等</span>
            </div>
            <div class="td td-left">
              <span>借助较大的外力才能完成站立、行走，不能上下楼梯</span>
            </div>
            <div class="td td-left">
              <span>完全需要帮助</span>
            </div>
            <div class="td td-left">
              <span></span>
            </div>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td td-left">
            <span>评分</span>
          </div>
          <div class="td5 row">
            <div class="td td-left">
              <el-radio label="0" v-model="info.lnrpghuodong">0</el-radio>
            </div>
            <div class="td td-left">
              <el-radio label="1" v-model="info.lnrpghuodong">1</el-radio>
            </div>
            <div class="td td-left">
              <el-radio label="5" v-model="info.lnrpghuodong">5</el-radio>
            </div>
            <div class="td td-left">
              <el-radio label="10" v-model="info.lnrpghuodong">10</el-radio>
            </div>
            <div class="td td-left">
              <span>{{ info.lnrpghuodong }}</span>
            </div>
          </div>
        </div>

        <div class="tr tr1">
          <div class="td td-left">
            <span>总得分：</span>
          </div>
          <div class="td td3">
            <span>{{ info.zdfhj }}</span>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td td-left">
            <span>录入机构：</span>
          </div>
          <div class="td td3">
            <el-input class="input" v-model="info.lrjg" placeholder=""></el-input>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td td-left">
            <span>录入人：</span>
          </div>
          <div class="td td3">
            <div class="td td-left">
              <el-input class="input" v-model="info.lrr" placeholder=""></el-input>
            </div>
            <div class="td td-left">
              <span>录入时间：</span>
            </div>
            <div class="td td-center">
              <el-date-picker
                v-model="info.lrsj"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td td-left">
            <span>更新机构：</span>
          </div>
          <div class="td td3">
            <el-input class="input" v-model="info.updatejg" placeholder=""></el-input>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td td-left">
            <span>更新人：</span>
          </div>
          <div class="td td3">
            <div class="td td-left">
              <el-input class="input" v-model="info.updateuser" placeholder=""></el-input>
            </div>
            <div class="td td-left">
              <span>更新时间：</span>
            </div>
            <div class="td td-center">
              <el-date-picker
                v-model="info.updateTime"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="services-btns">
      <el-button size="small" type="primary" @click="noClick">提交</el-button>
      <el-button size="small" type="primary" @click="noClick">打印预览</el-button>
      <el-button size="small" type="primary" @click="noClick">上传</el-button>
      <el-button size="small" type="primary" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: '',
          jktjid: ''
        }
      }
    }
  },
  computed: {
    lnrpgjincan () {
      if (!this.info.lnrpgjincan) return '0'
      return this.info.lnrpgjincan
      // return parseInt(this.info.lnrpgjincan)
    },
    lnrpgshufa () {
      if (!this.info.lnrpgshufa) return '0'
      return this.info.lnrpgshufa
      // return parseInt(this.info.lnrpgshufa)
    },
    lnrpgchuanyi () {
      if (!this.info.lnrpgchuanyi) return '0'
      return this.info.lnrpgchuanyi
      // return parseInt(this.info.lnrpgchuanyi)
    },
    lnrpgruce () {
      if (!this.info.lnrpgruce) return '0'
      return this.info.lnrpgruce
      // return parseInt(this.info.lnrpgruc)
    },
    lnrpghuodong () {
      if (!this.info.lnrpghuodong) return '0'
      return this.info.lnrpghuodong
      // return parseInt(this.info.lnrpghuodong)
    },
    total () {
      return parseInt(this.lnrpgjincan) + parseInt(this.lnrpgshufa) + parseInt(this.lnrpgchuanyi) + parseInt(this.lnrpgruce) + parseInt(this.lnrpghuodong)
    },
    score () {
      return this.scoreType()
    }
  },
  watch: {
    total (val) {
      console.log('*---', val)
      this.info.zdfhj = val
    }
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: [],
      radio: '1',
      value: '',
      input: '',
      info: {
        jktjid: '',
        grjbxxid: '',
        tjbh: '',
        name: '',
        lnrpgjincan: '',
        lnrpgshufa: '',
        lnrpgchuanyi: '',
        lnrpgruce: '',
        lnrpghuodong: '',
        zdfhj: '',
        jkdnid: '',
        flag: '',
        sfzh: '',
        lrjg: '',
        lrr: '',
        lrsj: '',
        age: '',
        xb: '',
        updatejg: '',
        updateuser: '',
        updateTime: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    scoreType () {
      if (this.total >= 0 && this.total <= 3) {
        return '可自理'
      } else if (this.total >= 4 && this.total <= 8) {
        return '轻度依赖'
      } else if (this.total >= 9 && this.total <= 18) {
        return '中度依赖'
      } else if (this.total >= 19) {
        return '不能自理'
      }
    },
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591859
      try {
        const obj = {
          grjbxxid: this.params.grjbxxid,
          jktjid: this.params.jktjid
        }
        const { data: res } = await this.$http.post('/ykdlnrshzlpgbs/finds', obj)
        console.log('老年人生活自理评估--查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data[0] === null) return
        for (const key in res.data[0]) {
          if (res.data[0][key] === null) {
            res.data[0][key] = ''
          }
        }
        this.info = res.data[0]
      } catch (error) {
        console.log('老年人生活自理评估--查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    submit () {
      console.log(this.info)
    },
    noClick () {
      this.$message.error('未开放')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.children {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .children-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 60px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54px;
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .children-title1 {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 300px;
    box-sizing: border-box;
    text-decoration: left;
    color: #000;
    font-size: 20px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 0 100px;
    box-sizing: border-box;

    .item {
      min-width: 206px;
      color: #000;
      font-size: 22px;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #000;
    box-sizing: border-box;

    .tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;

      .td {
        flex: 1;
        height: 100%;
        color: #000;
        font-size: 16px;
      }

      .td-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .input {

        /deep/.el-input__inner {
          border-radius: 0;
          border: none;
          border-bottom: 1px solid #000;
        }
      }

      .td3 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-right: 1px solid #000;
        box-sizing: border-box;
        flex: 3;
      }

      .td-left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 10px;
        border-right: 1px solid #000;
        box-sizing: border-box;
      }

      .td5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 5;
        height: 100%;

        .td-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 50px;
          color: #000;
          font-size: 16px;
        }

        .td-item:first-child {
          border-bottom: 1px solid #000;
          box-sizing: border-box;
        }

        .tr-td {
          flex: 1;
          height: 100%;
          line-height: 50px;
          text-align: center;
          border-left: 1px solid #000;
          box-sizing: border-box;
          color: #000;
          font-size: 16px;
        }

        .tr-td:first-child {
          border-left: none;
        }
      }

      .row {
        flex-direction: row;
      }

      .el-radio {
        display: flex;
        align-items: center;
        color: #000;
      }

      /deep/.el-radio__label {
        font-size: 16px;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #000;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        background-color: #000;
        border-color: #000;
      }

      /deep/.el-radio__inner {
        width: 18px;
        height: 18px;
        background-color: transparent;
      }
    }

    .tr:last-child {
      border-bottom: none;
    }

    .tr1 {
      height: 50px;
    }
  }

  .services-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;

    .el-button {
      width: 180px;
      height: 40px;
      line-height: 40px;
      margin: 0 10px;
      border: none;
      padding: 0;
      background-color: rgba(103,194,58,.6);
      text-align: center;
      color: #4d94ff;
      font-size: 16px;
      font-weight: 600;

      &::hover {
        background-color: #67c23a;
      }
    }
  }

}
</style>
