var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab"},[_c('div',{staticClass:"tab-scroll"},[_vm._m(0),_c('div',{staticClass:"info"},[_c('div',{staticClass:"line"},[_vm._m(1),_c('div',{staticClass:"text"},[_c('span',[_vm._v("超声号:"+_vm._s(_vm.info.tmcode || ''))])])]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"line1"},[_vm._m(4),_c('div',{staticClass:"img"},[(_vm.info.tp1url)?_c('img',{staticClass:"image",attrs:{"src":_vm.info.tp1url}}):_vm._e(),(_vm.info.tp2url)?_c('img',{staticClass:"image",attrs:{"src":_vm.info.tp2url}}):_vm._e()]),_c('div',{staticClass:"text"})]),_c('div',{staticClass:"line2"},[_vm._m(5),_c('div',{staticClass:"textarea"},[_vm._v(_vm._s(_vm.info.jktjcsts))])]),_vm._m(6),_vm._m(7)]),_c('div',{staticClass:"son-bottom son-bottom1"},[_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("编辑")]),_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("查看")])],1),_c('div',{staticClass:"son-bottom"},[_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("编辑")]),_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("审核")]),_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("上传")]),_c('el-button',{on:{"click":_vm.noClick}},[_vm._v("打印")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-title"},[_c('span',[_vm._v("超声医学影像报告单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('span',[_vm._v("检查部位:腹部")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("姓名：-")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("性别:-")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("年龄:-")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("初诊：-")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("科别：-")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("检查号:-")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("病历号：-")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("住院号：-")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("病区号：-")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("床位号：-")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('span',[_vm._v("超声所见")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('span',[_vm._v("超声所见：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line3"},[_c('div',{staticClass:"text"},[_c('span',[_vm._v("检查时间：-")])]),_c('div',{staticClass:"text"},[_c('span',[_vm._v("申请医师：-")])]),_c('div',{staticClass:"text"},[_c('span',[_vm._v("诊断医师：-")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip"},[_c('span',[_vm._v("只作临床参考，不作证明材料")])])
}]

export { render, staticRenderFns }