<template>
  <div class="children">
    <div class="children-title">
      <span>中医健康指导</span>
    </div>
    <div class="table">
      <div class="tr">
        <div class="td td-left">
          <span>姓名</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <el-input v-model="info.name"></el-input>
          </div>
          <div class="td td-left">
            <span>档案编号</span>
          </div>
          <div class="td td-left">
            <el-input v-model="info.dnbh"></el-input>
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>性别</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <el-input v-model="info.xb"></el-input>
          </div>
          <div class="td td-left">
            <span>年龄</span>
          </div>
          <div class="td td-left">
            <el-input v-model="info.age"></el-input>
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>身份证号</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <el-input v-model="info.sfzh"></el-input>
          </div>
          <div class="td td-left">
            <span>联系电话</span>
          </div>
          <div class="td td-left">
            <el-input v-model="info.lxdh"></el-input>
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>家庭住址</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <span>{{ info.jkdnhjdz }}</span>
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>人群分类</span>
        </div>
        <div class="td5 row">
          <div class="td td-left td-left1">
            <el-radio-group v-model="info.groupClassifyCode">
              <el-radio label="1">儿童</el-radio>
              <el-radio label="2">老年人</el-radio>
              <el-radio label="3">孕产妇</el-radio>
              <el-radio label="4">糖尿病</el-radio>
              <el-radio label="5">其他</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>指导医师</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <el-input v-model="info.doctorName"></el-input>
          </div>
          <div class="td td-left">
            <span>指导时间</span>
          </div>
          <div class="td td-left">
            <el-input v-model="info.guideDate"></el-input>
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>录入人</span>
        </div>
        <div class="td5 row">
          <div class="td td-left">
            <el-input v-model="info.creator"></el-input>
          </div>
          <div class="td td-left">
            <span>录入时间</span>
          </div>
          <div class="td td-left">
            <el-input v-model="info.createTime"></el-input>
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>录入机构</span>
        </div>
        <div class="td5 row">
          <div class="td td-left td-left1">
            {{ info.orgName }}
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>中医体质辨识</span>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>中医体质辨识结果</span>
        </div>
        <div class="td5 row">
          <div class="td td-left td-left1">
            <el-checkbox-group v-model="info.physiqueIdentify">
              <el-checkbox label="1">平和质</el-checkbox>
              <el-checkbox label="2">气虚质</el-checkbox>
              <el-checkbox label="3">阳虚质</el-checkbox>
              <el-checkbox label="4">阴虚质</el-checkbox>
              <el-checkbox label="5">痰湿质</el-checkbox>
              <el-checkbox label="6">湿热质</el-checkbox>
              <el-checkbox label="7">血瘀质</el-checkbox>
              <el-checkbox label="8">气郁质</el-checkbox>
              <el-checkbox label="9">特禀质</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="tr">
        <div class="td td-left">
          <span>健康指导</span>
        </div>
      </div>
      <div class="tr tr1">
        <div class="td td-left">
          <span>指导内容</span>
        </div>
        <div class="td5 row">
          <div class="td td-left td-left2 td-scroll">
            <p>{{ info.content }}</p>
          </div>
        </div>
      </div>

    </div>
    <div class="services-btns">
      <el-button size="mini" class="blue">保存</el-button>
      <el-button size="mini" class="gray" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: ''
        }
      }
    }
  },
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: [],
      radio: '1',
      info: {
        name: '',
        dnbh: '',
        xb: '',
        age: '',
        sfzhm: '',
        lxdh: '',
        jkdnhjdz: '',
        groupClassifyCode: '',
        doctorName: '',
        guideDate: '',
        creator: '',
        createTime: '',
        orgName: '',
        physiqueIdentify: [],
        content: '',
        id: 0,
        updateTime: '',
        physiqueIdentifyAgeCode: '',
        personInfoId: '',
        doctorId: '',
        hyperSymptomsCode: '',
        diabetesSymptomsCode: '',
        cmgoperationId: '',
        isCancel: '',
        createOrgId: '',
        creatorId: '',
        modifiedOrgId: '',
        modifiedOrgName: '',
        modifierId: '',
        modifier: '',
        fileCommittee: '',
        modifiedTime: '',
        isAppCreate: '',
        sfzh: '',
        tjid: '',
        jgbh: '',
        tjjgbh: '',
        tjjgmc: '',
        tjjgcwhid: '',
        tjjgcwhmc: '',
        jkdnid: '',
        zytzzs: '',
        zyzd: '',
        jktjid: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591842
      try {
        const obj = {
          tjjgcwhid: '',
          tjjgbh: '',
          grjbxxid: this.params.grjbxxid || '',
          jktjid: this.params.tmcode || ''
        }
        const { data: res } = await this.$http.post('/cmgoperationtjs/finds', obj)
        console.log('中医药健康指导--查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        this.info = res.data
        if (res.data.physiqueIdentify) {
          this.info.physiqueIdentify = res.data.physiqueIdentify.split(',')
        } else {
          this.info.physiqueIdentify = []
        }
      } catch (error) {
        console.log('中医药健康指导--查询请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    color: #000;
    font-size: 35px;
  }

  .children-title1 {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 300px;
    box-sizing: border-box;
    text-decoration: left;
    color: #000;
    font-size: 20px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 0 100px;
    box-sizing: border-box;

    .item {
      min-width: 206px;
      color: #000;
      font-size: 22px;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #000;
    box-sizing: border-box;

    .tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 55px;
      border-bottom: 1px solid #000;
      box-sizing: border-box;

      .td {
        flex: 1;
        height: 100%;
        color: #000;
        font-size: 18px;
      }

      /deep/.el-input__inner {
        background-color: transparent;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #000;
        box-sizing: border-box;
        color:#000;
        font-size: 18px;
      }

      .td-left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        border-right: 1px solid #000;
        box-sizing: border-box;
      }

      .td-left1 {
        justify-content: flex-start;
      }

      .td-left2 {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }

      .td5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 5;
        height: 100%;

        .td-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 50px;
          color: #000;
          font-size: 14px;
        }

        .td-item:first-child {
          border-bottom: 1px solid #000;
          box-sizing: border-box;
        }

        .tr-td {
          flex: 1;
          height: 100%;
          line-height: 50px;
          text-align: center;
          border-left: 1px solid #000;
          box-sizing: border-box;
          color: #000;
          font-size: 18px;
        }

        .tr-td:first-child {
          border-left: none;
        }

        /deep/.el-checkbox__inner {
          background-color: transparent;
        }

        /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: #01EEFD;
        }

        /deep/.el-checkbox__label {
          color: #000;
          font-size: 16px;
        }

        /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
          color: #01EEFD;
        }

        .td-scroll {
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 0;
          }
        }
      }

      .row {
        flex-direction: row;
      }

      /deep/.el-radio__label {
        font-size: 18px;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #01EEFD;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        background-color: #01EEFD;
        border-color: #01EEFD;
      }

      /deep/.el-radio__inner {
        width: 18px;
        height: 18px;
        background-color: transparent;
      }
    }

    .tr:last-child {
      border-bottom: none;
    }

    .tr1 {
      height: 200px;
    }
  }

  .services-btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;

    .blue {
      margin-left: 50px;
      border-radius: 0;
      border: none;
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      color: #fff;
    }

    .gray {
      margin-left: 50px;
      border-radius: 0;
      border: none;
      background: #33465C;
      color: #fff;
    }
  }

}
</style>
