<template>
  <div class="children">
    <div class="children-title">
      <span>老年人中医药健康管理服务记录表</span>
    </div>
    <div class="children-info">
      <div class="item">姓名：{{ params.name || '-' }}</div>
      <div class="item">编号：{{ params.dnbh || '-' }}</div>
    </div>
    <div class="children-table children-border-bottom">
      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>请根据近一年的体验和感觉，回答以下问题。</span>
        </div>
        <div class="children-td children-flex10 children-text-center children-border-right-none">
          <div class="children-td children-flex1 children-text-center">
            <span>没有(根本不/从来没有)</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>很少(有一点/偶尔)</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>有时(有些/少数时间)</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>经常(相当/多数时间)</span>
          </div>
          <div class="children-td children-flex1 children-text-center children-border-right-none">
            <span>总是(非常/每天)</span>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您精力充沛吗？（指精神头足，乐于做事）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnjlcpm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您容易疲乏吗？（指体力如何，是否稍微活动一下或做一点家务劳动就感到累）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnrypfm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您容易气短，呼吸短促，接不上气吗？</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnryqdjcm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您说话声音低弱无力吗?（指说话没有力气）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnshsydrwlm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您感到闷闷不乐、情绪低沉吗?（指心情不愉快，情绪低落）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmngdmmblm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您容易精神紧张、焦虑不安吗?（指遇事是否心情紧张）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnryjsjzjlbam" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您因为生活状态改变而感到孤独、失落吗？</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnysshztgb" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您容易感到害怕或受到惊吓吗?</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnrygdhpjx" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您感到身体超重不轻松吗?(感觉身体沉重)[BMI指数=体重（kg）/身高2（m）]?</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmngdstczbqs" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1 (BMI＜24)</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2 (24≤BMI＜25)</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3 (25≤BMI＜26)</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4 26≤BMI＜28）</el-radio>
            <el-radio label="5" class="children-flex1">5 (BMI≥28)</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您眼睛干涩吗?</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnyjgsm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您手脚发凉吗?（不包含因周围温度低或穿的少导致的手脚发冷）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnsjflm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您胃脘部、背部或腰膝部怕冷吗？（指上腹部、背部、腰部或膝关节等，有一处或多处怕冷）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnwwbbbpl" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您比一般人耐受不了寒冷吗？（指比别人容易害怕冬天或是夏天的冷空调、电扇等）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnbybrnhl" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您容易患感冒吗?（指每年感冒的次数）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnryhgmm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您没有感冒时也会鼻塞、流鼻涕吗?</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnmygmyhbslbt" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您有口粘口腻，或睡眠打鼾吗？</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnykzkydhm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您容易过敏(对药物、食物、气味、花粉或在季节交替、气候变化时)吗?</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnrygm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1 从来没有</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2 一年1、2次</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3 一年3、4次</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4 一年5、6次</el-radio>
            <el-radio label="5" class="children-flex1"><span class="font-size: 12px;">5 每次遇到上述原因都过敏</span></el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您的皮肤容易起荨麻疹吗? (包括风团、风疹块、风疙瘩)</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmndpfryqmzm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您的皮肤在不知不觉中会出现青紫瘀斑、皮下出血吗?（指皮肤在没有外伤的情况下出现青一块紫一块的情况）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmndpfrycxqz" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您的皮肤一抓就红，并出现抓痕吗?（指被指甲或钝物划过后皮肤的反应）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmndpfyzjhzh" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您皮肤或口唇干吗?</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnpfhkcgm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您有肢体麻木或固定部位疼痛的感觉吗?</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnyztmm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您面部或鼻部有油腻感或者油亮发光吗?（指脸上或鼻子）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnmbhbbyynm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您面色或目眶晦黯，或出现褐色斑块/斑点吗?</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnmshmkham" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您有皮肤湿疹、疮疖吗？</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnypfszzhm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您感到口干咽燥、总想喝水吗？</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmngdkgszzshsm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您感到口苦或嘴里有异味吗?（指口苦或口臭）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmngdkdzlywm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您腹部肥大吗?（指腹部脂肪肥厚）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnfbfdm" class="group-flex ">
            <el-radio label="1" class="children-flex1 children-border-right">1 (腹围&lt;80cm)</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2 (腹围80-85cm)</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3 (腹围86-90cm)</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4 (腹围1-105cm)</el-radio>
            <el-radio label="5" class="children-flex1">5 (腹围>105cm)</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您吃(喝)凉的东西会感到不舒服或者怕吃(喝)凉的东西吗？（指不喜欢吃凉的食物，或吃了凉的食物后会不舒服）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnclbsfm" class="group-flex">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您有大便黏滞不爽、解不尽的感觉吗?(大便容易粘在马桶或便坑壁上)</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmndbncbusm" class="group-flex">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您容易大便干燥吗?</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnrhdbgzm" class="group-flex">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您舌苔厚腻或有舌苔厚厚的感觉吗?（如果自我感觉不清楚可由调查员观察后填写）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnsthyhhm" class="group-flex">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex3">
          <span>您舌下静脉瘀紫或增粗吗？（可由调查员辅助观察后填写）</span>
        </div>
        <div class="children-td children-flex10 children-border-right-none">
          <el-radio-group v-model="info.tmnsxjmzcm" class="group-flex">
            <el-radio label="1" class="children-flex1 children-border-right">1</el-radio>
            <el-radio label="2" class="children-flex1 children-border-right">2</el-radio>
            <el-radio label="3" class="children-flex1 children-border-right">3</el-radio>
            <el-radio label="4" class="children-flex1 children-border-right">4</el-radio>
            <el-radio label="5" class="children-flex1">5</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex5">
          <div class="children-td children-flex1 children-text-center">
            <span>体质类型</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>气虚质</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>阳虚质</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>阴虚质</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>痰湿质</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>湿热质</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>血瘀质</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>气郁质</span>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <span>特禀质</span>
          </div>
          <div class="children-td children-flex1 children-text-center children-border-right-none">
            <span>平和质</span>
          </div>
        </div>
      </div>

      <div class="children-tr children-tr5 children-border">
        <div class="children-td children-flex3">
          <div class="children-td children-flex1 children-text-center">
            <span>体质辨识</span>
          </div>
          <div class="children-td children-flex1 children-td-column  children-text-center">
            <div class="display-flex display-flex1">
              <span class="margin-bottom">得分：{{ info.tzlxqxzdf }}</span>
              <el-radio-group v-model="info.tzlxqxzbs">
                <el-radio label="1" class="margin-bottom">是</el-radio>
                <el-radio label="2" class="margin-bottom">倾向是</el-radio>
              </el-radio-group>
            </div>

          </div>
          <div class="children-td children-flex1 children-text-center">
            <div class="display-flex display-flex1">
              <span class="margin-bottom">得分：{{ info.tzlxyxzdf }}</span>
              <el-radio-group v-model="info.tzlxyxzbs">
                <el-radio label="1" class="margin-bottom">是</el-radio>
                <el-radio label="2" class="margin-bottom">倾向是</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <div class="display-flex display-flex1">
              <span class="margin-bottom">得分：{{ info.tzlxyinxzdf }}</span>
              <el-radio-group v-model="info.tzlxyinxzbs">
                <el-radio label="1" class="margin-bottom">是</el-radio>
                <el-radio label="2" class="margin-bottom">倾向是</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <div class="display-flex display-flex1">
              <span class="margin-bottom">得分：{{ info.tzlxtszdf }}</span>
              <el-radio-group v-model="info.tzlxtszbs">
                <el-radio label="1" class="margin-bottom">是</el-radio>
                <el-radio label="2" class="margin-bottom">倾向是</el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="children-td children-flex1 children-text-center">
            <div class="display-flex display-flex1">
              <span class="margin-bottom">得分：{{ info.tzlxsrzdf }}</span>
              <el-radio-group v-model="info.tzlxsrzbs">
                <el-radio label="1" class="margin-bottom">是</el-radio>
                <el-radio label="2" class="margin-bottom">倾向是</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <div class="display-flex display-flex1">
              <span class="margin-bottom">得分：{{ info.tzlxxyzdf }}</span>
              <el-radio-group v-model="info.tzlxxyzbs">
                <el-radio label="1" class="margin-bottom">是</el-radio>
                <el-radio label="2" class="margin-bottom">倾向是</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <div class="display-flex display-flex1">
              <span class="margin-bottom">得分：{{ info.tzlxqyzdf }}</span>
              <el-radio-group v-model="info.tzlxqyzbs">
                <el-radio label="1" class="margin-bottom">是</el-radio>
                <el-radio label="2" class="margin-bottom">倾向是</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-text-center">
            <div class="display-flex display-flex1">
              <span class="margin-bottom">得分：{{ info.tzlxtbzdf }}</span>
              <el-radio-group v-model="info.tzlxtbzbs">
                <el-radio label="1" class="margin-bottom">是</el-radio>
                <el-radio label="2" class="margin-bottom">倾向是</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-text-center children-border-right-none">
            <div class="display-flex display-flex1">
              <span class="margin-bottom">得分：{{ info.tzlxphzdf }}</span>
              <el-radio-group v-model="info.tzlxphzbs">
                <el-radio label="1" class="margin-bottom">是</el-radio>
                <el-radio label="2" class="margin-bottom">倾向是</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="children-tr children-tr11 children-border">
        <div class="children-td children-flex3">
          <div class="children-td children-flex1 children-text-center">
            <span>中医药保健指导</span>
          </div>
          <div class="children-td children-flex1 children-td-column display-flex1">
            <div class="display-flex">
              <el-checkbox-group v-model="info.tzlxqxzzd">
                <el-checkbox label="1" class="margin-bottom">
                  <div class="text">
                    <span>情志</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="2" class="margin-bottom">
                  <div class="text">
                    <span>饮食</span>
                    <span>调养</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="3" class="margin-bottom">
                  <div class="text">
                    <span>起居</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="4" class="margin-bottom">
                  <div class="text">
                    <span>运动</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="5" class="margin-bottom">
                  <div class="text">
                    <span>穴位</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="6" class="margin-bottom">其他</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-td-column display-flex1">
            <div class="display-flex">
              <el-checkbox-group v-model="info.tzlxyxzzd">
                <el-checkbox label="1" class="margin-bottom">
                  <div class="text">
                    <span>情志</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="2" class="margin-bottom">
                  <div class="text">
                    <span>饮食</span>
                    <span>调养</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="3" class="margin-bottom">
                  <div class="text">
                    <span>起居</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="4" class="margin-bottom">
                  <div class="text">
                    <span>运动</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="5" class="margin-bottom">
                  <div class="text">
                    <span>穴位</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="6" class="margin-bottom">其他</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-td-column display-flex1">
            <div class="display-flex">
              <el-checkbox-group v-model="info.tzlxyinxzzd">
                <el-checkbox label="1" class="margin-bottom">
                  <div class="text">
                    <span>情志</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="2" class="margin-bottom">
                  <div class="text">
                    <span>饮食</span>
                    <span>调养</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="3" class="margin-bottom">
                  <div class="text">
                    <span>起居</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="4" class="margin-bottom">
                  <div class="text">
                    <span>运动</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="5" class="margin-bottom">
                  <div class="text">
                    <span>穴位</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="6" class="margin-bottom">其他</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-td-column display-flex1">
            <div class="display-flex">
              <el-checkbox-group v-model="info.tzlxtszzd">
                <el-checkbox label="1" class="margin-bottom">
                  <div class="text">
                    <span>情志</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="2" class="margin-bottom">
                  <div class="text">
                    <span>饮食</span>
                    <span>调养</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="3" class="margin-bottom">
                  <div class="text">
                    <span>起居</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="4" class="margin-bottom">
                  <div class="text">
                    <span>运动</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="5" class="margin-bottom">
                  <div class="text">
                    <span>穴位</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="6" class="margin-bottom">其他</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-td-column display-flex1">
            <div class="display-flex">
              <el-checkbox-group v-model="info.tzlxsrzzd">
                <el-checkbox label="1" class="margin-bottom">
                  <div class="text">
                    <span>情志</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="2" class="margin-bottom">
                  <div class="text">
                    <span>饮食</span>
                    <span>调养</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="3" class="margin-bottom">
                  <div class="text">
                    <span>起居</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="4" class="margin-bottom">
                  <div class="text">
                    <span>运动</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="5" class="margin-bottom">
                  <div class="text">
                    <span>穴位</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="6" class="margin-bottom">其他</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-td-column display-flex1">
            <div class="display-flex">
              <el-checkbox-group v-model="info.tzlxxyzzd">
                <el-checkbox label="1" class="margin-bottom">
                  <div class="text">
                    <span>情志</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="2" class="margin-bottom">
                  <div class="text">
                    <span>饮食</span>
                    <span>调养</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="3" class="margin-bottom">
                  <div class="text">
                    <span>起居</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="4" class="margin-bottom">
                  <div class="text">
                    <span>运动</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="5" class="margin-bottom">
                  <div class="text">
                    <span>穴位</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="6" class="margin-bottom">其他</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-td-column display-flex1">
            <div class="display-flex">
              <el-checkbox-group v-model="info.tzlxqyzzd">
                <el-checkbox label="1" class="margin-bottom">
                  <div class="text">
                    <span>情志</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="2" class="margin-bottom">
                  <div class="text">
                    <span>饮食</span>
                    <span>调养</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="3" class="margin-bottom">
                  <div class="text">
                    <span>起居</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="4" class="margin-bottom">
                  <div class="text">
                    <span>运动</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="5" class="margin-bottom">
                  <div class="text">
                    <span>穴位</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="6" class="margin-bottom">其他</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-td-column display-flex1">
            <div class="display-flex">
              <el-checkbox-group v-model="info.tzlxtbzzd">
                <el-checkbox label="1" class="margin-bottom">
                  <div class="text">
                    <span>情志</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="2" class="margin-bottom">
                  <div class="text">
                    <span>饮食</span>
                    <span>调养</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="3" class="margin-bottom">
                  <div class="text">
                    <span>起居</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="4" class="margin-bottom">
                  <div class="text">
                    <span>运动</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="5" class="margin-bottom">
                  <div class="text">
                    <span>穴位</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="6" class="margin-bottom">其他</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="children-td children-flex1 children-td-column display-flex1 children-border-right-none">
            <div class="display-flex">
              <el-checkbox-group v-model="info.tzlxphzzd">
                <el-checkbox label="1" class="margin-bottom">
                  <div class="text">
                    <span>情志</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="2" class="margin-bottom">
                  <div class="text">
                    <span>饮食</span>
                    <span>调养</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="3" class="margin-bottom">
                  <div class="text">
                    <span>起居</span>
                    <span>调摄</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="4" class="margin-bottom">
                  <div class="text">
                    <span>运动</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="5" class="margin-bottom">
                  <div class="text">
                    <span>穴位</span>
                    <span>保健</span>
                  </div>
                </el-checkbox>
                <el-checkbox label="6" class="margin-bottom">其他</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>

      <div class="children-tr children-border">
        <div class="children-td children-flex1">
          <div class="children-td children-flex1 children-text-center children-border-right-none">
            <span class="color">填表日期</span>
          </div>
          <div class="children-td children-flex1 children-border-right-none">
            <span class="color">年 月 日</span>
          </div>
        </div>
        <div class="children-td children-flex2 children-text-center children-border-right-none">
          <div class="children-td children-flex2 children-text-center">
            <span class="color">随访医生签名</span>
          </div>
          <div class="children-td children-flex3 children-border-right-none">
            <span></span>
          </div>
        </div>

      </div>
    </div>
    <div class="services-btns">
      <el-button @click="noClick">编辑</el-button>
      <el-button @click="noClick">保存</el-button>
      <el-button @click="noClick">审核</el-button>
      <el-button @click="noClick">上传</el-button>
      <el-button @click="noClick">打印</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: '',
          jktjid: ''
        }
      }
    }
  },
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: [],
      info: {
        id: 0,
        createTime: '',
        updateTime: '',
        jktjid: '',
        grjbxxid: '',
        tjbh: '',
        sfzh: '',
        name: '',
        tmnjlcpm: '',
        tmnrypfm: '',
        tmnryqdjcm: '',
        tmnshsydrwlm: '',
        tmngdmmblm: '',
        tmnryjsjzjlbam: '',
        tmnysshztgb: '',
        tmnrygdhpjx: '',
        tmngdstczbqs: '',
        tmnyjgsm: '',
        tmnsjflm: '',
        tmnwwbbbpl: '',
        tmnbybrnhl: '',
        tmnryhgmm: '',
        tmnmygmyhbslbt: '',
        tmnykzkydhm: '',
        tmnrygm: '',
        tmndpfryqmzm: '',
        tmndpfrycxqz: '',
        tmndpfyzjhzh: '',
        tmnpfhkcgm: '',
        tmnyztmm: '',
        tmnmbhbbyynm: '',
        tmnmshmkham: '',
        tmnypfszzhm: '',
        tmngdkgszzshsm: '',
        tmngdkdzlywm: '',
        tmnfbfdm: '',
        tmnclbsfm: '',
        tmndbncbusm: '',
        tmnrhdbgzm: '',
        tmnsthyhhm: '',
        tmnsxjmzcm: '',
        tzlxqxzbs: '',
        tzlxqxzdf: '',
        tzlxqxzzd: '',
        tzlxyxzzd: '',
        tzlxyxzdf: '',
        tzlxyxzbs: '',
        tzlxyinxzbs: '',
        tzlxyinxzdf: '',
        tzlxyinxzzd: '',
        tzlxtszbs: '',
        tzlxtszdf: '',
        tzlxtszzd: '',
        tzlxsrzbs: '',
        tzlxsrzdf: '',
        tzlxsrzzd: '',
        tzlxxyzbs: '',
        tzlxxyzdf: '',
        tzlxxyzzd: '',
        tzlxqyzdf: '',
        tzlxqyzbs: '',
        tzlxqyzzd: '',
        tzlxtbzbs: '',
        tzlxtbzdf: '',
        tzlxtbzzd: '',
        tzlxphzbs: '',
        tzlxphzdf: '',
        tzlxphzzd: '',
        tbrq: '',
        ysqm: '',
        ysid: '',
        lrsj: '',
        lrr: '',
        jgmc: '',
        jgbh: '',
        flag: '',
        jkdnid: '',
        age: '',
        xb: '',
        tzzd: '',
        tzbs: ''
      }
    }
  },
  computed: {
    // 气虚质得分
    qxzScore () {
      const tmnrypfm = parseInt(this.info.tmnrypfm) || 0
      const tmnryqdjcm = parseInt(this.info.tmnryqdjcm) || 0
      const tmnshsydrwlm = parseInt(this.info.tmnshsydrwlm) || 0
      const tmnryhgmm = parseInt(this.info.tmnryhgmm) || 0
      return tmnrypfm + tmnryqdjcm + tmnshsydrwlm + tmnryhgmm
    },
    // 阳虚质得分
    yxzScore () {
      const tmnsjflm = parseInt(this.info.tmnsjflm) || 0
      const tmnwwbbbpl = parseInt(this.info.tmnwwbbbpl) || 0
      const tmnbybrnhl = parseInt(this.info.tmnbybrnhl) || 0
      const tmnclbsfm = parseInt(this.info.tmnclbsfm) || 0
      return tmnsjflm + tmnwwbbbpl + tmnbybrnhl + tmnclbsfm
    },
    // 阴虚质得分
    yxzsScore () {
      const tmnyjgsm = parseInt(this.info.tmnyjgsm) || 0
      const tmnpfhkcgm = parseInt(this.info.tmnpfhkcgm) || 0
      const tmngdkgszzshsm = parseInt(this.info.tmngdkgszzshsm) || 0
      const tmnrhdbgzm = parseInt(this.info.tmnrhdbgzm) || 0
      return tmnyjgsm + tmnpfhkcgm + tmngdkgszzshsm + tmnrhdbgzm
    },
    // 痰湿质得分
    tszScore () {
      const tmngdstczbqs = parseInt(this.info.tmngdstczbqs) || 0
      const tmnykzkydhm = parseInt(this.info.tmnykzkydhm) || 0
      const tmnfbfdm = parseInt(this.info.tmnfbfdm) || 0
      const tmnsthyhhm = parseInt(this.info.tmnsthyhhm) || 0
      return tmngdstczbqs + tmnykzkydhm + tmnfbfdm + tmnsthyhhm
    },
    // 湿热质得分
    srzScore () {
      const tmnmbhbbyynm = parseInt(this.info.tmnmbhbbyynm) || 0
      const tmnypfszzhm = parseInt(this.info.tmnypfszzhm) || 0
      const tmngdkdzlywm = parseInt(this.info.tmngdkdzlywm) || 0
      const tmndbncbusm = parseInt(this.info.tmndbncbusm) || 0
      return tmnmbhbbyynm + tmnypfszzhm + tmngdkdzlywm + tmndbncbusm
    },
    // 血瘀质得分
    xyzScore () {
      const tmndpfrycxqz = parseInt(this.info.tmndpfrycxqz) || 0
      const tmnyztmm = parseInt(this.info.tmnyztmm) || 0
      const tmnmshmkham = parseInt(this.info.tmnmshmkham) || 0
      const tmnsxjmzcm = parseInt(this.info.tmnsxjmzcm) || 0
      return tmndpfrycxqz + tmnyztmm + tmnmshmkham + tmnsxjmzcm
    },
    // 气郁质得分
    qyzScore () {
      const tmngdmmblm = parseInt(this.info.tmngdmmblm) || 0
      const tmnryjsjzjlbam = parseInt(this.info.tmnryjsjzjlbam) || 0
      const tmnysshztgb = parseInt(this.info.tmnysshztgb) || 0
      const tmnrygdhpjx = parseInt(this.info.tmnrygdhpjx) || 0
      return tmngdmmblm + tmnryjsjzjlbam + tmnysshztgb + tmnrygdhpjx
    },
    // 特禀质得分
    tbzScore () {
      const tmnmygmyhbslbt = parseInt(this.info.tmnmygmyhbslbt)
      const tmnrygm = parseInt(this.info.tmnrygm)
      const tmndpfryqmzm = parseInt(this.info.tmndpfryqmzm)
      const tmndpfyzjhzh = parseInt(this.info.tmndpfyzjhzh)
      return tmnmygmyhbslbt + tmnrygm + tmndpfryqmzm + tmndpfyzjhzh
    },
    // 平和质得分
    phzScore () {
      const arr = [0, 5, 4, 3, 2, 1]
      const tmnjlcpm = parseInt(this.info.tmnjlcpm)
      let tmnrypfm = parseInt(this.info.tmnrypfm) || 0
      tmnrypfm = arr[tmnrypfm]
      let tmnshsydrwlm = parseInt(this.info.tmnshsydrwlm) || 0
      tmnshsydrwlm = arr[tmnshsydrwlm]
      let tmngdmmblm = parseInt(this.info.tmngdmmblm) || 0
      tmngdmmblm = arr[tmngdmmblm]
      let tmnbybrnhl = parseInt(this.info.tmnbybrnhl) || 0
      tmnbybrnhl = arr[tmnbybrnhl]
      return tmnjlcpm + tmnrypfm + tmnshsydrwlm + tmngdmmblm + tmnbybrnhl
    }
  },
  watch: {
    qxzScore (val) {
      this.info.tzlxqxzdf = val
    },
    yxzScore (val) {
      this.info.tzlxyxzdf = val
    },
    yxzsScore (val) {
      this.info.tzlxyinxzdf = val
    },
    tszScore (val) {
      this.info.tzlxtszdf = val
    },
    srzScore (val) {
      this.info.tzlxsrzdf = val
    },
    xyzScore (val) {
      this.info.tzlxxyzdf = val
    },
    qyzScore (val) {
      this.info.tzlxqyzdf = val
    },
    tbzScore (val) {
      this.info.tzlxtbzdf = val
    },
    phzScore (val) {
      this.info.tzlxphzdf = val
    }
  },
  created () {
    console.log('中医药健康管理请求参数：', this.params)
    this.getInfo()
  },
  methods: {
    // 中医药健康管理--查询
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591866
      try {
        const obj = {
          grjbxxid: this.params.grjbxxid || '',
          jktjid: this.params.tmcode || ''
        }
        const { data: res } = await this.$http.post('/ykdlnrzyyjkfwjls/finds', obj)
        console.log('中医药健康管理--查询信息', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data[0]
        const arr = ['tzlxqxzzd', 'tzlxyxzzd', 'tzlxyinxzzd', 'tzlxtszzd', 'tzlxsrzzd', 'tzlxxyzzd', 'tzlxqyzzd', 'tzlxtbzzd', 'tzlxphzzd']
        for (var i = 0; i < arr.length; i++) {
          this.updateData(arr[i])
        }
      } catch (error) {
        console.log('中医药健康管理--查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    updateData (key) {
      if (!this.info[key]) {
        this.info[key] = []
      } else {
        this.info[key] = this.info[key].split(',')
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    noClick () {
      this.$message.error('未开放')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.children {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54px;
    text-align: center;
    color: #475265;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    .item {
      min-width: 206px;
      color: #475265;
      font-size: 22px;
    }
  }

  .children-table {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .position {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 251px;
      left: 1px;
      width: 67px;
      height: 198px;
      background-color: #081C38;
      border-right: 1px solid #475265;
      box-sizing: border-box;
      color: #475265;
      font-size: 14px;
      letter-spacing: 4px;
      writing-mode: vertical-lr;
    }

    .children-tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;
      box-sizing: border-box;
    }

    .children-tr1 {
      height: 106px;
    }

    .children-tr2 {
      height: 200px;
    }

    .children-tr3 {
      height: 160px;
    }

    .children-tr4 {
      height: 200px;
    }

    .children-tr5 {
      height: 250px;
    }

    .children-tr6 {
      height: 650px;
    }

    .children-tr7 {
      height: 150px;
    }

    .children-tr8 {
      height: 100px;
    }

    .children-tr9 {
      height: 500px;
    }

    .children-tr10 {
      height: 300px;
    }

    .children-tr11 {
      height: 450px;
    }

    .children-td {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid #475265;
      box-sizing: border-box;
      color: #475265;
      font-size: 16px;

      >span {
        margin-left: 14px;
      }

      .margin-left-none {
        margin-left: 0;
      }

      .color {
        white-space: nowrap;
        color: #475265;
      }
    }

    .children-td-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 100%;
      color: #475265;
      font-size: 14px;
      letter-spacing: 4px;
    }

    .children-td-text {
      writing-mode: vertical-lr;
    }

    .children-td-text1 {
      padding-left: 4px;
      box-sizing: border-box;
    }

    .children-td-right {
      width: calc(100% - 46px);
      height: 100%;
    }

    .children-td-list {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      color: #475265;
      font-size: 14px;
    }

    .children-td-list-center {

      justify-content: center;
    }

    .children-td-column {
      flex-direction: column;
    }

    .children-td-title {
      width: 100%;
      height: 45px;
      line-height: 45px;
      padding-left: 14px;
      box-sizing: border-box;
    }

    .checkbox {
      width: 100%;
      height: calc(100% - 50px);
      padding-left: 8px;
      box-sizing: border-box;
    }

    .margin-left {
      margin-left: 14px;
    }

    .margin-bottom {
      margin-bottom: 10px;

    }

    .group-margin {
      margin-left: 40px;
    }

    .el-radio {
      display: flex;
      align-items: center;
    }

    .children-flex1 {
      flex: 1;
    }

    .children-flex2 {
      flex: 2;
    }

    .children-flex3 {
      flex: 3;
    }

    .children-flex4 {
      flex: 4;
    }

    .children-flex5 {
      flex: 5;
    }

    .children-flex6 {
      flex: 6;
    }

    .children-flex7 {
      flex: 7;
    }

    .children-flex8 {
      flex: 8;
    }

    .children-flex10 {
      flex: 10;
    }

    .children-flex12 {
      flex: 12;
    }

    .children-text-center {
      padding-left: 0;
      justify-content: center;

      >span {
        margin-left: 0;
      }
    }

    .children-text-right {
      justify-content: flex-end;

      >span {
        width: calc(100% - 67px);
        text-align: center;
      }
    }

    .children-wrap {
      flex-wrap: wrap;
    }

    .input {
      width: 30px;
    }

    .input1 {
      width: 80px;
    }

    .label {
      width: calc(100% - 14px);
      margin-left: 10px;
    }

    /deep/.el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #475265;
      color: #475265;
      font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
      display: flex;
      align-items: center;
      color: #475265;
      font-size: 16px;
    }

    /deep/.el-radio__inner,
    /deep/.el-checkbox__inner {
      // background-color: transparent;
    }

    /deep/.el-radio__label,/deep/.el-checkbox__label {
      font-size: 16px;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner,
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #475265;
      border-color: #475265;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label,
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #475265;
    }

  }

  .children-border {
    border: 1px solid #475265;
    border-bottom: none;
    box-sizing: border-box;
  }

  .children-border-bottom {
    border-bottom: 1px solid #475265;
    box-sizing: border-box;
  }

  .children-border-right {
    border-right: 1px solid #475265;
    box-sizing: border-box;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-three {
    border-right: 3px solid transparent !important;
  }

  .group-flex {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    /deep/.el-radio-group {
      width: 100%;
      height: 100%;
    }

    /deep/.el-radio {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      height: 100%;
      margin-right: 0 !important;
    }
  }

  .display-flex {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    /deep/.el-radio-group,
    /deep/.el-checkbox-group {
      width: 100% !important;
    }

    /deep/.el-radio__label {
      padding-left: 2px;
      font-size: 16px !important;
    }

    /deep/.el-checkbox__label {
      display: flex;
      padding-left: 2px;

      .text {
        display: flex;
        align-items: center;
        height: 50px;
      }
    }

    /deep/.el-checkbox {
      display: flex;
      align-items: center;
      width: 90%;
      padding-left: 20px;
    }
  }

  .display-flex1 {
    width: 70px;

    /deep/.el-radio__label {
      display: block;
      font-size: 16px;
    }
  }

  .display-flex2 {
    padding-left: 30px;
    box-sizing: border-box;
  }

  .display-flex1 {
    align-items: center;
    justify-content: center;
  }

  .services-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #475265;

    .el-button {
      min-width: 120px;
      margin-left: 15px;
      margin-right: 15px;
      padding-top: 6px;
      padding-bottom: 6px;
      border: none;
      background-color: #3782F2;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
</style>
