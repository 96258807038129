<template>
  <div class="son">
    <div class="son-left">
      <el-input placeholder="机构名称/诊断名称/就诊名称" suffix-icon="el-icon-search" v-model="value">
      </el-input>
      <el-radio-group v-model="radio">
        <el-radio label="1">日期</el-radio>
        <el-radio label="2">疾病</el-radio>
        <el-radio label="3">机构</el-radio>
      </el-radio-group>
      <div class="scroll">
        <div class="list">
          <div class="top">
            <div class="info">
              <span>2022-05-01</span>
              <span>皮下组织感染</span>
            </div>
            <div class="blue">
              <span>门诊</span>
            </div>
          </div>
          <div class="title">
            <span>YD二院</span>
          </div>
          <div class="label">
            <span class="item" :class="{ active: labelIndex === index }" v-for="(item, index) in labels" :key="index"
              @click="labelClick(index)">{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="son-right">
      <div class="son-title">
        <span>门诊摘要</span>
        <span class="nubmer">NO:-</span>
      </div>
      <div class="son-info">
        <span class="son-info-item">就诊机构：-</span>
        <span class="son-info-item">就诊科室：-</span>
        <span class="son-info-item">就诊医生：-</span>
        <span class="son-info-item">就诊时间：-</span>
        <span class="son-info-item">就诊号：-</span>
        <span class="son-info-item">保险类型：-</span>
      </div>
      <div class="son-line">
        <span>主诉：-</span>
      </div>
      <div class="son-line">
        <span>现病史：-</span>
      </div>
      <div class="son-line">
        <span>药物过敏史：-</span>
      </div>
      <div class="son-line">
        <span>体格检查：-</span>
      </div>
      <div class="son-line">
        <span>诊断：-</span>
      </div>
      <div class="son-line">
        <span>用药情况：-</span>
      </div>
      <div class="son-table">
        <div class="son-tr">
          <span class="son-td son-td1 flex10">基本信息</span>
          <span class="son-td son-td1 flex5">每次剂量</span>
          <span class="son-td son-td1 flex7">用法</span>
          <span class="son-td son-td1 flex8">总量</span>
        </div>
        <div class="son-tr">
          <div class="son-td son-td2 flex10">
            <span class="son-td flex1">组</span>
            <span class="son-td flex4">药品名称</span>
            <span class="son-td flex3">规格</span>
            <span class="son-td flex2 border-right-none">类型</span>
          </div>
          <div class="son-td son-td2 flex5">
            <span class="son-td flex3">剂量</span>
            <span class="son-td flex2 border-right-none">单位</span>
          </div>
          <div class="son-td son-td2 flex7">
            <span class="son-td flex2">用法</span>
            <span class="son-td flex3">频次</span>
            <span class="son-td flex2 border-right-none">天数</span>
          </div>
          <div class="son-td son-td2 flex8">
            <span class="son-td flex2">数量</span>
            <span class="son-td flex2">单位</span>
            <span class="son-td flex4 border-right-none">嘱托</span>
          </div>
        </div>

        <div class="son-tr">
          <div class="son-td son-td2 flex10">
            <span class="son-td flex1">-</span>
            <span class="son-td flex4">-</span>
            <span class="son-td flex3">-</span>
            <span class="son-td flex2 border-right-none"></span>
          </div>
          <div class="son-td son-td2 flex5">
            <span class="son-td flex3">-</span>
            <span class="son-td flex2 border-right-none">-</span>
          </div>
          <div class="son-td son-td2 flex7">
            <span class="son-td flex2">-</span>
            <span class="son-td flex3">-</span>
            <span class="son-td flex2 border-right-none">-</span>
          </div>
          <div class="son-td son-td2 flex8">
            <span class="son-td flex2">-</span>
            <span class="son-td flex2">-</span>
            <span class="son-td flex4 border-right-none">-</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: '',
      radio: '1',
      labelIndex: 0,
      labels: Object.freeze(['门诊摘要', '门诊摘要', '检验记录', '门诊记录', '收费记录'])
    }
  },
  methods: {
    labelClick (index) {
      this.labelIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 0;
  }

  .son-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    height: 100%;
    padding: 0 7px;
    padding-top: 30px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    .el-input {
      width: 300px;
    }

    .el-radio-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 300px;
      height: 70px;
    }

    /deep/.el-radio__label {
      color: #fff;
      font-size: 18px;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner {
      background-color: #01EEFD;
      border-color: #01EEFD;
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 130px);
      padding: 0 10px;
      padding-top: 10px;
      border-top: 1px solid #fff;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .list {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .top {
          display: flex;
          justify-content: space-between;

          .info {
            display: flex;
            flex-direction: column;
            color: #fff;
            font-size: 16px;
          }

          .blue {
            height: 30px;
            line-height: 30px;
            padding: 0 5px;
            background-color: #01EEFD;
            color: #fff;
            font-size: 16px;
          }
        }

        .title {
          margin: 10px 0;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
        }

        .label {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .item {
            margin-right: 20px;
            margin-bottom: 20px;
            padding: 6px;
            background-color: #fff;
            color: #999999;
            font-size: 16px;
            cursor: pointer;
          }

          .active {
            background-color: #00D5EA;
            color: #fff;
          }
        }
      }
    }
  }

  .son-right {
    width: calc(100% - 380px);
    height: 100%;
    padding: 0 9px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    .son-title {
      position: relative;
      height: 68px;
      line-height: 68px;
      text-align: center;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      font-weight: 600;

      .nubmer {
        position: absolute;
        right: 31px;
        top: 8px;
        color: #fff;
        font-size: 16px;
      }
    }

    .son-info {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: space-evenly;
      width: 100%;
      height: 113px;
      padding: 0 10px;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;

      .son-info-item {
        width: 33%;
        color: #fff;
        font-size: 18px;
      }
    }

    .son-line {
      width: 100%;
      padding-left: 10px;
      margin-top: 20px;
      box-sizing: border-box;
      color: #fff;
      font-size: 18px;
    }

    .son-table {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      background-color: #068CA7;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      box-sizing: border-box;

      .son-tr {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 40px;
        line-height: 40px;

        .son-td {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          box-sizing: border-box;
          color: #fff;
          font-size: 16px;
        }

        .border-right-none {
          border-right: none;
        }

        .son-td1 {
          font-size: 18px;
        }

        .son-td2 {
          display: flex;
          align-items: center;
        }

        .flex1 {
          flex: 1;
        }

        .flex2 {
          flex: 2;
        }

        .flex3 {
          flex: 3;
        }

        .flex4 {
          flex: 4;
        }

        .flex5 {
          flex: 5;
        }

        .flex7 {
          flex: 7;
        }

        .flex8 {
          flex: 8;
        }

        .flex10 {
          flex: 10;
        }
      }
    }
  }
}
</style>
