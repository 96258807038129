var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_vm._m(1),_c('div',{staticClass:"scroll"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"scroll-line",class:{ 'scroll-active': _vm.tabIndex === 1 },on:{"click":function($event){return _vm.tabClick(1)}}},[_c('span',[_vm._v("血压：122/83mmHg")])]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_c('div',{staticClass:"scroll-line",class:{ 'scroll-active': _vm.tabIndex === 3 },on:{"click":function($event){return _vm.tabClick(3)}}},[_c('span',[_vm._v("2022-08-03：合欢花")])]),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_c('div',{staticClass:"scroll-line",class:{ 'scroll-active': _vm.tabIndex === 5 },on:{"click":function($event){return _vm.tabClick(5)}}},[_c('span',[_vm._v("2022-08-03：生化全套")])])])]),_c('div',{staticClass:"right"},[(_vm.tabIndex === 1)?_c('Item2'):(_vm.tabIndex === 3)?_c('Item4'):(_vm.tabIndex === 5)?_c('Item6'):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"user"}),_c('span',{staticClass:"text"},[_vm._v("测试")]),_c('span',{staticClass:"text"},[_vm._v("女")]),_c('span',{staticClass:"text"},[_vm._v("-岁")]),_c('span',{staticClass:"text"},[_vm._v("电话：-")]),_c('span',{staticClass:"text"},[_vm._v("地址：-")]),_c('span',{staticClass:"label organ"},[_vm._v("高")]),_c('span',{staticClass:"label green"},[_vm._v("糖")]),_c('span',{staticClass:"label blue"},[_vm._v("冠")]),_c('span',{staticClass:"label purple"},[_vm._v("肿")]),_c('span',{staticClass:"label red"},[_vm._v("精")])]),_c('div',{staticClass:"right"},[_c('span',{staticClass:"el-icon-search icon"}),_c('span',{staticClass:"text"},[_vm._v("筛选")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("患者摘要")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-title"},[_c('span',{staticClass:"blue"}),_c('span',[_vm._v("诊断")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('span',[_vm._v("便秘：2次")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('span',[_vm._v("带状疱症：3次")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-title"},[_c('span',{staticClass:"blue"}),_c('span',[_vm._v("体征")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('span',[_vm._v("血糖：3.2mmol")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('span',[_vm._v("BMI：24.46")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-title"},[_c('span',{staticClass:"blue"}),_c('span',[_vm._v("生活习惯")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('span',[_vm._v("吸烟情况：无")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('span',[_vm._v("饮食情况：嗜糖")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('span',[_vm._v("饮酒情况：从不")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('span',[_vm._v("运动情况：0次/周，0分钟/次")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-title"},[_c('span',{staticClass:"blue"}),_c('span',[_vm._v("门诊用药")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('span',[_vm._v("2022-08-03：阿斯匹林胶囊")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-title"},[_c('span',{staticClass:"blue"}),_c('span',[_vm._v("异常检测项目")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('span',[_vm._v("2022-08-03：CT常规检查")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-title"},[_c('span',{staticClass:"blue"}),_c('span',[_vm._v("异常检验")])])
}]

export { render, staticRenderFns }