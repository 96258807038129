<template>
  <div class="page">
    <div class="switch">
      <span class="item" :class="{ item1: switchIndex === index }" v-for="(item, index) in switchs" :key="index"
        @click="switchClick(index)">{{ item }}</span>
    </div>
    <div class="content">
      <div class="line">
        <div class="search"></div>
        <el-table :data="tableData" height="456" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="床号">
          </el-table-column>
          <el-table-column prop="date" label="患者姓名">
          </el-table-column>
          <el-table-column prop="date" label="性别">
          </el-table-column>
          <el-table-column prop="type" label="年龄">
          </el-table-column>
          <el-table-column prop="type1" label="科室">
          </el-table-column>
          <el-table-column prop="type1" label="测量人">
          </el-table-column>
          <el-table-column prop="type2" label="主治医生">
          </el-table-column>
          <el-table-column prop="type" label="检测时间">
          </el-table-column>
          <el-table-column prop="type" label="检测时段">
          </el-table-column>
          <el-table-column prop="type" label="血糖值">
          </el-table-column>
          <el-table-column prop="type" label="方法">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex">
                <span>编辑</span>
                <span>删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      switchIndex: 0,
      switchs: Object.freeze(['血糖记录', '血压记录', '体温记录', '用药记录']),
      myChart1: null,
      myChart2: null,
      myChart3: null,
      tableData: [{
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '已结束'
      }, {
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }]
    }
  },
  mounted () {
    // this.canvas1()
    // this.canvas2()
    // this.canvas3()
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    },
    canvas1 () {
      const datas = [
        { value: 23, name: '23人/63%' },
        { value: 13, name: '13人/37%' }
      ]
      const colors = ['#0089F6', '#00DDF6']
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '性别对比',
          left: 'left',
          textStyle: {
            color: '#01EEFD',
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        color: colors,
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        series: [
          {
            name: '性别对比',
            type: 'pie',
            radius: '50%',
            data: datas.map((item, index) => {
              item.label = {
                color: colors[index]
              }
              return item
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              color: ['#0089F6', '#00DDF6']
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      const datas = [
        { value: 23, name: '0-20岁' },
        { value: 13, name: '21-40岁' },
        { value: 13, name: '41-50岁' },
        { value: 13, name: '51-60岁' },
        { value: 13, name: '61-70岁' },
        { value: 13, name: '70岁以上' }
      ]
      const colors = ['#30F05D', '#0089F6', '#00DDF6', '#FFCB23', '#CE7500', '#FF2A2A']
      var chartDom = document.getElementById('echarts2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '年龄对比',
          left: 'left',
          textStyle: {
            color: '#01EEFD',
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        color: colors,
        legend: {
          orient: 'vertical',
          left: 'right',
          textStyle: {
            color: 'auto'
          }
        },
        series: [
          {
            name: '性别对比',
            type: 'pie',
            radius: '50%',
            data: datas.map((item, index) => {
              item.label = {
                color: colors[index]
              }
              return item
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              color: ['#0089F6', '#00DDF6']
            }
          }
        ]
      }

      option && this.myChart2.setOption(option)
    },
    canvas3 () {
      var chartDom = document.getElementById('echarts3')
      this.myChart3 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '分组对比',
          left: 'left',
          textStyle: {
            color: '#01EEFD',
            fontWeight: '400'
          }
        },
        xAxis: {
          type: 'category',
          data: ['1型糖尿病', '2型糖尿病', '妊娠型糖尿病', '特殊型糖尿病', '其他', '糖尿病合并高血压', '高尿酸血症', '3级高血压', '2级高血压', '1级高血压'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',
              fontSize: 12
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',
              fontSize: 16
            }
          }
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130, 70, 110, 130],
            type: 'bar',
            barWidth: '20',
            itemStyle: {
              color: '#0077F9'
            }
          }
        ]
      }

      option && this.myChart3.setOption(option)
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding: .25rem;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }

  .switch {
    display: flex;
    width: 100%;
    height: .5rem;

    .item {
      height: .5rem;
      line-height: .5rem;
      margin-right: .25rem;
      padding: 0 .125rem;
      text-align: center;
      color: #fff;
      font-size: .175rem;
      background-color: rgba(115, 115, 115, .4);
      cursor: pointer;
    }

    .item1 {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - .625rem);
    margin-top: .125rem;

    .line {
      width: 100%;
      padding: .25rem;
      border: .05rem solid #073F73;
      box-sizing: border-box;
      border-bottom: none;

      .title {
        color: #01EEFD;
        font-size: .25rem;
      }

      .count {
        display: flex;
        align-items: center;
        width: 100%;
        height: .5rem;
        margin-top: .125rem;

        .count-item {
          display: flex;
          align-items: center;
          width: 4.5rem;
          height: .5rem;
          margin-right: .25rem;
          background: linear-gradient(79deg, #0A477B 0%, #0E67AD 100%);
          color: #fff;
          font-size: .225rem;

          img {
            width: .375rem;
            height: .375rem;
            margin: 0 .25rem;
          }
        }
      }

      .echarts {
        width: 4.975rem;
        height: 4.125rem;
      }

      .echarts1 {
        width: 7.5rem;
        height: 4.125rem;
      }

      .echarts2 {
        width: 100%;
        height: 4.9375rem;
      }

      .search {
        width: 100%;
      }

      ::v-deep .el-table__body-wrapper {
        &::-webkit-scrollbar {
          width: 0rem;
        }
      }

      ::v-deep .el-table th.gutter {
        display: none;
        width: 0;
      }

      ::v-deep .el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      .el-table {
        background-color: #092B50;
        border: .05rem #073F73 solid;
      }

      // 表头文字颜色
      /deep/.el-table__header-wrapper {
        thead {
          th {
            div {
              color: #01EEFD;
              font-size: .2125rem;
              font-weight: 400;
            }
          }
        }
      }

      //表格内容颜色
      /deep/.el-table__body tbody tr {
        color: #fff;
        background-color: #092B50;
      }

      /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #092B50;
      }

      /deep/.el-table tbody tr:hover>td {
        background-color: transparent !important
      }

      // 改变边框颜色
      .el-table--border,
      .el-table--group {
        border: .025rem solid #ddd !important;
      }

      /**
  改变表格内竖线颜色
   */
      .el-table--border td,
      .el-table--border th,
      .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: .0125rem solid #ddd !important;
      }

      /deep/.el-table td {
        border-bottom: .0125rem solid #01EEFD;
      }

      /deep/.el-table thead tr th {
        border-color: transparent;
      }

      /deep/ .el-table--border::after,
      /deep/ .el-table--group::after,
      /deep/ .el-table::before {
        background-color: #073F73;
      }

      /deep/.el-table--border {
        border: .0125rem solid #073F73 !important;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #01EEFD;
      }

      /deep/.el-checkbox__inner {
        background-color: transparent;
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: .5rem;

        ::v-deep.el-pagination__total,
        ::v-deep.el-pagination__jump {
          color: #01EEFD;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 15px;
        color: #01EEFD;

        span {
          cursor: pointer;
        }
      }
    }

    .line1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
