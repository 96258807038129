<template>
  <div class="popup">
    <div class="form">
      <el-form ref="form">
        <div class="tip">干预详情</div>
        <div class="title">基本信息</div>
        <div class="info">
          <div class="item">
            <div class="key">姓名</div>
            <span>{{ info.name || '-' }}</span>
          </div>
          <div class="item">
            <div class="key">性别</div>
            <span>{{ info.xb | sexType }}</span>
          </div>
          <div class="item">
            <div class="key">年龄</div>
            <span>{{ info.age || '-' }}</span>
          </div>
          <div class="item">
            <div class="key">疾病史</div>
            <span>{{ info.jbsjb | jbsjbType }}</span>
          </div>
          <div class="item">
            <div class="key">遗传史</div>
            <span>{{ info.jbsycs | jbsjbType }}</span>
          </div>
          <div class="item">
            <div class="key">家族史</div>
            <span>-</span>
          </div>
          <div class="item">
            <div class="key">血压</div>
            <span>{{ info.fzjcxyg || '-' }}/{{ info.fzjcxyd || '-' }}</span>
          </div>
          <div class="item">
            <div class="key">血糖</div>
            <span>{{ info.fzjcxt || '-' }}</span>
          </div>
          <div class="item">
            <div class="key">吸烟</div>
            <span>{{ info.xyqk | xyqkType }}</span>
          </div>
          <div class="item">
            <div class="key">饮酒</div>
            <span>{{ info.yjqk | yjqkType }}</span>
          </div>
          <div class="item">
            <div class="key">运动</div>
            <span>{{ info.ydqk | ydqkType }}</span>
          </div>
          <div class="item">
            <div class="key">评估结果</div>
            <span>-</span>
          </div>
        </div>
        <div class="title">综合处方</div>
        <div class="box">
          <el-table :data="info.zhgylist" height="200" border>
            <el-table-column prop="zhgygylx" label="干预类型">
            </el-table-column>
            <el-table-column prop="zhgygycs" label="干预措施">
            </el-table-column>
            <el-table-column prop="zhgyyf" label="用法">
            </el-table-column>
            <el-table-column prop="zhgypc" label="频次">
            </el-table-column>
            <el-table-column prop="zhgyyl" label="用量">
            </el-table-column>
            <el-table-column prop="zhgykssj" label="开始时间">
            </el-table-column>
            <el-table-column prop="zhgyjssj" label="结束时间">
            </el-table-column>
            <el-table-column prop="zhgysfjcx" label="是否检测项">
            </el-table-column>
            <el-table-column prop="zhgybz" label="备注">
            </el-table-column>
          </el-table>
        </div>
        <div class="title title1">饮食处方</div>
        <div class="box">
          <el-table :data="info.yscfyscf" height="200" border>
            <el-table-column prop="yscfcc" label="餐次">
            </el-table-column>
            <el-table-column prop="swfl" label="食物分类">
            </el-table-column>
            <el-table-column prop="swmc" label="食物名称">
            </el-table-column>
            <el-table-column prop="swzl" label="重量(g）">
            </el-table-column>
            <el-table-column prop="swrl" label="热量(kCal)">
            </el-table-column>
            <el-table-column prop="tshhw" label="碳水化合物(g)">
            </el-table-column>
            <el-table-column prop="zhif" label="脂肪(g)">
            </el-table-column>
            <el-table-column prop="tians" label="天数">
            </el-table-column>
          </el-table>
        </div>
        <div class="title title1">运动处方</div>
        <div class="box">
          <el-table :data="info.ydcf" height="200" border>
            <el-table-column prop="shij" label="时间">
            </el-table-column>
            <el-table-column prop="ydxm" label="运动项目">
            </el-table-column>
            <el-table-column prop="ydlx" label="运动类型">
            </el-table-column>
            <el-table-column prop="dwsj" label="单位时间(分钟)">
            </el-table-column>
            <el-table-column prop="nlxh" label="能量消耗(kcal)">
            </el-table-column>
          </el-table>
        </div>
        <div class="title title1">心理处方</div>
        <div class="box">
          <el-table :data="info.xlcf" height="200" border>
            <el-table-column prop="xlfxh" label="处方序号">
            </el-table-column>
            <el-table-column prop="xlcfmc" label="处方名称">
            </el-table-column>
            <el-table-column prop="xlcffl" label="处方分类">
            </el-table-column>
            <el-table-column prop="xlcfnr" label="处方内容">
            </el-table-column>
            <el-table-column prop="bz1" label="备注">
            </el-table-column>
          </el-table>
        </div>

      </el-form>
    </div>
    <div class="bts">
      <el-button size="small" @click="closePopup">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{}],
      info: {
        name: '',
        jkgyid: '',
        xb: '',
        age: '',
        sfzh: '',
        ssjgbh: '',
        ssjgmc: '',
        xzjdmc: '',
        xzjdmcid: '',
        cwhmcid: '',
        cwhmc: '',
        jbsjb: '',
        jbsshs: '',
        jbsshslist: [
          {
            jbsshsj: '',
            jbsshmc: '',
            jbsshlx: ''
          }
        ],
        jbsycs: '',
        jbsjzs: [
          {
            jgbjzslx: '',
            jgbjzsxx: ''
          }
        ],
        yjqk: '',
        yjqknl: '',
        yjqkjjnl: '',
        xyqk: '',
        xyqknl: '',
        xyqkjynl: '',
        ydqk: '',
        ydqkmcdlsj: '',
        ydqkdlfs: '',
        fzjcxyg: '',
        fzjcxyd: '',
        fzjcxt: '',
        fzjczdgc: '',
        fzjcgysz: '',
        zhgylist: [
          {
            zhgygylx: '',
            zhgygycs: '',
            zhgyyf: '',
            zhgypc: '',
            zhgyyl: '',
            zhgykssj: '',
            zhgyjssj: '',
            zhgysfjcx: '',
            zhgybz: '',
            zhgyfnmc: '',
            zhgyfnid: ''
          }
        ],
        zhgyysjy: '',
        yscfysfn: '',
        yscfyscf: [
          {
            yscfcc: '',
            swfl: '',
            swmc: '',
            swzl: '',
            swrl: '',
            tshhw: '',
            zhif: '',
            tians: '',
            bz1: '',
            yscfid: '',
            zhgyfnid: '',
            yscfnid: ''
          }
        ],
        ydcf: [
          {
            shij: '',
            ydxm: '',
            ydlx: '',
            dwsj: '',
            nlxh: '',
            bz1: ''
          }
        ],
        xlcf: [
          {
            xlfxh: '',
            xlcfmc: '',
            xlcffl: '',
            xlcfnr: '',
            bz1: ''
          }
        ],
        gyysbm: '',
        gyys: ''
      }
    }
  },
  filters: {
    sexType (val) {
      if (!val) return '-'
      return ['男', '女', '保密'][val - 1]
    },
    jbsjbType (val) {
      if (val === '0') return '无'
      return '有'
    },
    xyqkType (val) {
      if (val === '0') return '从不吸烟'
      if (val === '1') return '已戒烟'
      if (val === '2') return '吸烟'
      return '-'
    },
    yjqkType (val) {
      if (val === '0') return '从不'
      if (val === '1') return '偶尔'
      if (val === '2') return '经常'
      if (val === '3') return '每天'
      return '-'
    },
    ydqkType (val) {
      if (val === '0') return '每天'
      if (val === '1') return '每周一次以上'
      if (val === '2') return '偶尔'
      if (val === '3') return '不锻炼'
      return '-'
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // https://app.apifox.com/project/2726972/apis/api-208030326
      try {
        const { data: res } = await this.$http.post('/ykdjkgys/getbygyid', this.$parent.selectInfo)
        console.log('获取健康干预单条', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        this.info = res.data
      } catch (error) {
        console.log('获取健康干预单条请求失败', error)
      }
    },
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .form {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .icon {
    position: absolute;
    top: 10px;
    left: 140px;
    width: 82px;
    height: 66px;
  }

  .tip {
    padding-left: 18px;
    margin-bottom: 10px;
    box-sizing: border-box;
    color: #000;
    font-size: 20px;
  }

  .title {
    height: 45px;
    line-height: 45px;
    border-top: 1px solid #000;
    padding-left: 10px;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
  }

  .title1 {
    border: none;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;

      .key {
        width: 76px;
        padding-right: 10px;
        font-size: 18px;
        text-align-last: justify;
        white-space: nowrap;
      }
    }

    .item1 {
      width: 50%;
    }
  }

  .box {
    width: 100%;
    margin-top: 10px;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .scroll {
      overflow-y: scroll;
      max-height: 100px;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #000;
      font-size: 14px;

      .item {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }

    .line1 {
      height: 40px;
      line-height: 40px;
      background-color: #000;
      color: #fff;
    }

    .line2 {
      height: 40px;
      line-height: 40px;
    }
  }

  .font {
    margin-left: 50px;
    color: #000;
    font-size: 12px;
  }

  .bts {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 40px;
  }
}
</style>
