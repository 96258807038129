var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"tab-scroll"},[_c('div',{staticClass:"table"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_c('div',{staticClass:"tr2"},[_vm._m(12),_c('div',{staticClass:"flex1"},[_c('div',{staticClass:"item border"},[_c('el-checkbox-group',{model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},[_c('el-checkbox',{attrs:{"label":"1"}},[_vm._v("平和质")]),_c('el-checkbox',{attrs:{"label":"2"}},[_vm._v("气虚质")]),_c('el-checkbox',{attrs:{"label":"3"}},[_vm._v("阳虚质")]),_c('el-checkbox',{attrs:{"label":"4"}},[_vm._v("阴虚质")]),_c('el-checkbox',{attrs:{"label":"5"}},[_vm._v("痰湿质")]),_c('el-checkbox',{attrs:{"label":"6"}},[_vm._v("湿热质")]),_c('el-checkbox',{attrs:{"label":"7"}},[_vm._v("血瘀质")]),_c('el-checkbox',{attrs:{"label":"8"}},[_vm._v("气郁质")]),_c('el-checkbox',{attrs:{"label":"9"}},[_vm._v("特禀质")])],1)],1),_c('div',{staticClass:"item border"},[_c('el-checkbox-group',{model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},[_c('el-checkbox',{attrs:{"label":"1"}},[_vm._v("健康")]),_c('el-checkbox',{attrs:{"label":"2"}},[_vm._v("亚健康")]),_c('el-checkbox',{attrs:{"label":"3"}},[_vm._v("高危")]),_c('el-checkbox',{attrs:{"label":"4"}},[_vm._v("慢性病")]),_c('el-checkbox',{attrs:{"label":"5"}},[_vm._v("康复")]),_c('el-checkbox',{attrs:{"label":"6"}},[_vm._v("定期随访")])],1)],1)])])]),_c('div',{staticClass:"son-bottom"},[_c('el-button',[_vm._v("编辑")]),_c('el-button',[_vm._v("保存")]),_c('el-button',[_vm._v("审核")]),_c('el-button',[_vm._v("上传")]),_c('el-button',[_vm._v("打印")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-title"},[_c('span',[_vm._v("中医基本体质辨识、健康指导表")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-info"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("姓名：")])]),_c('div',{staticClass:"item"},[_vm._v("编号：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr"},[_c('div',{staticClass:"td td1"},[_c('span',[_vm._v("体质类型")])]),_c('div',{staticClass:"td td1"},[_c('span',[_vm._v("总体特征")])]),_c('div',{staticClass:"td td1"},[_c('span',[_vm._v("形体特征")])]),_c('div',{staticClass:"td td1"},[_c('span',[_vm._v("常见表现")])]),_c('div',{staticClass:"td td1"},[_c('span',[_vm._v("饮食调养")])]),_c('div',{staticClass:"td td1"},[_c('span',[_vm._v("起居")])]),_c('div',{staticClass:"td td1"},[_c('span',[_vm._v("中医药膳指导")])]),_c('div',{staticClass:"td td1"},[_c('span',[_vm._v("穴位保健")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr1"},[_c('div',{staticClass:"td"},[_c('span',[_vm._v("辨平和质(正常体质)")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("体形匀称、健壮")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("饮食有节、宜粗细粮合理搭配。")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("平素病较少，劳逸结合，坚持锻炼，穿戴求自然")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品 ")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("穴位保健")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr1"},[_c('div',{staticClass:"td"},[_c('span',[_vm._v("气虚质")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("体形匀称、健壮")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("饮食有节、宜粗细粮合理搭配。")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("平素病较少，劳逸结合，坚持锻炼，穿戴求自然")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品 ")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("穴位保健")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr1"},[_c('div',{staticClass:"td"},[_c('span',[_vm._v("阳虚质")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("体形匀称、健壮")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("饮食有节、宜粗细粮合理搭配。")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("平素病较少，劳逸结合，坚持锻炼，穿戴求自然")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品 ")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("穴位保健")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr1"},[_c('div',{staticClass:"td"},[_c('span',[_vm._v("阴虚质")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("体形匀称、健壮")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("饮食有节、宜粗细粮合理搭配。")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("平素病较少，劳逸结合，坚持锻炼，穿戴求自然")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品 ")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("穴位保健")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr1"},[_c('div',{staticClass:"td"},[_c('span',[_vm._v("痰湿质")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("体形匀称、健壮")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("饮食有节、宜粗细粮合理搭配。")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("平素病较少，劳逸结合，坚持锻炼，穿戴求自然")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品 ")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("穴位保健")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr1"},[_c('div',{staticClass:"td"},[_c('span',[_vm._v("湿热质")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("体形匀称、健壮")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("饮食有节、宜粗细粮合理搭配。")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("平素病较少，劳逸结合，坚持锻炼，穿戴求自然")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品 ")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("穴位保健")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr1"},[_c('div',{staticClass:"td"},[_c('span',[_vm._v("血瘀质")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("体形匀称、健壮")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("饮食有节、宜粗细粮合理搭配。")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("平素病较少，劳逸结合，坚持锻炼，穿戴求自然")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品 ")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("穴位保健")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr1"},[_c('div',{staticClass:"td"},[_c('span',[_vm._v("气郁质")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("体形匀称、健壮")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("饮食有节、宜粗细粮合理搭配。")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("平素病较少，劳逸结合，坚持锻炼，穿戴求自然")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品 ")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("穴位保健")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr1"},[_c('div',{staticClass:"td"},[_c('span',[_vm._v("特禀质")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("体形匀称、健壮")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("饮食有节、宜粗细粮合理搭配。")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("平素病较少，劳逸结合，坚持锻炼，穿戴求自然")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品 ")])]),_c('div',{staticClass:"td"},[_c('span',[_vm._v("穴位保健")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex2 border"},[_c('span',[_vm._v("诊断")])])
}]

export { render, staticRenderFns }