<template>
  <div class="cover">
    <div class="cover-box">
      <div class="conve-center">
        <div class="conve-title">
          <span>居民健康档案</span>
        </div>
        <div class="conve-line">
          <div class="conve-key">
            <span>姓名：</span>
          </div>
          <el-input class="input"></el-input>
        </div>
        <div class="conve-line">
          <div class="conve-key">
            <span>现住址：</span>
          </div>
          <el-input class="input"></el-input>
        </div>
        <div class="conve-line">
          <div class="conve-key">
            <span>户籍地址：</span>
          </div>
          <el-input class="input"></el-input>
        </div>
        <div class="conve-line">
          <div class="conve-key">
            <span>联系电话：</span>
          </div>
          <el-input class="input"></el-input>
        </div>
        <div class="conve-line">
          <div class="conve-key">
            <span>乡镇（街道）名称：</span>
          </div>
          <el-input class="input"></el-input>
        </div>
        <div class="conve-line">
          <div class="conve-key">
            <span>村（居）委员名称：</span>
          </div>
          <el-input class="input"></el-input>
        </div>

        <div class="conve-line conve-line1">
          <div class="conve-key">
            <span>建档单位：</span>
          </div>
          <el-input class="input"></el-input>
        </div>

        <div class="conve-line">
          <div class="conve-key">
            <span>建档人：</span>
          </div>
          <el-input class="input"></el-input>
        </div>

        <div class="conve-line">
          <div class="conve-key">
            <span>责任医生：</span>
          </div>
          <el-input class="input"></el-input>
        </div>

        <div class="conve-line">
          <div class="conve-key">
            <span>建档日期：</span>
          </div>
          <el-input class="input input1"></el-input>
          <div class="conve-date">
            <span>年</span>
          </div>
          <el-input class="input input1"></el-input>
          <div class="conve-date">
            <span>月</span>
          </div>
          <el-input class="input input1"></el-input>
          <div class="conve-date">
            <span>日</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      info: null
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 档案封面
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-84442710
      try {
        const id = ''
        const { data: res } = await this.$http.post('/zhyyapi/gldacha/' + id)
        console.log('档案封面', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
      } catch (error) {
        console.log('请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 35px 67px;
  background-color: #081C38;
  box-sizing: border-box;

  .cover-box {
    width: 100%;
    height: 100%;
    padding: 18px 34px;
    background-color: #0C4969;
    box-sizing: border-box;

    .conve-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 50px 0;
      border: 1px solid #01EEFD;
      box-sizing: border-box;

      .conve-title {
        color: #01EEFD;
        font-size: 26px;
      }

      .conve-line {
        display: flex;
        align-items: center;

        .conve-key {
          width: 250px;
          text-align: center;
          color: #01EEFD;
          font-size: 24px;
        }

        .input {
          width: 530px;
          border-bottom: 2px solid #01EEFD;
          box-sizing: border-box;

          /deep/.el-input__inner {
            background-color: transparent;
            border-radius: 0;
            border: none;
          }
        }

        .input1 {
          width: 152px;
        }

        .conve-date {
          color: #01EEFD;
          font-size: 24px;
        }
      }

      .conve-line1 {
        margin-top: 50px;
      }
    }
  }
}
</style>
