<template>
  <div class="tab">
    <div class="head">
      <div class="left">
        <div class="user"></div>
        <span class="text">测试</span>
        <span class="text">女</span>
        <span class="text">38岁</span>
        <span class="text">电话：15888888888</span>
        <span class="text">地址：-</span>
        <span class="label organ">高</span>
        <span class="label green">糖</span>
        <span class="label blue">冠</span>
        <span class="label purple">肿</span>
        <span class="label red">精</span>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <span class="item" :class="{ active: tabIndex === index }" v-for="(item, index) in tabs" :key="index"
          @click="tabClick(index)">{{ item }}</span>
      </div>
      <div class="right">
        <!-- 历次诊疗 -->
        <Item1></Item1>
      </div>
    </div>
  </div>
</template>

<script>
import Item1 from './item1/item1.vue'
export default {
  components: {
    Item1
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['历次诊疗', '检查记录', '检验记录', '门诊处方', '手术记录', '用血记录']),
      radio: '1',
      labelIndex: 0,
      labels: Object.freeze(['门诊摘要', '门诊摘要', '检验记录', '门诊记录', '收费记录'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    labelClick (index) {
      this.labelIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  // background-color: #081C38;
  background-color: #fff;

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 76px;
    padding-left: 28px;
    padding-right: 109px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .user {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #fff;
      }

      .text {
        margin-left: 15px;
        color: #fff;
        font-size: 16px;
      }

      .label {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 50%;
        margin-left: 20px;
        color: #fff;
        font-size: 16px;
      }

      .organ {
        background-color: #E34D04;
      }

      .green {
        background-color: #109607;
      }

      .blue {
        background-color: #075896;
      }

      .purple {
        background-color: #790796;
      }

      .red {
        background-color: #FA2323;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 83px);
    margin-top: 7px;

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 130px;
      height: 100%;
      padding-top: 23px;
      background-color: #0C4969;
      border-radius: 6px;
      box-sizing: border-box;

      .item {
        margin-bottom: 38px;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }

      .active {
        color: #01EEFD;
      }
    }

    .right {
      width: calc(100% - 150px);
      height: 100%;
    }
  }
}
</style>
