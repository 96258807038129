<template>
  <div class="tab-box">
    <div class="services-scroll">
      <div class="title">
        <span>健康体检表</span>
      </div>
      <div class="thead">
        <span>姓名：程霞</span>
        <span>编号：</span>
      </div>
      <div class="tr">
        <span>考核项</span>
        <span>缺项</span>
        <span>完整率</span>
      </div>
      <div class="tr tr2">
        <span class="flex1 boder-right padding">体检日期</span>
        <span class="flex2 boder-right padding">体检日期</span>
        <span class="flex4 padding">责任医师</span>
      </div>
      <div class="tr tr2">
        <span class="flex1 boder-right padding">内容</span>
        <span class="flex6 padding">检查项目</span>
      </div>
      <div class="tr tr2 t4">
        <span class="flex1 boder-right padding t4">症状</span>
        <div class="flex6 t4">
          <el-checkbox-group class="line-paddint-left" v-model="checkList">
            <el-checkbox v-for="(item, index) in checks" :key="index" :label="index + ''">{{ item }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="tr tr1 tr2">
        <span class="flex1 boder-right height padding">一般状况</span>
        <div class="flex6 height1">
          <div class="line">
            <span class="div1 boder-right">体温</span>
            <span class="div1 boder-right"></span>
            <span class="div2 boder-right">脉率</span>
            <span class="div4">
              <el-input></el-input>
              <span class="unit">次/分</span>
            </span>
          </div>
          <div class="line line1">
            <span class="div1 div-height1 boder-right">呼吸频率</span>
            <div class="div1 div-height boder-right">
              <div class="div4">
                <el-input class="input-width"></el-input>
                <span class="unit">次/分</span>
              </div>
            </div>
            <span class="div2 div-height1 boder-right">血压</span>
            <span class="div4 div-height2">
              <div class="div-line">
                <span class="div-line-flex1 div-line-border">左侧</span>
                <div class="div-line-flex5">
                  <el-input class="input-width" value="155"></el-input>
                  <el-input class="input-width" value="73"></el-input>
                  <span class="unit">mmHg</span>
                </div>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 div-line-border">左侧</span>
                <div class="div-line-flex5">
                  <el-input class="input-width" value="0"></el-input>
                  <el-input class="input-width" value="0"></el-input>
                  <span class="unit">mmHg</span>
                </div>
              </div>
            </span>
          </div>
          <div class="line">
            <span class="div1 boder-right">身高</span>
            <div class="div1 boder-right">
              <el-input class="input-width" value="165"></el-input>
              <span class="unit">cm</span>
            </div>
            <span class="div2 boder-right">脉率</span>
            <span class="div4">
              <el-input value="67"></el-input>
              <span class="unit">kg</span>
            </span>
          </div>
          <div class="line">
            <span class="div1 boder-right">腰围</span>
            <div class="div1 boder-right">
              <!-- <el-input class="input-width" value="165"></el-input>
            <span class="unit">cm</span> -->
            </div>
            <span class="div2 boder-right">体质指数</span>
            <span class="div4">
              <el-input value="23.3"></el-input>
            </span>
          </div>
          <div class="line">
            <span class="div2 boder-right">老年人健康状态自我评估</span>
            <div class="div6">
              <el-radio-group class="line-paddint-left" v-model="radio">
                <el-radio v-for="(item, index) in radios" :key="index" :label="index + ''">{{ item }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">老年人自我生活能力评估</span>
            <div class="div6">
              <el-radio-group class="line-paddint-left" v-model="radio">
                <el-radio v-for="(item, index) in radios1" :key="index" :label="index + ''">{{ item }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">老年人认知功能</span>
            <div class="div6">
              <el-radio-group class="line-paddint-left" v-model="radio">
                <el-radio v-for="(item, index) in radios2" :key="index" :label="index + ''">{{ item }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="line line2">
            <span class="div2 boder-right">老年人情感状态</span>
            <div class="div6">
              <el-radio-group class="line-paddint-left" v-model="radio">
                <el-radio v-for="(item, index) in radios3" :key="index" :label="index + ''">{{ item }}</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 tr3">
        <span class="flex1 boder-right height2 padding">生活方式</span>
        <div class="flex6 height2">
          <div class="line line3">
            <span class="div2 div-height3 boder-right">体育锻炼</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">锻炼频率</span>
                <div class="div-line-flex3">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios4" :key="index" :label="index + ''">{{ item }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">每次锻炼时间</span>
                <span class="div-line-flex1 border-right">
                  <el-input></el-input>
                  <span class="unit">分钟</span>
                </span>
                <span class="div-line-flex1 border-right">坚持锻炼时间</span>
                <div class="div-line-flex1">
                  <el-input value=""></el-input>
                </div>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 border-right">锻炼方式</span>
                <div class="div-line-flex3">
                  <el-input value=""></el-input>
                </div>
              </div>
            </div>
          </div>

          <div class="line">
            <span class="div2 boder-right">饮食习惯</span>
            <div class="div6">
              <el-checkbox-group class="line-paddint-left" v-model="checkList">
                <el-checkbox v-for="(item, index) in checks1" :key="index" :label="index + ''">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <div class="line line3">
            <span class="div2 div-height3 boder-right">吸烟情况</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">吸烟情况</span>
                <div class="div-line-flex3">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios5" :key="index" :label="index + ''">{{ item }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">日吸烟量</span>
                <div class="div-line-flex3">
                  <span class="unit">平均</span>
                  <el-input></el-input>
                  <span class="unit">支</span>
                </div>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 border-right">每次锻炼时间</span>
                <span class="div-line-flex1 border-right">
                  <el-input></el-input>
                  <span class="unit">岁</span>
                </span>
                <span class="div-line-flex1 border-right">戒烟年龄</span>
                <div class="div-line-flex1">
                  <el-input></el-input>
                </div>
              </div>

            </div>
          </div>

          <div class="line line4">
            <span class="div2 div-height4 boder-right">饮酒情况</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">饮酒频率</span>
                <div class="div-line-flex3">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios6" :key="index" :label="index + ''">{{ item }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">日饮酒量</span>
                <div class="div-line-flex3">
                  <span class="unit">平均</span>
                  <el-input></el-input>
                  <span class="unit">两</span>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">是否戒酒</span>
                <div class="div-line-flex3">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios7" :key="index" :label="index + ''">{{ item }}</el-radio>
                  </el-radio-group>
                  <span class="unit">戒酒年龄：</span>
                  <el-input></el-input>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">开始饮酒年龄</span>
                <span class="div-line-flex1 border-right">
                  <el-input></el-input>
                  <span class="unit">岁</span>
                </span>
                <span class="div-line-flex1 border-right">近一年是否戒酒</span>
                <div class="div-line-flex1">
                  <el-radio-group v-model="radio">
                    <el-radio v-for="(item, index) in radios8" :key="index" :label="index + ''">{{ item }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 border-right">职业病危害因素接触史</span>
                <div class="div-line-flex3">
                  <el-checkbox-group class="line-paddint-left" v-model="checkList">
                    <el-checkbox v-for="(item, index) in checks2" :key="index" :label="index + ''">{{ item
                    }}</el-checkbox>
                    <el-input></el-input>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>

          <div class="line line4 line2">
            <span class="div2 div-height4 boder-right">职业病危害因素接触史</span>
            <div class="div6 div-flex">
              <div class="div-flex-line">
                <el-radio-group v-model="radio">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <div class="div-flex-line-width">
                  <span>（工种</span>
                  <el-input class="span-input"></el-input>
                  <span>从业</span>
                  <el-input class="span-input"></el-input>
                  <span>年）</span>
                </div>
              </div>
              <div class="div-flex-line">
                <span class="div-flex-line-width1">毒粉种类：</span>
                <span class="div-flex-line-width2">粉尘</span>
                <el-input class="span-input"></el-input>
                <span class="div-flex-line-width2 white-space">防护措施</span>
                <el-radio-group v-model="radio">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
              <div class="div-flex-line">
                <span class="div-flex-line-width1"></span>
                <span class="div-flex-line-width2 white-space">放射物质</span>
                <el-input class="span-input"></el-input>
                <span class="div-flex-line-width2 white-space">防护措施</span>
                <el-radio-group v-model="radio">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
              <div class="div-flex-line">
                <span class="div-flex-line-width1"></span>
                <span class="div-flex-line-width2 white-space">物理因素</span>
                <el-input class="span-input"></el-input>
                <span class="div-flex-line-width2 white-space">防护措施</span>
                <el-radio-group v-model="radio">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
              <div class="div-flex-line">
                <span class="div-flex-line-width1"></span>
                <span class="div-flex-line-width2 white-space">化学物质</span>
                <el-input class="span-input"></el-input>
                <span class="div-flex-line-width2 white-space">防护措施</span>
                <el-radio-group v-model="radio">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
              <div class="div-flex-line">
                <span class="div-flex-line-width1"></span>
                <span class="div-flex-line-width2">其他</span>
                <el-input class="span-input"></el-input>
                <span class="div-flex-line-width2 white-space">防护措施</span>
                <el-radio-group v-model="radio">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="tr tr1 tr2 t5">
        <div class="flex1 t5 t5-border-right">
          <span>脏器功能</span>
        </div>
        <div class="flex6 t5 t61">

          <div class="line line5">
            <span class="div2 boder-right div-height5">口腔</span>
            <span class="div6 div-height5">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">口唇</span>
                <div class="div-line-flex3 div-line-height1">
                  <el-radio-group class="line-paddint-left line-paddint-left1" v-model="radio">
                    <el-radio v-for="(item, index) in radios10" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
              <div class="line">
                <span class="span-width line-paddint-left">齿列</span>
                <el-checkbox label="0">正常</el-checkbox>
              </div>
              <div class="line">
                <el-checkbox label="1" class="line-paddint-left">缺齿</el-checkbox>
                <el-checkbox label="1">全口</el-checkbox>
                <span class="span-width1">左上：</span>
                <el-input class="span-input"></el-input>
                <span class="span-width1">左下：</span>
                <el-input class="span-input"></el-input>
                <span class="span-width1">右上：</span>
                <el-input class="span-input"></el-input>
                <span class="span-width1">右下：</span>
                <el-input class="span-input"></el-input>
              </div>
              <div class="line">
                <el-checkbox label="1" class="line-paddint-left">龋齿</el-checkbox>
                <el-checkbox label="1">全口</el-checkbox>
                <span class="span-width1">左上：</span>
                <el-input class="span-input"></el-input>
                <span class="span-width1">左下：</span>
                <el-input class="span-input"></el-input>
                <span class="span-width1">右上：</span>
                <el-input class="span-input"></el-input>
                <span class="span-width1">右下：</span>
                <el-input class="span-input"></el-input>
              </div>
              <div class="line">
                <el-checkbox label="1" class="line-paddint-left">义齿</el-checkbox>
                <el-checkbox label="1">全口</el-checkbox>
                <span class="span-width1">左上：</span>
                <el-input class="span-input"></el-input>
                <span class="span-width1">左下：</span>
                <el-input class="span-input"></el-input>
                <span class="span-width1">右上：</span>
                <el-input class="span-input"></el-input>
                <span class="span-width1">右下：</span>
                <el-input class="span-input"></el-input>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 border-right">咽部</span>
                <div class="div-line-flex3 div-line-height1">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios11" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </span>
          </div>
          <div class="line">
            <span class="div2 boder-right">视力</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <span class="width-span">左眼：</span>
                <el-input class="span-input"></el-input>
                <span class="width-span">右眼：</span>
                <el-input class="span-input"></el-input>
                <span class="width-span1">(矫正视力：左眼</span>
                <el-input class="span-input"></el-input>
                <span class="width-span">右眼</span>
                <el-input class="span-input"></el-input>
                <span>)</span>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">听力</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios12" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">运动功能</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios13" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">眼底</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios14" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">皮肤</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios15" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">巩膜</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios16" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">淋巴结</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios17" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line line3">
            <span class="div2 div-height3 boder-right">肺</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">桶状胸</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios18" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">呼吸音</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios14" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 border-right">呼吸音</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios19" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">心脏</span>
            <div class="div6 box-flex border1">
              <div class="box-flex line-margin-left">
                <span>心率</span>
                <el-input class="span-input"></el-input>
                <span>分钟</span>
              </div>
              <div class="box-flex line-margin-left line-margin-left1">
                <span>心率</span>
              </div>
              <div class="line line2">
                <el-radio-group v-model="radio">
                  <el-radio v-for="(item, index) in radios20" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line">
                <span class="div-line-flex1 border-right">桶状胸</span>
                <div class="div-line-flex3">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">腹部</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">压痛</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">包块</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">肝大</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">脾大</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line">
                <span class="div-line-flex1 border-right">移动性杂音</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">下肢水肿</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios21" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">足背动脉搏动</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios22" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">肛门指诊</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios23" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">乳腺</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios24" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">妇科</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">外阴</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">阴道</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">宫颈</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">宫体</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line">
                <span class="div-line-flex1 border-right">附件</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">其他</span>
            <div class="div6">
              <el-input></el-input>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t6">
        <div class="flex1 t6 t5-border-right">
          <span>辅助检查</span>
        </div>
        <div class="flex6 t6 t61">
          <div class="line">
            <span class="div2 boder-right">血常规</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <span class="width-span2">血红蛋白</span>
                <el-input class="span-input"></el-input>
                <span class="width-span">g/L</span>
                <span class="width-span2">白细胞</span>
                <el-input class="span-input"></el-input>
                <span class="width-span">10^9/L</span>
                <span class="width-span2">血小板</span>
                <el-input class="span-input"></el-input>
                <span class="width-span">10^9/L</span>
                <span class="width-span">其他</span>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">尿常规</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <span class="width-span2">尿蛋白</span>
                <el-input class="span-input"></el-input>
                <span class="width-span2">尿糖</span>
                <el-input class="span-input"></el-input>
                <span class="width-span2">尿酮体</span>
                <el-input class="span-input"></el-input>
                <span class="width-span2">尿潜血</span>
                <el-input class="span-input"></el-input>
                <span class="width-span">其他</span>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">空腹血糖</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <el-input class="span-input"></el-input>
                <span class="width-span2">mmol/L</span>
                <span class="width-span2">或</span>
                <el-input class="span-input"></el-input>
                <span class="width-span2">mg/L</span>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">心电图</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios14" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">尿微量白蛋白</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <el-input class="span-input"></el-input>
                <span class="width-span2">mg/L</span>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">大便潜血</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios25" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">糖化血红蛋白</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <el-input class="span-input"></el-input>
                <span class="width-span2">%</span>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">乙型肝炎表面抗原</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios25" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">肝功能</span>
            <div class="div6">
              <div class="line line2 line-warp width-span-margin ">
                <div class="width-span-width1">血清谷丙转氨酶</div>
                <el-input class="span-input"></el-input>
                <div>U/L</div>
                <div class="width-span-margin1 width-span-width1">血清谷丙转氨酶</div>
                <el-input class="span-input"></el-input>
                <div>U/L</div>
                <div class="width-span-margin1">白蛋白</div>
                <el-input class="span-input"></el-input>
                <div>g/L</div>
                <div class="width-span-margin1">总胆红素</div>
                <el-input class="span-input"></el-input>
                <div>μmol/L</div>
                <div class="width-span-margin1">结合胆红素</div>
                <el-input class="span-input"></el-input>
                <div>μmol/L</div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">肾功能</span>
            <div class="div6">
              <div class="line line2 line-warp width-span-margin">
                <div>血清肌酐 </div>
                <el-input class="span-input"></el-input>
                <div>μmol/L</div>
                <div class="width-span-margin1">血尿素</div>
                <el-input class="span-input"></el-input>
                <div>mmol/L</div>
                <div class="width-span-margin1">血钾浓度</div>
                <el-input class="span-input"></el-input>
                <div>mmol/L</div>
                <div class="width-span-margin1">血钠浓度</div>
                <el-input class="span-input"></el-input>
                <div>mmol/L</div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">血脂</span>
            <div class="div6">
              <div class="line line2 line-warp width-span-margin">
                <div>总胆固醇</div>
                <el-input class="span-input"></el-input>
                <div>μmol/L</div>
                <div class="width-span-margin1">甘油三酯</div>
                <el-input class="span-input"></el-input>
                <div>mmol/L</div>
                <div class="width-span-margin1 width-span-width">血清低密度脂蛋白胆固醇</div>
                <el-input class="span-input"></el-input>
                <div>mmol/L</div>
                <div class="width-span-margin1 width-span-width">血清高密度脂蛋白胆固醇</div>
                <el-input class="span-input"></el-input>
                <div>mmol/L</div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">胸部X线片</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios14" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">B超</span>
            <div class="div6">
              <div class="div-line border1">
                <span class="div-line-flex1 border-right">腹部B超 </span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios14" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line">
                <span class="div-line-flex1 border-right">其他</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="radio">
                    <el-radio v-for="(item, index) in radios14" :key="index" :label="index + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">宫颈涂片</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios14" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">其他</span>
            <div class="div6">
              <el-input></el-input>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t7">
        <div class="flex1 t7 t5-border-right">
          <span>现存主要健康问题</span>
        </div>
        <div class="flex6 t7 t61">
          <div class="line">
            <span class="div2 boder-right">脑血管疾病</span>
            <div class="div6">
              <div class="line line2 div-line-height1">
                <el-checkbox-group class="line-paddint-left" v-model="checkList">
                  <el-checkbox v-for="(item, index) in checks3" :key="index" :label="index + ''">{{ item }}</el-checkbox>
                </el-checkbox-group>
                <el-input class="span-input line-paddint-left"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">肾脏疾病</span>
            <div class="div6">
              <div class="line line2 div-line-height1">
                <el-checkbox-group class="line-paddint-left" v-model="checkList">
                  <el-checkbox v-for="(item, index) in checks4" :key="index" :label="index + ''">{{ item }}</el-checkbox>
                  <el-input class="span-input line-paddint-left"></el-input>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">心脏疾病</span>
            <div class="div6">
              <div class="line line2 div-line-height1">
                <el-checkbox-group class="line-paddint-left" v-model="checkList">
                  <el-checkbox v-for="(item, index) in checks5" :key="index" :label="index + ''">{{ item }}</el-checkbox>
                  <el-input class="span-input line-paddint-left"></el-input>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">血管疾病</span>
            <div class="div6">
              <div class="line line2 div-line-height1">
                <el-checkbox-group class="line-paddint-left" v-model="checkList">
                  <el-checkbox v-for="(item, index) in checks6" :key="index" :label="index + ''">{{ item }}</el-checkbox>
                  <el-input class="span-input line-paddint-left"></el-input>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">眼部疾病</span>
            <div class="div6">
              <div class="line line2 div-line-height1">
                <el-checkbox-group class="line-paddint-left" v-model="checkList">
                  <el-checkbox v-for="(item, index) in checks7" :key="index" :label="index + ''">{{ item }}</el-checkbox>
                  <el-input class="span-input line-paddint-left"></el-input>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">神经系统疾病</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios26" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">其他系统疾</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="radio" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios26" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t8">
        <div class="flex1 t8 t5-border-right">
          <span>现存主要健康问题</span>
        </div>
        <div class="flex6 t8 t61">
          <div class="tab">
            <div class="tab-left t5-border-right border1">
              <span>住院史</span>
            </div>
            <div class="tab-right">
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right">入/出院日期</div>
                <div class="tab-line-item t5-border-right">原因</div>
                <div class="tab-line-item t5-border-right">医疗机构名称</div>
                <div class="tab-line-item">病案号</div>
              </div>
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item"></div>
              </div>
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item"></div>
              </div>
            </div>
          </div>
          <div class="tab">
            <div class="tab-left t5-border-right">
              <span>住院史</span>
            </div>
            <div class="tab-right">
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right">建/撤床日期</div>
                <div class="tab-line-item t5-border-right">原因</div>
                <div class="tab-line-item t5-border-right">医疗机构名称</div>
                <div class="tab-line-item">病案号</div>
              </div>
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item"></div>
              </div>
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item t5-border-right"></div>
                <div class="tab-line-item"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t9">
        <div class="flex1 t9 t5-border-right">
          <span>主要用药情况</span>
        </div>
        <div class="flex6 t9 t61">
          <div class="tab-right">
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1">药物名称</div>
              <div class="tab-line-item t5-border-right">用法</div>
              <div class="tab-line-item t5-border-right">用量</div>
              <div class="tab-line-item t5-border-right">用药时间</div>
              <div class="tab-line-item tab-line-item1">

                <span>服药依从性</span>
                <el-radio-group v-model="radio">
                  <el-radio v-for="(item, index) in radios27" :key="index" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item tab-line-item1"></div>
            </div>
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item tab-line-item1"></div>
            </div>
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item tab-line-item1"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t9">
        <div class="flex1 t9 t5-border-right">
          <span>非免疫规划预防接种史</span>
        </div>
        <div class="flex6 t9 t61">
          <div class="tab-right">
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1">药物名称</div>
              <div class="tab-line-item t5-border-right">接种日期</div>
              <div class="tab-line-item t5-border-right tab-line-item2">接种机构</div>
            </div>
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item t5-border-right tab-line-item2"></div>
            </div>
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item t5-border-right tab-line-item2"></div>
            </div>
            <div class="tab-line">
              <div class="tab-line-item t5-border-right tab-line-item1"></div>
              <div class="tab-line-item t5-border-right"></div>
              <div class="tab-line-item t5-border-right tab-line-item2"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t9">
        <div class="flex1 t9 t5-border-right">
          <span>健康评价</span>
        </div>
        <div class="flex6 t9 t61">
          <el-radio-group v-model="radio" class="line-paddint-left line-margin-top">
            <el-radio v-for="(item, index) in radios29" :key="index" :label="index + ''">{{ item }}</el-radio>
          </el-radio-group>
          <div class="err">
            <span class="err-width">异常1</span>
            <el-input></el-input>
          </div>
          <div class="err">
            <span class="err-width">异常2</span>
            <el-input></el-input>
          </div>
          <div class="err">
            <span class="err-width">异常3</span>
            <el-input></el-input>
          </div>
          <div class="err">
            <span class="err-width">异常4</span>
            <el-input></el-input>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t9">
        <div class="flex1 t9 t5-border-right">
          <span>健康指导</span>
        </div>
        <div class="flex6 t9 t61">
          <div class="tr-box-left">
            <el-checkbox-group class="line-paddint-left" v-model="checkList">
              <el-checkbox v-for="(item, index) in checks8" :key="index" :label="index + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="tr-box-left">
            <span style="width: 1.625rem;margin-left: 18px;">危险因素控制：</span>
            <el-checkbox>戒烟</el-checkbox>
            <el-checkbox>健康饮酒</el-checkbox>
            <el-checkbox>饮食</el-checkbox>
            <el-checkbox>锻炼</el-checkbox>
            <div class="tr-box-flex">
              <el-checkbox>减体重</el-checkbox>
              <span class="width-span-width2">（目标</span>
              <div class="width-span-width2">
                <el-input></el-input>
              </div>
              <span>kg）</span>
            </div>
            <div class="tr-box-flex">
              <el-checkbox>减体重</el-checkbox>
              <span class="width-span-width2">（目标</span>
              <div class="width-span-width2">
                <el-input></el-input>
              </div>
              <span>kg）</span>
            </div>

          </div>
        </div>
      </div>

      <div class="tr tr2">
        <span class="flex1 boder-right padding">录入机构</span>
        <span class="flex6 padding"></span>
      </div>

      <div class="tr tr2">
        <span class="flex7 boder-right padding">体检日期</span>
        <span class="flex4 boder-right padding"></span>
        <span class="flex1 boder-right padding">录入时间</span>
        <span class="flex4 padding"></span>
      </div>

      <div class="tr tr2">
        <span class="flex1 boder-right padding">更新机构</span>
        <span class="flex6 padding"></span>
      </div>

      <div class="tr tr2 border1">
        <span class="flex7 boder-right padding">体检日期</span>
        <span class="flex4 boder-right padding"></span>
        <span class="flex1 boder-right padding">录入时间</span>
        <span class="flex4 padding"></span>
      </div>
    </div>
    <div class="services-btns">
      <el-button size="mini" class="blue">保存</el-button>
      <el-button size="mini" class="gray" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: ''
        }
      }
    }
  },
  data () {
    return {
      tabs: Object.freeze(['体检表', '中医体质辨识', '老年人评估', '中医健康指导']),
      checkList: ['0'],
      checks: Object.freeze(['无症状', '头疼', '心悸', '胸闷', '胸痛', '慢性咳嗽', '咳痰', '呼吸苦难', '多饮', '多尿', '体重下降', '乳房胀痛', '乏力', '关节肿痛', '视力模糊', '手脚麻木', '尿急', '尿痛', '便秘', '腹泻', '恶心呕吐', '眼花', '耳鸣', '其他']),
      checks1: Object.freeze(['荤素均衡', '荤食为主', '素食为主', '嗜盐', '嗜油', '嗜糖']),
      checks2: Object.freeze(['白酒', '红酒', '啤酒', '黄酒', '其他']),
      checks3: Object.freeze(['未发现', '缺血性卒中', '脑出血', '蛛网膜下腔出血', '短暂性脑缺血发作', '其他']),
      checks4: Object.freeze(['未发现', '糖尿病肾病', '肾功能衰竭', '急性肾炎', '慢性肾炎', '其他']),
      checks5: Object.freeze(['未发现', '心肌梗死', '心绞痛', '冠状动脉血运重建 ', '充血性心力', '心前区疼痛', '其他']),
      checks6: Object.freeze(['未发现', '夹层动脉瘤', '动脉闭塞性疾病', '其他']),
      checks7: Object.freeze(['未发现', '视网膜出血或渗出', '视乳头水肿', '白内障', '其他']),
      checks8: Object.freeze(['纳入慢性病患者健康管理', '建议复查', '建议转诊']),
      radio: '0',
      radios: Object.freeze(['满意', '基本满意', '说不清楚', '不太满意', '不满意']),
      radios1: Object.freeze(['可自理（0-3分）', '轻度依赖（4-8分） ', '中度依赖（9-18分） ', '不能（9-18分） ']),
      radios2: Object.freeze(['粗筛阴性', '粗筛阳性，老年人智力检查']),
      radios3: Object.freeze(['粗筛阴性', '粗筛阳性，老年人抑郁检查']),
      radios4: Object.freeze(['每天', '每周一次以上', '偶尔', '不锻炼']),
      radios5: Object.freeze(['从不吸烟', '已戒烟', '吸烟']),
      radios6: Object.freeze(['从不', '偶尔', '经常', '每天']),
      radios7: Object.freeze(['未戒酒', '已戒酒', '经常']),
      radios8: Object.freeze(['是', '否']),
      radios9: Object.freeze(['无', '有']),
      radios10: Object.freeze(['红润', '苍白', '发敢', '皲裂', '疱诊']),
      radios11: Object.freeze(['无充血', '充血', '淋巴滤泡增长']),
      radios12: Object.freeze(['听见', '听不清或无法听见']),
      radios13: Object.freeze(['可顺利完成', '无法独立完成任意一个动作']),
      radios14: Object.freeze(['正常', '异常']),
      radios15: Object.freeze(['正常', '潮红', '苍白', '发绀', '黄染', '色素沉着', '其他']),
      radios16: Object.freeze(['正常', '黄染', '充血', '其他']),
      radios17: Object.freeze(['未触及', '锁骨上', '腋窝', '其他']),
      radios18: Object.freeze(['否', '是']),
      radios19: Object.freeze(['无', '干罗音', '湿罗音', '其他']),
      radios20: Object.freeze(['齐', '不齐', '绝对不齐']),
      radios21: Object.freeze(['无', '单侧', '双侧不对称', '双侧对称']),
      radios22: Object.freeze(['未触及', '触及双侧堆成', '触及左侧弱或消失', '触及右侧弱或消失']),
      radios23: Object.freeze(['未见异常', '触痛', '包块', '前列腺异常', '其他']),
      radios24: Object.freeze(['未见异常', '乳房切除', '异常泌乳', '乳腺包块', '其他']),
      radios25: Object.freeze(['阴性', '阳性']),
      radios26: Object.freeze(['未发现', '有']),
      radios27: Object.freeze(['规律', '间断', '不服药']),
      radios28: Object.freeze(['无', '有']),
      radios29: Object.freeze(['体检无异常', '有异常']),
      info: {}
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 体检记录--查询单条信息
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97905103
      try {
        const obj = {
          tmcode: this.params.tmcode,
          sfzh: this.params.sfzh
        }
        const { data: res } = await this.$http.post('/api/jkct/app/findtjinfo', obj)
        console.log('体检记录--查询单条信息', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
      } catch (error) {
        console.log('体检记录--查询单条信息请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.tab-box {
  width: 100%;
  height: 100%;

  .services-btns {
    .blue {
      border-radius: 0;
      border: none;
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      color: #fff;
    }

    .gray {
      border-radius: 0;
      border: none;
      background: #33465C;
      color: #fff;
    }
  }

  .services-scroll {
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .title {
    width: 100%;
    height: 30px;
    text-align: center;
    color: #01EEFD;
    font-size: 22px;
  }

  .thead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: 46px;
    color: #01EEFD;
    font-size: 22px;
  }

  .tr {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 11px;
    box-sizing: border-box;
    border: 1px solid #01EEFD;
    border-bottom: none;
    color: #01EEFD;
    font-size: 14px;

    .line-paddint-left {
      padding-left: 20px;
      box-sizing: border-box;
    }

    span {
      height: 50px;
      line-height: 50px;
      width: 33%;
    }

    .flex1 {
      flex: 1;
    }

    .flex2 {
      flex: 2;
    }

    .flex4 {
      flex: 4;
    }

    .flex6 {
      flex: 6;
    }

    .flex7 {
      flex: 1.5;
    }

    .boder-right {
      border-right: 1px solid #01EEFD;
      box-sizing: border-box;
    }

    .padding {
      text-align: center;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-checkbox__label, /deep/.el-radio__label {
      font-size: 14px;
    }

    /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
    /deep/.el-radio__input.is-checked .el-radio__inner {
      background-color: #01EEFD;
      border-color: #01EEFD;
    }

    .el-checkbox,
    .el-radio {
      color: #fff;
    }

    /deep/.el-checkbox__input.is-checked+.el-checkbox__label,
    /deep/.el-radio__input.is-checked+.el-radio__label {
      color: #01EEFD;
    }

  }

  .tr1 {
    height: 450px;

    .height {
      height: 450px;
      line-height: 450px;
    }

    .height1 {
      height: 450px;
    }

    .height2 {
      height: 850px;
      line-height: 850px;
    }

    .width1 {
      flex: 9;
    }

    .line {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      border-bottom: .0125rem solid #01EEFD;
      box-sizing: border-box;

      .span-width {
        width: 60px;
        margin-right: 10px;
      }

      .span-width1 {
        width: 60px;
        text-align: right;
        color: #fff;
      }

      .span-input {
        overflow: hidden;
        width: 1rem !important;
        height: 30px !important;
        line-height: 30px !important;
        border-bottom: 1px #fff solid;
      }

      .span-color {
        color: #fff;
      }

      .span-width2 {
        width: 128px;
      }

      .line-paddint-left {
        margin-left: 20px;
      }

      .div1 {
        flex: 1;
        height: 50px;
        text-align: center;
      }

      .div2 {
        flex: 2;
        text-align: center;
      }

      .div4 {
        flex: 4;
      }

      .div6 {
        flex: 6;
      }

      .div-flex {
        display: flex;
        flex-direction: column;
      }

      .div-height {
        display: flex;
        align-items: center;
        height: 100px;
      }

      .div-height1 {
        height: 100px;
        line-height: 100px;
      }

      .div-height2 {
        height: 100px;
      }

      .div-height3 {
        height: 150px;
      }

      .div-height4 {
        height: 250px;
      }

      .div-height5 {
        height: 300px;
      }

      .el-input {
        width: 140px;

        height: 50px;
      }

      .input-width {
        width: 60px;
      }

      /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        color: #01EEFD;
      }

      .unit {
        margin-left: .0625rem;
      }

      .div-line {
        display: flex;
        align-items: center;
        height: 50px;

        .div-line-flex1 {
          flex: 1;
          text-align: center;
        }

        .div-line-flex3 {
          flex: 3;
        }

        .div-line-flex5 {
          flex: 5;
        }

        .div-line-border {
          border-right: .0125rem solid #01EEFD;
          box-sizing: border-box;
        }

        .border-right {
          border-right: .0125rem solid #01EEFD;
          box-sizing: border-box;
        }

        .div-line-width {
          display: flex;
          align-items: center;
          width: 5rem;
          margin-bottom: .05rem;
        }
      }

      .div-border {
        border-bottom: .0125rem solid #01EEFD;
        box-sizing: border-box;
      }

      .div-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 136px;

        .el-input {
          width: 60px;
          background-color: red;
        }

        .div-box-line {
          display: flex;
          align-items: center;
          width: 80%;
          height: 30px;
          border: 1px red solid;

          span {
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }

    .line1 {
      height: 100px;
    }

    .line2 {
      border: none;
    }

    .line3 {
      height: 150px;
    }

    .line4 {
      height: 250px;
    }

    .line5 {
      height: 300px;
    }
  }

  .tr2 {
    padding: 0;
  }

  .tr3 {
    height: 850px;
  }

  .t4 {
    height: 80px !important;
  }

  .t5 {
    text-align: center;
    height: 1650px !important;
  }

  .t6 {
    text-align: center;
    height: 800px !important;
  }

  .t7 {
    text-align: center;
    height: 350px;
  }

  .t8 {
    text-align: center;
    height: 300px;
  }

  .t9 {
    text-align: center;
    height: 200px;
  }

  .t61 {
    text-align: left;
  }

  .box-flex {
    display: flex;
    align-items: center;
  }

  .line-margin-left {
    width: 200px;
    margin-left: 40px;
  }

  .line-margin-left1 {
    justify-content: flex-end;
    text-align: right;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .border-none {
    border: none !important;
  }

  .border1 {
    border-bottom: .0125rem solid #01EEFD !important;
    box-sizing: border-box;
  }

  .t5-border-right {
    border-right: .0125rem solid #01EEFD;
  }

  .div-line-flex4 {
    height: 50px !important;
  }

  .div-line-height1 {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .width-span {
    width: 60px !important;
    text-align: center;
  }

  .width-span1 {
    width: 120px !important;
    margin-left: 40px;
  }

  .width-span2 {
    width: 70px !important;
  }

  .width-span-margin {
    margin-left: 32px;
  }

  .width-span-margin1 {
    margin-left: 4px;
  }

  .width-span-width {
    width: 120px;
  }

  .width-span-width1 {
    width: 100px;
  }

  .width-span-width2 {
    width: 100px;
    white-space: nowrap;
  }

  .white-space {
    white-space: nowrap;
  }

  // .el-input {
  //   width: 32px !important;
  //   height: .375rem !important;
  //   border-bottom: .0125rem solid #fff;
  //   box-sizing: border-box;
  // }

  .line-warp {
    font-size: 10px !important;
  }

  .tab {
    display: flex;
    width: 100%;
    height: 150px;
  }

  .tab-left {
    flex: 2;
    text-align: center;
  }

  .tab-right {
    flex: 6;
  }

  .tab-line {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
  }

  .tab-line-item {
    flex: 1;
    text-align: center;
    height: 50px;
    line-height: 50px;
  }

  .tab-line-item1 {
    flex: 1.51;
  }

  .tab-line-item2 {
    flex: 3.51;
  }

  .div-flex-line {
    display: flex;
    align-items: center;
    margin-left: 18px;
    height: 40px !important;
  }

  .div-flex-line-width {
    display: flex;
    align-items: center;
    width: 320px;
  }

  .div-flex-line-width1 {
    width: 88px !important;
    text-align-last: justify;
    white-space: nowrap;
  }

  .div-flex-line-width2 {
    width: 70px !important;
    text-align-last: justify;
    margin-left: 5px;
    margin-right: 6px;
  }

  .line-margin-top {
    margin-top: 10px;
  }

  .err {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 29px;

    .err-width {
      width: 60px;
      white-space: nowrap;
    }

    .el-input {
      width: 100%;
      margin: 0;
    }

    /deep/.el-input__inner {
      width: 100px;
      margin: 0;
      height: 20px;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: .0125rem solid #fff;
    }
  }

  .tr-box-left {
    display: flex;
    align-items: center;
    margin-top: 32px;

    /deep/.el-input__inner {
      width: 100px;
      margin: 0;
      height: 20px;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: .0125rem solid #fff;
    }

    .tr-box-flex {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
}
</style>
