<template>
  <div class="services">
    <div class="open" @click="open">
      <span class="el-icon-s-operation"></span>
    </div>
    <div class="test-position">
      <div class="test-position-item">
        <span>身高体重</span>
        <span class="el-icon-check"></span>
      </div>
      <div class="test-position-item">
        <span>血压</span>
        <span class="el-icon-check"></span>
      </div>
      <div class="test-position-item">
        <span>采血</span>
        <span class="el-icon-check"></span>
      </div>
      <div class="test-position-item">
        <span>B超</span>
        <span class="el-icon-check"></span>
      </div>
      <div class="test-position-item">
        <span>心电图</span>
        <span class="el-icon-check"></span>
      </div>
      <div class="test-position-item">
        <span>生化</span>
        <span class="el-icon-check"></span>
      </div>
      <div class="test-position-item">
        <span>血常规</span>
        <span class="el-icon-check"></span>
      </div>
      <div class="test-position-item">
        <span>尿常规</span>
        <span class="el-icon-check"></span>
      </div>
    </div>
    <div class="userinfo" v-if="!isOpen">
      <div class="content">
        <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" size="small" @keyup.enter.native="handleEnterKey" v-model="sxtj">
        </el-input>
        <div class="scroll" v-loading="loading">
          <div class="scroll-item" v-for="(item, index) in list" :key="index" @click="tagClick(item)">
            <div class="scroll-item-line">
              <div class="scroll-text">
                <span>姓名：{{ item.name }} {{ item.age }} {{ item.xb === '1' ? '男' : '女' }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text">
                <span>体检编号：{{ item.tmcode || '' }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>人群分类：{{ item.nametags || '' }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>所属机构:{{ item.tjjgmc || '' }}</span>
              </div>
            </div>
            <div class="scroll-item-line">
              <div class="scroll-text scroll-text1">
                <span>身份证号:{{ item.sfzh || '' }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <!-- hide-on-single-page -->
          <el-pagination small @size-change="handleSizeChange" :pager-count="5" @current-change="handleCurrentChange" :current-page="query1.pageNum"
            background :page-size="query1.pageSize" layout="prev, pager, next" :total="query1.total" hide-on-single-page>
          </el-pagination>
          <div class="total" v-if="query1.total > 0">
            <span>共{{ query1.total }}条</span>
          </div>
        </div>
      </div>

    </div>
    <div class="services-boxs" :class="{ 'services-boxs1': isOpen }">

      <el-menu :default-active="tabIndex" mode="horizontal" @select="tabClick">
        <el-menu-item index="4">个人基本信息</el-menu-item>
        <el-menu-item index="0">体检表</el-menu-item>
        <el-menu-item index="1">中医体质辨识</el-menu-item>
        <el-menu-item index="2">老年人评估</el-menu-item>
        <el-menu-item index="3">中医健康指导</el-menu-item>
      </el-menu>
      <div class="services-tab-box">
        <Tab1 v-if="tabIndex === '0'" ref="tab1" :params="params" @close="close"></Tab1>
        <Tab2 v-if="tabIndex === '1'" :params="params" @close="close"></Tab2>
        <Tab3 v-if="tabIndex === '2'" :params="params" @close="close"></Tab3>
        <Tab4 v-if="tabIndex === '3'" @close="close"></Tab4>
        <Tab5 v-if="tabIndex === '4'" @close="close"></Tab5>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tab1 from './tab1/tab1.vue'
import Tab2 from './tab2.vue'
import Tab3 from './tab3.vue'
import Tab4 from './tab4.vue'
import Tab5 from './tab5.vue'
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: '',
          jktjid: ''
        }
      }
    }
  },
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5
  },
  data () {
    return {
      tabIndex: '0',
      sxtj: '',
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      list: [],
      loading: false,
      isOpen: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
    this.source = this.$http.CancelToken.source()
  },
  methods: {
    open () {
      this.isOpen = !this.isOpen
    },
    tagClick (item) {
      const obj = {
        tmcode: item.tmcode,
        sfzh: item.sfzh
      }
      this.$refs.tab1.getInfo(obj)
    },
    tabClick (index) {
      this.tabIndex = index
    },
    handleEnterKey () {
      console.log('回车')
      this.getList()
    },
    // 体检记录
    async getList () {
      this.loading = true
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97904037
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: '10',
          ssjgbh: this.loginInfo.jgbh,
          xzjdmcid: this.xzjdmcid,
          cwhmcid: this.cwhmcid,
          flag: '',
          sfzh: this.sfzh,
          sxtj: this.sxtj
        }
        const { data: res } = await this.$http.get('/ykdjktjbs', { params: obj, headers: { token: this.loginInfo.token } })
        console.log('体检记录', res)
        this.loading = false
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list = res.data
        this.query1.total = res.recordsTotal
      } catch (error) {
        console.log('体检记录请求失败', error)
        this.loading = false
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.services {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  background-color: #fff;
  box-sizing: border-box;

  .test-position {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 8;
    display: flex;
    align-items: center;
    height: 60px;

    .test-position-item {
      color: #000;
      font-size: 16px;
    }
  }

  .open {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 20px;
    background-color: #25A0FF;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }

  .userinfo {
    width: 250px;
    height: 100%;
    background-color: #25A0FF;
    box-sizing: border-box;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-top: 20px;
      border-radius: 6px;
      box-sizing: border-box;

      .el-input {
        width: 95%;

        /deep/.el-input__inner {
          background-color: #fff;
          border-radius: 6px;
          border: 1px solid #1F72D3;
        }

        /deep/.el-input__inner::placeholder {
          color: #999999;
        }

        /deep/.el-input__suffix {
          color: #999999;
        }
      }

      .scroll {
        overflow-y: scroll;
        width: calc(100% - 5px);
        height: calc(100% - 110px);
        margin-top: 10px;
        margin-left: 5px;

        &::-webkit-scrollbar {
          width: 0;
        }

        .scroll-title {
          position: sticky;
          top: 0;
          background-color: rgba(29, 64, 112);
        }

        .scroll-title,
        .scroll-line {
          display: flex;
          align-items: center;
          width: 1300px;
          height: 40px;
          color: #01EEFD;
          font-size: 16px;
          cursor: pointer;

          .flex {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 70px;
            text-align: center;
          }

          .flex1 {
            width: 100px;
          }

          .flex2 {
            width: 180px;
          }

          .flex3 {
            width: 300px;
          }
        }

        .scroll-line {
          font-size: 14px;
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          background-color: transparent;
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(255, 255, 255, .4);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-corner {
          background-color: transparent;
        }

        .scroll-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          height: 136px;
          margin-bottom: 10px;
          padding: 10px 2px;
          background: #fff;
          box-sizing: border-box;
          cursor: pointer;

          .scroll-item-line {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .scroll-text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              color: #333333;
              font-size: 14px;
            }

            .scroll-text1 {
              width: 100%;
            }
          }
        }
      }

      .pagination {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;

        ::v-deep.el-pagination__total,
        ::v-deep.el-pagination__jump {
          color: #fff;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }

        .total {
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          font-size: 14px;
        }
      }
    }

  }

  .services-boxs {
    width: calc(100% - 260px);
    height: 100%;
    margin: 0 5px;
  }

  .services-boxs1 {
    width: 100%;
  }

  .services-tab {
    display: flex;
    width: 100%;
    height: 50px;

    .services-item {
      height: 40px;
      line-height: 40px;
      margin-right: 20px;
      padding: 0 20px;
      background-color: #D5D5D5;
      color: #fff;
      font-size: 22px;
      cursor: pointer;
    }

    .services-item-active {
      background-color: #409EFF;
    }
  }

  /deep/.el-menu-item {
    font-size: 22px;
  }

  .services-tab-box {
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 10px;
  }
}
</style>
