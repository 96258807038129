<template>
  <div class="tab">
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="0">中医指导</el-menu-item>
      <el-menu-item index="1">中医评估</el-menu-item>
    </el-menu>
    <div class="tab-son">
      <Son1 v-if="activeIndex === '0'"></Son1>
      <Son2 v-if="activeIndex === '1'"></Son2>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Son1 from './son1/son1.vue'
import Son2 from './son2/son2.vue'
export default {
  components: {
    Son1,
    Son2
  },
  data () {
    return {
      activeIndex: '0'
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  methods: {
    handleSelect (key) {
      this.activeIndex = key
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  box-sizing: border-box;

  .tab-son {
    width: 100%;
    height: calc(100% - 70px);
  }
}
</style>
