<template>
  <div class="write">
    <div class="content-box-search">
      <div class="left">
        <el-select v-model="value" placeholder="权限归属">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value" placeholder="权限类型">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value" placeholder="医护姓名">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value" placeholder="状态">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button size="small" class="blue">搜索</el-button>
        <el-button size="small" class="gray">重置</el-button>
      </div>
      <div class="right">
        <div class="add" @click="openAddDoctor">
          <span class="el-icon-plus"></span>
        </div>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border>
        <el-table-column prop="name" label="用户名">
        </el-table-column>
        <el-table-column prop="date" label="姓名">
        </el-table-column>
        <el-table-column prop="date" label="组织">
        </el-table-column>
        <el-table-column prop="type" label="职业">
        </el-table-column>
        <el-table-column prop="type1" label="职称">
        </el-table-column>
        <el-table-column prop="type2" label="创建时间">
        </el-table-column>
        <el-table-column prop="type2" label="状态">
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template>
            <!-- slot-scope="scope" -->
            <div class="flex">
              <span>重置密码</span>
              <span @click="openAddDoctor">编辑</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <!-- 添加医护 -->
    <AddDoctor v-if="showAddDoctor" @close="closeAddDoctor" @closePopup="closePopup"></AddDoctor>
    <DoctorRunPopup v-if="showDoctorRunPopup" @close="closeDoctorRunPopup"></DoctorRunPopup>
  </div>
</template>

<script>
import AddDoctor from './addDoctor.vue'
import DoctorRunPopup from './doctorRunPopup.vue'
export default {
  components: {
    AddDoctor,
    DoctorRunPopup
  },
  data () {
    return {
      tableData: [{
        name: '111'
      },
      { name: '111' },
      { name: '111' },
      { name: '111' },
      { name: '111' },
      { name: '111' },
      { name: '111' },
      { name: '111' },
      { name: '111' },
      { name: '111' },
      { name: '111' }
      ],
      value: '',
      options: [{
        value: '',
        label: ''
      }],
      showAddDoctor: false,
      showDoctorRunPopup: false
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openAddDoctor () {
      this.showAddDoctor = true
    },
    closeAddDoctor () {
      this.showAddDoctor = false
    },
    openDoctorRunPopup () {
      this.showDoctorRunPopup = true
    },
    closeDoctorRunPopup () {
      this.showDoctorRunPopup = false
    },
    closePopup (index) {
      this.$emit('closePopup', index)
      this.closeAddDoctor()
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  position: relative;
  width: 100%;
  height: 100%;
  border-top: 1px solid #000;
  box-sizing: border-box;

  .content-box-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .left {
      display: flex;
      align-items: center;

      .search-input {
        width: 260px;
      }

      .el-select,
      .el-input {
        width: 200px;
        margin-left: 10px;
      }

      .date {
        margin-left: 10px;
      }

      .blue,
      .gray {
        margin-left: 40px;
        background-color: #04B4D0;
        color: #fff;
        border: none;
        border-radius: 0;
      }

      .gray {
        margin-right: 10px;
        background-color: #284867;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .add {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        background-color: #04B4D0;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
      }

      .blue {
        margin-right: 40px;
        background-color: #04B4D0;
        color: #fff;
        border: none;
        border-radius: 0;
      }
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 200px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    color: #0e75d4;
    font-size: 15px;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }
}
</style>
