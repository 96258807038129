<template>
  <div class="popup">
    <el-form ref="form">
      <div class="form">
        <div class="tip">编辑干预</div>
        <div class="title">基本信息</div>
        <div class="info">
          <div class="item">
            <div class="key">患者姓名</div>
            <el-input size="small" v-model="info.name"></el-input>
          </div>
          <div class="item">
            <div class="key">性别</div>
            <el-radio-group v-model="info.xb">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
              <el-radio label="3">保密</el-radio>
            </el-radio-group>
          </div>
          <div class="item">
            <div class="key">年龄</div>
            <el-input size="small" v-model="info.age"></el-input>
          </div>
        </div>
        <div class="title title1">疾病史</div>
        <div class="info">
          <div class="item item4">
            <div class="key">疾病</div>
            <el-checkbox-group class="width2" v-model="info.jbsjb" @change="jbChange">
              <el-checkbox v-for="(item, index) in checkList" :key="index" :label="index + ''"
                :disabled="index > 0 && info.jbsjb.indexOf('0') > -1">{{ item }}</el-checkbox>
              <el-input size="small" v-show="info.jbsjb.indexOf('14') > -1" v-model="info.jbDes"></el-input>
            </el-checkbox-group>
          </div>
          <div class="item item4">
            <div class="key">手术</div>
            <el-checkbox-group class="width" v-model="info.jbsshs" @change="ssChange">
              <el-checkbox class="width-item" name="type" v-for="(item, index) in checkList1" :key="index"
                :label="index + ''" :disabled="index > 0 && info.jbsshs.indexOf('0') > -1">{{ item }}</el-checkbox>
            </el-checkbox-group>
            <div class="show">
              <div class="score" :class="{ 'score-hide': index === 0 || info.jbsshs.indexOf(index + '') === -1 }"
                v-for="(item, index) in checkList1" :key="index">
                <span>手术名称</span>
                <el-input size="small" v-model="info['ssName' + index]" />
                <span>确诊时间</span>
                <el-date-picker size="small" type="date" placeholder="选择日期" v-model="info['ssDate' + index]">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="title title1 title2">遗传史</div>
        <div class="info">
          <div class="item item4">
            <div class="key"></div>
            <el-checkbox-group class="width2" v-model="info.jbsycs" @change="ycsChange">
              <el-checkbox v-for="(item, index) in checkList2" :key="index" :label="index + ''"
                :disabled="index > 0 && info.jbsycs.indexOf('0') > -1">{{ item }}</el-checkbox>
              <el-input size="small" v-show="info.jbsycs.indexOf('7') > -1" v-model="info.ycsDes" />
            </el-checkbox-group>
          </div>
        </div>
        <div class="title title1 title2">家族史</div>
        <div class="info">
          <div class="item item4">
            <div class="key">父亲</div>
            <el-checkbox-group class="width2" v-model="info.fq" @change="fqChange">
              <el-checkbox v-for="(item, index) in checkList" :key="index" :label="index + 1 + ''"
                :disabled="index > 0 && info.fq.indexOf('1') > -1">{{ item }}</el-checkbox>
              <el-input size="small" v-show="info.fq.indexOf('15') > -1" v-model="info.fqDes" />
            </el-checkbox-group>
          </div>
          <div class="item item4">
            <div class="key">母亲</div>
            <el-checkbox-group class="width2" v-model="info.mq" @change="mqChange">
              <el-checkbox v-for="(item, index) in checkList" :key="index" :label="index + 1 + ''"
                :disabled="index > 0 && info.mq.indexOf('1') > -1">{{ item }}</el-checkbox>
              <el-input size="small" v-show="info.mq.indexOf('15') > -1" v-model="info.mqDes" />
            </el-checkbox-group>
          </div>
          <div class="item item4">
            <div class="key">兄弟姐妹</div>
            <el-checkbox-group class="width2" v-model="info.xdjm" @change="xdjmChange">
              <el-checkbox v-for="(item, index) in checkList" :key="index" :label="index + 1 + ''"
                :disabled="index > 0 && info.xdjm.indexOf('1') > -1">{{ item }}</el-checkbox>
              <el-input size="small" v-show="info.xdjm.indexOf('15') > -1" v-model="info.xdjmDes" />
            </el-checkbox-group>
          </div>
          <div class="item item4">
            <div class="key">子女</div>
            <el-checkbox-group class="width2" v-model="info.zn" @change="znChange">
              <el-checkbox v-for="(item, index) in checkList" :key="index" :label="index + 1 + ''"
                :disabled="index > 0 && info.zn.indexOf('1') > -1">{{ item }}</el-checkbox>
              <el-input size="small" v-show="info.zn.indexOf('15') > -1" v-model="info.znDes" />
            </el-checkbox-group>
          </div>
        </div>
        <div class="title title1 title2">饮酒情况</div>
        <div class="info">
          <div class="item item4">
            <div class="key"></div>
            <el-radio-group v-model="info.yjqk">
              <el-radio v-for="(item, index) in checkList3" :key="index" :label="index + ''">{{ item }}</el-radio>
            </el-radio-group>
            <div class="key3 key4">开始饮酒年龄</div>
            <el-input size="small" :disabled="info.yjqk === '0'" v-model="info.yjqknl"></el-input>
            <div class="key3">岁</div>
          </div>
        </div>
        <div class="title title1 title2">吸烟情况</div>
        <div class="info">
          <div class="item item4">
            <div class="key"></div>
            <el-radio-group v-model="info.xyqk">
              <el-radio v-for="(item, index) in checkList4" :key="index" :label="index + ''">{{ item }}</el-radio>
            </el-radio-group>
            <div class="key3 key4">开始吸烟年龄</div>
            <el-input size="small" :disabled="info.xyqk === '0'" v-model="info.xyqknl"></el-input>
            <div class="key3">岁</div>
          </div>
        </div>
        <div class="title title1 title2">运动情况</div>
        <div class="info">
          <div class="item item4">
            <div class="key"></div>
            <el-radio-group v-model="info.ydqk">
              <el-radio v-for="(item, index) in checkList5" :key="index" :label="index + ''">{{ item }}</el-radio>
            </el-radio-group>
            <div class="key3 key4">每次锻炼时间</div>
            <el-input size="small" :disabled="info.ydqk === '3'" v-model="info.ydqkmcdlsj"></el-input>
            <div class="key3">分钟</div>
            <div class="key3 key4">锻炼方式</div>
            <el-input size="small" :disabled="info.ydqk === '3'" v-model="info.ydqkdlfs"></el-input>
          </div>
        </div>
        <div class="title title1 title2">辅助检查</div>
        <div class="info">
          <div class="item item1">
            <div class="font">血压：</div>
            <el-input size="small" placeholder="请输入..." v-model="info.fzjcxyg"></el-input>
            <div class="font font1">mmHg/</div>
            <el-input size="small" placeholder="请输入..." v-model="info.fzjcxyd"></el-input>
            <div class="font">mmHg/</div>
          </div>
          <div class="item">
            <div class="font">血糖：</div>
            <el-input size="small" placeholder="请输入..." v-model="info.fzjcxt"></el-input>
            <div class="font">mmol/L</div>
          </div>
          <div class="item">
            <div class="font">总胆固醇：</div>
            <el-input size="small" placeholder="请输入..." v-model="info.fzjczdgc"></el-input>
            <div class="font">mmol/L</div>
          </div>
          <div class="item">
            <div class="font">甘油三酯：</div>
            <el-input size="small" placeholder="请输入..." v-model="info.fzjcgysz"></el-input>
            <div class="font">mmol/L</div>
          </div>
        </div>
        <div class="title title1 title2">综合干预</div>
        <div class="select">
          <el-button type="primary" @click="openSelectModule">选择干预模板</el-button>
          <el-button type="primary">添加干预列表</el-button>
        </div>
        <div class="table">
          <el-table :data="info.zhgylist" height="200" border>
            <el-table-column prop="zhgygylx" label="干预类型">
            </el-table-column>
            <el-table-column prop="zhgygycs" label="干预措施">
            </el-table-column>
            <el-table-column prop="zhgyyf" label="用法">
            </el-table-column>
            <el-table-column prop="zhgypc" label="频次">
            </el-table-column>
            <el-table-column prop="zhgyyl" label="用量">
            </el-table-column>
            <el-table-column prop="zhgykssj" label="开始时间">
            </el-table-column>
            <el-table-column prop="zhgyjssj" label="结束时间">
            </el-table-column>
            <el-table-column prop="zhgysfjcx" label="是否检测项">
            </el-table-column>
            <el-table-column prop="zhgybz" label="备注">
            </el-table-column>
            <el-table-column prop="address" label="操作" width="100">
              <el-button type="danger" size="small">删除</el-button>
            </el-table-column>
          </el-table>
        </div>
        <div class="textarea-title">医生建议</div>
        <el-input class="textarea" type="textarea" :rows="2" placeholder="请输入内容" v-model="info.zhgyysjy">
        </el-input>
        <div class="title title1 title2">
          <span class="icon"></span>
          <span>饮食处方</span>
        </div>
        <div class="textarea-title">
          <span class="margin">饮食方案</span>
          <div @click="switchTab(2)" class="textarea-select">
            <span>请选择方案</span>
            <span class="icon">
              <span class="el-icon-arrow-down"></span>
            </span>
          </div>
        </div>
        <el-input class="textarea" type="textarea" :rows="2" placeholder="饮食方案" v-model="info.yscfysfn"></el-input>
        <div class="textarea-title">
          <span class="margin">饮食处方</span>
        </div>
        <el-input class="textarea" type="textarea" :rows="2" placeholder="饮食处方" v-model="info.yscf"></el-input>

        <div class="title title1 title2">
          <span class="icon"></span>
          <span>运动处方</span>
        </div>
        <div class="textarea-title">
          <span class="margin">运动方案</span>
          <div @click="switchTab(3)" class="textarea-select">
            <span>请选择运动方案</span>
            <span class="icon">
              <span class="el-icon-arrow-down"></span>
            </span>
          </div>
        </div>
        <el-input class="textarea" type="textarea" :rows="2" placeholder="运动方案" v-model="info.ydfa"></el-input>
        <div class="textarea-title">
          <span class="margin">运动处方</span>
        </div>
        <el-input class="textarea" type="textarea" :rows="2" placeholder="运动处方" v-model="info.ydcfs"></el-input>

        <div class="title title1 title2">
          <span class="icon"></span>
          <span>心理处方</span>
        </div>
        <div class="textarea-title">
          <span class="margin">心理方案</span>
          <div @click="switchTab(4)" class="textarea-select">
            <span>请选择心理方案</span>
            <span class="icon">
              <span class="el-icon-arrow-down"></span>
            </span>
          </div>
        </div>
        <el-input class="textarea" type="textarea" :rows="2" placeholder="心理方案" v-model="info.xlfa"></el-input>
        <div class="textarea-title">
          <span class="margin">心理处方</span>
        </div>
        <el-input class="textarea" type="textarea" :rows="2" placeholder="心理处方" v-model="info.xlcfs"></el-input>
      </div>
    </el-form>
    <div class="bts">
      <el-button type="primary" size="small" @click="submit">保存</el-button>
      <el-button size="small" @click="closePopup">返回</el-button>
    </div>
    <!-- 选择干预模块 -->
    <SelectModule v-if="showSelectModule" @closePopup="closeSelectModule"></SelectModule>
    <!-- 营养处方 -->
    <Nutrition v-if="showNutrition" @selectInfo="selectInfo" @closePopup="closeNutrition"></Nutrition>
    <!-- 理疗处方 -->
    <Physio v-if="showPhysio" @selectInfo="selectInfo" @closePopup="closePhysio"></Physio>
    <!-- 饮食处方 -->
    <Diet v-if="showDiet" @selectInfo="selectInfo" @closePopup="closeDiet"></Diet>
    <!-- 运动处方 -->
    <Motion v-if="showMotion" @selectInfo="selectInfo" @closePopup="closeMotion"></Motion>
    <!-- 心理处方 -->
    <Psychology v-if="showPsychology" @selectInfo="selectInfo" @closePopup="closePsychology"></Psychology>
  </div>
</template>

<script>
import SelectModule from './selectModule.vue'
import Nutrition from '../prescriptionRun/nutritionPopup.vue'
import Physio from '../prescriptionRun/physioPopup.vue'
import Diet from '../prescriptionRun/dietPopup.vue'
import Motion from '../prescriptionRun/motionPopup.vue'
import Psychology from '../prescriptionRun/psychologyPopup.vue'
export default {
  components: {
    Nutrition,
    Physio,
    Diet,
    Motion,
    Psychology,
    SelectModule
  },
  data () {
    return {
      tableData: [{}],
      region: '',
      radio: 0,
      tabIndex: -1,
      list: ['营养处方', '理疗处方', '饮食处方', '运动处方', '心理处方'],
      checkValue: ['0'],
      checkList: Object.freeze(['无', '高血压', '糖尿病', '冠心病', '慢性阻塞性肺疾病', '恶心肿瘤', '脑卒中', '重性精神疾病', '结核病', '脂肪肝', '先天畸形', '骨质疏松', '高尿酸证', '高脂血症', '其他']),
      checkValue1: ['0', '1', '2'],
      checkList1: Object.freeze(['无', '心脏', '胸部', '肝胆', '肾脏', '脊柱', '眼', '口', '耳鼻咽喉', '其他']),
      checkValue2: ['0'],
      checkList2: Object.freeze(['无', '高血压', '糖尿病', '心脑血管疾病', '骨质疏松', '恶性肿瘤', '精神疾病', '其他']),
      checkValue3: '0',
      checkList3: Object.freeze(['从不', '偶尔', '经常', '每天']),
      checkValue4: '0',
      checkList4: Object.freeze(['从不吸烟', '已戒烟', '吸烟']),
      checkValue5: '0',
      checkList5: Object.freeze(['每天', '每周一次以上', '偶尔', '不锻炼']),
      showNutrition: false,
      showPhysio: false,
      showDiet: false,
      showMotion: false,
      showPsychology: false,
      showSelectModule: false,
      info: {
        name: '',
        jkgyid: '',
        xb: '',
        age: '',
        sfzh: '',
        ssjgbh: '',
        ssjgmc: '',
        xzjdmc: '',
        xzjdmcid: '',
        cwhmcid: '',
        cwhmc: '',
        jbsjb: [],
        jbsshs: [],
        jbsshslist: [
          {
            jbsshsj: '',
            jbsshmc: '',
            jbsshlx: ''
          }
        ],
        jbsycs: [],
        jbsjzs: [
          {
            jgbjzslx: '',
            jgbjzsxx: ''
          }
        ],
        yjqk: '',
        yjqknl: '',
        yjqkjjnl: '',
        xyqk: '',
        xyqknl: '',
        xyqkjynl: '',
        ydqk: '',
        ydqkmcdlsj: '',
        ydqkdlfs: '',
        fzjcxyg: '',
        fzjcxyd: '',
        fzjcxt: '',
        fzjczdgc: '',
        fzjcgysz: '',
        zhgylist: [
          {
            zhgygylx: '',
            zhgygycs: '',
            zhgyyf: '',
            zhgypc: '',
            zhgyyl: '',
            zhgykssj: '',
            zhgyjssj: '',
            zhgysfjcx: '',
            zhgybz: '',
            zhgyfnmc: '',
            zhgyfnid: ''
          }
        ],
        zhgyysjy: '',
        yscfysfn: '',
        yscfyscf: [
          {
            yscfcc: '',
            swfl: '',
            swmc: '',
            swzl: '',
            swrl: '',
            tshhw: '',
            zhif: '',
            tians: '',
            bz1: '',
            yscfid: '',
            zhgyfnid: '',
            yscfnid: ''
          }
        ],
        ydcf: [
          {
            shij: '',
            ydxm: '',
            ydlx: '',
            dwsj: '',
            nlxh: '',
            bz1: ''
          }
        ],
        xlcf: [
          {
            xlfxh: '',
            xlcfmc: '',
            xlcffl: '',
            xlcfnr: '',
            bz1: ''
          }
        ],
        gyysbm: '',
        gyys: '',
        sex: '',
        jbDes: '',
        ssName1: '',
        ssDate1: '',
        ssName2: '',
        ssDate2: '',
        ssName3: '',
        ssDate3: '',
        ssName4: '',
        ssDate4: '',
        ssName5: '',
        ssDate5: '',
        ssName6: '',
        ssDate6: '',
        ssName7: '',
        ssDate7: '',
        ssName8: '',
        ssDate8: '',
        ssName9: '',
        ssDate9: '',
        ycs: [],
        ycsDes: '',
        fq: [],
        fqDes: '',
        mq: [],
        mqDes: '',
        xdjm: [],
        xdjmDes: '',
        zn: [],
        znDes: '',
        ksyjnl: '',
        ksxynl: '',
        mcdlsj: '',
        dlfs: '',
        gy: '',
        dy: '',
        xt: '',
        zdgc: '',
        gysz: '',
        ysjy: '',
        ysfa: '',
        yscf: '',
        ydcfs: '',
        xlcfs: '',
        ydfa: '',
        xlfa: ''
      }
    }
  },
  computed: {
    jb () {
      return this.info.jbsjb
    },
    ss () {
      return this.info.jbsshs
    },
    ycs () {
      return this.info.jbsycs
    },
    fq () {
      return this.info.fq
    },
    mq () {
      return this.info.mq
    },
    xdjm () {
      return this.info.xdjm
    },
    zn () {
      return this.info.zn
    },
    yjqk () {
      return this.info.yjqk
    },
    xyqk () {
      return this.info.xyqk
    },
    ydqk () {
      return this.info.ydqk
    }
  },
  watch: {
    jb (val) {
      if (val.indexOf('14') === -1) {
        this.info.jbDes = ''
      }
    },
    ss (val) {
      for (let index = 1; index < 10; index++) {
        if (val.indexOf(index + '') === -1) {
          this.info['ssName' + index] = ''
          this.info['ssDate' + index] = ''
        }
      }
    },
    ycs (val) {
      if (val.indexOf('8') === -1) {
        this.info.ycsDes = ''
      }
    },
    fq (val) {
      if (val.indexOf('15') === -1) {
        this.info.fqDes = ''
      }
    },
    mq (val) {
      if (val.indexOf('15') === -1) {
        this.info.mqDes = ''
      }
    },
    xdjm (val) {
      if (val.indexOf('15') === -1) {
        this.info.xdjmDes = ''
      }
    },
    zn (val) {
      if (val.indexOf('15') === -1) {
        this.info.znDes = ''
      }
    },
    yjqk (val) {
      if (val === '0') {
        this.info.yjqknl = ''
      }
    },
    xyqk (val) {
      if (val === '0') {
        this.info.xyqknl = ''
      }
    },
    ydqk (val) {
      if (val === '3') {
        this.info.ydqkmcdlsj = ''
        this.info.ydqkdlfs = ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // https://app.apifox.com/project/2726972/apis/api-208030326
      try {
        const { data: res } = await this.$http.post('/ykdjkgys/getbygyid', this.$parent.selectInfo)
        console.log('获取健康干预单条', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        if (res.data.jbsjb) {
          res.data.jbsjb = res.data.jbsjb.split(',')
        } else {
          res.data.jbsjb = []
        }
        if (res.data.jbsshs) {
          res.data.jbsshs = res.data.jbsshs.split(',')
        } else {
          res.data.jbsshs = []
        }
        if (res.data.jbsycs) {
          res.data.jbsycs = res.data.jbsycs.split(',')
        } else {
          res.data.jbsycs = []
        }

        if (res.data.jbsshslist) {
          res.data.jbsshslist = res.data.jbsshslist.split(',')
        } else {
          res.data.jbsshslist = []
        }
        if (res.data.jbsjzs) {
          res.data.jbsjzs = res.data.jbsjzs.split(',')
        } else {
          res.data.jbsjzs = []
        }
        if (res.data.zhgylist) {
          res.data.zhgylist = res.data.zhgylist.split(',')
        } else {
          res.data.zhgylist = []
        }
        if (res.data.yscfyscf) {
          res.data.yscfyscf = res.data.yscfyscf.split(',')
        } else {
          res.data.yscfyscf = []
        }
        if (res.data.ydcf) {
          res.data.ydcf = res.data.ydcf.split(',')
        } else {
          res.data.ydcf = []
        }
        if (res.data.xlcf) {
          res.data.xlcf = res.data.xlcf.split(',')
        } else {
          res.data.xlcf = []
        }
        for (const key in res.data) {
          this.info[key] = res.data[key]
        }
        console.log(this.info)
      } catch (error) {
        console.log('获取健康干预单条请求失败', error)
      }
    },
    jbChange (e) {
      if (e.indexOf('1') > -1) {
        this.info.jbsjb = ['1']
      }
    },
    ssChange (e) {
      if (e.indexOf('1') > -1) {
        this.info.jbsshs = ['1']
        for (let index = 1; index < 10; index++) {
          this.info['ssName' + [index]] = ''
          this.info['ssDate' + [index]] = ''
        }
      }
    },
    ycsChange (e) {
      if (e.indexOf('1') > -1) {
        this.info.jbsycs = ['1']
      }
    },
    fqChange (e) {
      if (e.indexOf('1') > -1) {
        this.info.fq = ['1']
      }
    },
    mqChange (e) {
      if (e.indexOf('1') > -1) {
        this.info.mq = ['1']
      }
    },
    xdjmChange (e) {
      if (e.indexOf('1') > -1) {
        this.info.xdjm = ['1']
      }
    },
    znChange (e) {
      if (e.indexOf('1') > -1) {
        this.info.zn = ['1']
      }
    },
    openSelectModule () {
      this.showSelectModule = true
    },
    closeSelectModule () {
      this.showSelectModule = false
    },
    switchTab (index) {
      if (index === 0) {
        this.showNutrition = true
      } else if (index === 1) {
        this.showPhysio = true
      } else if (index === 2) {
        this.showDiet = true
      } else if (index === 3) {
        this.showMotion = true
      } else if (index === 4) {
        this.showPsychology = true
      }
      this.tabIndex = index
    },
    openNutrition () {
      this.showNutrition = true
    },
    closeNutrition () {
      this.showNutrition = false
    },
    closePhysio () {
      this.showPhysio = false
    },
    closeDiet () {
      this.showDiet = false
    },
    closeMotion () {
      this.showMotion = false
    },
    closePsychology () {
      this.showPsychology = false
    },
    selectInfo (obj) {
      console.log('选择', obj)
      const keys = ['yycfmc', 'llcfxmmc', 'yscffamc', 'ydcffamc', 'xlcffamc']
      const keys1 = ['', '', 'yscfysfn', 'ydfa', 'xlfa']
      this.info[keys1[this.tabIndex]] = obj[keys[this.tabIndex]]
    },
    async submit () {
      // https://app.apifox.com/project/2726972/apis/api-208030326
      try {
        const params = {
          ...this.info,
          jbsjb: this.info.jbsjb.join(','),
          jbsshs: this.info.jbsshs.join(','),
          jbsycs: this.info.jbsycs.join(','),
          jbsshslist: this.info.jbsshslist.join(','),
          jbsjzs: this.info.jbsjzs.join(','),
          zhgylist: this.info.zhgylist.join(','),
          yscfyscf: this.info.yscfyscf.join(','),
          ydcf: this.info.ydcf.join(','),
          xlcf: this.info.xlcf.join(',')
        }
        console.log(params)
        const { data: res } = await this.$http.post('/ykdjkgys/savejkgy', params)
        console.log('健康干预保存', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.closePopup()
      } catch (error) {
        console.log('健康干预保存请求失败', error)
      }
    },
    closePopup () {
      this.$parent.showUpdateIntervene = false
      for (const key in this.$parent.selectInfo) {
        this.$parent.selectInfo[key] = ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .form {
    display: flex;
    flex-direction: column;
  }

  .el-form {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  // /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  //   background-color: #000;
  //   border-color: #000;
  // }

  // /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
  //   color: #000;
  // }

  // .el-checkbox,
  // /deep/.el-checkbox__label {
  //   color: #fff;
  //   font-size: 18px !important;
  // }

  // /deep/.el-checkbox__inner {
  //   background-color: transparent;
  // }

  // /deep/.el-icon-date:before {
  //   content: '';
  // }

  .el-checkbox {
    height: 30px;
  }

  .tip {
    margin-bottom: 10px;
    padding-left: 18px;
    box-sizing: border-box;
    color: #000;
    font-size: 20px;
  }

  .title {
    display: flex;
    align-items: center;
    height: 45px;
    line-height: 45px;
    padding-left: 10px;
    border-top: 1px solid #000;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;

    .icon {
      display: inline-block;
      width: 4px;
      height: 18px;
      margin-right: 4px;
      background-color: #366FF7;
      border-radius: 2px;
    }
  }

  .title1 {
    padding-left: 20px;
    border: none;
    font-weight: 600;
  }

  .title2 {
    margin-top: 30px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #000;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 30%;
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;

      .key {
        width: 78px;
        padding-right: 20px;
        font-size: 18px;
        text-align-last: justify;
        white-space: nowrap;
      }

      .key1 {
        width: 90px;
      }

      .key2 {
        width: 110px;
      }

      .key3 {
        margin-left: 10px;
        font-size: 18px;
        white-space: nowrap;
      }

      .key4 {
        margin-left: 30px;
      }

      .font {
        padding-right: 10px;
        font-size: 18px;
        white-space: nowrap;
      }

      .font1 {
        margin-right: 10px;
      }

      .img {
        width: 25px;
        height: 25px;
      }

      .el-input,
      .el-select {
        width: 151px;
      }

      ::v-deep .el-input__inner::placeholder {
        color: #252523;
      }

      .el-date-picker {
        width: 170px;
      }

      .input {
        width: 211px !important;
      }

      .input1 {
        width: 272px;
      }

      // .el-radio {
      //   color: #fff;
      // }

      // ::v-deep .el-radio__input.is-checked .el-radio__inner {
      //   border-color: #000;
      //   background: #000;
      // }

      // ::v-deep .el-radio__input.is-checked+.el-radio__label {
      //   color: #000;
      //   display: inline-block;
      // }

      // ::v-deep.el-radio__label {
      //   font-size: 18px;
      // }

      // ::v-deep.el-radio__inner {
      //   width: 18px;
      //   height: 18px;
      // }

      .width {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        width: 10%;
        height: 410px;

        .width-item {
          width: 100%;
        }
      }

      .width2 {
        width: 80%;
      }

      .show {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        width: 500px;
        height: 410px;
      }

      .score {
        display: flex;
        align-items: center;
        // width: 500px;
        font-size: 18px;
        white-space: nowrap;

        span {
          padding: 0 15px;
        }
      }

      .score-hide {
        visibility: hidden;
      }
    }

    .item1 {
      width: 50%;
    }

    .item4 {
      width: 100%;
    }
  }

  .select {
    display: flex;
    align-items: center;
    height: 38px;
  }

  .table {
    width: 100%;
    height: 200px;
    margin: 0 auto;
    margin-top: 20px;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .tr {
      display: flex;
      align-items: center;
      height: 37px;
      color: #000;
      font-size: 14px;

      .td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 10%;
        text-align: center;
        height: 37px;
        line-height: 37px;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
      }
    }

    .tr1 {
      color: #fff;
      font-size: 16px;
      background-color: #000;
    }
  }

  .textarea-title {
    display: flex;
    align-items: center;
    width: 96%;
    margin: 20px auto;
    color: #000;
    font-size: 18px;

    .margin {
      margin-right: 10px;
    }

    .textarea-select {
      position: relative;
      width: 169px;
      height: 38px;
      line-height: 38px;
      padding: 0 8px;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 16px;
      color: #2A374D;
      background-color: #fff;

      .icon {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  /deep/.el-textarea__inner {
    width: 96%;
    margin: 0 auto;
    height: 80px;
    margin: 0 auto;
    background-color: transparent;
    border: 1px solid #000;
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 40px;
  }
}
</style>
