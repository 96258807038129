<template>
  <div class="table">
    <el-table border height="400" :data="tableData" highlight-current-row @current-change="currentChange">
      <!-- <el-table-column type="selection">
      </el-table-column> -->
      <el-table-column v-for="(item, index) in tableKeyValue" :key="index" :prop="item.prop" :label="item.label">
      </el-table-column>
    </el-table>
    <div class="bottom">
      <div style="width: 100px"></div>
      <div class="bts">
        <el-button size="small">取消</el-button>
        <el-button size="small" type="primary" @click="submit">确定</el-button>
      </div>
      <!-- <el-pagination
      :page-size="100"
      layout="total, prev, pager, next"
      :total="1000">
    </el-pagination> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableKeyValue: {
      type: Array
    },
    tableData: {
      type: Array
    }
  },
  data () {
    return {
    }
  },
  methods: {
    currentChange (val) {
      this.$emit('selectInfo', val)
    },
    submit () {
      this.$parent.submit()
    },
    tableClosePopup () {
      this.$emit('tableClosePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.table {
  position: relative;
  width: 100%;
  height: 100%;

  /deep/.el-table__cell {
    text-align: center;
    color: #000;
  }

  .bottom {
    position: absolute;
    left: 0;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 26px;
    margin-top: 10px;

    .bts {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 40%;
    }
  }
}
</style>
