<template>
  <div class="popup">
    <el-form ref="form" class="form">
      <div class="tip">处方详情</div>
      <div class="title">居民信息</div>
      <div class="info">
        <div class="item">
          <div class="key">居民编号</div>
          <span>{{ info.id || '-' }}</span>
        </div>
        <div class="item">
          <div class="key">姓名</div>
          <span>{{ info.name || '-' }}</span>
        </div>
        <div class="item">
          <div class="key">性别</div>
          <span>{{ info.xb | xbType }}</span>
        </div>
        <div class="item">
          <div class="key">年龄</div>
          <span>{{ info.age || '-' }}</span>
        </div>
        <div class="item">
          <div class="key">联系方式</div>
          <span>{{ info.lxdh || '-' }}</span>
        </div>
        <div class="item">
          <div class="key">会员分组</div>
          <span>{{ info.hyfzmc || '-' }}</span>
        </div>
        <div class="item">
          <div class="key">主管医生</div>
          <span>{{ info.zgysmc || '-' }}</span>
        </div>
        <div class="item">
          <div class="key">状态</div>
          <span>{{ info.status | statusType }}</span>
        </div>
        <div class="item">
          <div class="key">住址</div>
          <span>{{ info.address || '-' }}</span>
        </div>
        <div class="item">
          <div class="key">所属门店</div>
          <span>{{ info.mdname || '-' }}</span>
        </div>
        <div class="item">
          <div class="key">添加时间</div>
          <span>{{ info.createTime || '-' }}</span>
        </div>
        <div class="item">
          <div class="key">处方名称</div>
          <span>{{ info.cfmc || '-' }}</span>
        </div>
        <div class="item">
          <div class="key">评估时间</div>
          <span>{{ info.pgsj || '-' }}</span>
        </div>
      </div>
      <div class="title">理疗处方</div>
      <div class="box">
        <el-table :data="tableData" height="200" border>
          <el-table-column prop="address" label="项目名称">
          </el-table-column>
          <el-table-column prop="name" label="仪器名称">
          </el-table-column>
          <el-table-column prop="address" label="方法">
          </el-table-column>
          <el-table-column prop="name" label="部位">
          </el-table-column>
          <el-table-column prop="address" label="频率">
          </el-table-column>
          <el-table-column prop="name" label="影响">
          </el-table-column>
        </el-table>
      </div>
      <div class="title">饮食处方</div>
      <div class="box">
        <el-table :data="tableData" height="200" border>
          <el-table-column prop="address" label="餐次">
          </el-table-column>
          <el-table-column prop="name" label="食物分类">
          </el-table-column>
          <el-table-column prop="address" label="食物名称">
          </el-table-column>
          <el-table-column prop="name" label="重量(g）">
          </el-table-column>
          <el-table-column prop="address" label="热量(kCal)">
          </el-table-column>
          <el-table-column prop="name" label="碳水化合物(g)">
          </el-table-column>
          <el-table-column prop="name" label="脂肪(g)">
          </el-table-column>
          <el-table-column prop="address" label="天数">
          </el-table-column>
        </el-table>
      </div>
    </el-form>
    <div class="bts">
      <el-button size="small" @click="closePopup">返回</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      info: {
        name: '',
        xb: '',
        age: '',
        lxdh: '',
        hyfzmc: '',
        hyfzbh: '',
        zgysmc: '',
        zgysbh: '',
        address: '',
        mdname: '',
        status: '',
        cfmc: '',
        pgsj: '',
        yycfid: '',
        llcfid: '',
        yscfid: '',
        ydcfid: '',
        xlcfid: '',
        ssjgbh: '',
        jgbh: ''
      },
      tableData: []
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  filters: {
    xbType (val) {
      if (val === '1') return '男'
      if (val === '2') return '女'
      return '-'
    },
    statusType (val) {
      if (val === '1') return '正常'
      if (val === '2') return '注销'
      return '-'
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-208862418
      try {
        const { data: res } = await this.$http.post('/ydkjkcfbtlbs/getone', {
          ssjgbh: this.loginInfo.jgbh || '',
          jgbh: this.loginInfo.jgbh || '',
          id: this.$parent.selectId || ''
        })
        console.log('处方居民信息单条获取', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
      } catch (error) {
        console.log('处方居民信息单条获取请求失败', error)
        // this.$message.error('系统异常，请稍后重试')
      }
    },
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;

  .form {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 40px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .icon {
    position: absolute;
    top: 10px;
    left: 140px;
    width: 82px;
    height: 66px;
  }

  .tip {
    padding-left: 18px;
    margin-bottom: 10px;
    box-sizing: border-box;
    color: #000;
    font-size: 20px;
  }

  .title {
    height: 45px;
    line-height: 45px;
    border-top: 1px solid #000;
    padding-left: 10px;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;

      .key {
        width: 76px;
        margin-right: 8px;
        font-size: 18px;
        text-align: justify;
        text-align-last: justify;
      }
    }

    .item1 {
      width: 50%;
    }
  }

  .box {
    width: 100%;
    // height: 91px;
    margin-top: 10px;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .scroll {
      overflow-y: scroll;
      max-height: 100px;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #000;
      font-size: 14px;

      .item {
        flex: 1;
        text-align: center;
      }
    }

    .line1 {
      height: 40px;
      line-height: 40px;
      background-color: #000;
      color: #fff;
    }

    .line2 {
      height: 40px;
      line-height: 40px;
    }
  }

  .bts {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #fff;
  }
}
</style>
