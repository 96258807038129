<template>
  <div class="info">
    <div class="info-box">
      <div class="info-scroll">
        <div class="info-title">
          <span>居民信息</span>
        </div>
        <div class="info-list">
          <div class="info-item">
            <div class="info-key">
              <span>档案编号</span>
            </div>
            <div class="info-value">
              <span>{{ info.jkdnid || '-' }}</span>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>姓名</span>
            </div>
            <div class="info-value">
              <el-input v-model="info.name"></el-input>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>居民头像</span>
            </div>
            <el-upload class="avatar-uploader" action="" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <el-image :src="sfztp" fit="contain" class="avatar"></el-image>
              <i class="el-icon-plus avatar-uploader-icon add"></i>
              <span class="text">点击上传</span>
            </el-upload>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>联系电话</span>
            </div>
            <div class="info-value">
              <el-input v-model="info.lxdh"></el-input>
            </div>
          </div>

          <div class="info-item">
            <div class="info-key">
              <span>身份证</span>
            </div>
            <div class="info-value">
              <el-input v-model="info.sfzhm"></el-input>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>人群分类</span>
            </div>
            <div class="info-value">
              <el-select v-model="info.nametage" placeholder="请选择">
                <el-option v-for="item in isageds" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>乡镇/社区</span>
            </div>
            <div class="info-value">
              <!-- xzjdmc xzjdmcid -->
              <el-select v-model="info.xzjdmcid" placeholder="请选择" @change="xzChange">
                <el-option v-for="item in arr1" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>村/街道</span>
            </div>
            <div class="info-value">
              <!-- cwhmc cwhmcid -->
              <el-select v-model="info.cwhmcid" placeholder="请选择" @change="cChange">
                <el-option v-for="item in arr2" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>现居地</span>
            </div>
            <div class="info-value">
              <el-input placeholder="请输入" v-model="info.jkdnxzz"></el-input>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>户籍地址</span>
            </div>
            <div class="info-value">
              <el-input placeholder="请输入" v-model="info.jkdnhjdz"></el-input>
            </div>
          </div>
        </div>

        <div class="info-list">
          <div class="info-item">
            <div class="info-key">
              <span>性别</span>
            </div>
            <div class="info-value">
              <el-select v-model="info.xb" placeholder="请选择">
                <el-option v-for="item in sexs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>出生年月</span>
            </div>
            <div class="info-value">
              <el-date-picker v-model="info.csrq" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>籍贯</span>
            </div>
            <div class="info-value">
              <el-input placeholder="请输入" v-model="info.jig"></el-input>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>民族</span>
            </div>
            <div class="info-value">
              <el-select v-model="info.minzu" placeholder="请选择">
                <el-option v-for="item in mzs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="info-item">
            <div class="info-key">
              <span>文化程度</span>
            </div>
            <div class="info-value">
              <el-select v-model="info.whcd" placeholder="请选择">
                <el-option v-for="item in whcds" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>婚姻状况</span>
            </div>
            <div class="info-value">
              <el-select v-model="info.hyqk" placeholder="请选择">
                <el-option v-for="item in hyqks" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>职业</span>
            </div>
            <div class="info-value">
              <el-input placeholder="请输入" v-model="info.zhiye"></el-input>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>行政区划</span>
            </div>
            <div class="info-value">
              <el-input placeholder="请输入" v-model="info.xzqh"></el-input>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>紧急联系人</span>
            </div>
            <div class="info-value">
              <el-input placeholder="请输入" v-model="info.lxrxm"></el-input>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>联系人电话</span>
            </div>
            <div class="info-value">
              <el-input placeholder="请输入" v-model="info.lxrdh"></el-input>
            </div>
          </div>
        </div>

        <div class="info-list">
          <div class="info-item">
            <div class="info-key">
              <span>户籍类型</span>
            </div>
            <div class="info-value">
              <el-select v-model="info.czlx" placeholder="请选择">
                <el-option v-for="item in hjlxs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>定点医疗单位</span>
            </div>
            <div class="info-value">
              <el-input placeholder="请输入" v-model="info.ddyldw"></el-input>
            </div>
          </div>
          <div class="info-item1"></div>
          <div class="info-item info-item1">
            <div class="info-key">
              <span>费用类型</span>
            </div>
            <div class="info-value">
              <el-checkbox-group v-model="info.ylfy">
                <el-checkbox label="0">自费</el-checkbox>
                <el-checkbox label="1">社会医疗保险</el-checkbox>
                <el-checkbox label="2">商业保险</el-checkbox>
                <el-checkbox label="3">新农合</el-checkbox>
                <el-checkbox label="4">其他</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="info-item info-item1">
            <div class="info-key">
              <span>医疗保障卡号：</span>
            </div>
            <div class="info-value">
              <el-input placeholder="请输入" v-model="info.ylbzkh"></el-input>
            </div>
          </div>
        </div>

        <div class="info-title">
          <span>健康信息</span>
        </div>

        <div class="info-list">
          <div class="info-item">
            <div class="info-key">
              <span>身体：</span>
            </div>
            <div class="info-value">
              <el-input placeholder="请输入" v-model="info.ybzksg"></el-input>
            </div>
            <div class="info-unit">
              <span>cm</span>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>体重：</span>
            </div>
            <div class="info-value">
              <el-input placeholder="请输入" v-model="info.yzbktz"></el-input>
            </div>
            <div class="info-unit">
              <span>kg</span>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>血型：</span>
            </div>
            <div class="info-value">
              <el-select v-model="info.xuexing" placeholder="请选择">
                <el-option v-for="item in xxs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>RH阴性：</span>
            </div>
            <div class="info-value">
              <el-select v-model="info.rh" placeholder="请选择">
                <el-option v-for="item in rhs" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="info-item">
            <div class="info-key">
              <span>既往史</span>
            </div>
            <div class="info-value">
              <el-radio-group v-model="info.jwsjb">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input v-show="info.jwsjb === '2'" class="input" placeholder="请输入" v-model="info.jwsDes"></el-input>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>过敏史</span>
            </div>
            <div class="info-value">
              <el-radio-group v-model="info.allergyHistory">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input v-if="info.allergyHistory === '2'" class="input" placeholder="请输入"
                v-model="info.gmsDes"></el-input>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>就诊史</span>
            </div>
            <div class="info-value">
              <el-radio-group v-model="info.jzszn">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input v-if="info.jzszn === '2'" class="input" placeholder="请输入" v-model="info.jzsDes"></el-input>
            </div>
          </div>
          <div class="info-item">
            <div class="info-key">
              <span>家族病史</span>
            </div>
            <div class="info-value">
              <el-radio-group v-model="info.familyHistory">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <el-input v-if="info.familyHistory === '2'" class="input" placeholder="请输入"
                v-model="info.jzbsDes"></el-input>
            </div>
          </div>
        </div>

        <div class="info-list">
          <div class="info-item info-item2">
            <div class="info-key">
              <span style="white-space: nowrap;">家庭成员</span>
            </div>
            <div class="info-value info-value1">
              <div v-for="(item, index) in members" :key="index" @click="openUpdateMember(item)" class="info-blue">
                <img src="../../../img/emr/user.png" class="img">
                <div class="news">
                  <span>{{ item.name || '-' }}</span>
                  <span>{{ item.age || '-' }}岁</span>
                  <span>关系</span>
                  <span>{{ item.yjsgx | gxType }}</span>
                </div>
              </div>

              <div @click="showAddMember = true" class="add-box">
                <i  class="el-icon-plus avatar-uploader-icon add"></i>
                <span class="text">关联家庭成员</span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="info-btns">
        <el-button size="small" type="primary" @click="submit">保存</el-button>
        <el-button size="small" @click="close">返回</el-button>
      </div>
    </div>
    <AddMember v-if="showAddMember"></AddMember>
    <UpdateMember v-if="showUpdateMember"></UpdateMember>
  </div>
</template>

<script>
import AddMember from './addMember.vue'
import UpdateMember from './updateMember.vue'
import { mapState } from 'vuex'
export default {
  components: {
    AddMember,
    UpdateMember
  },
  props: {
    selectSfzh: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      sexs: Object.freeze([
        { label: '男', value: '1' },
        { label: '女', value: '2' }
      ]),
      arr1: [],
      arr2: [],
      radio: '',
      value: '',
      value1: '',
      imageUrl: '',
      checkList: [],
      mzs: Object.freeze([
        { label: '汉族', value: '01' },
        { label: '少数民族', value: '99' }
      ]),
      rhs: Object.freeze([
        { label: '阴性', value: '1' },
        { label: '阳性', value: '2' },
        { label: '不详', value: '3' }
      ]),
      xxs: Object.freeze([
        { label: 'A型', value: '1' },
        { label: 'B型', value: '2' },
        { label: 'O型', value: '3' },
        { label: 'AB型', value: '4' },
        { label: '不详', value: '5' }
      ]),
      whcds: Object.freeze([
        { label: '研究生', value: '1' },
        { label: '大学本科', value: '2' },
        { label: '大学专科和专科学校', value: '3' },
        { label: '中等专业校', value: '4' },
        { label: '技工学校', value: '5' },
        { label: '高中', value: '6' },
        { label: '初中', value: '7' },
        { label: '小学', value: '8' },
        { label: '文盲及半文盲', value: '9' },
        { label: '不详', value: '10' }
      ]),
      hyqks: Object.freeze([
        { label: '未婚', value: '1' },
        { label: '已婚', value: '2' },
        { label: '丧偶', value: '3' },
        { label: '离婚', value: '4' },
        { label: '未说明', value: '5' }
      ]),
      hjlxs: Object.freeze([
        { label: '户籍', value: '1' },
        { label: '非户籍', value: '2' }
      ]),
      isageds: [
        { label: '老年人', value: '1' },
        { label: '非老年人', value: '2' }
      ],
      loading: false,
      sfztp: '',
      members: [],
      showAddMember: false,
      selectMember: null,
      showUpdateMember: false,
      info: {
        id: 0,
        createTime: '',
        updateTime: '',
        jkdnid: '',
        name: '',
        lxdh: '',
        jdrq: '',
        sfzhm: '',
        grjbxxid: '',
        tmcode: '',
        jgbh: '',
        cwhmcid: '',
        age: '',
        personInfoId: '',
        isHypertension: '',
        isDiabetes: '',
        isSmi: '',
        isTuberculosis: '',
        isChd: '',
        isCa: '',
        isaged: '',
        isWomen: '',
        isOld: '',
        isChildren: '',
        isMaternal: '',
        isPhychosis: '',
        personInfoNo: '',
        manageOrgId: '',
        xb: '',
        sfztp: '',
        xzjdmcid: '',
        jddwid: '',
        tjlx: '',
        nametage: '',
        deflag: '',
        csrq: '',
        jkdnxzz: '',
        jkdnhjdz: '',
        xzjdmc: '',
        cwhmc: '',
        jdr: '',
        jddw: '',
        zeys: '',
        dnbh: '',
        whcd: '',
        hyqk: '',
        zhiye: '',
        lxrxm: '',
        lxrdh: '',
        czlx: '',
        ylfy: [],
        ddyldw: '',
        ybzksg: '',
        yzbktz: '',
        xuexing: '',
        rh: '',
        jig: '',
        minzu: '',
        xzqh: '',
        ylbzkh: '',
        jwsjb: '',
        jwsDes: '',
        allergyHistory: '',
        gmsDes: '',
        jzszn: '',
        jzsDes: '',
        familyHistory: '',
        sfzh: '',
        jzbsDes: '',
        ssjgbh: '',
        type: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo']),
    jwsjb () {
      return this.info.jwsjb
    }
  },
  watch: {
    jwsjb (newVal, oldVal) {
      console.log(newVal, oldVal)
      if (oldVal !== newVal && newVal === '1') {
        this.info.jwsDes = ''
      }
    }
  },
  filters: {
    gxType (val) {
      if (val === '1') {
        return '父母'
      } else if (val === '2') {
        return '配偶'
      } else if (val === '3') {
        return '子女'
      } else {
        return '-'
      }
    }
  },
  created () {
    console.log('所选身份证号', this.selectSfzh)
    this.getInfo()
    this.arr1 = this.loginInfo.cjrylist
    this.arr2 = this.loginInfo.children
    this.getList()
  },
  methods: {
    async handleAvatarSuccess (res, file) {
      if (this.loading) return
      // this.info.sfztp = URL.createObjectURL(file.raw)
      const fileData = URL.createObjectURL(file.raw)
      try {
        this.loading = true
        const obj = {
          qmtype: 'ysqm',
          username: this.$store.state.loginInfo.username || '',
          jgbh: this.$store.state.loginInfo.jgbh || '',
          openid: '',
          qmid: 1,
          unionid: '',
          fileData
        }
        const { data: res } = await this.$http.post('/files/uploadfile', obj)
        console.log('头像上传', res)
        this.loading = false
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('上传成功')
        this.info.sfztp = res.data.ocrtpurl
        this.sfztp = fileData
      } catch (error) {
        this.loading = false
      }
    },
    beforeAvatarUpload (file) { },
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-124388850
      try {
        const { data: res } = await this.$http.post('/api/jkct/app/findinfo', {
          sfzh: this.selectSfzh,
          type: 'jkdn',
          ssjgbh: this.loginInfo.jgbh || ''
        })
        console.log('根据参数获取档案信息', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
        this.sfztp = this.info.sfztp
      } catch (error) {
        console.log('根据参数获取档案信息请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getList () {
      // 接口地址 https://app.apifox.com/link/project/2726972/apis/api-212325580
      try {
        const { data: res } = await this.$http.post('/ykdjtcylbs/getlistjtcy', { jrsfzh: this.selectSfzh })
        console.log('获取家庭成员列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.members = res.data
      } catch (error) {
        console.log('获取家庭成员列表请求失败', error)
      }
    },
    openUpdateMember (info) {
      this.selectMember = info
      this.showUpdateMember = true
    },
    async submit () {
      try {
        this.info.sfzh = this.selectSfzh
        this.info.type = 'jkdn'
        this.info.ssjgbh = this.loginInfo.jgbh || ''
        const { data: res } = await this.$http.post('/api/jkct/app/updateinfo', this.info)
        console.log('修改保存档案信息', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('修改保存档案信息请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    xzChange (e) {
      this.info.xzjdmc = this.arr1.filter(item => item.value === e)[0].label
      console.log(this.info.xzjdmc)
      console.log(this.info.xzjdmcid)
    },
    cChange (e) {
      this.info.cwhmc = this.arr2.filter(item => item.value === e)[0].label
      console.log(this.info.cwhmc)
      console.log(this.info.cwhmcid)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  // background-color: #081C38;
  background-color: #fff;
  box-sizing: border-box;

  .info-box {
    width: 100%;
    height: 100%;

    .info-scroll {
      overflow: hidden scroll;
      width: 100%;
      height: calc(100% - 50px);

      &::-webkit-scrollbar {
        display: none;
      }

      .info-title {
        height: 30px;
        line-height: 30px;
        // color: #01EEFD;
        color: #000;
        font-size: 18px;
      }

      .info-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px;
        // border-bottom: 1px solid #01EEFD;
        border-bottom: 1px solid #000;

        .info-item {
          display: flex;
          align-items: center;
          width: 25%;
          margin-bottom: 10px;

          .info-key {
            // color: #01EEFD;
            color: #000;
            font-size: 18px;
          }

          .info-value {
            display: flex;
            align-items: center;
            margin-left: 10px;
            // color: #01EEFD;
            color: #000;
            font-size: 16px;
          }

          .info-value1 {
            flex-wrap: wrap;
          }

          .input {
            width: 100px;
          }

          .info-unit {
            margin-left: 5px;
            // color: #01EEFD;
            color: #000;
            font-size: 18px;
          }

          .avatar-uploader {
            display: flex;
            align-items: center;
            margin-left: 10px;

            /deep/.el-upload {
              display: flex;
              align-items: center;
            }

            .avatar {
              width: 36px;
              height: 36px;
            }
          }

          .add-box {
            display: flex;
            align-items: center;
          }

          .add {
            margin: 0 5px;
            // color: #01EEFD;
            color: #000;
            font-size: 24px;
            cursor: pointer;
          }

          .text {
            // color: #01EEFD;
            color: #000;
            font-size: 16px;
            cursor: pointer;
          }

          .info-blue {
            display: flex;
            align-items: center;
            margin: 5px;
            padding: 7px 10px;
            background: linear-gradient(124deg, #0171C4 0%, #024479 100%);
            cursor: pointer;

            .img {
              width: 25px;
              height: 25px;
            }

            .news {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 90px;
              height: 60px;
              margin-left: 10px;
              color: #fff;
              font-size: 16px;
            }
          }

          /deep/.el-checkbox__label,
          /deep/.el-radio__label {
            // color: #fff;
            color: #000;
          }

        }

        .info-item1 {
          width: 50%;
        }

        .info-item2 {
          width: 100%;
        }
      }
    }

    .info-btns {
      display: flex;
      align-items: center;
      height: 50px;
    }

  }
}
</style>
