<template>
  <div class="seeInfo">
    <div class="seeInfo-left">
      <div class="title">
        <span>患者住院信息</span>
      </div>
      <el-select size="small" v-model="value" placeholder="内分泌">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select size="small" v-model="value" placeholder="全部责任医师">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select size="small" v-model="value" placeholder="姓名">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select size="small" v-model="value" placeholder="床号">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <div class="btns">
        <el-button size="mini">搜索</el-button>
        <el-button size="mini">重置</el-button>
      </div>
      <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
    </div>
    <div class="seeInfo-right">
      <div class="title">
        <span>查看详情</span>
      </div>
      <div class="scroll">
        <div class="title1">
          <span>基本信息</span>
          <div class="bts">
            <el-button size="small" @click="openUpdateInfo">编辑信息</el-button>
          </div>
        </div>
        <div class="box">
          <div class="item">
            <span class="key">姓名</span>
            <span class="value">李明明</span>
          </div>
          <div class="item">
            <span class="key">性别</span>
            <span class="value">男</span>
          </div>
          <div class="item">
            <span class="key">年龄</span>
            <span class="value">40</span>
          </div>
          <div class="item">
            <span class="key">联系电话</span>
            <span class="value">15803339264</span>
          </div>
          <div class="item">
            <span class="key">身高</span>
            <span class="value">175cm</span>
          </div>
          <div class="item">
            <span class="key">体重</span>
            <span class="value">75kg</span>
          </div>
          <div class="item">
            <span class="key">身份账号</span>
            <span class="value">130133199909090909</span>
          </div>
        </div>
        <div class="title1 border-none">
          <span>门诊血糖</span>
        </div>
        <div class="date">
          <span>日期</span>
          <el-date-picker class="margin-left" size="small" type="date" placeholder="选择日期">
          </el-date-picker>
          <el-button size="small" class="margin-left">全部数据</el-button>
        </div>
        <div class="table">
          <el-table :data="tableData" height="206" :header-cell-style="{ background: '#024276' }">
            <el-table-column prop="date" label="检测时间">
            </el-table-column>
            <el-table-column prop="date" label="血糖值">
            </el-table-column>
            <el-table-column prop="type" label="时段">
            </el-table-column>
            <el-table-column prop="type1" label="门诊医生">
            </el-table-column>
            <el-table-column prop="type1" label="方式">
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
        <div class="title1 border-none">
          <span>门诊血压</span>
        </div>
        <div class="table">
          <el-table :data="tableData" height="206" :header-cell-style="{ background: '#024276' }">
            <el-table-column prop="date" label="检测时间">
            </el-table-column>
            <el-table-column prop="date" label="收缩压(mmHg)">
            </el-table-column>
            <el-table-column prop="type" label="舒张压(mmHg)">
            </el-table-column>
            <el-table-column prop="type1" label="心率(次/分)">
            </el-table-column>
            <el-table-column prop="type1" label="门诊医生">
            </el-table-column>
            <el-table-column prop="type1" label="方式">
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
      <div class="bottom">
        <el-button @click="close">返回</el-button>
      </div>
    </div>
    <UpdateInfo v-if="showUpdateInfo" @close="closeUpdateInfo"></UpdateInfo>
  </div>
</template>

<script>
import UpdateInfo from './updateInfo.vue'
export default {
  components: {
    UpdateInfo
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      data: [{
        label: '内分泌',
        children: [{
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }]
      }],
      tableData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      showUpdateInfo: false
    }
  },
  mounted () {
    // setTimeout(() => {
    //   debugger
    // }, 3000)
  },
  methods: {
    tabClick (index) {
      this.tabsIndex = index
    },
    handleNodeClick (data) {
      // console.log(data)
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openUpdateInfo () {
      this.showUpdateInfo = true
    },
    closeUpdateInfo () {
      this.showUpdateInfo = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.seeInfo {
  position: relative;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: #071224;

  .seeInfo-left {
    width: 3.375rem;
    height: 10.3125rem;
    padding: .125rem;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      width: 100%;
      height: .4375rem;
      line-height: .52rem;
      text-align: center;
      background-image: url('../../img/ssjk/title.png');
      background-size: 100% 100%;
      color: #fff;
      font-size: .25rem;
      font-weight: bold;
    }

    .el-select {
      margin-top: .25rem;
    }

    .btns {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: .125rem auto;

      .el-button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        border-radius: 0;
        color: #fff;
        border: none;

        &::after {
          border: none;
        }
      }
    }

    .el-tree {
      background-color: #081C38;
      color: #fff;

      ::v-deep .el-tree-node>.el-tree-node__content:hover {
        background-color: transparent;
      }

      ::v-deep.is-current {
        background-color: transparent;
        color: #01EEFD;
        background-color: #081C38;
      }

      ::v-deep.el-tree-node.is-current>.el-tree-node__content {
        background-color: transparent !important;
      }
    }
  }

  .seeInfo-right {
    width: calc(100% - 3.625rem);
    height: 100%;
    padding: .125rem;
    border-left: .05rem solid rgba(1, 124, 219, .27);
    box-sizing: border-box;
    background-color: #08284B;

    .title {
      display: flex;
      align-items: center;
      color: #01EEFD;
      font-size: .25rem;
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 1rem);

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .title1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 97%;
      height: .625rem;
      margin: 0 auto;
      padding: 0 .225rem;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: .225rem;

      .bts {

        .el-button {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
          border: none;
          border-radius: 0;
          color: #fff;
        }
      }
    }

    .border-none {
      border: none;
    }

    .box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 97%;
      margin: 0 auto;
      padding: .25rem;
      box-sizing: border-box;
      border-bottom: 1px solid #01EEFD;

      .item {
        display: flex;
        align-items: center;
        width: 25%;
        padding: .1rem 0;
        color: #01EEFD;
        font-size: .2rem;

        .key {
          width: .875rem;
          margin-right: .225rem;
          white-space: nowrap;
          text-align-last: justify;
        }

        .margin {
          margin-right: .225rem;
        }

        .value {
          width: calc(100% - 1.1rem);
          white-space: wrap;
        }

        .value1 {
          width: calc(100% - 2.5rem);
        }
      }

      .item1 {
        width: 75%;
      }
    }

    .date {
      display: flex;
      align-items: center;
      margin-bottom: .125rem;
      padding: 0 .475rem;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: .2rem;

      .margin-left {
        margin-left: .25rem;
      }

      .el-button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        border: none;
        border-radius: 0;
        color: #fff;
      }
    }

    .table {
      padding: 0 .475rem;
      box-sizing: border-box;
    }

    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0rem;
      }
    }

    ::v-deep .el-table th.gutter {
      display: none;
      width: 0;
    }

    ::v-deep .el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    ::v-deep .el-table__body {
      width: 100% !important;
    }

    .el-table {
      background-color: #092B50;
      border: .05rem #073F73 solid;
    }

    // 表头文字颜色
    /deep/.el-table__header-wrapper {
      thead {
        th {
          div {
            color: #01EEFD;
            font-size: .2125rem;
            font-weight: 400;
          }
        }
      }
    }

    //表格内容颜色
    /deep/.el-table__body tbody tr {
      color: #fff;
      background-color: #092B50;
    }

    /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #092B50;
    }

    /deep/.el-table tbody tr:hover>td {
      background-color: transparent !important
    }

    // 改变边框颜色
    .el-table--border,
    .el-table--group {
      border: .025rem solid #ddd !important;
    }

    /**
  改变表格内竖线颜色
   */
    .el-table--border td,
    .el-table--border th,
    .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      border-right: .0125rem solid #ddd !important;
    }

    /deep/.el-table td {
      border-bottom: .0125rem solid #01EEFD;
    }

    /deep/.el-table thead tr th {
      border-color: transparent;
    }

    /deep/ .el-table--border::after,
    /deep/ .el-table--group::after,
    /deep/ .el-table::before {
      background-color: #073F73;
    }

    /deep/.el-table--border {
      border: .0125rem solid #073F73 !important;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    ::v-deep .el-table__empty-text {
      color: #01EEFD;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: .5rem;

      ::v-deep.el-pagination__total,
      ::v-deep.el-pagination__jump {
        color: #01EEFD;

        .el-input__inner {
          color: #fff;
          background-color: transparent;
        }
      }

      ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #01EEFD;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      height: .6rem;

      .el-button {
        width: .875rem;
        height: .475rem;
        line-height: .475rem;
        margin: 0;
        padding: 0;
        background-color: #33465C;
        color: #FFFFFF;
        font-size: .225rem;
        border: none;
        border-radius: 0;
      }
    }
  }

}
</style>
