<template>
  <div class="tab">
    <div class="head">
      <div class="left">
        <div class="user"></div>
        <span class="text">李明敏</span>
        <span class="text">女</span>
        <span class="text">38岁</span>
        <span class="text">电话：15888888888</span>
        <span class="text">地址：河北省石家庄市桥西区友谊街道258号</span>
        <span class="label organ">高</span>
        <span class="label green">糖</span>
        <span class="label blue">冠</span>
        <span class="label purple">肿</span>
        <span class="label red">精</span>
      </div>
      <div class="right">
        <span class="el-icon-search icon"></span>
        <span class="text">筛选</span>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="item" :class="{ active: tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
          <div class="item-top" :class="{ 'item-top-active': tabIndex === index }">
            <span>{{ item.n }}</span>
          </div>
          <div class="item-text" :class="{ 'item-text-active': tabIndex === index }">
            <span>{{ item.text }}</span>
          </div>
          <div class="item-line" :class="{ 'item-line1': index > 5 }"></div>
        </div>
      </div>
      <div class="right">
        <!-- 青春期 -->
        <Item3></Item3>
      </div>
    </div>
  </div>
</template>

<script>
import Item3 from './item3/item3.vue'
export default {
  components: {
    Item3
  },
  data () {
    return {
      tabIndex: 2,
      tabs: Object.freeze([
        { n: 0, text: '婴幼儿时期' },
        { n: 3, text: '学龄前期' },
        { n: 12, text: '青春期' },
        { n: 20, text: '青年期' },
        { n: 45, text: '中年期' },
        { n: 60, text: '中老年期' },
        { n: 70, text: '老年期' },
        { n: 90, text: '长寿老年' }
      ])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 76px;
    padding-left: 28px;
    padding-right: 109px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .user {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #fff;
      }

      .text {
        margin-left: 15px;
        color: #fff;
        font-size: 16px;
      }

      .label {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 50%;
        margin-left: 20px;
        color: #fff;
        font-size: 16px;
      }

      .organ {
        background-color: #E34D04;
      }

      .green {
        background-color: #109607;
      }

      .blue {
        background-color: #075896;
      }

      .purple {
        background-color: #790796;
      }

      .red {
        background-color: #FA2323;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        color: #fff;
        font-size: 30px;
      }

      .text {
        margin-left: 7px;
        color: #fff;
        font-size: 14px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 83px);
    margin-top: 7px;

    .left {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 130px;
      height: 100%;
      padding-top: 23px;
      background-color: #0C4969;
      border-radius: 6px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        font-size: 18px;
        cursor: pointer;

        .item-top {
          width: 35px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          border-radius: 50%;
          border: 1px solid #fff;
          color: #fff;
          font-size: 18px;
        }

        .item-top-active {
          background-color: #01EEFD;
          border: none;
        }

        .item-text {
          color: #fff;
          font-size: 18px;
        }

        .item-text-active {
          color: #01EEFD;
        }

        .item-line {
          width: 1px;
          height: 25px;
          background-color: #fff;
        }

        .item-line1 {
          background-color: transparent;
        }
      }

      .active {
        color: #01EEFD;
      }
    }

    .right {
      width: calc(100% - 150px);
      height: 100%;
    }
  }
}
</style>
