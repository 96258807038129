<template>
  <div class="write">
    <div class="title">
      <span>编辑信息</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="srcoll">
        <div class="box">
          <div class="item">
            <span class="span">姓名</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span class="span">性别</span>
            <el-select v-model="value">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="span">出生日期</span>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item">
            <span class="span">身高（cm）</span>
            <el-input maxlength="3" minlength="2"></el-input>
          </div>
          <div class="item">
            <span class="span">身份证号</span>
            <el-input maxlength="18" minlength="18"></el-input>
          </div>
          <div class="item">
            <span class="span">体重（kg）</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span class="span">BMI值</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span class="span">年龄</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span class="span">手机号码</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span class="span">床号</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span class="span">分组</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span class="span">住院号</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span class="span">护理等级</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span class="span">责任护士</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span class="span">住址医师</span>
            <el-input></el-input>
          </div>
          <div class="item">
            <span class="span">科室</span>
            <el-input></el-input>
          </div>
          <div class="item item1">
            <span class="span">住址</span>
            <el-input class="width"></el-input>
          </div>
        </div>
        <div class="font">
          <span>并发症</span>
        </div>
        <el-input type="textarea">
        </el-input>
        <div class="font">
          <span>入院评估</span>
        </div>
        <el-input type="textarea">
        </el-input>
        <div class="font">
          <span>家族史</span>
        </div>
        <el-input type="textarea">
        </el-input>
        <div class="font">
          <span>药物过敏史</span>
        </div>
        <el-input type="textarea">
        </el-input>
        <div class="font">
          <span>过去治疗方式</span>
        </div>
        <el-input type="textarea">
        </el-input>
      </div>
      <div class="btns">
        <el-button @click="closePopup">取消</el-button>
        <el-button class="button">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: ''
    }
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 .625rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: calc(100% - 1rem);
    margin-top: .125rem;
    padding: .625rem;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .srcoll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 1rem);

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .close {
      position: absolute;
      top: .375rem;
      right: .375rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .item {
      display: flex;
      align-items: center;
      width: 19%;
      margin-top: .125rem;
      color: #01EEFD;
      font-size: .2rem;

      .span {
        text-align-last: justify;
        margin-right: .125rem;
        white-space: nowrap;
      }

      .el-input,
      .el-select,
      .el-date-picker {
        width: 2.5rem;
      }
    }

    .item1 {
      width: 79.8%;

      .width {
        width: 6rem;
      }
    }

    .font {
      display: flex;
      align-items: center;
      margin-top: .1rem;
      color: #01EEFD;
      font-size: .2rem;
    }

    ::v-deep .el-textarea__inner {
      width: 100%;
      height: .75rem;
      margin-top: .075rem;
      border: 1px solid #01EEFD;
      box-sizing: border-box;
      resize: none;
      background-color: transparent;
      color: #fff;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3rem;
      height: .325rem;
      margin: .25rem auto 0;

      .el-button {
        width: .875rem;
        height: .325rem;
        line-height: .325rem;
        padding: 0;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
