var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab"},[_c('div',{staticClass:"tab-scroll"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"table"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"tr tr1"},[_c('div',{staticClass:"left"},[_vm._m(7),_c('el-checkbox-group',{model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},[_c('el-checkbox',{attrs:{"label":"1"}},[_vm._v("1、纳入慢性病患者健康管理")]),_c('el-checkbox',{attrs:{"label":"2"}},[_vm._v("2、建议复查")]),_c('el-checkbox',{attrs:{"label":"3"}},[_vm._v("3、建议转诊")])],1)],1),_c('div',{staticClass:"right"},[_vm._m(8),_c('el-checkbox-group',{model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},[_c('el-checkbox',{attrs:{"label":"1"}},[_vm._v("戒烟")]),_c('el-checkbox',{attrs:{"label":"2"}},[_vm._v("健康饮酒")]),_c('el-checkbox',{attrs:{"label":"3"}},[_vm._v("饮食")]),_c('el-checkbox',{attrs:{"label":"4"}},[_vm._v("锻炼")]),_c('el-checkbox',{attrs:{"label":"5"}},[_c('span',[_vm._v("减体重（目标")]),_c('el-input',{staticClass:"input",attrs:{"value":"88.0"}}),_c('span',[_vm._v("kg）")])],1),_c('el-checkbox',{attrs:{"label":"6"}},[_vm._v("建议接种疫苗 ")]),_c('el-checkbox',{attrs:{"label":"7"}},[_c('span',[_vm._v("其他")]),_c('el-input',{staticClass:"input1"})],1)],1)],1)])]),_c('div',{staticClass:"son-bottom"},[_c('el-button',[_vm._v("编辑")]),_c('el-button',[_vm._v("保存")]),_c('el-button',[_vm._v("审核")]),_c('el-button',[_vm._v("上传")]),_c('el-button',[_vm._v("打印")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-head"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_c('span',[_vm._v("生化")])]),_c('div',{staticClass:"icon"},[_c('span',{staticClass:"el-icon-check"})])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"label label1"},[_c('span',[_vm._v("血常规")])]),_c('div',{staticClass:"icon red"},[_c('span',{staticClass:"el-icon-close"})])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_c('span',[_vm._v("b超")])]),_c('div',{staticClass:"icon"},[_c('span',{staticClass:"el-icon-check"})])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_c('span',[_vm._v("尿常规")])]),_c('div',{staticClass:"icon"},[_c('span',{staticClass:"el-icon-check"})])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_c('span',[_vm._v("心电图")])]),_c('div',{staticClass:"icon"},[_c('span',{staticClass:"el-icon-check"})])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_c('span',[_vm._v("中医药")])]),_c('div',{staticClass:"icon"},[_c('span',{staticClass:"el-icon-check"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-title"},[_c('span',[_vm._v("体检结果评价报告及健康指导")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"line border"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("编号：304076981")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("姓名：李明明")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("性别:男")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("身份证:130429193205164852")])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("电话:17367759256")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr"},[_c('div',{staticClass:"list"},[_c('span',[_vm._v("一、体检异常结果：")])]),_c('div',{staticClass:"list list1"},[_c('span',[_vm._v("1、血红蛋白偏高：162 g/L;")]),_c('span',[_vm._v("2、高密度胆固醇偏低：0.70mmol/L")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr"},[_c('div',{staticClass:"list"},[_c('span',[_vm._v("二、体检小结（评价）：")])]),_c('div',{staticClass:"list list1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr"},[_c('div',{staticClass:"list"},[_c('span',[_vm._v("三、健康管理：")])]),_c('div',{staticClass:"list list1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr"},[_c('div',{staticClass:"list"},[_c('span',[_vm._v("四、采取的健康指导措施")])]),_c('div',{staticClass:"list list1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('span',[_vm._v("健康指导:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('span',[_vm._v("危险因素控制：:")])])
}]

export { render, staticRenderFns }