<template>
  <div class="tab">
    <div class="head">
      <div class="left">
        <div class="user"></div>
        <span class="text">李明敏</span>
        <span class="text">女</span>
        <span class="text">38岁</span>
        <span class="text">电话：15888888888</span>
        <span class="text">地址：河北省石家庄市桥西区友谊街道258号</span>
        <span class="label organ">高</span>
        <span class="label green">糖</span>
        <span class="label blue">冠</span>
        <span class="label purple">肿</span>
        <span class="label red">精</span>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="title">
          <el-input placeholder="机构名称/诊断名称/就诊名称" suffix-icon="el-icon-search">
          </el-input>
          <div class="title-text">
            <span>体检记录</span>
          </div>
        </div>
        <div class="scroll">
          <div class="scroll-title">
            <span>一年前（2次）</span>
          </div>
          <div class="scroll-list">
            <div class="scroll-item">
              <span>2016-08-03</span>
              <span>石家庄市第一医院</span>
            </div>
            <div class="scroll-item">
              <span>2016-08-03</span>
              <span>石家庄市第一医院</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="scroll">
          <div class="scroll-title">
            <span>健康记录</span>
          </div>
          <div class="scroll-info">
            <span>姓名：程霞</span>
            <span>编号：</span>
          </div>
          <div class="table">
            <div class="tr">
              <div class="td flex1 padding-left">
                <span>考核项</span>
              </div>
              <div class="td flex1">
                <span>缺项</span>
              </div>
              <div class="td flex1">
                <span>完整率</span>
              </div>
            </div>
            <div class="tr">
              <div class="td flex3 padding-left border-right">
                <span>体检日期</span>
              </div>
              <div class="td flex4 padding-left border-right">
                <span>体检日期</span>
              </div>
              <div class="td flex8 padding-left">
                <span>责任医师</span>
              </div>
            </div>
            <div class="tr">
              <div class="td flex2 padding-left border-right">
                <span>内容</span>
              </div>
              <div class="td flex12">
                <span class="padding-left">检查项目</span>
              </div>
            </div>
            <div class="tr">
              <div class="td flex2 padding-left border-right">
                <span>症状</span>
              </div>
              <div class="td flex12">
                <el-checkbox-group v-model="checkList" class="padding-left">
                  <el-checkbox v-for="(item, index) in checks" :key="index" :label="index + ''">{{ item }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="tr tr1">
              <div class="td flex2 padding-left border-right">
                <span>一般状况</span>
              </div>
              <div class="td td-column flex12">
                <div class="list">
                  <div class="list-flex flex2 border-right">
                    <span>体温</span>
                  </div>
                  <div class="list-flex flex2 border-right">
                    <span></span>
                  </div>
                  <div class="list-flex flex3 border-right">
                    <span>脉率</span>
                  </div>
                  <div class="list-flex flex9">
                    <span>67 次/分</span>
                  </div>
                </div>
                <div class="list list1">
                  <div class="list-flex flex2 border-right">
                    <span>呼吸频率</span>
                  </div>
                  <div class="list-flex flex2 border-right">
                    <span>67 次/分</span>
                  </div>
                  <div class="list-flex flex3 border-right">
                    <span>血压</span>
                  </div>
                  <div class="list-flex flex9">
                    <div class="list-item">
                      <div class="list-item-left">
                        <div class="list-item-line">
                          <span>左侧</span>
                        </div>
                        <div class="list-item-line">
                          <span>右侧</span>
                        </div>
                      </div>
                      <div class="list-item-right">
                        <div class="list-item-line">
                          <span>155</span>
                          <span>73</span>
                          <span>mmHg</span>
                        </div>
                        <div class="list-item-line">
                          <span>0</span>
                          <span>0</span>
                          <span>mmHg</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex flex2 border-right">
                    <span>身高</span>
                  </div>
                  <div class="list-flex flex2 border-right">
                    <span>165cm</span>
                  </div>
                  <div class="list-flex flex3 border-right">
                    <span>体重</span>
                  </div>
                  <div class="list-flex flex9">
                    <span>67kg</span>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex flex2 border-right">
                    <span>腰围</span>
                  </div>
                  <div class="list-flex flex2 border-right">
                    <span></span>
                  </div>
                  <div class="list-flex flex3 border-right">
                    <span>体质指数</span>
                  </div>
                  <div class="list-flex flex9">
                    <span>23.3</span>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex flex4 border-right">
                    <span>老年人健康状态自我评估</span>
                  </div>
                  <div class="list-flex list-flex1 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">满意</el-radio>
                      <el-radio label="2">基本满意</el-radio>
                      <el-radio label="3">说不清楚</el-radio>
                      <el-radio label="4">不太满意</el-radio>
                      <el-radio label="5">不满意</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex flex4 border-right">
                    <span>老年人自我生活能力评估</span>
                  </div>
                  <div class="list-flex list-flex1 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">可自理(0-3分)</el-radio>
                      <el-radio label="2">轻度依赖(4-8分)</el-radio>
                      <el-radio label="3">中度依赖(9-18分)</el-radio>
                      <el-radio label="4">不能(9-18分)</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex flex4 border-right">
                    <span>老年人认知功能</span>
                  </div>
                  <div class="list-flex list-flex1 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">粗筛阴性</el-radio>
                      <el-radio label="2">粗筛阳性，老年人智力检查</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex flex4 border-right">
                    <span>老年人情感状态</span>
                  </div>
                  <div class="list-flex list-flex1 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">粗筛阴性</el-radio>
                      <el-radio label="2">粗筛阳性，老年人抑郁检查</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="tr tr2">
              <div class="td flex2 padding-left border-right">
                <span>生活方式</span>
              </div>
              <div class="td td-column flex12">
                <div class="list list1">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>体育锻炼</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>锻炼频率</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">每天</el-radio>
                          <el-radio label="2">每周一次以上</el-radio>
                          <el-radio label="3">偶尔</el-radio>
                          <el-radio label="4">不锻炼</el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>每次锻炼时间</span>
                      </div>
                      <div class="list-flex3">
                        <div class="list-flex1 border-right">
                          <span>分钟</span>
                        </div>
                        <div class="list-flex1 border-right">
                          <span>坚持锻炼时间</span>
                        </div>
                        <div class="list-flex1 border-right">
                          <span></span>
                        </div>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>锻炼方式</span>
                      </div>
                      <div class="list-flex3"></div>
                    </div>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>饮食习惯</span>
                  </div>
                  <div class="list-flex list-flex1 flex12">
                    <el-checkbox-group v-model="checkList">
                      <el-checkbox label="1">荤素均衡</el-checkbox>
                      <el-checkbox label="2">荤食为主</el-checkbox>
                      <el-checkbox label="3">素食为主</el-checkbox>
                      <el-checkbox label="4">嗜盐</el-checkbox>
                      <el-checkbox label="5">嗜油</el-checkbox>
                      <el-checkbox label="6">嗜糖</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="list list1">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>吸烟情况</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>吸烟情况</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">从不吸烟</el-radio>
                          <el-radio label="2">已戒烟</el-radio>
                          <el-radio label="3">吸烟</el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>日吸烟量</span>
                      </div>
                      <div class="list-flex3">
                        <span class="group">平均 支</span>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>每次锻炼时间</span>
                      </div>
                      <div class="list-flex3">
                        <div class="list-flex1 border-right">
                          <span>岁</span>
                        </div>
                        <div class="list-flex1 border-right">
                          <span>戒烟年龄</span>
                        </div>
                        <div class="list-flex1">
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="list list2">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>饮酒情况</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>饮酒频率</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">从不</el-radio>
                          <el-radio label="2">偶尔</el-radio>
                          <el-radio label="3">经常</el-radio>
                          <el-radio label="4">每天</el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>日饮酒量</span>
                      </div>
                      <div class="list-flex3">
                        <span class="group">平均 两</span>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>是否戒酒</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">未戒酒</el-radio>
                          <el-radio label="2">已戒酒</el-radio>
                        </el-radio-group>
                        <span class="span">戒酒年龄：</span>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>每次锻炼时间</span>
                      </div>
                      <div class="list-flex3">
                        <div class="list-flex1 border-right">
                          <span>岁</span>
                        </div>
                        <div class="list-flex1 border-right">
                          <span>近一年是否戒酒</span>
                        </div>
                        <div class="list-flex1">
                          <el-radio-group class="group" v-model="radio">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="2">否</el-radio>
                          </el-radio-group>
                        </div>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>饮酒种类</span>
                      </div>
                      <div class="list-flex3">
                        <el-checkbox-group class="group" v-model="checkList">
                          <el-checkbox label="1">白酒</el-checkbox>
                          <el-checkbox label="2">红酒</el-checkbox>
                          <el-checkbox label="3">啤酒</el-checkbox>
                          <el-checkbox label="4">黄酒</el-checkbox>
                          <el-checkbox label="5">其他</el-checkbox>
                        </el-checkbox-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="list list2">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>职业病危害因素接触史</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-box">
                      <div class="list-box-top">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <span class="text">（工种</span>
                        <el-input class="input"></el-input>
                        <span class="text">从业</span>
                        <el-input class="input"></el-input>
                        <span class="text">年）</span>
                      </div>
                      <div class="list-box-bottom">
                        <div class="list-box-left">
                          <span>毒粉种类：</span>
                        </div>
                        <div class="list-box-right">
                          <div class="list-box-item">
                            <span class="list-width">粉尘</span>
                            <el-input class="input"></el-input>
                            <span class="list-text">防护措施</span>
                            <el-radio-group class="group" v-model="radio">
                              <el-radio label="1">无</el-radio>
                              <el-radio label="2">有</el-radio>
                            </el-radio-group>
                            <el-input class="input"></el-input>
                          </div>
                          <div class="list-box-item">
                            <span class="list-width">放射物质</span>
                            <el-input class="input"></el-input>
                            <span class="list-text">防护措施</span>
                            <el-radio-group class="group" v-model="radio">
                              <el-radio label="1">无</el-radio>
                              <el-radio label="2">有</el-radio>
                            </el-radio-group>
                            <el-input class="input"></el-input>
                          </div>
                          <div class="list-box-item">
                            <span class="list-width">物理因素</span>
                            <el-input class="input"></el-input>
                            <span class="list-text">防护措施</span>
                            <el-radio-group class="group" v-model="radio">
                              <el-radio label="1">无</el-radio>
                              <el-radio label="2">有</el-radio>
                            </el-radio-group>
                            <el-input class="input"></el-input>
                          </div>
                          <div class="list-box-item">
                            <span class="list-width">化学物质</span>
                            <el-input class="input"></el-input>
                            <span class="list-text">防护措施</span>
                            <el-radio-group class="group" v-model="radio">
                              <el-radio label="1">无</el-radio>
                              <el-radio label="2">有</el-radio>
                            </el-radio-group>
                            <el-input class="input"></el-input>
                          </div>
                          <div class="list-box-item">
                            <span class="list-width">其他</span>
                            <el-input class="input"></el-input>
                            <span class="list-text">防护措施</span>
                            <el-radio-group class="group" v-model="radio">
                              <el-radio label="1">无</el-radio>
                              <el-radio label="2">有</el-radio>
                            </el-radio-group>
                            <el-input class="input"></el-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tr tr2 tr3">
              <div class="td flex2 padding-left border-right">
                <span>脏器功能</span>
              </div>
              <div class="td td-column flex12">
                <div class="list list3">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>口腔</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-flex-item">
                      <div class="list-flex1">
                        <span>口唇</span>
                      </div>
                      <div class="list-flex3 list-flex6">
                        <el-radio-group v-model="radio">
                          <el-radio label="1">红润</el-radio>
                          <el-radio label="2">苍白</el-radio>
                          <el-radio label="3">发敢</el-radio>
                          <el-radio label="4">皲裂</el-radio>
                          <el-radio label="5">疱诊</el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1">
                        <span>齿列</span>
                      </div>
                      <div class="list-flex3 list-flex6">
                        <el-checkbox v-model="checkList">正常</el-checkbox>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1">
                        <span></span>
                      </div>
                      <div class="list-flex3 list-flex6">
                        <el-checkbox v-model="checkList">缺齿</el-checkbox>
                        <el-checkbox class="group" v-model="checkList">全口</el-checkbox>
                        <span class="group color">左上：</span>
                        <el-input class="input input-width"></el-input>
                        <span class="group color">左下：</span>
                        <el-input class="input input-width"></el-input>
                        <span class="group color">右上：</span>
                        <el-input class="input input-width"></el-input>
                        <span class="group color">右下：</span>
                        <el-input class="input input-width"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1">
                        <span></span>
                      </div>
                      <div class="list-flex3 list-flex6">
                        <el-checkbox v-model="checkList">龋齿</el-checkbox>
                        <el-checkbox class="group" v-model="checkList">全口</el-checkbox>
                        <span class="group color">左上：</span>
                        <el-input class="input input-width"></el-input>
                        <span class="group color">左下：</span>
                        <el-input class="input input-width"></el-input>
                        <span class="group color">右上：</span>
                        <el-input class="input input-width"></el-input>
                        <span class="group color">右下：</span>
                        <el-input class="input input-width"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1">
                        <span></span>
                      </div>
                      <div class="list-flex3 list-flex6">
                        <el-checkbox v-model="checkList">义齿</el-checkbox>
                        <el-checkbox class="group" v-model="checkList">全口</el-checkbox>
                        <span class="group color">左上：</span>
                        <el-input class="input input-width"></el-input>
                        <span class="group color">左下：</span>
                        <el-input class="input input-width"></el-input>
                        <span class="group color">右上：</span>
                        <el-input class="input input-width"></el-input>
                        <span class="group color">右下：</span>
                        <el-input class="input input-width"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1">
                        <span>咽部</span>
                      </div>
                      <div class="list-flex3 list-flex6">
                        <el-radio-group v-model="radio">
                          <el-radio label="1">无充血</el-radio>
                          <el-radio label="2">充血</el-radio>
                          <el-radio label="3">淋巴滤泡增长</el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>视力</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <span class="color group">左眼:</span>
                    <el-input class="input input-width"></el-input>
                    <span class="color">右眼:</span>
                    <el-input class="input input-width"></el-input>
                    <span class="color group">（矫正视力：左眼</span>
                    <el-input class="input input-width"></el-input>
                    <span class="color">右眼</span>
                    <el-input class="input input-width"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>听力</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">听见</el-radio>
                      <el-radio label="2">听不清或无法听见</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>运动功能</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">可顺利完成</el-radio>
                      <el-radio label="2">无法独立完成任意一个动作</el-radio>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="tr tr2 tr4">
              <div class="td flex2 padding-left border-right">
                <span></span>
              </div>
              <div class="td td-column flex12">
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>眼底</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">正常</el-radio>
                      <el-radio label="2">异常</el-radio>
                    </el-radio-group>
                    <el-input class="input input-width"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>皮肤</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">正常</el-radio>
                      <el-radio label="2">潮红</el-radio>
                      <el-radio label="3">苍白</el-radio>
                      <el-radio label="4">发绀</el-radio>
                      <el-radio label="5">黄染</el-radio>
                      <el-radio label="6">色素沉着</el-radio>
                      <el-radio label="7">其他</el-radio>
                    </el-radio-group>
                    <el-input class="input input-width"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>巩膜</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">正常</el-radio>
                      <el-radio label="2">黄染</el-radio>
                      <el-radio label="3">充血</el-radio>
                      <el-radio label="4">其他</el-radio>
                    </el-radio-group>
                    <el-input class="input input-width"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>淋巴结</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">未触及</el-radio>
                      <el-radio label="2">锁骨上</el-radio>
                      <el-radio label="3">腋窝</el-radio>
                      <el-radio label="4">其他</el-radio>
                    </el-radio-group>
                    <el-input class="input input-width"></el-input>
                  </div>
                </div>
                <div class="list list1">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>肺</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>桶状胸</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">否</el-radio>
                          <el-radio label="2">是</el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>呼吸音</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">正常</el-radio>
                          <el-radio label="2">异常</el-radio>
                        </el-radio-group>
                        <el-input class="input input-width"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>罗音</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">干罗音</el-radio>
                          <el-radio label="3">湿罗音</el-radio>
                          <el-radio label="4">其他</el-radio>
                        </el-radio-group>
                        <el-input class="input input-width"></el-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list list4">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>心脏</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-flex-item">
                      <div class="list-flex3">
                        <span class="group">心率</span>
                        <el-input class="input input-width"></el-input>
                        <span>分钟</span>
                        <span class="group1">心率</span>
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">齐</el-radio>
                          <el-radio label="2">不齐</el-radio>
                          <el-radio label="3">绝对不齐</el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>杂音</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list list2">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>腹部</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>压痛</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>包块</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>肝大</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>脾大</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>移动性杂音</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>下肢水肿</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">无</el-radio>
                      <el-radio label="2">单侧</el-radio>
                      <el-radio label="3">双侧不对称</el-radio>
                      <el-radio label="4">双侧不对称</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>足背动脉搏动</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">未触及</el-radio>
                      <el-radio label="2">触及双侧堆成</el-radio>
                      <el-radio label="3">触及左侧弱或消失</el-radio>
                      <el-radio label="4">触及右侧弱或消失</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>肛门指诊</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">未见异常</el-radio>
                      <el-radio label="2">触痛</el-radio>
                      <el-radio label="3">包块</el-radio>
                      <el-radio label="4">前列腺异常</el-radio>
                      <el-radio label="5">其他</el-radio>
                    </el-radio-group>
                    <el-input class="input"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>乳腺</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">未见异常</el-radio>
                      <el-radio label="2">乳房切除</el-radio>
                      <el-radio label="3">异常泌乳</el-radio>
                      <el-radio label="4">乳腺包块</el-radio>
                      <el-radio label="5">其他</el-radio>
                    </el-radio-group>
                    <el-input class="input"></el-input>
                  </div>
                </div>
                <div class="list list2">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>妇科</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>外阴</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>阴道</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>宫颈</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>宫体</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>附件</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">无</el-radio>
                          <el-radio label="2">有</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>其他</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                  </div>
                </div>
              </div>
            </div>
            <div class="tr tr2 tr5">
              <div class="td flex2 padding-left border-right">
                <span>辅助检查</span>
              </div>
              <div class="td td-column flex12">
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>血常规</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <span class="group">血红蛋白</span>
                    <el-input class="input input-width"></el-input>
                    <span>g/L</span>
                    <span class="group">白细胞</span>
                    <el-input class="input input-width"></el-input>
                    <span>10^9/L</span>
                    <span class="group">血小板</span>
                    <el-input class="input input-width"></el-input>
                    <span>10^9/L</span>
                    <span class="group">其他</span>
                    <el-input class="input"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>尿常规</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <span class="group">尿蛋白</span>
                    <el-input class="input input-width"></el-input>
                    <span class="group">尿糖</span>
                    <el-input class="input input-width"></el-input>
                    <span class="group">尿酮体</span>
                    <el-input class="input input-width"></el-input>
                    <span class="group">尿潜血</span>
                    <el-input class="input input-width"></el-input>
                    <span class="group">其他</span>
                    <el-input class="input"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>空腹血糖</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <span class="group"></span>
                    <el-input class="input"></el-input>
                    <span>mmol/L 或</span>
                    <el-input class="input group"></el-input>
                    <span>mg/L</span>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>心电图</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">正常</el-radio>
                      <el-radio label="2">异常</el-radio>
                    </el-radio-group>
                    <el-input class="input input-width"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>尿微量白蛋白</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <span class="group"></span>
                    <el-input class="input"></el-input>
                    <span>mg/L</span>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>大便潜血</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">阴性</el-radio>
                      <el-radio label="2">阳性</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>糖化血红蛋白</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <span class="group"></span>
                    <el-input class="input"></el-input>
                    <span>%</span>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>乙型肝炎表面抗原</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">阴性</el-radio>
                      <el-radio label="2">阳性</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>肝功能</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <span class="group">血清谷丙转氨酶</span>
                    <el-input class="input input-width"></el-input>
                    <span>U/L</span>
                    <span class="group">血清谷草转氨酶</span>
                    <el-input class="input input-width"></el-input>
                    <span>U/L</span>
                    <span class="group">白蛋白</span>
                    <el-input class="input input-width"></el-input>
                    <span>g/L</span>
                    <span class="group">总胆红素</span>
                    <el-input class="input input-width"></el-input>
                    <span>μmol/L</span>
                    <span class="group">结合胆红素</span>
                    <el-input class="input input-width"></el-input>
                    <span>μmol/L</span>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>肾功能</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <span class="group">血清肌酐</span>
                    <el-input class="input input-width"></el-input>
                    <span>μmol/L</span>
                    <span class="group">血尿素</span>
                    <el-input class="input input-width"></el-input>
                    <span>mmol/L</span>
                    <span class="group">血钾浓度</span>
                    <el-input class="input input-width"></el-input>
                    <span>mmol/L</span>
                    <span class="group">血钠浓度</span>
                    <el-input class="input input-width"></el-input>
                    <span>mmol/L</span>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>血脂</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <span class="group">总胆固醇</span>
                    <el-input class="input input-width"></el-input>
                    <span>mmol/L</span>
                    <span class="group">甘油三酯</span>
                    <el-input class="input input-width"></el-input>
                    <span>mmol/L</span>
                    <span class="group">血清低密度脂蛋白胆固醇</span>
                    <el-input class="input input-width"></el-input>
                    <span>mmol/L</span>
                    <span class="group">血清高密度脂蛋白胆固醇</span>
                    <el-input class="input input-width"></el-input>
                    <span>mmol/L</span>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>胸部X线片</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">正常</el-radio>
                      <el-radio label="2">异常</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="list list4">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>B超</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>腹部B超</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">正常</el-radio>
                          <el-radio label="2">异常</el-radio>
                        </el-radio-group>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>其他</span>
                      </div>
                      <div class="list-flex3">
                        <el-radio-group class="group" v-model="radio">
                          <el-radio label="1">正常</el-radio>
                          <el-radio label="2">异常</el-radio>
                        </el-radio-group>
                        <el-input class="input"></el-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>异常</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">正常</el-radio>
                      <el-radio label="2">异常</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>其他</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                  </div>
                </div>
              </div>
            </div>
            <div class="tr tr2 tr6">
              <div class="td flex2 padding-left border-right">
                <span>现存主要健康问题</span>
              </div>
              <div class="td td-column flex12">
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>脑血管疾病</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-checkbox-group v-model="checkList" class="padding-left">
                      <el-checkbox label="1">未发现</el-checkbox>
                      <el-checkbox label="2">缺血性卒中</el-checkbox>
                      <el-checkbox label="3">脑出血</el-checkbox>
                      <el-checkbox label="4">蛛网膜下腔出血</el-checkbox>
                      <el-checkbox label="5">短暂性脑缺血发作</el-checkbox>
                      <el-checkbox label="6">其他</el-checkbox>
                    </el-checkbox-group>
                    <el-input class="input input-width"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>尿微量白蛋白</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-checkbox-group v-model="checkList" class="padding-left">
                      <el-checkbox label="1">未发现</el-checkbox>
                      <el-checkbox label="2">糖尿病肾病</el-checkbox>
                      <el-checkbox label="3">肾功能衰竭</el-checkbox>
                      <el-checkbox label="4">急性肾炎</el-checkbox>
                      <el-checkbox label="5">慢性肾炎</el-checkbox>
                      <el-checkbox label="6">其他</el-checkbox>
                    </el-checkbox-group>
                    <el-input class="input input-width"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>心脏疾病</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-checkbox-group v-model="checkList" class="padding-left checkbox-group">
                      <el-checkbox label="1">未发现</el-checkbox>
                      <el-checkbox label="2">心肌梗死</el-checkbox>
                      <el-checkbox label="3">心绞痛</el-checkbox>
                      <el-checkbox label="4">冠状动脉血运重建 </el-checkbox>
                      <el-checkbox label="5">充血性心力</el-checkbox>
                      <el-checkbox label="6">心前区疼痛</el-checkbox>
                      <el-checkbox label="7">其他</el-checkbox>
                      <el-input class="input input-width"></el-input>
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>血管疾病</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-checkbox-group v-model="checkList" class="padding-left">
                      <el-checkbox label="1">未发现</el-checkbox>
                      <el-checkbox label="2">夹层动脉瘤</el-checkbox>
                      <el-checkbox label="3">动脉闭塞性疾病</el-checkbox>
                      <el-checkbox label="4">其他</el-checkbox>
                    </el-checkbox-group>
                    <el-input class="input input-width"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>血管疾病</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-checkbox-group v-model="checkList" class="padding-left">
                      <el-checkbox label="1">未发现</el-checkbox>
                      <el-checkbox label="2">视网膜出血或渗出</el-checkbox>
                      <el-checkbox label="3">视乳头水肿</el-checkbox>
                      <el-checkbox label="4">白内障</el-checkbox>
                      <el-checkbox label="5">其他</el-checkbox>
                    </el-checkbox-group>
                    <el-input class="input input-width"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>神经系统疾病</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">未发现</el-radio>
                      <el-radio label="2">有</el-radio>
                    </el-radio-group>
                    <el-input class="input"></el-input>
                  </div>
                </div>
                <div class="list">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>其他系统疾</span>
                  </div>
                  <div class="list-flex list-flex3 flex12">
                    <el-radio-group class="group" v-model="radio">
                      <el-radio label="1">未发现</el-radio>
                      <el-radio label="2">有</el-radio>
                    </el-radio-group>
                    <el-input class="input"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="tr tr2 tr7">
              <div class="td flex2 padding-left border-right">
                <span>住院治疗情况</span>
              </div>
              <div class="td td-column flex12">
                <div class="list list1">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>住院史</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>入/出院日期</span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span>原因</span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span>医疗机构名称</span>
                      </div>
                      <div class="list-flex1 list-flex2">
                        <span>病案号</span>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 list-flex2">
                        <span></span>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 list-flex2">
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list list1">
                  <div class="list-flex list-flex1 flex4 border-right">
                    <span>住院史</span>
                  </div>
                  <div class="list-flex list-flex2 flex12">
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span>建/撤床日期</span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span>原因</span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span>医疗机构名称</span>
                      </div>
                      <div class="list-flex1 list-flex2">
                        <span>病案号</span>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 list-flex2">
                        <span></span>
                      </div>
                    </div>
                    <div class="list-flex-item">
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 border-right">
                        <span></span>
                      </div>
                      <div class="list-flex1 list-flex2">
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tr tr2 tr8">
              <div class="td flex2 padding-left border-right">
                <span>主要用药情况</span>
              </div>
              <div class="td flex12 direction">
                <div class="td-line">
                  <div class="td-line-item td-line-flex4 border-right">
                    <span>药物名称</span>
                  </div>
                  <div class="td-line-item td-line-item1 td-line-flex12">
                    <div class="td-line-box">
                      <div class="td-line-item border-right">
                        <span>用法</span>
                      </div>
                      <div class="td-line-item border-right">
                        <span>用量</span>
                      </div>
                      <div class="td-line-item border-right">
                        <span>用药时间</span>
                      </div>
                      <div class="td-line-item td-line-flex2">
                        <span>服药依从性</span>
                      </div>
                    </div>
                    <div class="td-line-box">
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item td-line-flex2">
                        <span></span>
                      </div>
                    </div>
                    <div class="td-line-box">
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item td-line-flex2">
                        <span></span>
                      </div>
                    </div>
                    <div class="td-line-box">
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item td-line-flex2">
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tr tr2 tr8">
              <div class="td flex2 padding-left border-right">
                <span>非免疫规划预防接种史</span>
              </div>
              <div class="td flex12 direction">
                <div class="td-line">
                  <div class="td-line-item td-line-flex4 border-right">
                    <span>药物名称</span>
                  </div>
                  <div class="td-line-item td-line-item1 td-line-flex12">
                    <div class="td-line-box">
                      <div class="td-line-item border-right">
                        <span>接种日期</span>
                      </div>
                      <div class="td-line-item td-line-flex4">
                        <span>接种机构</span>
                      </div>
                    </div>
                    <div class="td-line-box">
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item td-line-flex4">
                        <span></span>
                      </div>
                    </div>
                    <div class="td-line-box">
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item td-line-flex4">
                        <span></span>
                      </div>
                    </div>
                    <div class="td-line-box">
                      <div class="td-line-item border-right">
                        <span></span>
                      </div>
                      <div class="td-line-item td-line-flex4">
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tr tr2 tr9">
              <div class="td flex2 padding-left border-right">
                <span>健康评价</span>
              </div>
              <div class="td flex12 td-column">
                <el-radio-group class="group" v-model="radio">
                  <el-radio label="1">体检无异常</el-radio>
                  <el-radio label="2">有异常</el-radio>
                </el-radio-group>
                <div class="td-item">
                  <span>异常1</span>
                  <el-input class="input"></el-input>
                </div>
                <div class="td-item">
                  <span>异常2</span>
                  <el-input class="input"></el-input>
                </div>
                <div class="td-item">
                  <span>异常3</span>
                  <el-input class="input"></el-input>
                </div>
                <div class="td-item">
                  <span>异常4</span>
                  <el-input class="input"></el-input>
                </div>
              </div>
            </div>
            <div class="tr tr2 tr10">
              <div class="td flex2 padding-left border-right">
                <span>健康指导</span>
              </div>
              <div class="td flex12 td-box">
                <div class="td-left">
                  <el-checkbox-group v-model="checkList" class="padding-left checkbox-box">
                    <el-checkbox label="1">纳入慢性病患者健康管理</el-checkbox>
                    <el-checkbox label="2">建议复查</el-checkbox>
                    <el-checkbox label="3">建议转诊</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="td-right">
                  <div class="checkbox-title">
                    <span>危险因素控制：</span>
                  </div>
                  <el-checkbox-group v-model="checkList" class="padding-left checkbox-box">
                    <el-checkbox label="1">戒烟 </el-checkbox>
                    <el-checkbox label="2">健康饮酒</el-checkbox>
                    <el-checkbox label="3">饮食</el-checkbox>
                    <el-checkbox label="4">锻炼</el-checkbox>
                    <el-checkbox label="5" class="checkbox-item">减体重（目标<el-input class="input"></el-input>kg）</el-checkbox>
                    <el-checkbox label="6" class="checkbox-item">减体重（目标<el-input class="input"></el-input>kg）</el-checkbox>
                    <el-checkbox label="7" class="checkbox-item">建议接种疫苗<el-input class="input"></el-input></el-checkbox>
                    <el-checkbox label="8" class="checkbox-item">其他<el-input class="input"></el-input></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="tr">
              <div class="td flex2 padding-left border-right">
                <span>录入机构</span>
              </div>
              <div class="td flex12">
              </div>
            </div>

            <div class="tr">
              <div class="td flex2 padding-left border-right">
                <span>录入人</span>
              </div>
              <div class="td td-box flex12">
                <div class="td-tr">
                  <div class="td-td flex4 border-right">
                    <span></span>
                  </div>
                  <div class="td-td flex2 border-right">
                    <span>录入时间</span>
                  </div>
                  <div class="td-td flex4">
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="tr">
              <div class="td flex2 padding-left border-right">
                <span>录入机构</span>
              </div>
              <div class="td flex12">
                <span class="padding-left"></span>
              </div>
            </div>

            <div class="tr">
              <div class="td flex2 padding-left border-right">
                <span>更新人</span>
              </div>
              <div class="td td-box flex12">
                <div class="td-tr">
                  <div class="td-td flex4 border-right">
                    <span></span>
                  </div>
                  <div class="td-td flex2 border-right">
                    <span>更新时间</span>
                  </div>
                  <div class="td-td flex4">
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      checkList: ['0'],
      checks: Object.freeze(['无症状', '头疼', '心悸', '胸闷', '胸痛', '慢性咳嗽', '咳痰', '呼吸苦难', '多饮', '多尿', '体重下降', '乳房胀痛', '乏力', '关节肿痛', '视力模糊', '手脚麻木', '尿急', '尿痛', '便秘', '腹泻', '恶心呕吐', '眼花', '耳鸣', '其他']),
      radio: '1'
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 76px;
    padding-left: 28px;
    padding-right: 109px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .user {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #fff;
      }

      .text {
        margin-left: 15px;
        color: #fff;
        font-size: 16px;
      }

      .label {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 50%;
        margin-left: 20px;
        color: #fff;
        font-size: 16px;
      }

      .organ {
        background-color: #E34D04;
      }

      .green {
        background-color: #109607;
      }

      .blue {
        background-color: #075896;
      }

      .purple {
        background-color: #790796;
      }

      .red {
        background-color: #FA2323;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 83px);
    margin-top: 7px;

    .left {
      width: 360px;
      height: 100%;

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 160px;
        padding: 9px 19px;
        background-color: #0C4969;
        border-radius: 6px;
        box-sizing: border-box;

        .title-text {
          width: 100%;
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #FFFFFF;
          box-sizing: border-box;
          color: #fff;
          font-size: 18px;
        }
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 160px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .scroll-title {
          width: 100%;
          height: 65px;
          line-height: 65px;
          text-align: center;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
        }

        .scroll-list {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 20px;
          border-radius: 6px;
          background-color: #0C4969;
          box-sizing: border-box;

          .scroll-item {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            color: #fff;
            font-size: 16px;
          }
        }
      }
    }

    .right {
      width: calc(100% - 380px);
      height: 100%;
      padding: 9px;
      margin-left: 20px;
      background-color: #0C4969;
      border-radius: 6px;
      box-sizing: border-box;

      .scroll {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        border-top: 1px solid #fff;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 0;
        }

        .scroll-title {
          width: 100%;
          text-align: center;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
        }

        .scroll-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          color: #01EEFD;
          font-size: 22px;
        }

        .table {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 8px;
          border: 1px solid #01EEFD;
          box-sizing: border-box;

          .tr {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 70px;
            border-bottom: 1px solid #01EEFD;
            box-sizing: border-box;

            .td {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 100%;
              box-sizing: border-box;
              color: #01EEFD;
              font-size: 14px;
            }

            .flex1 {
              flex: 1;
            }

            .flex2 {
              flex: 2;
            }

            .flex3 {
              flex: 3;
            }

            .flex4 {
              flex: 4;
            }

            .flex6 {
              flex: 6;
            }

            .flex8 {
              flex: 8;
            }

            .flex9 {
              flex: 9;
            }

            .flex12 {
              flex: 12;
            }

            .padding-left {
              padding-left: 11px;
            }

            .border-right {
              border-right: 1px solid #01EEFD;
            }

            .border-right2 {
              border-right: 2px solid #01EEFD;
            }

            /deep/.el-checkbox__label {
              color: #fff;
            }

            /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
              background-color: #01EEFD;
            }

            /deep/.is-checked .el-checkbox__label {
              color: #01EEFD;
            }

            .td-box {
              display: flex;
              flex-direction: row;
              width: 100%;
              height: 70px;

              .td-tr {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 70px;

                .td-td {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                }
              }
            }
          }

          .tr:last-child {
            border-bottom: none;
          }

          .tr1 {
            height: 560px;

            .td-column {
              flex-direction: column;
            }

            .list {
              display: flex;
              flex-direction: row;
              width: 100%;
              height: 70px;
              border-bottom: 1px solid #01EEFD;
              box-sizing: border-box;

              .list-flex {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 100%;
                text-align: center;
                color: #01EEFD;
                font-size: 14px;
              }

              .list-flex1 {
                justify-content: flex-start;

                .group {
                  margin-left: 18px;
                }

                /deep/.el-radio__inner {
                  background-color: transparent;
                }

                /deep/.el-radio__label {
                  color: #fff;
                }

                /deep/.el-radio__input.is-checked .el-radio__inner {
                  background-color: #01EEFD;
                }

                /deep/.el-radio__input.is-checked+.el-radio__label {
                  color: #01EEFD;
                }
              }

              .flex1 {
                flex: 1;
              }

              .flex2 {
                flex: 2;
              }

              .flex3 {
                flex: 3;
              }

              .flex4 {
                flex: 4;
              }

              .flex9 {
                flex: 9;
              }

              .flex12 {
                flex: 12;
              }

              .border-right {
                border-right: 1px solid #01EEFD;
                box-sizing: border-box;
              }

              .list-item {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 100%;

                .list-item-left {
                  width: 91px;
                  height: 100%;

                  .list-item-line {
                    width: 100%;
                    height: 70px;
                    line-height: 70px;
                    text-align: center;
                    border-right: 1px solid #01EEFD;
                    box-sizing: border-box;
                    color: #01EEFD;
                    font-size: 14px;
                  }
                }

                .list-item-right {
                  width: calc(100% - 91px);
                  height: 100%;

                  .list-item-line {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: 70px;
                    line-height: 70px;
                    color: #01EEFD;
                    font-size: 14px;

                    span {
                      width: 120px;
                    }
                  }

                  .list-item-line:first-child {
                    border-bottom: 1px solid #01EEFD;
                    box-sizing: border-box;
                  }
                }
              }
            }

            .list:last-child {
              border-bottom: none;
            }

            .list1 {
              height: 140px;
            }
          }

          .tr2 {
            height: 1190px;

            .td-column {
              flex-direction: column;

              .list {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 70px;
                border-bottom: 1px solid #01EEFD;
                box-sizing: border-box;

                .list-flex {
                  display: flex;
                  flex-direction: row;
                  height: 100%;
                  text-align: center;
                  color: #01EEFD;
                  font-size: 14px;

                  .list-flex-item {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: 70px;
                    border-bottom: 1px solid #01EEFD;
                    box-sizing: border-box;
                  }

                  .list-flex-item1 {
                    height: 280px;

                    .checkbox-label {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    }
                  }

                  .list-flex-item:last-child {
                    border-bottom: none;
                  }

                  .list-flex1 {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    height: 100%;
                    color: #01EEFD;
                    font-size: 14px;
                  }

                  .list-flex2 {
                    flex: 2;
                  }

                  .list-flex3 {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex: 3;
                    height: 100%;
                  }

                  .list-flex6 {
                    flex: 6;
                  }

                  .group {
                    margin-left: 18px;
                  }

                  .group1 {
                    margin-left: 74px;
                  }

                  .span {
                    margin-left: 20px;
                    margin-bottom: 2px;
                    color: #fff;
                    font-size: 14px;
                  }

                  .color {
                    color: #fff;
                  }

                  .input {
                    width: 123px;
                    margin-left: 10px;

                    /deep/.el-input__inner {
                      background-color: transparent;
                      border: none;
                      border-radius: 0;
                      border-bottom: 1px solid #fff;
                      color: #fff;
                      font-size: 14px;
                    }
                  }

                  .input-width {
                    width: 58px;
                  }

                  .color {
                    color: #fff;
                  }

                  /deep/.el-radio__inner {
                    background-color: transparent;
                  }

                  /deep/.el-radio__label {
                    color: #fff;
                  }

                  /deep/.el-radio__input.is-checked .el-radio__inner {
                    background-color: #01EEFD;
                  }

                  /deep/.el-radio__input.is-checked+.el-radio__label {
                    color: #01EEFD;
                  }
                }

                .list-flex1 {
                  align-items: center;
                  justify-content: center;
                }

                .list-flex2 {
                  flex-direction: column;
                }

                .list-flex3 {
                  align-items: center;
                }

                .flex4 {
                  flex: 4;
                }

                .flex12 {
                  flex: 12;
                }
              }

              .list1 {
                height: 210px;
              }

              .list2 {
                height: 350px;
              }

              .list3 {
                height: 420px;
              }

              .list4 {
                height: 140px;
              }

              .list:last-child {
                border-bottom: none;
              }

              .border-right {
                border-right: 1px solid #01EEFD;
                box-sizing: border-box;
              }

              .list-box {
                width: 100%;
                height: 100%;
                padding: 20px;
                box-sizing: border-box;

                .list-box-top {
                  display: flex;
                  flex-direction: row;
                  align-items: center;

                  .text {
                    color: #fff;
                    font-size: 14px;
                  }
                }

                .list-box-bottom {
                  display: flex;
                  flex-direction: row;
                  margin-top: 10px;
                }

                .list-box-left {
                  margin-top: 10px;
                  color: #fff;
                  font-size: 14px;
                }

                .list-box-right {
                  display: flex;
                  flex-direction: column;
                  margin-left: 20px;

                  .list-box-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: #fff;
                    font-size: 14px;

                    .list-width {
                      width: 70px;
                      text-align-last: justify;
                    }

                    .list-text {
                      margin: 0 10px;
                      color: #fff;
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }

          .tr3 {
            height: 630px;
          }

          .tr4 {
            height: 1680px;
          }

          .tr5 {
            height: 1120px;
          }

          .tr6 {
            height: 490px;
          }

          .tr7 {
            height: 420px;
          }

          .tr8 {
            height: 280px;

            .direction {
              display: flex;
              flex-direction: column;
            }

            .td-line {
              display: flex;
              flex-direction: row;
              width: 100%;
              height: 100%;
              border-bottom: 1px solid #01EEFD;
              box-sizing: border-bottom;

              .td-line-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                flex: 1;
                height: 100%
              }

              .td-line-item1 {
                flex-direction: column !important;
              }

              .td-line-box {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 70px;
                border-bottom: 1px solid #01EEFD;
                box-sizing: border-bottom;
              }

              .td-line-box:last-child {
                border-bottom: none;
              }

              .td-line-flex2 {
                flex: 2;
              }

              .td-line-flex4 {
                flex: 4;
              }

              .td-line-flex12 {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex: 12
              }
            }

            .td-line:last-child {
              border-bottom: none;
            }
          }

          .tr9 {
            height: 350px;

            .td-column {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
            }

            .el-radio {
              margin-top: 20px;
            }

            .td-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              margin-left: 18px;
            }

            .group {
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-left: 18px;
            }

            .input {
              width: 123px;
              margin-left: 10px;

              /deep/.el-input__inner {
                background-color: transparent;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #fff;
                color: #fff;
                font-size: 14px;
              }
            }

            .input-width {
              width: 58px;
            }

            .color {
              color: #fff;
            }

            /deep/.el-radio__inner {
              background-color: transparent;
            }

            /deep/.el-radio__label {
              color: #fff;
            }

            /deep/.el-radio__input.is-checked .el-radio__inner {
              background-color: #01EEFD;
            }

            /deep/.el-radio__input.is-checked+.el-radio__label {
              color: #01EEFD;
            }
          }

          .tr10 {
            height: 350px;

            .td-box {
              display: flex;
              flex-direction: row;
              height: 350px;

              .td-left {
                flex: 2;
                height: 350px;
                border-right: 1px solid #01EEFD;

                .checkbox-box {
                  display: flex;
                  flex-direction: column;

                  .el-checkbox {
                    margin-top: 20px;
                  }
                }
              }

              .td-right {
                flex: 3;
                height: 350px;

                .checkbox-title {
                  height: 50px;
                  line-height: 50px;
                  padding-left: 18px;
                  box-sizing: border-box;
                }

                .checkbox-item {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  width: 100%;
                  margin-top: 20px;
                }

                .input {
                  width: 123px;
                  margin-left: 10px;

                  /deep/.el-input__inner {
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid #fff;
                    color: #fff;
                    font-size: 14px;
                  }
                }

                .text {
                  width: 120px;
                  height: 40px;
                }

                .input-width {
                  width: 58px;
                }
              }
            }
          }

          .checkbox-group {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
</style>
