<template>
  <div class="children-page">
    <div class="head">
      <el-button type="primary">随访记录</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="input input1">
      <div class="item">
        <div class="key">
          <span>管辖范围</span>
        </div>
        <el-select v-model="value" placeholder="我管辖的档案">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>个人档案编号</span>
        </div>
        <el-input v-model="dabh"></el-input>
        <el-select v-model="value" placeholder="医疗机构" class="margin">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-radio v-model="radio" label="1">只查本级</el-radio>
      <div class="item">
        <div class="key">
          <span>建档日期范围</span>
        </div>
        <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="item">
        <el-select v-model="value" placeholder="身份证">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div class="key">
          <span> 证件号:</span>
        </div>
        <el-input v-model="params.sfzh"></el-input>
      </div>
      <div class="item">
        <el-button type="primary" @click="getList1">查询</el-button>
        <el-button type="primary" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="input">
      <el-button type="primary" size="small">新增</el-button>
    </div>
    <el-table :data="list1" border height="50%">
      <el-table-column type="selection" width="50">
      </el-table-column>
      <el-table-column prop="name" label="姓名" :width="flexWidth('name', list1, '姓名')">
      </el-table-column>
      <el-table-column prop="xb" label="性别" :width="flexWidth('xb', list1, '性别')">
        <template slot-scope="scope">
          <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="age" label="年龄" :width="flexWidth('age', list1, '年龄')">
      </el-table-column>
      <el-table-column prop="sfzh" label="身份证号" :width="flexWidth('sfzh', list1, '身份证号')">
      </el-table-column>
      <el-table-column prop="time" label="随访日期" :width="flexWidth('time', list1, '随访日期')">
      </el-table-column>
      <el-table-column prop="glys" label="管理医生" :width="flexWidth('glys', list1, '管理医生')">
      </el-table-column>
      <el-table-column prop="title" label="随访类型" :width="flexWidth('title', list1, '随访类型')">
      </el-table-column>
      <el-table-column prop="sfms" label="随访模式" :width="flexWidth('sfms', list1, '随访模式')">
        <template slot-scope="scope">
          <span>{{ scope.row.sfms === '1' ? '平台' : scope.row.sfms === '2' ? '传统' :  '' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="xzjdmc" label="乡镇/社区" :width="flexWidth('xzjdmc', list1, '乡镇/社区')">
      </el-table-column>
      <el-table-column prop="cwhmc" label="村/街道" :width="flexWidth('cwhmc', list1, '村/街道')">
      </el-table-column>
      <el-table-column prop="date" label="随访方式" :width="flexWidth('date', list1, '随访方式')">
      </el-table-column>
      <el-table-column prop="date" label="责任人" :width="flexWidth('date', list1, '责任人')">
      </el-table-column>
      <el-table-column prop="ssjgmc" label="所属机构" :width="flexWidth('ssjgmc', list1, '所属机构')">
      </el-table-column>
      <el-table-column label="操作" width="340" fixed="right">
        <template slot-scope="scope">
          <div class="operate">
            <el-button type="primary" size="mini" @click="openUpdate(scope.row)">查看</el-button>
            <el-button type="primary" size="mini" @click="openUpdate(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini">删除</el-button>
            <el-button type="primary" size="mini">查体报告</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total" hide-on-single-page>
      </el-pagination>
    </div>

    <Services :params="params" v-if="showUpdate" @close="closeUpdate"></Services>
    <GxyUpdate v-if="showGxyUpdate" :selectSfzh="selectSfzh" :sfjlid="sfjlid" @close="closeGxyUpdate"></GxyUpdate>
    <TnbUpdate v-if="showTnbUpdate" :selectSfzh="selectSfzh" :sfjlid="sfjlid" @close="closeTnbUpdate"></TnbUpdate>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Services from './services.vue'
import GxyUpdate from './gxyUpdate.vue'
import TnbUpdate from './tnbUpdate.vue'
export default {
  props: {
    selectSfzh: {
      type: String,
      default: ''
    }
  },
  components: {
    Services,
    GxyUpdate,
    TnbUpdate
  },
  data () {
    return {
      options: [],
      dabh: '',
      value: '',
      radio: '',
      value1: '',
      showUpdate: false,
      showGxyUpdate: false,
      showTnbUpdate: false,
      sfjlid: '',
      sfzh: '',
      list1: [],
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        tmcode: '',
        sfzh: '',
        ssjgbh: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList1()
  },
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) {
        return title.length * 30 + 'px'
      }
      let flexWidth = 0
      let columnContent = ''
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) {
        columnContent = title
      } else {
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openUpdate (obj) {
      console.log(obj)
      this.sfjlid = obj.sfjlid || ''
      if (obj.value === 'G') {
        this.openGxyUpdate()
      } else if (obj.value === 'T') {
        this.openTnbUpdate()
      }
    },
    closeUpdate () {
      this.showUpdate = false
    },
    handleSizeChange (val) {},
    handleCurrentChange (val) {
      this.query1.pageNum = val
      this.getList1()
    },
    async getList1 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-125691714
      try {
        this.list1 = []
        // this.params.sfzh = this.params.sfzh || this.selectSfzh
        this.params.ssjgbh = this.loginInfo.jgbh || ''
        const { data: res } = await this.$http.post('/api/jkct/app/getzhgwsfjl', this.params)
        console.log('随访记录列表查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data.length < 1) return this.$message.error('暂无数据')
        this.list1 = res.data
        // this.query1.total = this.list1.length
      } catch (error) {
        console.log('随访记录列表查询请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    openGxyUpdate () {
      this.showGxyUpdate = true
    },
    closeGxyUpdate () {
      this.sfjlid = ''
      this.showGxyUpdate = false
    },
    openTnbUpdate () {
      this.showTnbUpdate = true
    },
    closeTnbUpdate () {
      this.sfjlid = ''
      this.showTnbUpdate = false
    },
    clear () {
      this.dabh = ''
      this.radio = ''
      this.value1 = ''
      this.sfzh = ''
      this.params.sfzh = ''
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: calc(100% - 10px);
  height: 100%;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
    }
  }

  .input {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 10px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #000;
        font-size: 16px;
      }

      .margin {
        margin-left: 20px;
      }

      .green {
        width: 90px;
        height: 38px;
        line-height: 38px;
        padding: 0;
        margin: 0;
        margin-right: 20px;
        text-align: center;
        background-color: #04B4D0;
        color: #fff;
      }
    }

    .search {
      width: 300px;
    }
  }

  .input1 {
    flex-wrap: wrap;
    height: 120px;
    border-bottom: 1px solid #000;
  }

  .el-table {
    margin-top: 20px;
  }

  .operate {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    span {
      cursor: pointer;
      color: #0077F9;
    }
  }

  /deep/.el-table__cell {
    text-align: center;
    color: #000;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
  }
}
</style>
