<template>
  <div class="details">
    <div class="details-box">
      <div class="details-box-item">
        <div class="details-box-title">
          <span>可用的权限</span>
        </div>
        <el-tree :data="data" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node }">
            <span class="tree-label">{{ node.label }}</span>

          </span>
          <span>新增</span>
        </el-tree>
      </div>
      <div class="details-box-item">
        <div class="details-box-title">
          <span>业务角色</span>
        </div>
        <el-radio-group v-model="radio">
            <el-radio :label="0">
              <span class="width">角色A</span>
              <span class="blue">查看权限</span>
            </el-radio>
            <el-radio :label="1">
              <span class="width">角色B</span>
              <span class="blue">查看权限</span>
            </el-radio>
            <el-radio :label="2">
              <span class="width">角色C</span>
              <span class="blue">查看权限</span>
            </el-radio>
            <el-radio :label="3">
              <span class="width">角色D</span>
              <span class="blue">查看权限</span>
            </el-radio>
            <el-radio :label="4">
              <span class="width">角色E</span>
              <span class="blue">查看权限</span>
            </el-radio>
          </el-radio-group>
      </div>
    </div>
    <div class="position">
      <div class="return" @click="returnPage">
        <span>保存</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      showUpdate: false,
      imageUrl: '',
      data: [
        {
          id: 1,
          label: 'A层级菜单1',
          children: [{
            id: 11,
            label: 'B层级菜单1'
          }, {
            id: 12,
            label: 'B层级菜单2'
          }, {
            id: 13,
            label: 'B层级菜单3'
          }]
        },
        {
          id: 2,
          label: 'A层级菜单1',
          children: []
        },
        {
          id: 3,
          label: 'A层级菜单1',
          children: [{
            id: 31,
            label: 'B层级菜单1'
          }, {
            id: 32,
            label: 'B层级菜单2'
          }, {
            id: 33,
            label: 'B层级菜单3'
          }]
        },
        {
          id: 4,
          label: 'A层级菜单1',
          children: [{
            id: 11,
            label: 'B层级菜单1'
          }, {
            id: 12,
            label: 'B层级菜单2'
          }, {
            id: 13,
            label: 'B层级菜单3'
          }]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      radio: 3
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    returnPage () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.details {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #0A2C51;
  border: 2px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .details-box {
    display: flex;
    width: 100%;
    height: calc(100% - 50px);
    padding: 0 20px;
    box-sizing: border-box;

    .details-box-item {
      width: 300px;
      height: 100%;

      .details-box-title {
        width: 100%;
        color: #04B4D0;
        font-size: 20px;
      }

      .el-radio-group {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        .el-radio {
          margin-bottom: 20px;
          color: #fff;
          font-size: 18px;
        }

        /deep/.el-radio__input.is-checked .el-radio__inner {
          background-color: #01EEFD;
        }

        /deep/.el-radio__input.is-checked+.el-radio__label {
          color: #01EEFD;
        }

        .width {
          display: inline-block;
          width: 70px;
        }

        .blue {
          color: #01EEFD;
        }
      }

      .el-tree {
        margin-top: 30px;
        background-color: transparent;
        color: #01EEFD;
        font-size: 18px;

        .el-button {
          padding-top: 4px;
          padding-bottom: 4px;
          color: #01EEFD;
          font-size: 16px;
          border-bottom: 1px solid #01EEFD;
          box-sizing: border-box;

          span {
            text-decoration: dashed;
          }
        }

        /deep/.el-tree-node__content:hover {
          background-color: transparent;
        }

        /deep/.el-tree-node:focus>.el-tree-node__content {
          background-color: transparent;
        }

        .tree-label {
          display: inline-block;
          width: 140px;
        }
      }

      .tree-button {
        margin-left: 50px;
      }

      .tree-button1 {
        margin-left: 32px;
      }

      /deep/.el-checkbox__inner {
        background-color: transparent;
      }
    }
  }

  .position {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  .return {
    width: 70px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
