<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-01-02 10:56:32
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-01-02 11:11:19
 * @FilePath: \visions\src\views\zhyl\smartPublicGuard\healthTestRun\healthTest\print.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="added">
    <div class="added-box">
      <div class="added-head">
        <span>批量打印</span>
        <span class="el-icon-close close" @click="close"></span>
      </div>
      <div class="added-box">
        <div class="key">
          <span>打印内容</span>
        </div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="1">全部：3</el-checkbox>
          <el-checkbox label="2">血常规缺失：0</el-checkbox>
          <el-checkbox label="3">心电图缺失：0</el-checkbox>
          <el-checkbox label="3">尿常规缺失：0</el-checkbox>
          <el-checkbox label="3">一般状况缺失：0</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="added-bottom">
        <el-button type="primary" @click="close">确认</el-button>
        <el-button type="primary" @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checkList: []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .added {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);

    .added-box {
      width: 70%;
      height: 40%;
      padding: 0 20px;
      border-radius: 12px;
      background-color: #fff;
      box-sizing: border-box;

      .added-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        color: #000;
        font-size: 20px;

        .close {
          font-size: 24px;
          cursor: pointer;
        }
      }

      .added-box {
        display: flex;
        align-items: center;
        width: 100%;
        height: calc(100% - 130px);

        .key {
          color: #000;
          font-size: 18px;
        }

        .el-checkbox-group {
          display: flex;
          align-items: center;
          margin-left: 20px;
        }

        .el-checkbox {
          display: flex;
          align-items: center;
        }

        /deep/.el-checkbox__label {
          color: #000;
          font-size: 18px;
        }
      }

      .added-bottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 70px;
      }
    }
  }
</style>
