<template>
  <div class="page">
    <div class="switch">
      <span class="item" :class="{ item1: switchIndex === index }" v-for="(item, index) in switchs" :key="index"
        @click="switchClick(index)">{{ item }}</span>
    </div>
    <div class="content" v-if="switchIndex === 0">
      <div class="line">
        <div class="title">
          <span>患者总览</span>
        </div>
        <div class="count">
          <div class="count-item">
            <img src="../../img/ssjk/count1.png" alt="入院">
            <span>入院1人</span>
          </div>
          <div class="count-item">
            <img src="../../img/ssjk/count2.png" alt="入院">
            <span>出院0人</span>
          </div>
          <div class="count-item">
            <img src="../../img/ssjk/count3.png" alt="入院">
            <span>总人数1人</span>
          </div>
        </div>
      </div>
      <div class="line line1">
        <div class="echarts" id="echarts1"></div>
        <div class="echarts1" id="echarts2"></div>
      </div>
      <div class="line line1">
        <div class="echarts2" id="echarts3"></div>
      </div>
      <div class="line">
        <div class="search">
          <el-input class="width" placeholder="姓名/床位号/身份证号" suffix-icon="el-icon-search"></el-input>
          <el-select v-model="value" placeholder="性别">
            <el-option label="女" value="1">
            </el-option>
            <el-option label="男" value="2">
            </el-option>
          </el-select>
          <el-select v-model="value" placeholder="患者状况">
            <el-option label="1" value="1">
            </el-option>
            <el-option label="2" value="2">
            </el-option>
          </el-select>
          <el-select v-model="value" placeholder="选择分组">
            <el-option label="1" value="1">
            </el-option>
            <el-option label="2" value="2">
            </el-option>
          </el-select>
          <el-select v-model="value" placeholder="年龄段">
            <el-option label="1" value="1">
            </el-option>
            <el-option label="2" value="2">
            </el-option>
          </el-select>
          <el-select v-model="value" placeholder="选择科室">
            <el-option label="1" value="1">
            </el-option>
            <el-option label="2" value="2">
            </el-option>
          </el-select>
          <el-button size="small" type="primary">搜索</el-button>
          <el-button size="small" type="info">重置</el-button>
        </div>
        <el-table :data="tableData" height="456" border>
          <el-table-column prop="name" label="床位">
          </el-table-column>
          <el-table-column prop="date" label="患者姓名">
          </el-table-column>
          <el-table-column prop="date" label="性别">
          </el-table-column>
          <el-table-column prop="type" label="年龄">
          </el-table-column>
          <el-table-column prop="type1" label="科室">
          </el-table-column>
          <el-table-column prop="type2" label="主治医师">
          </el-table-column>
          <el-table-column prop="type" label="入院日期">
          </el-table-column>
          <el-table-column prop="type" label="出院日期">
          </el-table-column>
          <el-table-column prop="type" label="分组">
          </el-table-column>
          <el-table-column prop="type" label="状态">
          </el-table-column>
          <el-table-column prop="type" label="检测次数">
          </el-table-column>
          <el-table-column label="操作" width="140">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex">
                <span @click="openSeeInfo">详情</span>
                <span @click="openSwitchGroup">切换分组></span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-else-if="switchIndex === 1">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-input class="search-input" placeholder="姓名/床位号/身份证号" suffix-icon="el-icon-search">
            </el-input>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">重置</el-button>
          </div>
          <div class="right" @click="openAddPatient">
            <span class="el-icon-plus"></span>
          </div>
        </div>
        <el-table :data="tableData" height="400" border>
          <el-table-column prop="name" label="科室">
          </el-table-column>
          <el-table-column prop="date" label="病区--病房">
          </el-table-column>
          <el-table-column prop="date" label="床号">
          </el-table-column>
          <el-table-column prop="type" label="姓名">
          </el-table-column>
          <el-table-column prop="type1" label="年龄">
          </el-table-column>
          <el-table-column label="操作" width="160">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex">
                <span @click="openSeeInfo">患者详情</span>
                <span @click="openHandlingDischarge">办理出院</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-else-if="switchIndex === 2">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-input class="search-input" placeholder="姓名/床位号/身份证号" suffix-icon="el-icon-search">
            </el-input>
            <el-select v-model="value" placeholder="性别">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="选择分组">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-date-picker type="date" class="date" placeholder="出院时间">
            </el-date-picker>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">重置</el-button>
          </div>
        </div>
        <el-table :data="tableData" height="400" border>
          <el-table-column prop="name" label="患者姓名">
          </el-table-column>
          <el-table-column prop="date" label="性别">
          </el-table-column>
          <el-table-column prop="date" label="年龄">
          </el-table-column>
          <el-table-column prop="type" label="身份证号">
          </el-table-column>
          <el-table-column prop="type1" label="科室">
          </el-table-column>
          <el-table-column prop="type1" label="主治医生">
          </el-table-column>
          <el-table-column prop="type1" label="入院日期">
          </el-table-column>
          <el-table-column prop="type1" label="出院日期">
          </el-table-column>
          <el-table-column prop="type1" label="分组">
          </el-table-column>
          <el-table-column prop="type1" label="检测次数">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex flex1">
                <span @click="openSeeInfo">患者详情</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 详情 -->
    <SeeInfo v-if="showSeeInfo" @close="closeSeeInfo"></SeeInfo>
    <!-- 切换分组 -->
    <SwitchGroup v-if="showSwitchGroup" @close="closeSwitchGroup"></SwitchGroup>
    <!-- 新增患者 -->
    <AddPatient v-if="showAddPatient" @close="closeAddPatient"></AddPatient>
    <!-- 办理出院 -->
    <HandlingDischarge v-if="showHandlingDischarge" @close="closeHandlingDischarge"></HandlingDischarge>
  </div>
</template>

<script>
import SeeInfo from './seeInfo.vue'
import SwitchGroup from './switchGroup.vue'
import AddPatient from './addPatient.vue'
import HandlingDischarge from './handlingDischarge.vue'
export default {
  components: {
    SeeInfo,
    SwitchGroup,
    AddPatient,
    HandlingDischarge
  },
  data () {
    return {
      switchIndex: 0,
      switchs: Object.freeze(['患者分析', '住院患者', '出院患者']),
      myChart1: null,
      myChart2: null,
      myChart3: null,
      tableData: [{
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '已结束'
      }, {
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      value: '',
      options: [{
        value: '',
        label: ''
      }],
      showSeeInfo: false,
      showSwitchGroup: false,
      showAddPatient: false,
      showHandlingDischarge: false
    }
  },
  mounted () {
    if (this.switchIndex === 0) {
      this.canvas()
      window.addEventListener('resize', this.getresize)
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    switchClick (index) {
      if (index === this.switchIndex) return
      this.switchIndex = index
      if (index === 0) {
        this.$nextTick(() => {
          this.canvas()
        })
      } else {
        window.removeEventListener('resize', this.getresize)
      }
    },
    getresize () {
      this.myChart1.resize()
      this.myChart2.resize()
      this.myChart3.resize()
    },
    canvas () {
      this.canvas1()
      this.canvas2()
      this.canvas3()
      window.addEventListener('resize', this.getresize)
    },
    canvas1 () {
      const datas = [
        { value: 23, name: '23人/63%' },
        { value: 13, name: '13人/37%' }
      ]
      const colors = ['#0089F6', '#00DDF6']
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '性别对比',
          left: 'left',
          textStyle: {
            color: '#000',
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        color: colors,
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        series: [
          {
            name: '性别对比',
            type: 'pie',
            radius: '50%',
            data: datas.map((item, index) => {
              item.label = {
                color: colors[index]
              }
              return item
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              color: ['#0089F6', '#00DDF6']
            }
          }
        ]
      }
      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      const datas = [
        { value: 23, name: '0-20岁' },
        { value: 13, name: '21-40岁' },
        { value: 13, name: '41-50岁' },
        { value: 13, name: '51-60岁' },
        { value: 13, name: '61-70岁' },
        { value: 13, name: '70岁以上' }
      ]
      const colors = ['#30F05D', '#0089F6', '#00DDF6', '#FFCB23', '#CE7500', '#FF2A2A']
      var chartDom = document.getElementById('echarts2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '年龄对比',
          left: 'left',
          textStyle: {
            color: '#000',
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        color: colors,
        legend: {
          orient: 'vertical',
          left: 'right',
          textStyle: {
            color: '#000'
          }
        },
        series: [
          {
            name: '性别对比',
            type: 'pie',
            radius: '50%',
            data: datas.map((item, index) => {
              item.label = {
                color: colors[index]
              }
              return item
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              color: ['#0089F6', '#00DDF6']
            }
          }
        ]
      }
      option && this.myChart2.setOption(option)
    },
    canvas3 () {
      var chartDom = document.getElementById('echarts3')
      this.myChart3 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '分组对比',
          left: 'left',
          textStyle: {
            color: '#000',
            fontWeight: '400'
          }
        },
        xAxis: {
          type: 'category',
          data: ['1型糖尿病', '2型糖尿病', '妊娠型糖尿病', '特殊型糖尿病', '其他', '糖尿病合并高血压', '高尿酸血症', '3级高血压', '2级高血压', '1级高血压'],
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 12
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#000'
            }
          },
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 16
          }
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130, 70, 110, 130],
            type: 'bar',
            barWidth: '20',
            itemStyle: {
              color: '#0077F9'
            }
          }
        ],
        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            disabled: false,
            zoomLock: true,
            preventDefaultMouseMove: false,
            filterMode: 'empty',
            type: 'inside',
            startValue: 0, // 从头开始。
            endValue: 6 // 一次性展示6个。
          }

        ]
      }
      option && this.myChart3.setOption(option)
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openSeeInfo () {
      this.showSeeInfo = true
    },
    closeSeeInfo () {
      this.showSeeInfo = false
    },
    openSwitchGroup () {
      this.showSwitchGroup = true
    },
    closeSwitchGroup () {
      this.showSwitchGroup = false
    },
    openAddPatient () {
      this.showAddPatient = true
    },
    closeAddPatient () {
      this.showAddPatient = false
    },
    openHandlingDischarge () {
      this.showHandlingDischarge = true
    },
    closeHandlingDischarge () {
      this.showHandlingDischarge = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .switch {
    display: flex;
    width: 100%;
    height: 40px;

    .item {
      height: 40px;
      line-height: 40px;
      margin-right: 20px;
      padding: 0 10px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      background-color: rgba(115, 115, 115, .4);
      cursor: pointer;
    }

    .item1 {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  .content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 50px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .line {
      width: 100%;
      padding: 20px;
      border: 4px solid rgba(0,0,0, .27);
      box-sizing: border-box;
      border-bottom: none;

      .title {
        color: #000;
        font-size: 20px;
      }

      .count {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        margin-top: 10px;

        .count-item {
          display: flex;
          align-items: center;
          width: 360px;
          height: 40px;
          margin-right: 20px;
          background: linear-gradient(79deg, #0A477B 0%, #0E67AD 100%);
          color: #fff;
          font-size: 18px;

          img {
            width: 30px;
            height: 30px;
            margin: 0 20px;
          }
        }
      }

      .echarts {
        width: 398px;
        height: 330px;
      }

      .echarts1 {
        width: 40%;
        height: 330px;
      }

      .echarts2 {
        width: 100%;
        height: 395px;
      }

      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;

        .width {
          width: 246px;
        }

        .el-select {
          width: 120px;
        }
      }
    }

    .line1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }
    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 40px;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      color: #0e75d4;
      font-size: 15px;

      span {
        cursor: pointer;
      }
    }

    .flex1 {
      justify-content: center;
    }

    .content-box {
      width: 100%;
      height: 100%;
      padding: 20px;
      border: 4px solid #073F73;
      box-sizing: border-box;

      .content-box-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 260px;
          }

          .el-select {
            width: 200px;
            margin-left: 10px;
          }

          .date {
            margin-left: 10px;
          }

          .blue,
          .gray {
            margin-left: 40px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            background-color: #284867;
          }
        }

        .right {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
