<template>
  <div class="message">
    <div class="message-box">
      <div class="message-head">
        <div class="message-title">
          <span></span>
        </div>
        <div class="message-title">
          <span>上传任务</span>
        </div>
        <div class="message-close" @click="close">
          <span class="el-icon-close"></span>
        </div>
      </div>
      <div class="message-table">
        <el-table :data="tableData" height="100%" border>
          <el-table-column prop="id" label="序号" :width="flexWidth('id', tableData, '序号')">
          </el-table-column>
          <el-table-column prop="name" label="机构名称" width="140"></el-table-column>
          <el-table-column prop="sfzh" label="机构级别" :width="flexWidth('sfzh', tableData, '机构级别')">
          </el-table-column>
          <el-table-column prop="age" label="上传类型" :width="flexWidth('age', tableData, '上传类型')">
          </el-table-column>
          <el-table-column prop="tmcode" label="执行时间" :width="flexWidth('tmcode', tableData, '执行时间')">
          </el-table-column>
          <el-table-column prop="tmcode" label="是否完成" :width="flexWidth('tmcode', tableData, '是否完成')">
            <template>
              <div class="table-flex red">
                <span>是</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="tjrq" label="操作人">
          </el-table-column>
          <el-table-column label="备注">
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template>
              <div class="table-flex" @click="openDetails">
                <span>详情</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="message-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper"
          :total="query1.total" hide-on-single-page>
        </el-pagination>
      </div>
      <div class="message-button">
        <el-button type="primary" @click="close">确认</el-button>
        <el-button type="info" @click="close">取消</el-button>
      </div>
    </div>
    <Details v-if="showDetails" @close="closeDetails"></Details>
  </div>
</template>

<script>
import Details from './details.vue'
export default {
  components: {
    Details
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      value1: '',
      radio: '',
      tableData: [{}],
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 100
      },
      showDetails: false
    }
  },
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    openDetails () {
      this.showDetails = true
    },
    closeDetails () {
      this.showDetails = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.message {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);

  .message-box {
    overflow: hidden;
    width: 85%;
    height: 85%;
    background-color: #fff;
    border-radius: 6px;
  }

  .message-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    padding: 0 29px;
    background-color: #006EFF;
    box-sizing: border-box;

    .message-title {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }

    .message-close {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .message-table {
    width: calc(100% - 48px);
    height: calc(100% - 175px - 127px);
    margin: 40px auto 0;

    .el-table {
      border: 2px solid #006EFF;
    }

    /deep/.el-table th.el-table__cell {
      background-color: #006EFF;
      color: #fff;
    }

    /deep/.el-table .cell {
      text-align: center;
      font-size: 16px;

    }

    /deep/.el-table__fixed-right-patch {
      background-color: #006EFF;
    }

    .table-flex {
      cursor: pointer;
    }

    .red {
      color: #FF0000;
    }

    .green {
      color: #00FF1A;
    }
  }

  .message-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90px;

    /deep/.el-pager .number {
      background-color: #ADADAD;
      color: #fff;
    }

    /deep/.btn-quicknext, /deep/.btn-prev, /deep/.btn-next {
      background-color: #ADADAD;
      color: #fff;
    }
  }

  .message-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 127px;
    margin: 0 auto;
    border-top: 1px solid #707070;
    box-sizing: border-box;

    .el-button {
      margin: 0 15px;
      padding: 5px 35px;
      font-size: 22px;
    }
  }
}
</style>
