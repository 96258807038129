<template>
  <div class="tab-box">
    <div class="services-scroll">
      <div class="title">
        <span>健康体检表</span>
      </div>
      <div class="thead">
        <span>姓名：{{ info.name || '-' }}</span>
        <span>编号：{{ info.tjbh || '-' }}</span>
      </div>
      <div class="tr">
        <span>考核项</span>
        <span>缺项</span>
        <span>完整率</span>
      </div>
      <div class="tr tr2">
        <span class="flex1 boder-right padding">体检日期</span>
        <span class="flex2 boder-right padding">{{ info.tjrq || '-' }}</span>
        <span class="flex4 padding">责任医师{{ info.zrys || '' }}</span>
      </div>
      <div class="tr tr2">
        <span class="flex1 boder-right padding">内容</span>
        <span class="flex6 padding">检查项目</span>
      </div>
      <div class="tr tr2 t4">
        <span class="flex1 boder-right padding t4">症状</span>
        <div class="flex6 t4">
          <el-checkbox-group class="line-paddint-left" v-model="info.jcxmzz">
            <el-checkbox v-for="(item, index) in checks" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="tr tr1 tr2">
        <span class="flex1 boder-right height padding">一般状况</span>
        <div class="flex6 height1">
          <div class="line">
            <span class="div1 boder-right">体温</span>
            <span class="div1 boder-right">{{ info.ybzktw || '-' }}</span>
            <span class="div2 boder-right">脉率</span>
            <span class="div4">
              <el-input v-model="info.ybzkml"></el-input>
              <span class="unit">次/分</span>
            </span>
          </div>
          <div class="line line1">
            <span class="div1 div-height1 boder-right">呼吸频率</span>
            <div class="div1 div-height boder-right">
              <div class="div4">
                <el-input class="input-width" v-model="info.ybzkhxpl"></el-input>
                <span class="unit">次/分</span>
              </div>
            </div>
            <span class="div2 div-height1 boder-right">血压</span>
            <span class="div4 div-height2">
              <div class="div-line">
                <span class="div-line-flex1 div-line-border">左侧</span>
                <div class="div-line-flex5">
                  <el-input class="input-width" v-model="info.ybzkxycc"></el-input>
                  <el-input class="input-width" v-model="info.ybzksxycc"></el-input>
                  <span class="unit">mmHg</span>
                </div>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 div-line-border">右侧</span>
                <div class="div-line-flex5">
                  <el-input class="input-width" v-model="info.ybzkxyyc"></el-input>
                  <el-input class="input-width" v-model="info.ybzksxyyc"></el-input>
                  <span class="unit">mmHg</span>
                </div>
              </div>
            </span>
          </div>
          <div class="line">
            <span class="div1 boder-right">身高</span>
            <div class="div1 boder-right">
              <el-input class="input-width" v-model="info.ybzksg"></el-input>
              <span class="unit">cm</span>
            </div>
            <span class="div2 boder-right">体重</span>
            <span class="div4">
              <el-input v-model="info.ybzktz"></el-input>
              <span class="unit">kg</span>
            </span>
          </div>
          <div class="line">
            <span class="div1 boder-right">腰围</span>
            <div class="div1 boder-right">
              <el-input class="input-width" v-model="info.ybzkyw"></el-input>
              <span class="unit">cm</span>
            </div>
            <span class="div2 boder-right">体质指数</span>
            <span class="div4">
              <el-input v-model="info.ybzktzzs"></el-input>
            </span>
          </div>
          <div class="line">
            <span class="div2 boder-right">老年人健康状态自我评估</span>
            <div class="div6">
              <el-radio-group class="line-paddint-left" v-model="info.lnrjkztzwpg">
                <el-radio v-for="(item, index) in radios" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">老年人自我生活能力评估</span>
            <div class="div6">
              <el-radio-group class="line-paddint-left" v-model="info.lnrshzlnlzwpg">
                <el-radio v-for="(item, index) in radios1" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">老年人认知功能</span>
            <div class="div6">
              <el-radio-group class="line-paddint-left" v-model="info.lnrrzgn">
                <el-radio v-for="(item, index) in radios2" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="line line2">
            <span class="div2 boder-right">老年人情感状态</span>
            <div class="div6">
              <el-radio-group class="line-paddint-left" v-model="info.lnrqgzt">
                <el-radio v-for="(item, index) in radios3" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 tr3">
        <span class="flex1 boder-right height2 padding">生活方式</span>
        <div class="flex6 height2">
          <div class="line line3">
            <span class="div2 div-height3 boder-right">体育锻炼</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">锻炼频率</span>
                <div class="div-line-flex3">
                  <el-radio-group class="line-paddint-left" v-model="info.shfstydldlpl" @change="dlplChange">
                    <el-radio v-for="(item, index) in radios4" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">每次锻炼时间</span>
                <span class="div-line-flex1 border-right">
                  <el-input :disabled="info.shfstydldlpl === '4'" v-model="info.shfstydlmcdlsj"></el-input>
                  <span class="unit">分钟</span>
                </span>
                <span class="div-line-flex1 border-right">坚持锻炼时间</span>
                <div class="div-line-flex1">
                  <el-input :disabled="info.shfstydldlpl === '4'" v-model="info.shfstydljcdlsj"></el-input>
                </div>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 border-right">锻炼方式</span>
                <div class="div-line-flex3">
                  <el-input :disabled="info.shfstydldlpl === '4'" v-model="info.shfstydldlfs"></el-input>
                </div>
              </div>
            </div>
          </div>

          <div class="line">
            <span class="div2 boder-right">饮食习惯</span>
            <div class="div6">
              <el-checkbox-group class="line-paddint-left" v-model="info.shfsysxg">
                <el-checkbox v-for="(item, index) in checks1" :key="index" :label="index + 1 + ''">{{ item
                }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <div class="line line3">
            <span class="div2 div-height3 boder-right">吸烟情况</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">吸烟情况</span>
                <div class="div-line-flex3">
                  <el-radio-group class="line-paddint-left" v-model="info.shfsxyqkxyzk" @change="xyqkChange">
                    <el-radio v-for="(item, index) in radios5" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">日吸烟量</span>
                <div class="div-line-flex3">
                  <span class="unit">平均</span>
                  <el-input :disabled="info.shfsxyqkxyzk === '1' || info.shfsxyqkxyzk === '2'"
                    v-model="info.shfsxyqkrxyl"></el-input>
                  <span class="unit">支</span>
                </div>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 border-right">开始吸烟年龄</span>
                <span class="div-line-flex1 border-right">
                  <el-input :disabled="info.shfsxyqkxyzk === '1' || info.shfsxyqkxyzk === '2'"
                    v-model="info.shfsxyqkksxynl"></el-input>
                  <span class="unit">岁</span>
                </span>
                <span class="div-line-flex1 border-right">戒烟年龄</span>
                <div class="div-line-flex1">
                  <el-input :disabled="info.shfsxyqkxyzk === '1' || info.shfsxyqkxyzk === '3'"
                    v-model="info.shfsxyqkjynl"></el-input>
                </div>
              </div>

            </div>
          </div>

          <div class="line line4">
            <span class="div2 div-height4 boder-right">饮酒情况</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">饮酒频率</span>
                <div class="div-line-flex3">
                  <el-radio-group class="line-paddint-left" v-model="info.shfsyjqkyjpl">
                    <el-radio v-for="(item, index) in radios6" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">日饮酒量</span>
                <div class="div-line-flex3">
                  <span class="unit">平均</span>
                  <el-input v-model="info.shfsyjqkryjl"></el-input>
                  <span class="unit">两</span>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">是否戒酒</span>
                <div class="div-line-flex3">
                  <el-radio-group class="line-paddint-left" v-model="info.shfsyjqksfjj">
                    <el-radio v-for="(item, index) in radios7" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                  </el-radio-group>
                  <span class="unit">戒酒年龄：</span>
                  <el-input v-model="info.shfsyjqkjjnl"></el-input>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">开始饮酒年龄</span>
                <span class="div-line-flex1 border-right">
                  <el-input v-model="info.shfsyjqkksyjnl"></el-input>
                  <span class="unit">岁</span>
                </span>
                <span class="div-line-flex1 border-right">近一年是否戒酒</span>
                <div class="div-line-flex1">
                  <el-radio-group v-model="info.shfsyjqksfzj">
                    <el-radio v-for="(item, index) in radios8" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 border-right">饮酒种类</span>
                <div class="div-line-flex3">
                  <el-checkbox-group class="line-paddint-left" v-model="info.shfsyjqkyjzl">
                    <el-checkbox v-for="(item, index) in checks2" :key="index" :label="index + 1 + ''">{{ item
                    }}</el-checkbox>
                    <el-input class="span-input"></el-input>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>

          <div class="line line4 line2">
            <span class="div2 div-height4 boder-right">职业病危害因素接触史</span>
            <div class="div6 div-flex">
              <div class="div-flex-line">
                <el-radio-group v-model="info.zybwhysjcs">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <div class="div-flex-line-width">
                  <span>（工种</span>
                  <el-input class="span-input" v-model="info.zybgz"></el-input>
                  <span>从业</span>
                  <el-input class="span-input" v-model="info.zybcysjn"></el-input>
                  <span>年）</span>
                </div>
              </div>
              <div class="div-flex-line">
                <span class="div-flex-line-width1">毒粉种类：</span>
                <span class="div-flex-line-width2">粉尘</span>
                <el-input class="span-input" v-model="info.dwzlfc"></el-input>
                <span class="div-flex-line-width2 white-space">防护措施</span>
                <el-radio-group v-model="info.dwzlfcfhcs">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.dwzlfcbz"></el-input>
              </div>
              <div class="div-flex-line">
                <span class="div-flex-line-width1"></span>
                <span class="div-flex-line-width2 white-space">放射物质</span>
                <el-input class="span-input" v-model="info.dwzlfswz"></el-input>
                <span class="div-flex-line-width2 white-space">防护措施</span>
                <el-radio-group v-model="info.dwzlfswzfhcs">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.dwzlfswzbz"></el-input>
              </div>
              <div class="div-flex-line">
                <span class="div-flex-line-width1"></span>
                <span class="div-flex-line-width2 white-space">物理因素</span>
                <el-input class="span-input" v-model="info.dwzlwlys"></el-input>
                <span class="div-flex-line-width2 white-space">防护措施</span>
                <el-radio-group v-model="info.dwzlwlysfhcs">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.dwzlwlysbz"></el-input>
              </div>
              <div class="div-flex-line">
                <span class="div-flex-line-width1"></span>
                <span class="div-flex-line-width2 white-space">化学物质</span>
                <el-input class="span-input" v-model="info.dwzlhxwz"></el-input>
                <span class="div-flex-line-width2 white-space">防护措施</span>
                <el-radio-group v-model="info.dwzkhxwzfhcs">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.dwzlhxwzbz"></el-input>
              </div>
              <div class="div-flex-line">
                <span class="div-flex-line-width1"></span>
                <span class="div-flex-line-width2">其他</span>
                <el-input class="span-input" v-model="info.dwzlfhcsqt"></el-input>
                <span class="div-flex-line-width2 white-space">防护措施</span>
                <el-radio-group v-model="info.dwzlqtfhcs">
                  <el-radio v-for="(item, index) in radios28" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.dwzlfhcsqtbz"></el-input>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="tr tr1 tr2 t5">
        <div class="flex1 t5 t5-border-right">
          <span>脏器功能</span>
        </div>
        <div class="flex6 t5 t61">

          <div class="line line5">
            <span class="div2 boder-right div-height5">口腔</span>
            <span class="div6 div-height5">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">口唇</span>
                <div class="div-line-flex3 div-line-height1">
                  <el-radio-group class="line-paddint-left line-paddint-left1" v-model="info.zqgnkqkc">
                    <el-radio v-for="(item, index) in radios10" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
              <div class="line">
                <span class="span-width line-paddint-left">齿列</span>
                <el-checkbox label="1" :checked="info.kqcl.indexOf('1') > -1">正常</el-checkbox>
              </div>
              <div class="line">
                <el-checkbox label="2" class="line-paddint-left" :disabled="info.kqcl.indexOf('1') > -1"
                  :checked="info.kqcl.indexOf('2') > -1">缺齿</el-checkbox>
                <el-checkbox label="1" :checked="info.allLoseTeeth.indexOf('1') > -1">全口</el-checkbox>
                <span class="span-width1">左上：</span>
                <el-input class="span-input" v-model="info.kqsfqczs"></el-input>
                <span class="span-width1">左下：</span>
                <el-input class="span-input" v-model="info.kqsfqczx"></el-input>
                <span class="span-width1">右上：</span>
                <el-input class="span-input" v-model="info.kqsfqcys"></el-input>
                <span class="span-width1">右下：</span>
                <el-input class="span-input" v-model="info.kqsfqcyx"></el-input>
              </div>
              <div class="line">
                <el-checkbox label="3" class="line-paddint-left" :disabled="info.kqcl.indexOf('1') > -1"
                  :checked="info.kqcl.indexOf('3') > -1">龋齿</el-checkbox>
                <el-checkbox label="1"
                  :checked="info.allDecayedTeeth && info.allDecayedTeeth.indexOf('1') > -1">全口</el-checkbox>
                <span class="span-width1">左上：</span>
                <el-input class="span-input" v-model="info.kqsfquczs"></el-input>
                <span class="span-width1">左下：</span>
                <el-input class="span-input" v-model="info.kqsfquczx"></el-input>
                <span class="span-width1">右上：</span>
                <el-input class="span-input" v-model="info.kqsfqucys"></el-input>
                <span class="span-width1">右下：</span>
                <el-input class="span-input" v-model="info.kqsfqucyx"></el-input>
              </div>
              <div class="line">
                <el-checkbox label="4" class="line-paddint-left" :disabled="info.kqcl.indexOf('1') > -1"
                  :checked="info.kqcl.indexOf('4') > -1">义齿</el-checkbox>
                <el-checkbox label="1" :checked="info.allFalseTeeth.indexOf('1') > -1">全口</el-checkbox>
                <span class="span-width1">左上：</span>
                <el-input class="span-input" v-model="info.kqyccs"></el-input>
                <span class="span-width1">左下：</span>
                <el-input class="span-input" v-model="info.kqyccx"></el-input>
                <span class="span-width1">右上：</span>
                <el-input class="span-input" v-model="info.kqycys"></el-input>
                <span class="span-width1">右下：</span>
                <el-input class="span-input" v-model="info.kqycyx"></el-input>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 border-right">咽部</span>
                <div class="div-line-flex3 div-line-height1">
                  <el-radio-group class="line-paddint-left" v-model="info.kqyb">
                    <el-radio v-for="(item, index) in radios11" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </span>
          </div>
          <div class="line">
            <span class="div2 boder-right">视力</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <span class="width-span">左眼：</span>
                <el-input class="span-input" v-model="info.shililyzy"></el-input>
                <span class="width-span">右眼：</span>
                <el-input class="span-input" v-model="info.shililyyy"></el-input>
                <span class="width-span1">(矫正视力：左眼</span>
                <el-input class="span-input" v-model="info.shilijzslcy"></el-input>
                <span class="width-span">右眼</span>
                <el-input class="span-input" v-model="info.shilijzslyy"></el-input>
                <span>)</span>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">听力</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.tingli" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios12" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">运动功能</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.ydgn" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios13" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">眼底</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.chatiyandi" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.chatiyandiDes"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">皮肤</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.chatipf" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios15" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.chatipfDes"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">巩膜</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.chatigm" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios16" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.chatigmDes"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">淋巴结</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.chatilbj" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios17" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.chatilbjDes"></el-input>
              </div>
            </div>
          </div>
          <div class="line line3">
            <span class="div2 div-height3 boder-right">肺</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">桶状胸</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifeitzx">
                    <el-radio v-for="(item, index) in radios18" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">呼吸音</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifeihxy">
                    <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
              <div class="div-line">
                <span class="div-line-flex1 border-right">罗音</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifeily">
                    <el-radio v-for="(item, index) in radios19" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">心脏</span>
            <div class="div6 box-flex border1">
              <div class="box-flex line-margin-left">
                <span>心率</span>
                <el-input class="span-input" v-model="info.chatixzxlv"></el-input>
                <span>分钟</span>
              </div>
              <div class="box-flex line-margin-left line-margin-left1">
                <span>心率</span>
              </div>
              <div class="line line2">
                <el-radio-group v-model="info.chatixzxlu">
                  <el-radio v-for="(item, index) in radios20" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line">
                <span class="div-line-flex1 border-right">杂音</span>
                <div class="div-line-flex3">
                  <el-radio-group class="line-paddint-left" v-model="info.chatixzxy">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.chatixzxyDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">腹部</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">压痛</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifbyt">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.chatifbytDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">包块</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifbbk">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.chatifbbkDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">肝大</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifbgd">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.chatifbgdDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">脾大</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifbpd">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.chatifbpdDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line">
                <span class="div-line-flex1 border-right">移动性杂音</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifbydxzy">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.chatifbydxzyDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">下肢水肿</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.chatixzsz" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios21" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">足背动脉搏动</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.chatizbdmbd" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios22" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">肛门指诊</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.chatigmzz" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios23" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.chatigmzzDes"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">乳腺</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.chatiruxian" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios24" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.chatiruxianDes"></el-input>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">妇科</span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">外阴</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifkwy">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.chatifkwyDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">阴道</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifkyd">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.chatifkydDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">宫颈</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifkgj">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.chatifkgjDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line div-border">
                <span class="div-line-flex1 border-right">宫体</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifkgt">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.chatifkgtDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line">
                <span class="div-line-flex1 border-right">附件</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.chatifkfj">
                    <el-radio v-for="(item, index) in radios9" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.chatifkfjDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">其他</span>
            <div class="div6">
              <el-input v-model="info.chatiqtjc"></el-input>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t6">
        <div class="flex1 t6 t5-border-right">
          <span>辅助检查</span>
        </div>
        <div class="flex6 t6 t61">
          <div class="line">
            <span class="div2 boder-right">血常规</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <span class="width-span2">血红蛋白</span>
                <el-input class="span-input" v-model="info.chatixcgxhdb"></el-input>
                <span class="width-span">g/L</span>
                <span class="width-span2">白细胞</span>
                <el-input class="span-input" v-model="info.chatixcgbxb"></el-input>
                <span class="width-span">10^9/L</span>
                <span class="width-span2">血小板</span>
                <el-input class="span-input" v-model="info.chatixcgxxb"></el-input>
                <span class="width-span">10^9/L</span>
                <span class="width-span">其他</span>
                <el-input class="span-input" v-model="info.chatixcgqt"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">尿常规</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <span class="width-span2">尿蛋白</span>
                <el-input class="span-input" v-model="info.chatincgndb"></el-input>
                <span class="width-span2">尿糖</span>
                <el-input class="span-input" v-model="info.chatincgnt"></el-input>
                <span class="width-span2">尿酮体</span>
                <el-input class="span-input" v-model="info.chatincgntt"></el-input>
                <span class="width-span2">尿潜血</span>
                <el-input class="span-input" v-model="info.chatincgnqx"></el-input>
                <span class="width-span">其他</span>
                <el-input class="span-input" v-model="info.chatincgqt"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">空腹血糖</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <el-input class="span-input" v-model="info.kfxtmool"></el-input>
                <span class="width-span2">mmol/L</span>
                <span class="width-span2">或</span>
                <el-input class="span-input" v-model="info.kfxtmg"></el-input>
                <span class="width-span2">mg/L</span>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">心电图</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.xdtzt" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.xdtztDes"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">尿微量白蛋白</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <el-input class="span-input" v-model="info.nwlbdb"></el-input>
                <span class="width-span2">mg/L</span>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">大便潜血</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.dbqxzt" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios25" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">糖化血红蛋白</span>
            <div class="div6">
              <div class="line line2 width-span-margin">
                <el-input class="span-input" v-model="info.thxhdb"></el-input>
                <span class="width-span2">%</span>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">乙型肝炎表面抗原</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.yxgybmky" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios25" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">肝功能</span>
            <div class="div6">
              <div class="line line2 line-warp width-span-margin ">
                <div class="width-span-width1">血清谷丙转氨酶</div>
                <el-input class="span-input" v-model="info.gnnxqgnzam"></el-input>
                <div>U/L</div>
                <div class="width-span-margin1 width-span-width1">血清谷丙转氨酶</div>
                <el-input class="span-input" v-model="info.gnnxqgczam"></el-input>
                <div>U/L</div>
                <div class="width-span-margin1">白蛋白</div>
                <el-input class="span-input" v-model="info.gnnbdb"></el-input>
                <div>g/L</div>
                <div class="width-span-margin1">总胆红素</div>
                <el-input class="span-input" v-model="info.gnnzdhs"></el-input>
                <div>μmol/L</div>
                <div class="width-span-margin1">结合胆红素</div>
                <el-input class="span-input" v-model="info.gnnjhdhs"></el-input>
                <div>μmol/L</div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">肾功能</span>
            <div class="div6">
              <div class="line line2 line-warp width-span-margin">
                <div>血清肌酐 </div>
                <el-input class="span-input" v-model="info.sgnxqjg"></el-input>
                <div>μmol/L</div>
                <div class="width-span-margin1">血尿素</div>
                <el-input class="span-input" v-model="info.sgnxns"></el-input>
                <div>mmol/L</div>
                <div class="width-span-margin1">血钾浓度</div>
                <el-input class="span-input" v-model="info.sgnxjnd"></el-input>
                <div>mmol/L</div>
                <div class="width-span-margin1">血钠浓度</div>
                <el-input class="span-input" v-model="info.sgnxnnd"></el-input>
                <div>mmol/L</div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">血脂</span>
            <div class="div6">
              <div class="line line2 line-warp width-span-margin">
                <div>总胆固醇</div>
                <el-input class="span-input" v-model="info.xzhizdgc"></el-input>
                <div>μmol/L</div>
                <div class="width-span-margin1">甘油三酯</div>
                <el-input class="span-input" v-model="info.xzhigysz"></el-input>
                <div>mmol/L</div>
                <div class="width-span-margin1 width-span-width">血清低密度脂蛋白胆固醇</div>
                <el-input class="span-input" v-model="info.xzhixqdmdzdbdgc"></el-input>
                <div>mmol/L</div>
                <div class="width-span-margin1 width-span-width">血清高密度脂蛋白胆固醇</div>
                <el-input class="span-input" v-model="info.xzhixqgmdzdbdgc"></el-input>
                <div>mmol/L</div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">胸部X线片</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group v-model="info.xbxxp" class="line-paddint-left">
                  <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.xbxxpDes"></el-input>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">B超</span>
            <div class="div6">
              <div class="div-line border1">
                <span class="div-line-flex1 border-right">腹部B超 </span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.bchaofbbc">
                    <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.bchaofbbcDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right"></span>
            <div class="div6">
              <div class="div-line">
                <span class="div-line-flex1 border-right">其他</span>
                <div class="div-line-flex3 div-line-flex4">
                  <el-radio-group class="line-paddint-left" v-model="info.bchaoqtbbc">
                    <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                    <el-input class="span-input" v-model="info.bchaoqtbbcDes"></el-input>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">宫颈涂片</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group class="line-paddint-left" v-model="info.gjgp">
                  <el-radio v-for="(item, index) in radios14" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.gjgpDes"></el-input>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">其他</span>
            <div class="div6">
              <el-input></el-input>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t7">
        <div class="flex1 t7 t5-border-right">
          <span>现存主要健康问题</span>
        </div>
        <div class="flex6 t7 t61">
          <div class="line">
            <span class="div2 boder-right">脑血管疾病</span>
            <div class="div6">
              <div class="line line2 div-line-height1">
                <el-checkbox-group class="line-paddint-left" v-model="info.xczyjkwtnxgjb">
                  <el-checkbox v-for="(item, index) in checks3" :key="index" :label="index + 1 + ''">{{ item
                  }}</el-checkbox>
                </el-checkbox-group>
                <el-input class="span-input line-paddint-left" v-model="info.xczyjkwtnxgjbDes"></el-input>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">肾脏疾病</span>
            <div class="div6">
              <div class="line line2 div-line-height1">
                <el-checkbox-group class="line-paddint-left" v-model="info.xczyjkwtszjb">
                  <el-checkbox v-for="(item, index) in checks4" :key="index" :label="index + 1 + ''">{{ item
                  }}</el-checkbox>
                  <el-input class="span-input line-paddint-left" v-model="info.xczyjkwtszjbDes"></el-input>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">心脏疾病</span>
            <div class="div6">
              <div class="line line2 div-line-height1">
                <el-checkbox-group class="line-paddint-left" v-model="info.xczyjkwtxzjb">
                  <el-checkbox v-for="(item, index) in checks5" :key="index" :label="index + 1 + ''">{{ item
                  }}</el-checkbox>
                  <el-input class="span-input line-paddint-left" v-model="info.xczyjkwtxzjbDes"></el-input>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">血管疾病</span>
            <div class="div6">
              <div class="line line2 div-line-height1">
                <el-checkbox-group class="line-paddint-left" v-model="info.xczyjkwtxgjb">
                  <el-checkbox v-for="(item, index) in checks6" :key="index" :label="index + 1 + ''">{{ item
                  }}</el-checkbox>
                  <el-input class="span-input line-paddint-left" v-model="info.xczyjkwtxgjbDes"></el-input>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">眼部疾病</span>
            <div class="div6">
              <div class="line line2 div-line-height1">
                <el-checkbox-group class="line-paddint-left" v-model="info.xczyjkwtybjb">
                  <el-checkbox v-for="(item, index) in checks7" :key="index" :label="index + 1 + ''">{{ item
                  }}</el-checkbox>
                  <el-input class="span-input line-paddint-left" v-model="info.xczyjkwtybjbDes"></el-input>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="line">
            <span class="div2 boder-right">神经系统疾病</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group class="line-paddint-left" v-model="info.xczyjkwtsjxtjb">
                  <el-radio v-for="(item, index) in radios26" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.xczyjkwtsjxtjbDes"></el-input>
              </div>
            </div>
          </div>
          <div class="line border-none">
            <span class="div2 boder-right">其他系统疾</span>
            <div class="div6">
              <div class="line line2">
                <el-radio-group class="line-paddint-left" v-model="info.xczyjkwtqtxtjb">
                  <el-radio v-for="(item, index) in radios26" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
                </el-radio-group>
                <el-input class="span-input" v-model="info.xczyjkwtqtxtjbDes"></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t8">
        <div class="flex1 t8 t5-border-right">
          <span>住院治疗情况</span>
        </div>
        <div class="flex6 t8 t61">
          <div class="tab">
            <div class="tab-left t5-border-right border1">
              <span>住院史</span>
            </div>
            <div class="tab-right">
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right">入/出院日期</div>
                <div class="tab-line-item t5-border-right">原因</div>
                <div class="tab-line-item t5-border-right">医疗机构名称</div>
                <div class="tab-line-item">病案号</div>
              </div>
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right">
                  {{ zys.length > 0 ? (zys[0].indate || '') + '/' + (zys[0].outDate || '') : '' }}
                </div>
                <div class="tab-line-item t5-border-right">
                  {{ zys.length > 0 ? (zys[0].zyzlqkyy || '') : '' }}
                </div>
                <div class="tab-line-item t5-border-right">
                  {{ zys.length > 0 ? (zys[0].zyzlqkyljgmc || '') : '' }}
                </div>
                <div class="tab-line-item">
                  {{ zys.length > 0 ? (zys[0].zyzlqkbah || '') : '' }}
                </div>
              </div>
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right">
                  {{ zys.length > 1 ? (zys[1].indate || '') + '/' + (zys[1].outDate || '') : '' }}
                </div>
                <div class="tab-line-item t5-border-right">
                  {{ zys.length > 1 ? (zys[1].zyzlqkyy || '') : '' }}
                </div>
                <div class="tab-line-item t5-border-right">
                  {{ zys.length > 1 ? (zys[1].zyzlqkyljgmc || '') : '' }}
                </div>
                <div class="tab-line-item">
                  {{ zys.length > 1 ? (zys[1].zyzlqkbah || '') : '' }}
                </div>
              </div>
            </div>
          </div>
          <div class="tab">
            <div class="tab-left t5-border-right">
              <span>家庭病床史</span>
            </div>
            <div class="tab-right">
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right">建/撤床日期</div>
                <div class="tab-line-item t5-border-right">原因</div>
                <div class="tab-line-item t5-border-right">医疗机构名称</div>
                <div class="tab-line-item">病案号</div>
              </div>
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right">
                  {{ jtbcs.length > 0 ? (jtbcs[0].indate || '') + '/' + (jtbcs[0].outDate || '') : '' }}
                </div>
                <div class="tab-line-item t5-border-right">
                  {{ jtbcs.length > 0 ? (jtbcs[0].zyzlqkyy || '') : '' }}
                </div>
                <div class="tab-line-item t5-border-right">
                  {{ jtbcs.length > 0 ? (jtbcs[0].zyzlqkyljgmc || '') : '' }}
                </div>
                <div class="tab-line-item">
                  {{ jtbcs.length > 0 ? (jtbcs[0].zyzlqkbah || '') : '' }}
                </div>
              </div>
              <div class="tab-line border1">
                <div class="tab-line-item t5-border-right">
                  {{ jtbcs.length > 1 ? (jtbcs[1].indate || '') + '/' + (jtbcs[1].outDate || '') : '' }}
                </div>
                <div class="tab-line-item t5-border-right">
                  {{ jtbcs.length > 1 ? (jtbcs[1].zyzlqkyy || '') : '' }}
                </div>
                <div class="tab-line-item t5-border-right">
                  {{ jtbcs.length > 1 ? (jtbcs[1].zyzlqkyljgmc || '') : '' }}
                </div>
                <div class="tab-line-item">
                  {{ jtbcs.length > 1 ? (jtbcs[1].zyzlqkbah || '') : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t9">
        <div class="flex1 t9 t5-border-right">
          <span>主要用药情况</span>
        </div>
        <div class="flex6 t9 t61">
          <div class="tab-right">
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1">药物名称</div>
              <div class="tab-line-item t5-border-right">用法</div>
              <div class="tab-line-item t5-border-right">用量</div>
              <div class="tab-line-item t5-border-right">用药时间</div>
              <div class="tab-line-item tab-line-item1">
                <span>服药依从性</span>
              </div>
            </div>
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1">
                {{ yys.length > 0 ? (yys[0].zyyyqkywmc || '') : '' }}
              </div>
              <div class="tab-line-item t5-border-right">
                {{ yys.length > 0 ? (yys[0].zyyyqkyf || '') : '' }}
              </div>
              <div class="tab-line-item t5-border-right">
                {{ yys.length > 0 ? (yys[0].zyyyqkyl || '') : '' }}
              </div>
              <div class="tab-line-item t5-border-right">
                {{ yys.length > 0 ? (yys[0].zyyyqkyysj || '') : '' }}
              </div>
              <div class="tab-line-item tab-line-item1">
                <!-- v-model="yys[0].zyyyqkfyycx" -->
                <el-radio-group>
                  <el-radio v-for="(item, index) in radios27" :key="index" :checked="yys.length > 0 && yys[0].zyyyqkfyycx" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1">
                {{ yys.length > 1 ? (yys[1].zyyyqkywmc || '') : '' }}
              </div>
              <div class="tab-line-item t5-border-right">
                {{ yys.length > 1 ? (yys[1].zyyyqkyf || '') : '' }}
              </div>
              <div class="tab-line-item t5-border-right">
                {{ yys.length > 1 ? (yys[1].zyyyqkyl || '') : '' }}
              </div>
              <div class="tab-line-item t5-border-right">
                {{ yys.length > 1 ? (yys[1].zyyyqkyysj || '') : '' }}
              </div>
              <div class="tab-line-item tab-line-item1">
                <!-- v-model="yys[1].zyyyqkfyycx" -->
                <el-radio-group>
                  <el-radio v-for="(item, index) in radios27" :key="index" :checked="yys.length > 1 && yys[1].zyyyqkfyycx" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1">
                {{ yys.length > 2 ? (yys[2].zyyyqkywmc || '') : '' }}
              </div>
              <div class="tab-line-item t5-border-right">
                {{ yys.length > 2 ? (yys[2].zyyyqkyf || '') : '' }}
              </div>
              <div class="tab-line-item t5-border-right">
                {{ yys.length > 2 ? (yys[2].zyyyqkyl || '') : '' }}
              </div>
              <div class="tab-line-item t5-border-right">
                {{ yys.length > 2 ? (yys[2].zyyyqkyysj || '') : '' }}
              </div>
              <div class="tab-line-item tab-line-item1">
                <!-- v-model="yys[2].zyyyqkfyycx" -->
                <el-radio-group>
                  <el-radio v-for="(item, index) in radios27" :key="index" :checked="yys.length > 2 && yys[2].zyyyqkfyycx" :label="index + ''">{{ item }}</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t9">
        <div class="flex1 t9 t5-border-right">
          <span>非免疫规划预防接种史</span>
        </div>
        <div class="flex6 t9 t61">
          <div class="tab-right">
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1">药物名称</div>
              <div class="tab-line-item t5-border-right">接种日期</div>
              <div class="tab-line-item t5-border-right tab-line-item2">接种机构</div>
            </div>
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1">{{ jzss.length > 0 ? jzss[0].fmyghyfjzsmc : '' }}
              </div>
              <div class="tab-line-item t5-border-right">{{ jzss.length > 0 ? jzss[0].fmyghyfjzsrq : '' }}</div>
              <div class="tab-line-item t5-border-right tab-line-item2">{{ jzss.length > 0 ? jzss[0].fmyghyfyljgmc : '' }}
              </div>
            </div>
            <div class="tab-line border1">
              <div class="tab-line-item t5-border-right tab-line-item1">{{ jzss.length > 1 ? jzss[1].fmyghyfjzsmc : '' }}
              </div>
              <div class="tab-line-item t5-border-right">{{ jzss.length > 1 ? jzss[1].fmyghyfjzsrq : '' }}</div>
              <div class="tab-line-item t5-border-right tab-line-item2">{{ jzss.length > 1 ? jzss[1].fmyghyfyljgmc : '' }}
              </div>
            </div>
            <div class="tab-line">
              <div class="tab-line-item t5-border-right tab-line-item1">{{ jzss.length > 2 ? jzss[2].fmyghyfjzsmc : '' }}
              </div>
              <div class="tab-line-item t5-border-right">{{ jzss.length > 2 ? jzss[2].fmyghyfjzsrq : '' }}</div>
              <div class="tab-line-item t5-border-right tab-line-item2">{{ jzss.length > 2 ? jzss[2].fmyghyfyljgmc : '' }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t9">
        <div class="flex1 t9 t5-border-right">
          <span>健康评价</span>
        </div>
        <div class="flex6 t9 t61">
          <el-radio-group class="line-paddint-left line-margin-top" v-model="info.jkpjnr">
            <el-radio v-for="(item, index) in radios29" :key="index" :label="index + 1 + ''">{{ item }}</el-radio>
          </el-radio-group>
          <div class="err">
            <span class="err-width">异常1</span>
            <el-input v-model="info.jkpjnrDes1"></el-input>
          </div>
          <div class="err">
            <span class="err-width">异常2</span>
            <el-input v-model="info.jkpjnrDes2"></el-input>
          </div>
          <div class="err">
            <span class="err-width">异常3</span>
            <el-input v-model="info.jkpjnrDes3"></el-input>
          </div>
          <div class="err">
            <span class="err-width">异常4</span>
            <el-input v-model="info.jkpjnrDes4"></el-input>
          </div>
        </div>
      </div>

      <div class="tr tr1 tr2 t9">
        <div class="flex1 t9 t5-border-right">
          <span>健康指导</span>
        </div>
        <div class="flex6 t9 t61">
          <div class="tr-box-left">
            <el-checkbox-group class="line-paddint-left" v-model="info.jkzdnrff">
              <el-checkbox v-for="(item, index) in checks8" :key="index" :label="index + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="tr-box-left">
            <span style="width: 150px;margin-left: 18px;">危险因素控制：</span>
            <el-checkbox v-model="info.jkzdwxyskzff">戒烟</el-checkbox>
            <el-checkbox v-model="info.jkzdwxyskzff">健康饮酒</el-checkbox>
            <el-checkbox v-model="info.jkzdwxyskzff">饮食</el-checkbox>
            <el-checkbox v-model="info.jkzdwxyskzff">锻炼</el-checkbox>
            <div class="tr-box-flex">
              <el-checkbox v-model="info.jkzdwxyskzff">减体重</el-checkbox>
              <span class="width-span-width2">（目标</span>
              <div class="width-span-width2">
                <el-input v-model="info.wxyskzjtzmb"></el-input>
              </div>
              <span>kg）</span>
            </div>
            <div class="tr-box-flex">
              <el-checkbox v-model="info.jkzdwxyskzff">建议接种疫苗</el-checkbox>
              <div class="width-span-width2">
                <el-input v-model="info.wxyskzjyjzym"></el-input>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="tr tr2">
        <span class="flex1 boder-right padding">录入机构</span>
        <span class="flex6 padding">{{ info.orgName || '' }}</span>
      </div>

      <div class="tr tr2">
        <span class="flex7 boder-right padding">体检日期</span>
        <span class="flex4 boder-right padding">{{ info.tjrq || '' }}</span>
        <span class="flex1 boder-right padding">录入时间</span>
        <span class="flex4 padding">{{ info.createTime || '' }}</span>
      </div>

      <div class="tr tr2 border1">
        <span class="flex1 boder-right padding">更新机构</span>
        <span class="flex6 padding">{{ info.upjgbh || '' }}</span>
      </div>

      <!-- <div class="tr tr2 border1">
        <span class="flex7 boder-right padding">体检日期</span>
        <span class="flex4 boder-right padding">{{ info.tjrq || '' }}</span>
        <span class="flex1 boder-right padding">录入时间</span>
        <span class="flex4 padding"></span>
      </div> -->

      <div class="services-btns">
        <el-button>编辑</el-button>
        <el-button>保存</el-button>
        <el-button>审核</el-button>
        <el-button>上传</el-button>
        <el-button>打印</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: '',
          jktjid: ''
        }
      }
    }
  },
  data () {
    return {
      tabs: Object.freeze(['体检表', '中医体质辨识', '老年人评估', '中医健康指导']),
      checkList: ['0'],
      checks: Object.freeze(['无症状', '头疼', '心悸', '胸闷', '胸痛', '慢性咳嗽', '咳痰', '呼吸苦难', '多饮', '多尿', '体重下降', '乳房胀痛', '乏力', '关节肿痛', '视力模糊', '手脚麻木', '尿急', '尿痛', '便秘', '腹泻', '恶心呕吐', '眼花', '耳鸣', '其他']),
      checks1: Object.freeze(['荤素均衡', '荤食为主', '素食为主', '嗜盐', '嗜油', '嗜糖']),
      checks2: Object.freeze(['白酒', '红酒', '啤酒', '黄酒', '其他']),
      checks3: Object.freeze(['未发现', '缺血性卒中', '脑出血', '蛛网膜下腔出血', '短暂性脑缺血发作', '其他']),
      checks4: Object.freeze(['未发现', '糖尿病肾病', '肾功能衰竭', '急性肾炎', '慢性肾炎', '其他']),
      checks5: Object.freeze(['未发现', '心肌梗死', '心绞痛', '冠状动脉血运重建 ', '充血性心力', '心前区疼痛', '其他']),
      checks6: Object.freeze(['未发现', '夹层动脉瘤', '动脉闭塞性疾病', '其他']),
      checks7: Object.freeze(['未发现', '视网膜出血或渗出', '视乳头水肿', '白内障', '其他']),
      checks8: Object.freeze(['纳入慢性病患者健康管理', '建议复查', '建议转诊']),
      radio: '0',
      radios: Object.freeze(['满意', '基本满意', '说不清楚', '不太满意', '不满意']),
      radios1: Object.freeze(['可自理（0-3分）', '轻度依赖（4-8分） ', '中度依赖（9-18分） ', '不能（9-18分） ']),
      radios2: Object.freeze(['粗筛阴性', '粗筛阳性，老年人智力检查']),
      radios3: Object.freeze(['粗筛阴性', '粗筛阳性，老年人抑郁检查']),
      radios4: Object.freeze(['每天', '每周一次以上', '偶尔', '不锻炼']),
      radios5: Object.freeze(['从不吸烟', '已戒烟', '吸烟']),
      radios6: Object.freeze(['从不', '偶尔', '经常', '每天']),
      radios7: Object.freeze(['未戒酒', '已戒酒', '经常']),
      radios8: Object.freeze(['是', '否']),
      radios9: Object.freeze(['无', '有']),
      radios10: Object.freeze(['红润', '苍白', '发敢', '皲裂', '疱诊']),
      radios11: Object.freeze(['无充血', '充血', '淋巴滤泡增长']),
      radios12: Object.freeze(['听见', '听不清或无法听见']),
      radios13: Object.freeze(['可顺利完成', '无法独立完成任意一个动作']),
      radios14: Object.freeze(['正常', '异常']),
      radios15: Object.freeze(['正常', '潮红', '苍白', '发绀', '黄染', '色素沉着', '其他']),
      radios16: Object.freeze(['正常', '黄染', '充血', '其他']),
      radios17: Object.freeze(['未触及', '锁骨上', '腋窝', '其他']),
      radios18: Object.freeze(['否', '是']),
      radios19: Object.freeze(['无', '干罗音', '湿罗音', '其他']),
      radios20: Object.freeze(['齐', '不齐', '绝对不齐']),
      radios21: Object.freeze(['无', '单侧', '双侧不对称', '双侧对称']),
      radios22: Object.freeze(['未触及', '触及双侧堆成', '触及左侧弱或消失', '触及右侧弱或消失']),
      radios23: Object.freeze(['未见异常', '触痛', '包块', '前列腺异常', '其他']),
      radios24: Object.freeze(['未见异常', '乳房切除', '异常泌乳', '乳腺包块', '其他']),
      radios25: Object.freeze(['阴性', '阳性']),
      radios26: Object.freeze(['未发现', '有']),
      radios27: Object.freeze(['规律', '间断', '不服药']),
      radios28: Object.freeze(['无', '有']),
      radios29: Object.freeze(['体检无异常', '有异常']),
      info: {
        id: 0,
        createTime: '',
        updateTime: '',
        jktjid: '',
        grjbxxid: '',
        dwzlwlysfhcs: '',
        tjbh: '',
        name: '',
        zrys: '',
        jcxmzz: [],
        ybzktw: '',
        ybzkml: '',
        ybzkhxpl: '',
        ybzkxycc: '',
        ybzkxyyc: '',
        ybzksg: '',
        ybzktz: '',
        ybzkyw: '',
        ybzktzzs: '',
        lnrjkztzwpg: '',
        lnrshzlnlzwpg: '',
        lnrrzgn: '',
        lnrqgzt: '',
        shfstydldlpl: '',
        shfstydlmcdlsj: '',
        shfstydljcdlsj: '',
        shfstydldlfs: '',
        shfsysxg: '',
        shfsxyqkxyzk: '',
        shfsxyqkrxyl: '',
        shfsxyqkksxynl: '',
        shfsxyqkjynl: '',
        shfsyjqkyjpl: '',
        shfsyjqkryjl: '',
        shfsyjqksfjj: '',
        shfsyjqkjjnl: '',
        shfsyjqkksyjnl: '',
        shfsyjqksfzj: '',
        shfsyjqkyjzl: [],
        zybwhysjcs: '',
        zybgz: '',
        zybcysjn: '',
        dwzlfc: '',
        dwzlfcfhcs: '',
        dwzlfswzfhcs: '',
        dwzkhxwzfhcs: '',
        tjrq: '',
        dwzlfswz: '',
        dwzlwlys: '',
        dwzlhxwz: '',
        dwzlqtfhcs: '',
        dwzlfhcsqt: '',
        dwzlfcbz: '',
        dwzlfswzbz: '',
        dwzlwlysbz: '',
        dwzlhxwzbz: '',
        dwzlfhcsqtbz: '',
        zqgnkqkc: '',
        kqcl: '',
        kqsfqc: '',
        kqqcscs: '',
        kqqcscx: '',
        kqqcsyx: '',
        kqqcsys: '',
        kqsfquc: '',
        kqquccs: '',
        kqquccx: '',
        kqqucys: '',
        kqqucyx: '',
        kqsfyc: '',
        kqyccs: '',
        kqyccx: '',
        kqycys: '',
        kqycyx: '',
        kqsfqcyx: '',
        kqsfqczs: '',
        kqsfqczx: '',
        kqsfqcys: '',
        kqsfqucys: '',
        kqsfqucyx: '',
        kqsfquczs: '',
        kqsfquczx: '',
        kqyb: '',
        shililyzy: '',
        shililyyy: '',
        shilijzslcy: '',
        shilijzslyy: '',
        tingli: '',
        ydgn: '',
        chatiyandi: '',
        chatipf: '',
        chatigm: '',
        chatilbj: '',
        chatifeitzx: '',
        chatifeihxy: '',
        chatifeily: '',
        chatixzxlv: '',
        chatixzxlu: '',
        chatixzxy: '',
        chatifbyt: '',
        chatifbbk: '',
        chatifbgd: '',
        chatifbpd: '',
        chatifbydxzy: '',
        chatixzsz: '',
        chatizbdmbd: '',
        chatigmzz: '',
        chatiruxian: '',
        chatifkwy: '',
        chatifkyd: '',
        chatifkgj: '',
        chatifkgt: '',
        chatifkfj: '',
        chatiqtjc: '',
        chatixcgxhdb: '',
        chatixcgbxb: '',
        chatixcgxxb: '',
        chatixcgqt: '',
        chatincgndb: '',
        chatincgnt: '',
        chatincgntt: '',
        chatincgnqx: '',
        chatincgqt: '',
        kfxtmool: '',
        kfxtmg: '',
        xdtzt: '',
        nwlbdb: '',
        dbqxzt: '',
        thxhdb: '',
        yxgybmky: '',
        gnnxqgnzam: '',
        gnnxqgczam: '',
        gnnbdb: '',
        gnnzdhs: '',
        gnnjhdhs: '',
        sgnxqjg: '',
        sgnxns: '',
        sgnxjnd: '',
        sgnxnnd: '',
        xzhizdgc: '',
        xzhigysz: '',
        xzhixqdmdzdbdgc: '',
        xzhixqgmdzdbdgc: '',
        xbxxp: '',
        bchaofbbc: '',
        bchaoqtbbc: '',
        gjgp: '',
        fzjcqtjc: '',
        xczyjkwtnxgjb: '',
        xczyjkwtszjb: '',
        xczyjkwtxzjb: '',
        xczyjkwtxgjb: '',
        xczyjkwtybjb: '',
        xczyjkwtsjxtjb: '',
        xczyjkwtqtxtjb: '',
        zyzlqkzysryrq: '',
        zyzlqkzyscyrq: '',
        zyzlqkzysyy: '',
        zyzlqkzysyljg: '',
        zyzlqkzysybah: '',
        zyzlqkjtbcsjcrq: '',
        zyzlqkjtbcsccrq: '',
        zyzlqkjtbcsyy: '',
        zyzlqkjtbcsyljgmc: '',
        zyzlqkjtbcsbah: '',
        zyyyqkywmc: '',
        zyyyqkyf: '',
        zyyyqkyl: '',
        zyyyqkyysj: '',
        zyyyqkfyycx: '',
        fmyghyfjzsmc: '',
        fmyghyfjzsrq: '',
        fmyghyfjzsjg: '',
        jkpjnr: '',
        jkzdnrff: '',
        jkzdwxyskzff: '',
        jcxmzzDes: '',
        chatiyandiDes: '',
        chatipfDes: '',
        chatigmDes: '',
        chatilbjDes: '',
        chatifeihxyDes: '',
        chatifeilyDes: '',
        chatixzxyDes: '',
        chatifbytDes: '',
        chatifbbkDes: '',
        chatifbgdDes: '',
        chatifbpdDes: '',
        chatifbydxzyDes: '',
        chatigmzzDes: '',
        chatiruxianDes: '',
        chatifkwyDes: '',
        chatifkydDes: '',
        chatifkgjDes: '',
        chatifkgtDes: '',
        chatifkfjDes: '',
        xbxxpDes: '',
        bchaofbbcDes: '',
        bchaoqtbbcDes: '',
        gjgpDes: '',
        xczyjkwtnxgjbDes: '',
        xczyjkwtszjbDes: '',
        xczyjkwtxzjbDes: '',
        xczyjkwtxgjbDes: '',
        xczyjkwtybjbDes: '',
        xczyjkwtsjxtjbDes: '',
        xczyjkwtqtxtjbDes: '',
        jkpjnrDes: '',
        wxyskzjtzmb: '',
        wxyskzjywmb: '',
        wxyskzjyjzym: '',
        wxyskzDes: '',
        xdtztDes: '',
        ybzksxycc: '',
        ybzksxyyc: '',
        sfzh: '',
        tmcode: '',
        jgbh: '',
        age: '',
        hjdz: '',
        jkpjnrDes1: '',
        jkpjnrDes2: '',
        jkpjnrDes3: '',
        jkpjnrDes4: '',
        jkpjnrDes5: '',
        jkpjnrDes6: '',
        jkpjnrycjg: '',
        jkpjnrtjxj: '',
        ytjbz: '',
        bcbz: '',
        lisbz: '',
        sgtzbz: '',
        shbz: '',
        xqbz: '',
        xb: '',
        scflag: '',
        uploadFlag: '',
        uploadflagtj: '',
        uploadflagzyzd: '',
        uploadflagzyy: '',
        uploadflagsf: '',
        uploadflaglnr: '',
        person: '',
        healthExam: '',
        elderInfo: '',
        cmgOperation: '',
        elderSelfcareAbility: '',
        elderTcmHealth: '',
        picId: '',
        personInfoId: '',
        manageOrgId: '',
        sfztp: '',
        zppic: '',
        dnbh: '',
        pttbflag: '',
        jkdnid: '',
        bcflag: '',
        tpflag: '',
        bctbflag: '',
        sfzhm: '',
        jddw: '',
        sftbflag: '',
        cwhmcid: '',
        lxdh: '',
        jdrq: '',
        jkdnxzz: '',
        xdtbz: '',
        tjjgbh: '',
        tjjgmc: '',
        tjjgcmhmc: '',
        tjjgcmhid: '',
        jkpjnrybzk: '',
        downflag: '',
        lrr: '',
        zrysid: '',
        lrrid: '',
        czy: '',
        czyid: '',
        fileData: '',
        ocrtpurl: '',
        tptype: '',
        allLoseTeeth: '',
        allFalseTeeth: '',
        allDecayedTeeth: '',
        printflag: '',
        yctype: '',
        xcgyzflag: '',
        ncgyzflag: '',
        shyzflag: '',
        bcyzflag: '',
        xdtyzflag: '',
        sfyzflag: '',
        nametage: '',
        zyyzflag: '',
        printdesc: '',
        xcgyzdesc: '',
        ncgyzdesc: '',
        shyzdesc: '',
        bcyzdesc: '',
        xdtyzdesc: '',
        sfyzdesc: '',
        zyyzdesc: '',
        tjlx: '',
        deflag: '',
        deuserid: '',
        xdtflag: '',
        minzu: '',
        ocrtpbase64: '',
        xfflag: '',
        shryqm: '',
        xcgryqm: '',
        ncgryqm: '',
        bchaoryqm: '',
        xdtryqm: '',
        zeysqm: '',
        province: '',
        city: '',
        country: '',
        town: '',
        village: '',
        jgjb: '',
        tbtype: '',
        healthCheck: '',
        provinceid: '',
        cityid: '',
        countryid: '',
        townid: '',
        villageid: '',
        tjjgxzjdmc: '',
        tjjgxzjdid: '',
        jddwid: '',
        updateuser: '',
        updateuserid: '',
        xzjdmc: '',
        cwhmc: '',
        xzjdmcid: '',
        empiid: '',
        beginsj: '',
        endsj: '',
        limit: '',
        tjjgbhs: '',
        jkdnhjdz: '',
        tjssjgmc: '',
        uploadflagnlpgb: '',
        tjjlsum: '',
        uptjflag: '',
        rqfl: '',
        sxtj: '',
        type: '',
        tbtypes: '',
        jkpjnrycjgjkzd: '',
        jkpjnrycjgtzzs: '',
        jkpjnrycjglnrzyyjkglfw: '',
        jkpjnrycjgbczfg: '',
        jkpjnrycjgshsgn: '',
        jkpjnrycjgshxt: '',
        jkpjnrycjgyjqk: '',
        jkpjnrycjgtingli: '',
        jkpjnrycjgshxz: '',
        jkpjnrycjgbcdjs: '',
        jkpjnrycjglnrjkztpg: '',
        jkpjnrycjgydgn: '',
        jkpjnrycjgshggn: '',
        jkpjnrycjglnrshzlnlpg: '',
        jkpjnrycjgxyqk: '',
        jkpjnrycjgxcg: '',
        jkpjnrycjgncg: '',
        jkpjnrycjgsh: '',
        jkpjnrycjgbc: '',
        jkpjnrycjgxy: '',
        jkpjnrycjgxdt: '',
        jkpjnrqtyc: '',
        archivesNum: ''
      },
      jzss: [],
      zys: [],
      jtbcs: [],
      yys: []
    }
  },
  created () {
    this.getInfo()
    this.getJzsInfo()
    this.getZyInfo()
    this.getYyInfo()
  },
  methods: {
    // 体检记录--查询单条信息
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97905103
      try {
        const obj = {
          tmcode: this.params.tmcode,
          sfzh: this.params.sfzh
        }
        const { data: res } = await this.$http.post('/api/jkct/app/findtjxx', obj)
        console.log('体检记录--查询单条信息', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
        // this.getJzsInfo(res.data.jktjid)
        for (const key in this.info) {
          if (!this.info[key]) {
            this.info[key] = ''
          }
        }
        if (!this.info.jcxmzz) {
          this.info.jcxmzz = []
        } else {
          this.info.jcxmzz = this.info.jcxmzz.split(',')
        }
        if (!this.info.shfsysxg) {
          this.info.shfsysxg = []
        } else {
          this.info.shfsysxg = this.info.shfsysxg.split(',')
        }
        if (!this.info.shfsyjqkyjzl) {
          this.info.shfsyjqkyjzl = []
        } else {
          this.info.shfsyjqkyjzl = this.info.shfsyjqkyjzl.split(',')
        }
        if (!this.info.kqcl) {
          this.info.kqcl = []
        } else {
          this.info.kqcl = this.info.kqcl.split(',')
        }
        if (!this.info.allLoseTeeth) {
          this.info.allLoseTeeth = []
        } else {
          this.info.allLoseTeeth = this.info.allLoseTeeth.split(',')
        }
        if (!this.info.allDecayedTeeth) {
          this.info.allDecayedTeeth = []
        } else {
          this.info.allDecayedTeeth = this.info.allDecayedTeeth.split(',')
        }
        if (!this.info.allFalseTeeth) {
          this.info.allFalseTeeth = []
        } else {
          this.info.allFalseTeeth = this.info.allFalseTeeth.split(',')
        }
        if (!this.info.xczyjkwtnxgjb) {
          this.info.xczyjkwtnxgjb = []
        } else {
          this.info.xczyjkwtnxgjb = this.info.xczyjkwtnxgjb.split(',')
        }
        if (!this.info.xczyjkwtszjb) {
          this.info.xczyjkwtszjb = []
        } else {
          this.info.xczyjkwtszjb = this.info.xczyjkwtszjb.split(',')
        }
        if (!this.info.xczyjkwtxzjb) {
          this.info.xczyjkwtxzjb = []
        } else {
          this.info.xczyjkwtxzjb = this.info.xczyjkwtxzjb.split(',')
        }
        if (!this.info.xczyjkwtxgjb) {
          this.info.xczyjkwtxgjb = []
        } else {
          this.info.xczyjkwtxgjb = this.info.xczyjkwtxgjb.split(',')
        }
        if (!this.info.xczyjkwtybjb) {
          this.info.xczyjkwtybjb = []
        } else {
          this.info.xczyjkwtybjb = this.info.xczyjkwtybjb.split(',')
        }
        if (!this.info.jkzdnrff) {
          this.info.jkzdnrff = []
        } else {
          this.info.jkzdnrff = this.info.jkzdnrff.split(',')
        }
      } catch (error) {
        console.log('体检记录--查询单条信息请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getJzsInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-81591863
      try {
        const params = {
          start: '0',
          length: '100',
          jktjid: this.params.jktjid || ''
        }
        const { data: res } = await this.$http.get('/ykdfmyghjzss', { params: params })
        console.log('接种史--查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.jzss = res.data
        if (!res.data || res.data.length < 1) return
      } catch (error) {
        console.log('接种史--查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getZyInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-81591855
      try {
        const params = {
          start: '0',
          length: '100',
          jktjid: this.params.jktjid || '',
          grjbxxid: this.params.grjbxxid || ''
        }
        const { data: res } = await this.$http.get('/ykdzyzlqkzyss', { params: params })
        console.log('住院情况--查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (!res.data || res.data.length < 1) return
        this.zys = res.data.filter(item => item.zyzlqklx === '1')
        this.jtbcs = res.data.filter(item => item.zyzlqklx === '2')
      } catch (error) {
        console.log('住院情况--查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getYyInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-81591848
      try {
        const params = {
          start: '0',
          length: '100',
          jktjid: this.params.jktjid || ''
          // grjbxxid: this.params.grjbxxid || ''
        }
        const { data: res } = await this.$http.get('/ykdzyyyqks', { params: params })
        console.log('主要用药--列表查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (!res.data || res.data.length < 1) return
        this.yys = res.data
      } catch (error) {
        console.log('主要用药--列表查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    dlplChange (e) {
      if (e === '4') {
        this.info.shfstydlmcdlsj = ''
        this.info.shfstydljcdlsj = ''
        this.info.shfstydldlfs = ''
      }
    },
    xyqkChange (e) {
      if (e === '1') {
        this.info.shfsxyqkrxyl = ''
        this.info.shfsxyqkksxynl = ''
        this.info.shfsxyqkjynl = ''
      } else if (e === '2') {
        this.info.shfsxyqkksxynl = ''
        this.info.shfsxyqkrxyl = ''
      } else if (e === '3') {
        this.info.shfsxyqkjynl = ''
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.tab-box {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .services-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #475265;

    .el-button {
      min-width: 120px;
      margin-left: 15px;
      margin-right: 15px;
      padding-top: 6px;
      padding-bottom: 6px;
      border: none;
      background-color: #3782F2;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .services-scroll {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .title {
    width: 100%;
    height: 30px;
    text-align: center;
    color: #475265;
    font-size: 22px;
  }

  .thead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: 46px;
    color: #475265;
    font-size: 22px;
  }

  .tr {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 11px;
    box-sizing: border-box;
    border: 1px solid #475265;
    border-bottom: none;
    color: #475265;
    font-size: 14px;

    .line-paddint-left {
      padding-left: 10px;
      box-sizing: border-box;
    }

    span {
      height: 50px;
      line-height: 50px;
      width: 33%;
    }

    .flex1 {
      flex: 1;
    }

    .flex2 {
      flex: 2;
    }

    .flex4 {
      flex: 4;
    }

    .flex6 {
      flex: 6;
    }

    .flex7 {
      flex: 1.5;
    }

    .boder-right {
      border-right: 1px solid #475265;
      box-sizing: border-box;
    }

    .padding {
      text-align: center;
      // border: .0125rem red solid;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-checkbox__label,
    /deep/.el-radio__label {
      font-size: 14px;
    }

    /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
    /deep/.el-radio__input.is-checked .el-radio__inner {
      background-color: #475265;
      border-color: #475265;
    }

    .el-checkbox,
    .el-radio {
      color: #475265;
    }

    /deep/.el-checkbox__input.is-checked+.el-checkbox__label,
    /deep/.el-radio__input.is-checked+.el-radio__label {
      color: #475265;
    }

    // /deep/.el-radio__inner::after {
    //   content: '✓';
    //   width: 0;
    //   height: 0;
    //   top: 0;
    //   left: .025rem;
    //   font-size: .15rem;
    // }

  }

  .tr1 {
    height: 450px;

    .height {
      height: 450px;
      line-height: 450px;
    }

    .height1 {
      height: 450px;
    }

    .height2 {
      height: 850px;
      // line-height: 850px;
    }

    .width1 {
      flex: 9;
    }

    .line {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      border-bottom: .0125rem solid #475265;
      box-sizing: border-box;

      .span-width {
        width: 60px;
        margin-right: 10px;
      }

      .span-width1 {
        width: 60px;
        text-align: right;
        color: #fff;
      }

      .span-input {
        overflow: hidden;
        width: 1rem !important;
        height: 30px !important;
        line-height: 30px !important;
        border-bottom: 1px #fff solid;
      }

      .span-color {
        color: #fff;
      }

      .span-width2 {
        width: 128px;
      }

      .line-paddint-left {
        margin-left: 20px;
      }

      .div1 {
        flex: 1;
        height: 50px;
        text-align: center;
      }

      .div2 {
        flex: 2;
        text-align: center;
      }

      .div4 {
        flex: 4;
      }

      .div6 {
        flex: 6;
      }

      .div-flex {
        display: flex;
        flex-direction: column;
      }

      .div-height {
        display: flex;
        align-items: center;
        height: 100px;
      }

      .div-height1 {
        height: 100px;
        line-height: 100px;
      }

      .div-height2 {
        height: 100px;
      }

      .div-height3 {
        height: 150px;
      }

      .div-height4 {
        height: 250px;
      }

      .div-height5 {
        height: 300px;
      }

      .el-input {
        width: 140px;

        height: 50px;
      }

      .input-width {
        width: 60px;
      }

      /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        color: #475265;
      }

      .unit {
        margin-left: .0625rem;
      }

      .div-line {
        display: flex;
        align-items: center;
        height: 50px;

        .div-line-flex1 {
          flex: 1;
          text-align: center;
        }

        .div-line-flex3 {
          flex: 3;
        }

        .div-line-flex5 {
          flex: 5;
        }

        .div-line-border {
          border-right: 1px solid #475265;
          box-sizing: border-box;
        }

        .border-right {
          border-right: 1px solid #475265;
          box-sizing: border-box;
        }

        .div-line-width {
          display: flex;
          align-items: center;
          width: 400px;
          margin-bottom: 4px;
        }
      }

      .div-border {
        border-bottom: 1px solid #475265;
        box-sizing: border-box;
      }

      .div-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 136px;

        .el-input {
          width: 60px;
          background-color: red;
        }

        .div-box-line {
          display: flex;
          align-items: center;
          width: 80%;
          height: 30px;
          border: 1px red solid;

          span {
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }

    .line1 {
      height: 100px;
    }

    .line2 {
      border: none;
    }

    .line3 {
      height: 150px;
    }

    .line4 {
      height: 250px;
    }

    .line5 {
      height: 300px;
    }
  }

  .tr2 {
    padding: 0;
  }

  .tr3 {
    height: 850px;
  }

  .t4 {
    height: 80px !important;
  }

  .t5 {
    text-align: center;
    height: 1650px !important;
  }

  .t6 {
    text-align: center;
    height: 800px !important;
  }

  .t7 {
    text-align: center;
    height: 350px;
  }

  .t8 {
    text-align: center;
    height: 300px;
  }

  .t9 {
    text-align: center;
    height: 200px;
  }

  .t61 {
    text-align: left;
  }

  .box-flex {
    display: flex;
    align-items: center;
  }

  .line-margin-left {
    width: 200px;
    margin-left: 40px;
  }

  .line-margin-left1 {
    justify-content: flex-end;
    text-align: right;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .border-none {
    border: none !important;
  }

  .border1 {
    border-bottom: 1px solid #475265 !important;
    box-sizing: border-box;
  }

  .t5-border-right {
    border-right: 1px solid #475265;
  }

  .div-line-flex4 {
    height: 50px !important;
  }

  .div-line-height1 {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .width-span {
    width: 60px !important;
    text-align: center;
  }

  .width-span1 {
    width: 120px !important;
    margin-left: 40px;
  }

  .width-span2 {
    width: 70px !important;
  }

  .width-span-margin {
    margin-left: 32px;
  }

  .width-span-margin1 {
    margin-left: 4px;
  }

  .width-span-width {
    width: 120px;
  }

  .width-span-width1 {
    // width: 100px;
    white-space: nowrap;
  }

  .width-span-width2 {
    width: 100px;
    white-space: nowrap;
  }

  .white-space {
    white-space: nowrap;
  }

  // .el-input {
  //   width: 32px !important;
  //   height: .375rem !important;
  //   border-bottom: .0125rem solid #fff;
  //   box-sizing: border-box;
  // }

  .line-warp {
    font-size: 12px !important;
  }

  .tab {
    display: flex;
    width: 100%;
    height: 150px;
  }

  .tab-left {
    flex: 2;
    text-align: center;
  }

  .tab-right {
    flex: 6;
  }

  .tab-line {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
  }

  .tab-line-item {
    flex: 1;
    text-align: center;
    height: 50px;
    line-height: 50px;
  }

  .tab-line-item1 {
    flex: 1.51;
  }

  .tab-line-item2 {
    flex: 3.51;
  }

  .div-flex-line {
    display: flex;
    align-items: center;
    margin-left: 18px;
    height: 40px !important;
  }

  .div-flex-line-width {
    display: flex;
    align-items: center;
    width: 320px;
  }

  .div-flex-line-width1 {
    width: 88px !important;
    text-align-last: justify;
    white-space: nowrap;
  }

  .div-flex-line-width2 {
    width: 70px !important;
    text-align-last: justify;
    margin-left: 5px;
    margin-right: 6px;
  }

  .line-margin-top {
    margin-top: 10px;
  }

  .err {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 29px;

    .err-width {
      width: 60px;
      white-space: nowrap;
    }

    .el-input {
      width: 100%;
      margin: 0;
    }

    /deep/.el-input__inner {
      width: 100px;
      margin: 0;
      height: 20px;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #fff;
    }
  }

  .tr-box-left {
    display: flex;
    align-items: center;
    margin-top: 32px;

    /deep/.el-input__inner {
      width: 100px;
      margin: 0;
      height: 20px;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #fff;
    }

    .tr-box-flex {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
}</style>
