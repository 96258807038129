<template>
  <div class="page">
    <div class="head">
      <el-button type="primary">健康干预</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <el-button @click="openAddAssess(-1)" size="mini" type="primary">新增</el-button>
    <div class="box">
      <el-table :data="tableData" height="200" border>
        <el-table-column prop="text" label="评估名称">
        </el-table-column>
        <el-table-column prop="name" label="评估时间">
        </el-table-column>
        <el-table-column prop="address" label="操作" width="240">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="openAssess(scope.row.index)">查看</el-button>
            <el-button type="primary" size="small" @click="openAddAssess(scope.row.index)">编辑</el-button>
            <el-button type="danger" size="small">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 评估弹窗 -->
    <Assess v-if="showAssess" @close="closeAssess" :showIndex="tabIndex"></Assess>
    <!-- 新增 编辑 -->
    <AddAssess v-if="showAddAssess" @close="closeAddAssess"></AddAssess>
  </div>
</template>

<script>
import Assess from './basicInfo/assessPopup.vue'
import AddAssess from './addAssess.vue'
export default {
  components: {
    Assess,
    AddAssess
  },
  data () {
    return {
      tabIndex: 0,
      tableData: [
        { text: '高血压评估风险', index: 0 },
        { text: '糖尿病风险评估', index: 1 },
        { text: '肥胖症评估', index: 2 },
        { text: '血脂异常风险评估', index: 3 },
        { text: '代谢综合征风险评估', index: 4 },
        { text: '自我评估', index: 5 }
      ],
      showAssess: false,
      showAddAssess: false
    }
  },
  methods: {
    openAssess (index) {
      this.tabIndex = index
      this.showAssess = true
    },
    closeAssess () {
      this.tabIndex = 0
      this.showAssess = false
    },
    openAddAssess (index) {
      this.tabIndex = index
      console.log(index)
      this.showAddAssess = true
    },
    closeAddAssess () {
      this.tabIndex = 0
      this.showAddAssess = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    /deep/.el-table__cell {
      text-align: center;
      color: #000;
    }

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #fff;
      font-size: 14px;

      .item {
        flex: 1;
        text-align: center;
        color: #000;
      }

      .item1 {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          cursor: pointer;
          color: #01EEFD;
          font-size: 14px;
        }

        .btn1 {
          margin-left: 30px;
          margin-right: 30px;
        }

        .red {
          color: #FF0000;
        }
      }
    }

    .line1 {
      height: 40px;
      line-height: 40px;
      background-color: #024276;

      .item {
        color: #fff;
      }
    }

    .line2 {
      margin-top: 10px;
    }
  }

  .return {
    position: absolute;
    bottom: 30px;
  }
}</style>
