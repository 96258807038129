<template>
  <div class="popup1">
    <div class="title">
      <span>选择干预模板</span>
    </div>
    <div class="content">
      <!-- <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div> -->
      <el-input placeholder="请输入关键字搜索"></el-input>
      <div class="table">
        <el-table border height="440" :data="tableData">
          <el-table-column v-for="(item, index) in tableKeyValue" :key="index" :prop="item.prop" :label="item.label">
          </el-table-column>
          <el-table-column type="selection">
          </el-table-column>
        </el-table>
        <div class="bottom">
          <div style="width: 100px"></div>
          <div class="bts">
            <el-button size="small" @click="tableClosePopup">取消</el-button>
            <el-button type="primary" size="small" @click="tableClosePopup">确定</el-button>
          </div>
          <el-pagination :page-size="100" layout="total, prev, pager, next" :total="1000">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableKeyValue: [
        { prop: 'id', label: '模板标题' },
        { prop: 'name', label: '创建者' },
        { prop: 'type', label: '创建时间' },
        { prop: 'gg', label: '备注' }
      ],
      tableData: [
        { id: '睡眠管理干预', name: 'nnn', type: '2022-10-19', gg: '无' }
      ]
    }
  },
  methods: {
    tableClosePopup () {
      this.$emit('tableClosePopup')
      this.closePopup()
    },
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 888;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 30px;
    font-weight: 500;
  }

  .content {
    position: relative;
    width: 100%;
    height: calc(100% - 63px);
    margin-top: 10px;

    .close {
      position: absolute;
      top: 14px;
      right: 30px;
      color: #000;
      font-size: 24px;
    }

    .el-input {
      width: 300px;
      height: 38px;
      margin-bottom: 20px;
      line-height: 38px;
      padding: 0;
    }

    .table {
      width: 100%;
      height: calc(100% - 70px);

      /deep/.el-table__cell {
        text-align: center;
        color: #000;
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 26px;
        margin-top: 10px;

        .bts {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 164px;
          height: 26px;
          font-size: 14px;
          color: #fff;
        }

        /deep/.el-pagination__total {
          color: #fff;
        }
      }
    }
  }
}
</style>
