<template>
  <div class="assess">
    <div class="line">
      <div class="left">
        <span class="left-title">您的全身性肥胖评估结果为：</span>
        <div class="box">
          <div class="item"></div>
          <div class="item"></div>
          <div class="item"></div>
          <div class="item"></div>
          <img class="img img3" src="../../../../img/ssjk/man.png" alt="人物">
        </div>
        <div class="text">
          <span>体重过低</span>
          <span>体重正常</span>
          <span>超重</span>
          <span>肥胖</span>
        </div>
      </div>
      <div class="right">
        <span class="left-title">您的中心性肥胖评估结果：</span>
        <div class="box">
          <div class="item"></div>
          <div class="item"></div>
          <div class="item"></div>
          <img class="img" src="../../../../img/ssjk/man.png" alt="人物">
        </div>
        <div class="text text1">
          <span>腰围正常</span>
          <span>中心性肥胖（1级）</span>
          <span>中心性肥胖（2级）</span>
        </div>
      </div>
    </div>
    <div class="line-title">中国成人超重和肥胖的体重指数和腰围界限值与相关疾病危险的关系</div>
    <div class="tables">
      <div class="tr tr1">
        <span>分类</span>
        <span>体重指数（kg/平方米）</span>
        <span>体重过低&lt;80</span>
        <span>体重过低80-90</span>
        <span>体重过低≥90</span>
      </div>
      <div class="tr">
        <span>体重过低</span>
        <span>&lt;18.5</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
      </div>
      <div class="tr">
        <span>体重正常</span>
        <span>&lt;18.5</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
      </div>
      <div class="tr">
        <span>超重</span>
        <span>&lt;18.5</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
      </div>
      <div class="tr">
        <span>肥胖</span>
        <span>&lt;18.5</span>
        <span>...</span>
        <span>...</span>
        <span>...</span>
      </div>
    </div>
    <div class="tip">* 相关疾病指高血压、糖尿病、血脂异常和危险聚集</div>
    <div class="tip">** 体重过低可能预示有其他健康问题</div>
    <div class="line-title1">指导建议</div>
    <div class="div">多吃蔬菜水果</div>
    <!-- <div class="line-title1">运动建议</div>
    <div class="div">每天坚持30分钟有氧运动</div>
    <div class="line-title1">生活建议</div>
    <div class="div">作息规律，保持积极乐观的心态</div> -->
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.assess {
  display: flex;
  flex-direction: column;
  width: 96%;
  margin: 0 auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  div {
    color: #000;
  }

  .line {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4.4rem;
    margin-top: .25rem;
    border-bottom: .0125rem solid #000;
    box-sizing: border-box;

    .left,
    .right {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      position: relative;
      width: 50%;
      height: 4.25rem;
      .left-title {
        position: absolute;
        left: .75rem;
        top: .25rem;
        color: #000;
        font-size: .225rem;
      }

      .box {
        display: flex;
        align-items: flex-end;
        position: relative;
        height: 2rem;
        .img {
          position: absolute;
          bottom: -0.125rem;
          left: .9375rem;
          transform: translateX(-50%);
          width: .75rem;
          height: 1.25rem;
        }
        .img1 {
          position: absolute;
          bottom: .5rem;
          left: 2.8125rem;
        }
        .img2 {
          position: absolute;
          bottom: 1.125rem;
          left: 4.6875rem;
        }
         .img3 {
          position: absolute;
          bottom: 1.75rem;
          left: 6.625rem;
        }
        .item {
          width: 1.875rem;
          height: .125rem;
          background-color: #00DB00;
          &:nth-child(2) {
            height: .75rem;
            background-color: #97CBFF;
          }
          &:nth-child(3) {
            height: 1.375rem;
            background-color: #FFDC35;
          }
          &:nth-child(4) {
            height: 2rem;
            background-color: #FF9224;
          }
        }
      }
      .text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 7.5rem;
        margin-top: .0875rem;

        span {
          flex: 1;
          text-align: center;
          color: #000;
          font-size: .2rem;
        }
      }
      .text1 {
        width: 5.625rem;
      }
    }

    .left {
      box-sizing: border-box;
      border-right: .0125rem solid #000;

    }

    .right {
      padding: 0 .2375rem;
      box-sizing: border-box;
    }
  }

  .line-title {
    display: flex;
    align-items: center;
    margin-top: .35rem;
    margin-left: .55rem;
    font-size: .225rem;
  }

  .tables {
    width: 98%;
    height: 2.3375rem;
    margin-top: .25rem;
    margin-left: .55rem;
    border-left: .0125rem solid #000;
    border-top: .0125rem solid #000;
    box-sizing: border-box;

    .tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: .3875rem;
      border-bottom: .0125rem solid #000;
      box-sizing: border-box;
      font-size: .175rem;

      span {
        flex: 1;
        height: .3875rem;
        line-height: .3875rem;
        text-align: center;
        box-sizing: border-box;
        border-right: .0125rem solid #000;
      }
    }
    .tr1 {
      height: .7625rem;

      span {
        height: .7625rem;
        line-height: .7625rem;
      }
    }
  }
  .tip {
    margin-top: .125rem;
    margin-left: .625rem;
    color: #FF0000;
    font-size: .175rem;
  }
  .line-title1 {
    margin-top: .35rem;
    margin-left: .675rem;
    font-size: .225rem;
  }

  .div {
    width: 90%;
    height: 1rem;
    margin: 0 auto;
    margin-top: .25rem;
    padding: .1125rem .1rem;
    box-sizing: border-box;
    border: .0125rem solid #000;
    font-size: .175rem;
  }
}
</style>
