<template>
  <div class="popup1">
    <div class="title">
      <span>选择营养处方</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <TableEl :tableKeyValue="tableKeyValue" :tableData="tableData" @selectInfo="selectInfo" @tableClosePopup="closePopup"></TableEl>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TableEl from './elTable.vue'
export default {
  components: {
    TableEl
  },
  data () {
    return {
      tableKeyValue: [
        { prop: 'jkcfid', label: '编号' },
        { prop: 'yycfmc', label: '名称' },
        { prop: 'yycfyyplx', label: '营养品类型' },
        { prop: 'yycfgg', label: '规格' },
        { prop: 'yycfjl', label: '剂量' },
        { prop: 'yycfyfyl', label: '用法用量' }
      ],
      tableData: [],
      selectObj: null
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-208862418
      try {
        const { data: res } = await this.$http.post('/ydkjkcflbs/getlist', {
          ssjgbh: this.loginInfo.jgbh || '',
          jgbh: this.loginInfo.jgbh || '',
          cflx: '1'
        })
        console.log('处方列表获取', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
      } catch (error) {
        console.log('处方列表获取请求失败', error)
        // this.$message.error('系统异常，请稍后重试')
      }
    },
    selectInfo (val) {
      console.log(val)
      this.selectObj = val
    },
    submit () {
      this.$emit('selectInfo', this.selectObj)
      this.closePopup()
    },
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="less" scoped>
.popup1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 50px;
  background-color: rgba(0, 0, 0, .8);
  box-sizing: border-box;

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 30px;
    font-weight: 500;
  }

  .content {
    position: relative;
    width: 100%;
    height: calc(100% - 63px);
    margin-top: 10px;
    padding: 50px 60px;
    background-color: #fff;
    // background-image: url('../../../img/ssjk/background.png');
    // background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 15.6px;
      right: 30px;
      color: #000;
      font-size: 24px;
      cursor: pointer;
    }
  }
}
</style>
