<template>
  <div class="popup">
    <div class="scroll">
      <div class="title">
        <span class="span2" @click="closePopup(0)">系统管理</span>
        <span>/</span>
        <span class="span2" @click="closePopup(1)">医护管理</span>
        <span>/</span>
        <span>添加医护</span>
      </div>
      <el-tree :data="data" show-checkbox node-key="id" :default-expanded-keys="[1, 2, 3, 4, 5, 6, 7]"
        :props="defaultProps">
      </el-tree>
    </div>
    <div class="btns">
      <el-button @click="close">取消</el-button>
      <el-button class="color" @click="close">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: [{
        id: 1,
        label: '患者管理',
        children: [{
          id: 11,
          label: '患者分析'
        }, {
          id: 12,
          label: '住院患者'
        }, {
          id: 13,
          label: '出院患者'
        }]
      }, {
        id: 2,
        label: '数据管理',
        children: [{
          id: 21,
          label: '血糖记录'
        }, {
          id: 22,
          label: '血压记录'
        }, {
          id: 23,
          label: '体温记录'
        }, {
          id: 24,
          label: '用药记录'
        }, {
          id: 25,
          label: '医嘱执行'
        }]
      }, {
        id: 3,
        label: '智能提醒',
        children: [{
          id: 31,
          label: '血糖预警'
        }, {
          id: 32,
          label: '血压预警'
        }, {
          id: 33,
          label: '体温预警'
        }, {
          id: 34,
          label: '医嘱执行单'
        }]
      }, {
        id: 4,
        label: '门诊管理',
        children: [{
          id: 41,
          label: '患者管理'
        }, {
          id: 42,
          label: '血糖管理'
        }, {
          id: 43,
          label: '血压管理'
        }]
      }, {
        id: 5,
        label: '质控管理',
        children: [{
          id: 51,
          label: '质控记录'
        }, {
          id: 52,
          label: '试纸批次管理'
        }, {
          id: 53,
          label: '质控液备案'
        }]
      }, {
        id: 6,
        label: '设备管理',
        children: [{
          id: 61,
          label: '院内设备清单'
        }, {
          id: 62,
          label: '院外设备清单'
        }, {
          id: 63,
          label: '质控液备案'
        }]
      }, {
        id: 7,
        label: '智能提醒',
        children: [{
          id: 71,
          label: '系统管理'
        }, {
          id: 72,
          label: '权限管理'
        }, {
          id: 73,
          label: '医护管理'
        }, {
          id: 74,
          label: '规则管理'
        }, {
          id: 75,
          label: '科室规则'
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    closePopup (index) {
      this.$emit('closePopup', index)
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #08223E;

  .scroll {
    overflow-y: scroll;
    height: calc(100% - .5rem);

    &::-webkit-scrollbar {
      width: 0;
    }

    .title {
      height: .625rem;
      line-height: .625rem;
      color: #04B4D0;
      font-size: .225rem;
    }

    .el-tree {
      background-color: transparent;
      color: #01EEFD;

      .el-tree-node>.el-tree-node__content:hover {
        background-color: transparent;
      }

      .is-current {
        background-color: transparent;
        color: #01EEFD;
        background-color: #081C38;
      }

      .el-tree-node.is-current>.el-tree-node__content {
        background-color: transparent !important;
      }

      /deep/.el-tree-node>.el-tree-node__content:hover {
        background-color: transparent;
      }

      /deep/.is-current {
        background-color: transparent;
        color: #01EEFD;
        background-color: #081C38;
      }

      /deep/.el-tree-node.is-current>.el-tree-node__content {
        background-color: transparent !important;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: .5rem;

    .el-button {
      width: .875rem;
      height: .325rem;
      line-height: .325rem;
      margin-right: .625rem;
      text-align: center;
      background-color: #33465C;
      color: #fff;
      font-size: .175rem;
      border: 0;
      padding: 0;
      border-radius: 0;
    }

    .color {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
