<template>
  <div class="tab">
    <div class="head">
      <div class="left">
        <div class="user"></div>
        <span class="text">测试</span>
        <span class="text">女</span>
        <span class="text">-岁</span>
        <span class="text">电话：-</span>
        <span class="text">地址：-</span>
        <span class="label organ">高</span>
        <span class="label green">糖</span>
        <span class="label blue">冠</span>
        <span class="label purple">肿</span>
        <span class="label red">精</span>
      </div>
      <div class="right">
        <span class="el-icon-search icon"></span>
        <span class="text">筛选</span>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="title">
          <span>患者摘要</span>
        </div>
        <div class="scroll">
          <div class="scroll-title">
            <span class="blue"></span>
            <span>诊断</span>
          </div>
          <div class="scroll-line">
            <span>便秘：2次</span>
          </div>
          <div class="scroll-line">
            <span>带状疱症：3次</span>
          </div>

          <div class="scroll-title">
            <span class="blue"></span>
            <span>体征</span>
          </div>
          <div class="scroll-line" :class="{ 'scroll-active': tabIndex === 1 }" @click="tabClick(1)">
            <span>血压：122/83mmHg</span>
          </div>
          <div class="scroll-line">
            <span>血糖：3.2mmol</span>
          </div>
          <div class="scroll-line">
            <span>BMI：24.46</span>
          </div>

          <div class="scroll-title">
            <span class="blue"></span>
            <span>生活习惯</span>
          </div>
          <div class="scroll-line">
            <span>吸烟情况：无</span>
          </div>
          <div class="scroll-line">
            <span>饮食情况：嗜糖</span>
          </div>
          <div class="scroll-line">
            <span>饮酒情况：从不</span>
          </div>
          <div class="scroll-line">
            <span>运动情况：0次/周，0分钟/次</span>
          </div>

          <div class="scroll-title">
            <span class="blue"></span>
            <span>门诊用药</span>
          </div>
          <div class="scroll-line" :class="{ 'scroll-active': tabIndex === 3 }" @click="tabClick(3)">
            <span>2022-08-03：合欢花</span>
          </div>
          <div class="scroll-line">
            <span>2022-08-03：阿斯匹林胶囊</span>
          </div>

          <div class="scroll-title">
            <span class="blue"></span>
            <span>异常检测项目</span>
          </div>
          <div class="scroll-line">
            <span>2022-08-03：CT常规检查</span>
          </div>

          <div class="scroll-title">
            <span class="blue"></span>
            <span>异常检验</span>
          </div>
          <div class="scroll-line" :class="{ 'scroll-active': tabIndex === 5 }" @click="tabClick(5)">
            <span>2022-08-03：生化全套</span>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- 体征 -->
        <Item2 v-if="tabIndex === 1"></Item2>
        <Item4 v-else-if="tabIndex === 3"></Item4>
        <Item6 v-else-if="tabIndex === 5"></Item6>
      </div>
    </div>
  </div>
</template>

<script>
import Item2 from './item2/item2.vue'
import Item4 from './item4/item4.vue'
import Item6 from './item6/item6.vue'
export default {
  components: {
    Item2,
    Item4,
    Item6
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['血压', '心率', '血糖', '体温', '身高', '体重', 'BMI'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 76px;
    padding-left: 28px;
    padding-right: 109px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .user {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #fff;
      }

      .text {
        margin-left: 15px;
        color: #fff;
        font-size: 16px;
      }

      .label {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 50%;
        margin-left: 20px;
        color: #fff;
        font-size: 16px;
      }

      .organ {
        background-color: #E34D04;
      }

      .green {
        background-color: #109607;
      }

      .blue {
        background-color: #075896;
      }

      .purple {
        background-color: #790796;
      }

      .red {
        background-color: #FA2323;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        color: #fff;
        font-size: 30px;
      }

      .text {
        margin-left: 7px;
        color: #fff;
        font-size: 14px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 83px);
    margin-top: 7px;

    .left {
      width: 256px;
      height: 100%;
      padding: 0 3px;
      background-color: #0C4969;
      border-radius: 6px;
      box-sizing: border-box;

      .title {
        width: 100%;
        height: 36px;
        padding: 0 7px;
        padding-top: 12px;
        border-bottom: 1px solid #fff;
        box-sizing: border-box;
        color: #fff;
        font-size: 14px;
      }

      .scroll {
        width: 100%;
        height: calc(100% - 66px);
        margin-top: 20px;

        &::-webkit-scrollbar {
          width: 0;
        }

        .scroll-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 30px;
          margin-top: 10px;
          color: #fff;
          font-size: 16px;
          font-weight: 600;

          .blue {
            width: 3px;
            height: 17px;
            margin-right: 4px;
            background-color: #01EEFD;
          }
        }

        .scroll-line {
          width: 100%;
          margin-top: 10px;
          padding-left: 8px;
          color: #fff;
          font-size: 14px;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .scroll-active {
          color: #01EEFD;
        }
      }
    }

    .right {
      width: calc(100% - 268px);
      height: 100%;
      margin-left: 12px;
      background-color: #0C4969;
    }
  }
}
</style>
