<template>
  <div class="page">
    <div class="switch">
      <span class="item" :class="{ item1: switchIndex === index }" v-for="(item, index) in switchs" :key="index"
        @click="switchClick(index)">{{ item }}</span>
    </div>
    <div class="content" v-if="switchIndex === 0">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-input class="search-input" placeholder="姓名/床位" suffix-icon="el-icon-search">
            </el-input>
            <el-date-picker type="date" class="date margin-left" placeholder="时间">
            </el-date-picker>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">重置</el-button>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%" border>
            <el-table-column prop="name" label="检测日期">
            </el-table-column>
            <el-table-column prop="date" label="检测时间">
            </el-table-column>
            <el-table-column prop="type" label="床号">
            </el-table-column>
            <el-table-column prop="type1" label="姓名">
            </el-table-column>
            <el-table-column prop="type1" label="血糖值">
            </el-table-column>
            <el-table-column prop="type1" label="检测时段">
            </el-table-column>
            <el-table-column prop="type1" label="处理结果">
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template>
                <!-- slot-scope="scope" -->
                <div class="flex">
                  <span @click="openHandleRecord">处理</span>
                  <span @click="openDeleteRecord('是否删除患者：李四,血糖值为：21.2的预警？')">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-else-if="switchIndex === 1">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-input class="search-input" placeholder="姓名/床位" suffix-icon="el-icon-search">
            </el-input>
            <el-date-picker type="date" class="date margin-left" placeholder="时间">
            </el-date-picker>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">重置</el-button>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%" border>
            <el-table-column prop="name" label="检测日期">
            </el-table-column>
            <el-table-column prop="date" label="检测时间">
            </el-table-column>
            <el-table-column prop="type" label="床号">
            </el-table-column>
            <el-table-column prop="type1" label="姓名">
            </el-table-column>
            <el-table-column prop="type1" label="收缩压(mmHg)">
            </el-table-column>
            <el-table-column prop="type1" label="舒张压(mmHg)">
            </el-table-column>
            <el-table-column prop="type1" label="心率(次/分)">
            </el-table-column>
            <el-table-column label="预警结果">
              <template>
                <!-- slot-scope="scope" -->
                <div class="flex flex1">
                  <span>轻高</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-else-if="switchIndex === 2">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-input class="search-input" placeholder="姓名/床位" suffix-icon="el-icon-search">
            </el-input>
            <el-date-picker type="date" class="date margin-left" placeholder="时间">
            </el-date-picker>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">重置</el-button>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%" border>
            <el-table-column prop="name" label="检测日期">
            </el-table-column>
            <el-table-column prop="date" label="检测时间">
            </el-table-column>
            <el-table-column prop="type" label="床号">
            </el-table-column>
            <el-table-column prop="type1" label="姓名">
            </el-table-column>
            <el-table-column prop="type1" label="体温(℃)">
            </el-table-column>
            <el-table-column prop="type1" label="处理结果">
            </el-table-column>
            <el-table-column prop="type1" label="处理人">
            </el-table-column>
            <el-table-column prop="type1" label="处理时间">
            </el-table-column>
            <el-table-column label="操作">
              <template>
                <!-- slot-scope="scope" -->
                <div class="flex flex1">
                  <span @click="openDeleteRecord('是否删除患者：潘小章,体温值为：38.6℃的预警？')">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 处理记录 -->
    <HandleRecord v-if="showHandleRecord" @close="closeHandleRecord"></HandleRecord>
    <!-- 删除记录 -->
    <DeleteRecord v-if="showDeleteRecord" :tipInfo="tipInfo" @close="closeDeleteRecord"></DeleteRecord>
  </div>
</template>

<script>
import HandleRecord from './handleRecord.vue'
import DeleteRecord from './deleteRecord.vue'
export default {
  components: {
    HandleRecord,
    DeleteRecord
  },
  data () {
    return {
      switchIndex: 0,
      switchs: Object.freeze(['血糖预警', '血压预警', '体温预警']),
      tableData: [{
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }],
      value: '',
      options: [{
        value: '',
        label: ''
      }],
      showHandleRecord: false,
      tipInfo: '',
      showDeleteRecord: false
    }
  },
  methods: {
    switchClick (index) {
      if (index === this.switchIndex) return
      this.switchIndex = index
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openHandleRecord () {
      this.showHandleRecord = true
    },
    closeHandleRecord () {
      this.showHandleRecord = false
    },
    openDeleteRecord (value) {
      this.tipInfo = value
      this.showDeleteRecord = true
    },
    closeDeleteRecord () {
      this.showDeleteRecord = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .switch {
    display: flex;
    width: 100%;
    height: 40px;

    .item {
      width: 80px;
      height: 40px;
      line-height: 40px;
      margin-right: 20px;
      padding: 0 10px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      background-color: rgba(115, 115, 115, .4);
      cursor: pointer;
    }

    .item1 {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  .content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 50px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .table {
      width: 100%;
      height: calc(100% - 180px);

      /deep/.el-table .cell {
        text-align: center;
        color: #000;
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 40px;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      color: #01EEFD;
      font-size: 15px;

      span {
        cursor: pointer;
      }
    }

    .flex1 {
      justify-content: center;
    }

    .content-box {
      width: 100%;
      height: 100%;
      padding: 20px;
      border: 4px solid #073F73;
      box-sizing: border-box;

      .content-box-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 260px;
          }

          .el-select {
            width: 200px;
            margin-left: 10px;
          }

          .date {
            margin-right: 10px;
          }

          .margin-left {
            margin-left: 20px;
          }

          .blue,
          .gray {
            margin-left: 40px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            background-color: #284867;
          }
        }
      }
    }
  }
}
</style>
