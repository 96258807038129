<template>
  <div class="tab">
    <div class="tab-title">
      <span>中医基本体质辨识、健康指导表</span>
    </div>
    <div class="tab-info">
      <div class="item">
        <span>姓名：</span>
      </div>
      <div class="item">编号：</div>
    </div>
    <div class="tab-scroll">
      <div class="table">
        <div class="tr">
          <div class="td td1">
            <span>体质类型</span>
          </div>
          <div class="td td1">
            <span>总体特征</span>
          </div>
          <div class="td td1">
            <span>形体特征</span>
          </div>
          <div class="td td1">
            <span>常见表现</span>
          </div>
          <div class="td td1">
            <span>饮食调养</span>
          </div>
          <div class="td td1">
            <span>起居</span>
          </div>
          <div class="td td1">
            <span>中医药膳指导</span>
          </div>
          <div class="td td1">
            <span>穴位保健</span>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td">
            <span>辨平和质(正常体质)</span>
          </div>
          <div class="td">
            <span>阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征</span>
          </div>
          <div class="td">
            <span>体形匀称、健壮</span>
          </div>
          <div class="td">
            <span>面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力</span>
          </div>
          <div class="td">
            <span>饮食有节、宜粗细粮合理搭配。</span>
          </div>
          <div class="td">
            <span>平素病较少，劳逸结合，坚持锻炼，穿戴求自然</span>
          </div>
          <div class="td">
            <span>春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品
            </span>
          </div>
          <div class="td">
            <span>穴位保健</span>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td">
            <span>气虚质</span>
          </div>
          <div class="td">
            <span>阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征</span>
          </div>
          <div class="td">
            <span>体形匀称、健壮</span>
          </div>
          <div class="td">
            <span>面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力</span>
          </div>
          <div class="td">
            <span>饮食有节、宜粗细粮合理搭配。</span>
          </div>
          <div class="td">
            <span>平素病较少，劳逸结合，坚持锻炼，穿戴求自然</span>
          </div>
          <div class="td">
            <span>春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品
            </span>
          </div>
          <div class="td">
            <span>穴位保健</span>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td">
            <span>阳虚质</span>
          </div>
          <div class="td">
            <span>阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征</span>
          </div>
          <div class="td">
            <span>体形匀称、健壮</span>
          </div>
          <div class="td">
            <span>面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力</span>
          </div>
          <div class="td">
            <span>饮食有节、宜粗细粮合理搭配。</span>
          </div>
          <div class="td">
            <span>平素病较少，劳逸结合，坚持锻炼，穿戴求自然</span>
          </div>
          <div class="td">
            <span>春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品
            </span>
          </div>
          <div class="td">
            <span>穴位保健</span>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td">
            <span>阴虚质</span>
          </div>
          <div class="td">
            <span>阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征</span>
          </div>
          <div class="td">
            <span>体形匀称、健壮</span>
          </div>
          <div class="td">
            <span>面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力</span>
          </div>
          <div class="td">
            <span>饮食有节、宜粗细粮合理搭配。</span>
          </div>
          <div class="td">
            <span>平素病较少，劳逸结合，坚持锻炼，穿戴求自然</span>
          </div>
          <div class="td">
            <span>春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品
            </span>
          </div>
          <div class="td">
            <span>穴位保健</span>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td">
            <span>痰湿质</span>
          </div>
          <div class="td">
            <span>阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征</span>
          </div>
          <div class="td">
            <span>体形匀称、健壮</span>
          </div>
          <div class="td">
            <span>面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力</span>
          </div>
          <div class="td">
            <span>饮食有节、宜粗细粮合理搭配。</span>
          </div>
          <div class="td">
            <span>平素病较少，劳逸结合，坚持锻炼，穿戴求自然</span>
          </div>
          <div class="td">
            <span>春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品
            </span>
          </div>
          <div class="td">
            <span>穴位保健</span>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td">
            <span>湿热质</span>
          </div>
          <div class="td">
            <span>阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征</span>
          </div>
          <div class="td">
            <span>体形匀称、健壮</span>
          </div>
          <div class="td">
            <span>面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力</span>
          </div>
          <div class="td">
            <span>饮食有节、宜粗细粮合理搭配。</span>
          </div>
          <div class="td">
            <span>平素病较少，劳逸结合，坚持锻炼，穿戴求自然</span>
          </div>
          <div class="td">
            <span>春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品
            </span>
          </div>
          <div class="td">
            <span>穴位保健</span>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td">
            <span>血瘀质</span>
          </div>
          <div class="td">
            <span>阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征</span>
          </div>
          <div class="td">
            <span>体形匀称、健壮</span>
          </div>
          <div class="td">
            <span>面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力</span>
          </div>
          <div class="td">
            <span>饮食有节、宜粗细粮合理搭配。</span>
          </div>
          <div class="td">
            <span>平素病较少，劳逸结合，坚持锻炼，穿戴求自然</span>
          </div>
          <div class="td">
            <span>春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品
            </span>
          </div>
          <div class="td">
            <span>穴位保健</span>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td">
            <span>气郁质</span>
          </div>
          <div class="td">
            <span>阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征</span>
          </div>
          <div class="td">
            <span>体形匀称、健壮</span>
          </div>
          <div class="td">
            <span>面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力</span>
          </div>
          <div class="td">
            <span>饮食有节、宜粗细粮合理搭配。</span>
          </div>
          <div class="td">
            <span>平素病较少，劳逸结合，坚持锻炼，穿戴求自然</span>
          </div>
          <div class="td">
            <span>春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品
            </span>
          </div>
          <div class="td">
            <span>穴位保健</span>
          </div>
        </div>
        <div class="tr tr1">
          <div class="td">
            <span>特禀质</span>
          </div>
          <div class="td">
            <span>阴阳气血调和，以体态适中、面色缸润、精力充沛等为主要特征</span>
          </div>
          <div class="td">
            <span>体形匀称、健壮</span>
          </div>
          <div class="td">
            <span>面色、肤色润泽，头发稠密有光泽，目光有神，鼻色明润，嗅觉通利，唇色红润，不易疲劳，精力充沛，耐受寒热，睡眠良好，胃纳佳，二便正常，舌色淡红，苔薄白，脉和缓有力</span>
          </div>
          <div class="td">
            <span>饮食有节、宜粗细粮合理搭配。</span>
          </div>
          <div class="td">
            <span>平素病较少，劳逸结合，坚持锻炼，穿戴求自然</span>
          </div>
          <div class="td">
            <span>春宜多食蔬菜，夏宜多食新鲜水果：长夏宜选用茯茶、山药等利湿健牌之品：秋宜选用寒湿偏性不明显的平性药食：冬直选用湿补之品
            </span>
          </div>
          <div class="td">
            <span>穴位保健</span>
          </div>
        </div>
        <div class="tr2">
          <div class="flex flex2 border">
            <span>诊断</span>
          </div>
          <div class="flex1">
            <div class="item border">
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="1">平和质</el-checkbox>
                <el-checkbox label="2">气虚质</el-checkbox>
                <el-checkbox label="3">阳虚质</el-checkbox>
                <el-checkbox label="4">阴虚质</el-checkbox>
                <el-checkbox label="5">痰湿质</el-checkbox>
                <el-checkbox label="6">湿热质</el-checkbox>
                <el-checkbox label="7">血瘀质</el-checkbox>
                <el-checkbox label="8">气郁质</el-checkbox>
                <el-checkbox label="9">特禀质</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="item border">
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="1">健康</el-checkbox>
                <el-checkbox label="2">亚健康</el-checkbox>
                <el-checkbox label="3">高危</el-checkbox>
                <el-checkbox label="4">慢性病</el-checkbox>
                <el-checkbox label="5">康复</el-checkbox>
                <el-checkbox label="6">定期随访</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
      <div class="son-bottom">
        <el-button>编辑</el-button>
        <el-button>保存</el-button>
        <el-button>审核</el-button>
        <el-button>上传</el-button>
        <el-button>打印</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: ''
        }
      }
    }
  },
  data () {
    return {
      checkList: [],
      info: {
        id: 0,
        createTime: '',
        updateTime: '',
        physiqueIdentifyAgeCode: '',
        groupClassifyCode: '',
        physiqueIdentify: '',
        guideDate: '',
        personInfoId: '',
        doctorName: '',
        doctorId: '',
        hyperSymptomsCode: '',
        diabetesSymptomsCode: '',
        content: '',
        cmgoperationId: '',
        isCancel: '',
        createOrgId: '',
        orgName: '',
        creatorId: '',
        creator: '',
        modifiedOrgId: '',
        modifiedOrgName: '',
        modifierId: '',
        modifier: '',
        fileCommittee: '',
        modifiedTime: '',
        isAppCreate: '',
        sfzh: '',
        tjid: '',
        jgbh: '',
        tjjgbh: '',
        tjjgmc: '',
        tjjgcwhid: '',
        tjjgcwhmc: '',
        jkdnid: '',
        zytzzs: '',
        zyzd: '',
        jktjid: ''
      }
    }
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591842
      try {
        const obj = {
          grjbxxid: this.params.grjbxxid || '',
          jktjid: this.params.tmcode || ''
        }
        const { data: res } = await this.$http.post('/cmgoperationtjs/finds', obj)
        console.log('中医药健康指导--查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data[0]
        const arr = ['tzlxqxzzd', 'tzlxyxzzd', 'tzlxyinxzzd', 'tzlxtszzd', 'tzlxsrzzd', 'tzlxxyzzd', 'tzlxqyzzd', 'tzlxtbzzd', 'tzlxphzzd']
        for (var i = 0; i < arr.length; i++) {
          this.updateData(arr[i])
        }
      } catch (error) {
        console.log('中医药健康指导--查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .tab-title {
    width: 100%;
    height: 54px;
    line-height: 54px;
    text-align: center;
    color: #475265;
    font-size: 22px;
  }

  .tab-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    .item {
      min-width: 206px;
      color: #475265;
      font-size: 22px;

      .white {
        margin-left: 20px;
        color: #fff;
      }
    }
  }

  .tab-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 60px);
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1px solid #475265;
      border-left: 1px solid #475265;
      box-sizing: border-box;

      .tr {
        display: flex;
        width: 100%;
        height: 50px;

        .td {
          overflow-y: scroll;
          flex: 1;
          height: 100%;
          border-bottom: 1px solid #475265;
          border-right: 1px solid #475265;
          box-sizing: border-box;
          color: #475265;
          font-size: 14px;

          &::-webkit-scrollbar {
            width: 0;
          }
        }

        .td1 {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-weight: 600;
        }
      }

      .tr1 {
        height: 240px;

        .td {
          padding: 10px;
          box-sizing: border-box;
        }
      }

      .tr2 {
        display: flex;
        width: 100%;
        height: 80px;

        .flex {
          width: 12.5%;
          height: 80px;
          color: #475265;
          font-size: 14px;
        }

        .flex1 {
          display: flex;
          flex-direction: column;
          width: 87.5%;
          height: 80px;

          .item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 50px;
            padding-left: 16px;
            box-sizing: border-box;
          }
        }

        .flex2 {
          padding: 10px;
          box-sizing: border-box;
        }

        .border {
          border-bottom: 1px solid #475265;
          border-right: 1px solid #475265;
          box-sizing: border-box;
        }
      }
    }

    .son-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100px;
      border-bottom: 1px solid #475265;

      .el-button {
        min-width: 120px;
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 6px;
        padding-bottom: 6px;
        background-color: #3782F2;
        border: none;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
    }

  }

}
</style>
