<template>
  <div class="tab">
    <div class="tab-scroll">
      <div class="tab-title">
        <span>超声医学影像报告单</span>
      </div>
      <div class="info">
        <div class="line">
          <div class="text">
            <span>检查部位:腹部</span>
          </div>
          <div class="text">
            <span>超声号:{{ info.tmcode || '' }}</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>姓名：-</span>
          </div>
          <div class="item">
            <span>性别:-</span>
          </div>
          <div class="item">
            <span>年龄:-</span>
          </div>
          <div class="item">
            <span>初诊：-</span>
          </div>
          <div class="item">
            <span>科别：-</span>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <span>检查号:-</span>
          </div>
          <div class="item">
            <span>病历号：-</span>
          </div>
          <div class="item">
            <span>住院号：-</span>
          </div>
          <div class="item">
            <span>病区号：-</span>
          </div>
          <div class="item">
            <span>床位号：-</span>
          </div>
        </div>
        <div class="line1">
          <div class="text">
            <span>超声所见</span>
          </div>
          <div class="img">
            <img class="image" :src="info.tp1url" v-if="info.tp1url" />
            <img class="image" :src="info.tp2url" v-if="info.tp2url" />
          </div>
          <div class="text"></div>
        </div>
        <div class="line2">
          <div class="text">
            <span>超声所见：</span>
          </div>
          <div class="textarea">{{ info.jktjcsts }}</div>
        </div>
        <div class="line3">
          <div class="text">
            <span>检查时间：-</span>
          </div>
          <div class="text">
            <span>申请医师：-</span>
          </div>
          <div class="text">
            <span>诊断医师：-</span>
          </div>
        </div>
        <div class="tip">
          <span>只作临床参考，不作证明材料</span>
        </div>
      </div>
      <div class="son-bottom son-bottom1">
        <el-button @click="noClick">编辑</el-button>
        <el-button @click="noClick">查看</el-button>
      </div>
      <div class="son-bottom">
        <el-button @click="noClick">编辑</el-button>
        <el-button @click="noClick">保存</el-button>
        <el-button @click="noClick">审核</el-button>
        <el-button @click="noClick">上传</el-button>
        <el-button @click="noClick">打印</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          tmcode: '',
          sfzh: '',
          grjbxxid: '',
          jktjid: ''
        }
      }
    }
  },
  data () {
    return {
      checkList: [],
      info: {
        jktjcsms: '',
        jktjcsts: '',
        tmcode: '',
        flag: '',
        tp1url: '',
        tp2url: ''
      }
    }
  },
  created () {
    console.log('彩色超声检测报告参数：', this.params)
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591726
      try {
        const obj = {
          tmcode: this.params.tmcode || ''
        }
        const { data: res } = await this.$http.post('/ykdcscsjcbabs/findjg', obj)
        console.log('B超报告--查询', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        if (res.data === null) return
        this.info = res.data
      } catch (error) {
        console.log('B超报告--查询请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    noClick () {
      this.$message.error('未开放')
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;

  .tab-scroll {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tab-title {
      width: 100%;
      height: 54px;
      line-height: 54px;
      text-align: center;
      color: #475265;
      font-size: 22px;
    }

    .info {
      display: flex;
      flex-direction: column;
      width: 100%;

      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        padding: 0 26px;
        border-bottom: 1px solid #B1B1B1;
        box-sizing: border-box;

        .text {
          color: #2A374D;
          font-size: 16px;
        }

        .item {
          flex: 1;
          color: #2A374D;
          font-size: 16px;
        }
      }

      .line1 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 240px;
        padding: 0 26px;
        border-bottom: 1px solid #B1B1B1;
        box-sizing: border-box;

        .text {
          width: 120px;
          height: 40px;
          line-height: 40px;
          color: #2A374D;
          font-size: 16px;
        }

        .img {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 203px;
          height: 100%;

          .image {
            width: 203px;
            height: 203px;
            margin: 0 10px;
            background-color: #2A374D;
          }
        }
      }

      .line2 {
        display: flex;
        align-items: center;
        width: 100%;
        height: 66px;
        padding: 0 26px;
        border-bottom: 1px solid #B1B1B1;
        box-sizing: border-box;

        .text {
          width: 120px;
          height: 40px;
          line-height: 40px;
          color: #2A374D;
          font-size: 16px;
        }

        .textarea {
          width: calc(100% - 160px);
          font-size: 16px;
        }
      }

      .line3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        padding: 0 26px;
        box-sizing: border-box;

        .text {
          color: #2A374D;
          font-size: 16px;
        }
      }

      .tip {
        width: 100%;
        text-align: center;
        color: #999999;
        font-size: 13px;
      }
    }

    .son-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
      border-bottom: 1px solid #475265;

      .el-button {
        min-width: 120px;
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 6px;
        padding-bottom: 6px;
        background-color: #3782F2;
        border: none;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .son-bottom1 {
      height: 60px;
      justify-content: flex-end;
    }

  }

}
</style>
