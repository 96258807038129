<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-02-18 15:50:29
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-03-04 10:13:16
 * @FilePath: \visions\src\views\zhyl\smartPublicGuard\healthTestRun\healthTest\report\tab12\tab12.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="health">
    <div class="health-box">
      <div class="code">
        <span>编号</span>
      </div>
      <div class="title">
        <span>居民健康档案</span>
      </div>
      <div class="info">
        <div class="line">
          <div class="key key1">
            <span>姓名</span>
          </div>
          <div class="key">
            <span>：</span>
          </div>
          <el-input v-model="name"></el-input>
        </div>
        <div class="line">
          <div class="key key1">
            <span>现住址</span>
          </div>
          <div class="key">
            <span>：</span>
          </div>
          <el-input v-model="xzz"></el-input>
        </div>
        <div class="line">
          <div class="key">
            <span>户籍地址：</span>
          </div>
          <el-input v-model="hjdz"></el-input>
        </div>
        <div class="line">
          <div class="key">
            <span>联系电话：</span>
          </div>
          <el-input v-model="lxdh"></el-input>
        </div>
        <div class="line">
          <div class="key">
            <span>乡镇（街道）名称：</span>
          </div>
          <el-input v-model="xzmc"></el-input>
        </div>
        <div class="line">
          <div class="key">
            <span>村（居）委会名称：</span>
          </div>
          <el-input v-model="cmc"></el-input>
        </div>
      </div>

      <div class="info info1">
        <div class="line">
          <div class="key">
            <span>建档单位：</span>
          </div>
          <el-input v-model="jddw"></el-input>
        </div>
        <div class="line">
          <div class="key key1">
            <span>建档人</span>
          </div>
          <div class="key">
            <span>：</span>
          </div>
          <el-input v-model="jdr"></el-input>
        </div>
        <div class="line">
          <div class="key">
            <span>责任医生：</span>
          </div>
          <el-input v-model="zrys"></el-input>
        </div>
        <div class="line">
          <div class="key">
            <span>建档日期：</span>
          </div>
          <el-input v-model="jdrqn"></el-input>
          <div class="key">
            <span>年</span>
          </div>
          <el-input v-model="jdrqy"></el-input>
          <div class="key">
            <span>月</span>
          </div>
          <el-input v-model="jdrqr"></el-input>
          <div class="key">
            <span>日</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNew } from '../../../../../../../utils/print/tsc.js'
export default {
  data () {
    return {
      isConnect: false,
      connecting: false,
      currentTime: 2,
      // 打印计数
      printCount: 1,
      // 当前打印计数
      currentCount: 1,
      printing: false,
      timer: null,
      name: '',
      xzz: '',
      hjdz: '',
      lxdh: '',
      xzmc: '',
      cmc: '',
      jddw: '',
      jdr: '',
      zrys: '',
      jdrqn: '',
      jdrqy: '',
      jdrqr: ''
    }
  },
  components: {},
  mounted () {
    this.connect()
  },
  beforeDestroy () {
    this.disconnect()
  },
  methods: {
    async connect () {
      if ('bluetooth' in navigator) {
        try {
          this.device = await navigator.bluetooth.requestDevice({
            filters: [{ services: ['e7810a71-73ae-499d-8c15-faa9aef0c3f2'] }]
          })
          this.connecting = true
          this.device.gatt.connect().then(async server => {
            console.log(server)
            this.connecting = false
            const service = await server.getPrimaryService('e7810a71-73ae-499d-8c15-faa9aef0c3f2')
            this.characteristic = await service.getCharacteristic('bef8d6c9-9c21-4c9e-b632-bd58c1009f9f')
            this.$message({ message: '连接成功', type: 'success' })
            this.isConnect = true
            this.device.addEventListener('gattserverdisconnected', this.onDisconnected)
          }).catch(err => {
            console.log('连接失败', err)
            this.connecting = false
            this.isConnect = false
            this.$message({
              message: '连接失败',
              type: 'warning'
            })
          })
        } catch (error) {
          console.error('连接蓝牙打印机失败', error)
          this.isConnect = false
          this.$message({
            message: '连接失败',
            type: 'warning'
          })
        }
      } else {
        console.log('浏览器不支持Web Bluetooth API')
        this.isConnect = false
        this.$message({
          message: '浏览器不支持Web Bluetooth API',
          type: 'warning'
        })
      }
    },
    // 监听蓝牙断开事件
    onDisconnected (event) {
      console.log(event)
      const device = event.target
      this.isConnect = false
      this.$message({
        message: '设备 ' + (device.name || '') + ' 断开连接',
        type: 'warning'
      })
      // 取消监听蓝牙设备的连接状态
      device.removeEventListener('gattserverdisconnected', this.onDisconnected)
    },
    print () {
      if (!this.isConnect) {
        this.connect()
        return this.$message({
          message: '设备未连接，无法打印',
          type: 'warning'
        })
      }
      this.printing = true
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.printing = false
        console.log('强制取消打印')
      }, this.printCount * 6000)
      const command = createNew()
      command.setCls()
      setTimeout(() => {
        // command.setSize('40', '120')
        // command.setGap(1)
        // command.setCls()
        // command.setText(50, 38, 'TSS24.BF2', 0, 1, 1, '健康体检条码')
        // command.setBarCode(10, 72, '128', 60, 0, 0, 2, 2, '3023645644545')
        // command.setText(50, 140, 'TSS24.BF2', 0, 1, 1, '李四 女 23 ')
        // command.setText(50, 170, 'TSS24.BF2', 0, 1, 1, '3023645644545')
        command.setBox(-70, 50, 400, 970, 1)
        command.setText(190, 60, 'TSS24.BF2', 0, 1, 1, '编号:33384528899')
        command.setText(30, 120, 'TSS24.BF2', 0, 2, 2, '居民健康档案')

        command.setText(-40, 250, 'TSS24.BF2', 0, 1, 1, '姓名：')
        command.setBar(40, 280, 340, 1)
        command.setText(-40, 300, 'TSS24.BF2', 0, 1, 1, '现住址：')
        command.setBar(60, 330, 320, 1)
        command.setText(-40, 350, 'TSS24.BF2', 0, 1, 1, '户籍地址：')
        command.setBar(80, 380, 300, 1)
        command.setText(-40, 400, 'TSS24.BF2', 0, 1, 1, '联系电话：')
        command.setBar(80, 430, 300, 1)
        command.setText(-40, 450, 'TSS24.BF2', 0, 1, 1, '乡镇（街道）名称：')
        command.setBar(170, 480, 210, 1)
        command.setText(-40, 500, 'TSS24.BF2', 0, 1, 1, '村（居）委会名称：')
        command.setBar(170, 530, 210, 1)

        command.setText(-40, 600, 'TSS24.BF2', 0, 1, 1, '建档单位：')
        command.setBar(80, 630, 300, 1)
        command.setText(-40, 650, 'TSS24.BF2', 0, 1, 1, '建档人：')
        command.setBar(60, 680, 320, 1)
        command.setText(-40, 700, 'TSS24.BF2', 0, 1, 1, '责任医生：')
        command.setBar(80, 730, 300, 1)
        command.setText(-40, 750, 'TSS24.BF2', 0, 1, 1, '建档日期：')
        command.setBar(80, 780, 300, 1)

        // command.setQrcode(70, 640, 'L', '10', 'M', '18464156456')
        command.setPagePrint()
        this.Send(command.getData())
      }, 1500)
    },
    // 发送指令
    async Send (buff) {
      try {
        let currentTime = this.currentTime
        const loopTime = parseInt(buff.length / 20) + 1
        const lastData = parseInt(buff.length % 20)
        let currentCount = this.currentCount
        let buf = ''
        let dataView = ''
        if (currentTime < loopTime) {
          buf = new ArrayBuffer(20)
          dataView = new DataView(buf)
          for (var i = 0; i < 20; ++i) {
            dataView.setUint8(i, buff[(currentTime - 1) * 20 + i])
          }
        } else {
          buf = new ArrayBuffer(lastData)
          dataView = new DataView(buf)
          for (var j = 0; j < lastData; ++j) {
            dataView.setUint8(j, buff[(currentTime - 1) * 20 + j])
          }
        }
        const date = new Uint8Array(buf)
        await this.characteristic.writeValue(date)
        currentTime++
        if (currentTime <= loopTime) {
          this.currentTime = currentTime
          this.Send(buff)
        } else {
          if (currentCount === this.printCount) {
            this.currentTime = 1
            this.currentCount = 1
            setTimeout(() => {
              this.printing = false
              if (this.timer) {
                clearTimeout(this.timer)
              }
              this.$message({ message: '打印完毕', type: 'success' })
            }, 1000)
          } else {
            currentCount++
            this.currentCount = currentCount
            this.currentTime = 1
            this.Send(buff)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 断开连接
    disconnect () {
      if (this.device && this.device.gatt) {
        this.device.gatt.disconnect()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.health {
  width: 100%;
  height: 100%;

  .health-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 55%;
    height: 100%;
    margin: 0 auto;
    border: 1px solid #000;
    box-sizing: border-box;

    .code {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 300px;
      color: #000;
      font-size: 16px;
    }

    .title {
      letter-spacing: 2px;
      color: #000;
      font-size: 26px;
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .line {
        display: flex;
        align-items: center;
        width: 50%;

        .key {
          white-space: nowrap;
          color: #000;
          font-size: 18px;
        }

        .key1 {
          width: 98px;
          text-align-last: justify;
          text-align: right;
        }

        /deep/.el-input__inner {
          border-radius: 0;
          border: none;
          border-bottom: 1px solid #000;
          font-size: 17px;
        }
      }
    }

    .info1 {
      width: 85%;
    }
  }
}
</style>
