<template>
  <div class="schedule">
    <div class="schedule-box">
      <div class="schedule-head">
        <span>查看进度</span>
        <span class="el-icon-close close" @click="close"></span>
      </div>
      <div class="schedule-srcoll">
        <table class="table" border="1">
          <tr>
            <td class="td">姓名</td>
            <td class="td" colspan="2">张志民</td>
          </tr>
          <tr>
            <td class="td">条码号</td>
            <td class="td" colspan="2">603224416</td>
          </tr>
          <tr>
            <td class="td">B超</td>
            <td class="td" colspan="2">
              <el-tag type="danger">未同步</el-tag>
            </td>
          </tr>
          <tr>
            <td class="td">身高体重</td>
            <td class="td" colspan="2">
              <el-tag type="danger">未同步</el-tag>
            </td>
          </tr>
          <tr>
            <td class="td">一体机</td>
            <td class="td" colspan="2">
              <el-tag>已同步</el-tag>
            </td>
          </tr>
          <tr>
            <td class="td">血球</td>
            <td class="td" colspan="2">
              <el-tag>已同步</el-tag>
            </td>
          </tr>
          <tr>
            <td class="td">生化</td>
            <td class="td" colspan="2">
              <el-tag>已同步</el-tag>
            </td>
          </tr>
          <tr>
            <td class="td">心电图</td>
            <td class="td" colspan="2">
              <el-tag type="danger">未同步</el-tag>
            </td>
          </tr>
        </table>
      </div>
      <div class="schedule-bottom">
        <el-button type="primary" @click="noClick">
          <span class="el-icon-upload"></span>
          <span>一键同步</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    noClick () {
      this.$message.error('未开放')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .schedule {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);

    .schedule-box {
      width: 30%;
      height: 90%;
      padding: 0 20px;
      background-color: #fff;
      box-sizing: border-box;

      .schedule-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        font-size: 18px;

        .close {
          font-size: 22px;
          cursor: pointer;
        }
      }

      .schedule-srcoll {
        width: 100%;
        height: calc(100% - 120px);

        .table {
          width: 100%;
          border-collapse: collapse;
          border: 1px solid #c8e2d3;

          .td {
            padding: 20px 0;
            text-align: center;
            color: #000;
            font-size: 16px;
          }
        }
      }

      .schedule-bottom {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: 100%;
        height: 60px;
      }
    }
  }
</style>
