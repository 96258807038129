<template>
  <div class="write">
    <div class="title">
      <span>订单详情</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="content-title">
          <span>订单信息</span>
        </div>
        <div class="list">
          <div class="item">
            <span class="span">订单单号：LO2131232212312313</span>
          </div>
          <div class="item">
            <span class="span">订单金额：￥90.0</span>
          </div>
          <div class="item">
            <span class="span">订单状态：待发货</span>
          </div>
          <div class="item">
            <span class="span">支付方式：微信支付</span>
          </div>
          <div class="item">
            <span class="span">下单时间：2020/10/10 12:00:29</span>
          </div>
          <div class="item">
            <span class="span">支付时间：2020/10/10 12:00:29</span>
          </div>
          <div class="item">
            <span class="span">备注：我是一段关于订单的特别标注哈，我一般不会超过100个字那么长</span>
          </div>
        </div>
        <div class="content-title">
          <span>用户信息</span>
        </div>
        <div class="list">
          <div class="item">
            <span class="span">订单单号：LO2131232212312313</span>
          </div>
          <div class="item">
            <span class="span">患者姓名：会飞的松鼠</span>
          </div>
          <div class="item">
            <span class="span">患者年龄：18</span>
          </div>
          <div class="item">
            <span class="span">身份证：330330330302003020</span>
          </div>
          <div class="item">
            <span class="span">性别：男</span>
          </div>
          <div class="item">
            <span class="span">联系方式：18868803516</span>
          </div>
          <div class="item">
            <span class="span">就诊情况：已就诊</span>
          </div>
        </div>
        <div class="content-title">
          <span>咨询问题</span>
        </div>
        <div class="white">
          <span>手经常会发疼，胸口也经常发闷，难受，基本每周就会出现好几次这种情况，感觉自己快死了</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      value: '',
      radio: '1',
      imageUrl: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 200px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 700px;
    margin-top: 10px;
    padding: 40px 65px;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 30px;
      right: 24px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      height: 46px;
      line-height: 46px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      width: 100%;
      height: 100%;

      .list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .white {
      width: 80%;
      margin-top: 20px;
      padding: 19px;
      background-color: #fff;
      color: #333333;
      font-size: 16px;
    }

    .item {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-right: 40px;
      color: #01EEFD;
      font-size: 16px;

      .span {
        margin-right: 10px;
        white-space: nowrap;
      }
    }
  }
}
</style>
