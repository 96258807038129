<template>
  <div class="message">
    <div class="message-box">
      <div class="message-head">
        <div class="message-title">
          <span></span>
        </div>
        <div class="message-title">
          <span>{{ title }}</span>
        </div>
        <div class="message-close" @click="close">
          <span class="el-icon-close"></span>
        </div>
      </div>
      <div class="message-table">
        <el-table :data="tableData" height="100%" border>
          <el-table-column prop="id" label="序号" :width="flexWidth('id', tableData, '序号')">
          </el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="sfzh" label="身份证号">
          </el-table-column>
          <el-table-column label="体检编号">
          </el-table-column>
          <el-table-column label="体检日期">
          </el-table-column>
          <el-table-column label="结果">
          </el-table-column>
          <el-table-column label="状态">
          </el-table-column>
          <el-table-column label="备注">
          </el-table-column>
        </el-table>
      </div>
      <div class="message-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper"
          :total="query1.total" hide-on-single-page>
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    messageIndex: {
      type: String
    }
  },
  computed: {
    title () {
      return this.titles[this.messageIndex]
    }
  },
  data () {
    return {
      titles: Object.freeze(['任务数', '已打码人数', '身高', '体重', '体温', '血压', '中医问诊', '采血', 'B超检查', '心电检查', '血常规', '血生化', '尿常规', '报告人数', '报告人数']),
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      value1: '',
      radio: '',
      tableData: [{}],
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 100
      }
    }
  },
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.message {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);

  .message-box {
    overflow: hidden;
    width: 85%;
    height: 80%;
    background-color: #fff;
    border-radius: 6px;
  }

  .message-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    padding: 0 29px;
    background-color: #006EFF;
    box-sizing: border-box;

    .message-title {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }

    .message-close {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .message-table {
    width: calc(100% - 48px);
    height: calc(100% - 175px);
    margin: 40px auto 0;

    .el-table {
      border: 2px solid #006EFF;
    }

    /deep/.el-table th.el-table__cell {
      background-color: #006EFF;
      color: #fff;
    }

    /deep/.el-table .cell {
      text-align: center;
      font-size: 16px;

    }

    /deep/.el-table__fixed-right-patch {
      background-color: #006EFF;
    }

    .table-flex {
      cursor: pointer;
    }

    .red {
      color: #FF0000;
    }

    .green {
      color: #00FF1A;
    }
  }

  .message-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90px;

    /deep/.el-pager .number {
      background-color: #ADADAD;
      color: #fff;
    }

    /deep/.btn-quicknext, /deep/.btn-prev, /deep/.btn-next {
      background-color: #ADADAD;
      color: #fff;
    }
  }
}
</style>
