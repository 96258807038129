<template>
  <div class="tab">
    <div class="tab-label">
      <el-button type="primary" plain @click="openTest">体检人数：-</el-button>
      <el-button type="success" plain @click="openDetection">检测人数：-</el-button>
      <el-button type="danger" plain @click="openTestDetection">已体检未检测人数：-</el-button>
    </div>
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="0">生化</el-menu-item>
      <el-menu-item index="1">血常规</el-menu-item>
      <el-menu-item index="2">尿常规</el-menu-item>
      <el-menu-item index="3">B超</el-menu-item>
      <el-menu-item index="4">心电图</el-menu-item>
    </el-menu>
    <div class="tab-son">
      <Son1 v-if="activeIndex === '0'"></Son1>
      <Son2 v-if="activeIndex === '1'"></Son2>
      <Son3 v-if="activeIndex === '2'"></Son3>
      <Son4 v-if="activeIndex === '3'"></Son4>
      <Son5 v-if="activeIndex === '4'"></Son5>
    </div>
    <!-- 体检人数 -->
    <Test v-if="showTest" @close="closeTest"></Test>
    <!-- 检测人数 -->
    <Detection v-if="showDetection" @close="closeDetection"></Detection>
    <!-- 已体检未检测人数 -->
    <TestDetection v-if="showTestDetection" @close="closeTestDetection"></TestDetection>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Test from './test.vue'
import Son1 from './son1/son1.vue'
import Son2 from './son2/son2.vue'
import Son3 from './son3/son3.vue'
import Son4 from './son4/son4.vue'
import Son5 from './son5/son5.vue'
const Test = () => import('./test.vue')
const Detection = () => import('./detection.vue')
const TestDetection = () => import('./testDetection.vue')
export default {
  components: {
    Test,
    Detection,
    TestDetection,
    Son1,
    Son2,
    Son3,
    Son4,
    Son5
  },
  data () {
    return {
      activeIndex: '0',
      showTest: false,
      showDetection: false,
      showTestDetection: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  methods: {
    handleSelect (key) {
      this.activeIndex = key
    },
    openTest () {
      this.showTest = true
    },
    closeTest () {
      this.showTest = false
    },
    openDetection () {
      this.showDetection = true
    },
    closeDetection () {
      this.showDetection = false
    },
    openTestDetection () {
      this.showTestDetection = true
    },
    closeTestDetection () {
      this.showTestDetection = false
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  box-sizing: border-box;

  .tab-label {
    display: flex;
    align-items: center;
    height: 60px;
  }

  .tab-son {
    width: 100%;
    height: calc(100% - 130px);
  }
}
</style>
