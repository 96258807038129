<template>
  <div class="referral">
    <div class="referral-title">
      <span>双向转诊单</span>
    </div>
    <div class="referral-scroll">
      <div class="referral-box">
        <div class="referral-box-title">
          <span>存根</span>
        </div>
        <div class="referral-box-line">
          <div class="referral-box-item">
            <div class="text">
              <span>患者姓名</span>
            </div>
            <el-input class="width" v-model="info.name"></el-input>
          </div>
          <div class="referral-box-item">
            <div class="text">
              <span>性别</span>
            </div>
            <el-input class="width" v-model="info.sex"></el-input>
          </div>
          <div class="referral-box-item">
            <div class="text">
              <span>年龄</span>
            </div>
            <el-input class="width" v-model="info.age"></el-input>
          </div>
          <div class="referral-box-item">
            <div class="text">
              <span>身份证号</span>
            </div>
            <el-input class="width1" v-model="info.sfzh"></el-input>
          </div>
          <div class="referral-box-item">
            <div class="text">
              <span>档案编号</span>
            </div>
            <el-input class="width1" v-model="info.dabh"></el-input>
          </div>
        </div>
        <div class="referral-box-line">
          <div class="referral-box-item">
            <div class="text">
              <span>家庭住址</span>
            </div>
            <el-input class="width2" v-model="info.jtzz"></el-input>
          </div>
          <div class="referral-box-item">
            <div class="text">
              <span>联系电话</span>
            </div>
            <el-input class="width1" v-model="info.lxdh"></el-input>
          </div>
        </div>
        <div class="referral-box-line">
          <div class="referral-box-item">
            <div class="text">
              <span>于</span>
            </div>
            <el-input class="width" v-model="info.nian"></el-input>
            <div class="text">
              <span>年</span>
            </div>
            <el-input class="width" v-model="info.yue"></el-input>
            <div class="text">
              <span>月</span>
            </div>
            <el-input class="width" v-model="info.ri"></el-input>
            <div class="text">
              <span>日因病情需要，需要转入</span>
            </div>
          </div>
        </div>
        <div class="referral-box-line">
          <div class="referral-box-item">
            <div class="text">
              <span>单位</span>
            </div>
            <el-select v-model="info.dw" placeholder="" class="select select-width">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="referral-box-item">
            <div class="text">
              <span>科室</span>
            </div>
            <el-select v-model="info.ks" placeholder="" class="select">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="referral-box-item">
            <div class="text">
              <span>医生</span>
            </div>
            <el-select v-model="info.ys" placeholder="" class="select">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="referral-box-line referral-box-line1">
          <div class="referral-box-item">
            <div class="text">
              <span>转诊医生（签字）：</span>
            </div>
            <el-input v-model="info.zzys" class="width3"></el-input>
          </div>
        </div>
        <div class="referral-box-line referral-box-line1">
          <div class="referral-box-item">
            <el-input v-model="info.nian1" class="width"></el-input>
            <div class="text">
              <span>年</span>
            </div>
            <el-input v-model="info.yue1" class="width"></el-input>
            <div class="text">
              <span>月</span>
            </div>
            <el-input v-model="info.ri1" class="width"></el-input>
            <div class="text">
              <span>日</span>
            </div>
          </div>
        </div>
      </div>
      <div class="referral-box">
        <div class="referral-box-title">
          <span>双向转诊（转出）单</span>
        </div>
        <div class="referral-box-line">
          <div class="referral-box-item">
            <div class="text">
              <span>（机构名称）:</span>
            </div>
            <el-select v-model="info.jgmc" placeholder="" class="select">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="referral-box-line">
          <div class="referral-box-item">
            <div class="text">
              <span>现有患者</span>
            </div>
            <el-input v-model="info.xyhz" class="width"></el-input>
          </div>
          <div class="referral-box-item">
            <div class="text">
              <span>性别</span>
            </div>
            <el-input v-model="info.sex1" class="width"></el-input>
          </div>
          <div class="referral-box-item">
            <div class="text">
              <span>年龄</span>
            </div>
            <el-input v-model="info.age1" class="width"></el-input>
          </div>
          <div class="referral-box-item">
            <div class="text">
              <span>身份证号</span>
            </div>
            <el-input v-model="info.sfzh1" class="width1"></el-input>
            <div class="text">
              <span>因病情需要，需要转入贵单位，请予以接诊</span>
            </div>
          </div>
        </div>
        <div class="referral-box-line">
          <div class="referral-box-item">
            <div class="text">
              <span>初步印象：</span>
            </div>
            <el-input v-model="info.cbyx" class="width4"></el-input>
          </div>
        </div>
        <div class="referral-box-line">
          <div class="referral-box-item">
            <div class="text">
              <span>主要现病史（转出原因）：</span>
            </div>
            <el-input v-model="info.zcyy" class="width4"></el-input>
          </div>
        </div>
        <div class="referral-box-line">
          <div class="referral-box-item">
            <div class="text">
              <span>主要既往史：</span>
            </div>
            <el-input v-model="info.jws" class="width4"></el-input>
          </div>
        </div>
        <div class="referral-box-line">
          <div class="referral-box-item">
            <div class="text">
              <span>治疗经过：</span>
            </div>
            <el-input v-model="info.zljg" class="width4"></el-input>
          </div>
        </div>

        <div class="referral-box-line referral-box-line1">
          <div class="referral-box-item">
            <div class="text">
              <span>联系电话：</span>
            </div>
            <el-input v-model="info.lxdh1" class="width1"></el-input>
          </div>
          <div class="referral-box-item">
            <div class="text">
              <span>转诊医生（签字）：</span>
            </div>
            <el-input v-model="info.zzys1" class="width3"></el-input>
          </div>
        </div>
        <div class="referral-box-line referral-box-line1">
          <div class="referral-box-item">
            <el-input v-model="info.nian2" class="width"></el-input>
            <div class="text">
              <span>年</span>
            </div>
            <el-input v-model="info.yue2" class="width"></el-input>
            <div class="text">
              <span>月</span>
            </div>
            <el-input v-model="info.ri2" class="width"></el-input>
            <div class="text">
              <span>日</span>
            </div>
          </div>
          <div class="referral-box-item referral-box-item1">
            <div class="text">
              <span>（机构名称）:</span>
            </div>
            <el-select v-model="info.jgmc1" placeholder="" class="select">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="referral-btbs">
      <el-button size="small" @click="close">返回</el-button>
      <el-button type="primary" size="small" @click="close">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [],
      value: '',
      info: {
        name: '',
        sex: '',
        age: '',
        sfzh: '',
        dabh: '',
        jtzz: '',
        lxdh: '',
        nian: '',
        yue: '',
        ri: '',
        dw: '',
        ks: '',
        ys: '',
        zzys: '',
        nian1: '',
        yue1: '',
        ri1: '',
        jgmc: '',
        xyhz: '',
        sex1: '',
        age1: '',
        sfzh1: '',
        cbyx: '',
        zcyy: '',
        jws: '',
        zljg: '',
        lxdh1: '',
        zzys1: '',
        nian2: '',
        yue2: '',
        ri2: '',
        jgmc1: ''
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 10px 40px;
  background-color: #fff;
  box-sizing: border-box;

  .referral-title {
    width: 100%;
    height: 70px;
    line-height: 70px;
    margin-bottom: 10px;
    text-align: center;
    color: #000;
    font-size: 20px;
  }

  .referral-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 130px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .referral-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: 2px dashed #000;
      padding: 20px 0;

      .referral-box-title {
        height: 40px;
        color: #000;
        font-size: 20px;
      }

      .referral-box-line {
        display: flex;
        align-items: center;
        width: 70%;
        margin-top: 20px;

        .referral-box-item {
          display: flex;
          align-items: center;
          margin-right: 10px;

          .text {
            white-space: nowrap;
            color: #000;
            font-size: 20px;
          }

          .width {
            width: 85px;
          }

          .width1 {
            width: 180px;
          }

          .width2 {
            width: 230px;
          }

          .width3 {
            width: 130px;
          }

          .width4 {
            width: 700px;
          }

          /deep/.el-input__inner {
            height: 30px;
            line-height: 30px;
            background-color: transparent;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #000;
            color: #000;
          }

          .el-select {
            margin-left: 10px;

            /deep/.el-icon-arrow-up:before {
              color: #000;
            }

            /deep/.el-input__icon {
              line-height: normal;
            }
          }

          .select {
            /deep/.el-input__inner {
              border: 1px solid #000;
            }
          }

          .select-width {
            width: 320px;
          }
        }

        .referral-box-item1 {
          margin-right: 100px;
        }
      }

      .referral-box-line1 {
        flex-direction: row-reverse;
        padding-right: 200px;
        box-sizing: border-box;
      }
    }
  }

  .referral-btbs {
    display: flex;
    align-items: center;
    height: 50px;
  }
}
</style>
