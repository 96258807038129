<template>
  <div class="added">
    <div class="added-box">
      <div class="added-head">
        <span>新增</span>
        <span class="el-icon-close close" @click="close"></span>
      </div>
      <div class="added-scroll">
        <div class="added-content">
          <div class="added-item">
            <div class="added-key">
              <span>身份证图片</span>
            </div>
            <div class="added-value"></div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>图片</span>
            </div>
            <div class="added-value">
              <el-button type="primary" @click="noClick">
                <span class="el-icon-edit"></span>
                <span>拍照</span>
              </el-button>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>人群分类</span>
            </div>
            <div class="added-value">
              <el-input placeholder="请输入人群分类" v-model="params.rqfl">
              </el-input>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span class="red">*</span>
              <span>姓名</span>
            </div>
            <div class="added-value">
              <el-input placeholder="请输入姓名" v-model="params.name">
              </el-input>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span class="red">*</span>
              <span>身份证号</span>
            </div>
            <div class="added-value">
              <el-input placeholder="请输入身份证号" v-model="params.sfzh">
              </el-input>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>年龄</span>
            </div>
            <div class="added-value">
              <el-input placeholder="请输入年龄" v-model="params.age">
              </el-input>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>性别</span>
            </div>
            <div class="added-value">
              <el-radio-group v-model="params.sex">
                <el-radio label="1">男</el-radio>
                <el-radio label="2">女</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>户籍地址</span>
            </div>
            <div class="added-value">
              <el-input placeholder="请输入户籍地址" v-model="params.hjdz">
              </el-input>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>现住址</span>
            </div>
            <div class="added-value">
              <el-input placeholder="请输入现住址" v-model="params.xzz">
              </el-input>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>乡镇/社区</span>
            </div>
            <div class="added-value">
              <el-input placeholder="" v-model="params.xzsq">
              </el-input>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>乡镇/社区编码</span>
            </div>
            <div class="added-value">
              <el-input placeholder="" v-model="params.xzsqbm">
              </el-input>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>村/街道</span>
            </div>
            <div class="added-value">
              <el-select class="select" v-model="params.cjd" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>所属机构</span>
            </div>
            <div class="added-value">
              <el-select class="select" v-model="params.ssjg" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>责任机构</span>
            </div>
            <div class="added-value">
              <el-select class="select1" v-model="params.zrjg" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-input class="input" placeholder="" v-model="params.zrjgid">
              </el-input>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>录入人</span>
            </div>
            <div class="added-value">
              <el-select class="select1" v-model="params.lrr" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-input class="input" placeholder="" v-model="params.lrrid">
              </el-input>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>体检类型</span>
            </div>
            <div class="added-value">
              <el-select class="select" v-model="params.tjlx" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>档案编码</span>
            </div>
            <div class="added-value">
              <el-input placeholder="" v-model="params.dabm">
              </el-input>
            </div>
          </div>
          <div class="added-item">
            <div class="added-key">
              <span>建档日期</span>
            </div>
            <div class="added-value">
              <el-date-picker
                class="select"
                v-model="params.jdrq"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="added-bottom">
        <el-button type="primary" @click="noClick">识别身份证</el-button>
        <el-button type="primary" @click="noClick">保存</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '',
      options: [],
      value: '',
      value1: '',
      params: {
        rqfl: '',
        name: '',
        sfzh: '',
        age: '',
        sex: '',
        hjdz: '',
        xzz: '',
        xzsq: '',
        xzsqbm: '',
        cjd: '',
        ssjg: '',
        zrjg: '',
        zrjgid: '',
        lrr: '',
        lrrid: '',
        tjlx: '',
        dabm: '',
        jdrq: ''
      }
    }
  },
  methods: {
    noClick () {
      this.$message.error('未开放')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .added {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);

    .added-box {
      width: 90%;
      height: 80%;
      padding: 0 20px;
      border-radius: 12px;
      background-color: #fff;
      box-sizing: border-box;

      .added-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        color: #000;
        font-size: 20px;

        .close {
          font-size: 24px;
          cursor: pointer;
        }
      }

      .added-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 130px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .added-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          width: 100%;
          padding: 0 20px;
          box-sizing: border-box;

          .added-item {
            display: flex;
            align-items: center;
            width: 33%;
            margin: 20px 0;

            .added-key {
              width: 200px;
              text-align: right;
              margin-right: 10px;
              font-size: 18px;

              .red {
                color: red;
              }
            }

            .added-value {
              width: calc(100% - 210px);

              .el-radio-group {
                display: flex;
              }

              .el-radio {
                display: flex;
                align-items: center;
              }

              /deep/.el-radio__label {
                font-size: 18px;
              }

              .select {
                width: 100%;
              }

              .select1 {
                width: 60%;
              }

              .input {
                width: 40%;
              }
            }
          }
        }
      }

      .added-bottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 70px;
      }
    }
  }
</style>
