<template>
  <div class="children-page">
    <div class="children-head">
      <div class="children-head-item">
        <div class="children-head-left">
          <div class="children-head-open"></div>
          <el-input placeholder="请输入搜索内容"></el-input>
        </div>
        <div class="children-head-center">
          <span>切换机构：</span>
          <el-select v-model="params.cwhmcid" placeholder="请选择" @change="selectChange">
            <el-option v-for="item in organs" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="children-head-right">
          <span class="el-icon-success success"></span>
          <div class="div">
            <div class="div1">
              <div class="blue"></div>
            </div>
          </div>
          <span class="el-icon-lock lock"></span>
          <span class="el-icon-view lock"></span>
          <span class="el-icon-rank rank"></span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="children-box">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="0">查看报告</el-menu-item>
        <el-menu-item index="1">辅助检查</el-menu-item>
        <el-menu-item index="2">评价总结</el-menu-item>
        <el-menu-item index="3">中医健康管理</el-menu-item>
      </el-menu>
      <div class="tab-box">
        <Tab1 v-if="activeIndex === '0'" :paramsInfo="params"></Tab1>
        <Tab2 v-if="activeIndex === '1'"></Tab2>
        <Tab3 v-if="activeIndex === '2'"></Tab3>
        <Tab4 v-if="activeIndex === '3'"></Tab4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tab1 from './tab1/tab1.vue'
import Tab2 from './tab2/tab2.vue'
import Tab3 from './tab3/tab3.vue'
import Tab4 from './tab4/tab4.vue'
export default {
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4
  },
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      activeIndex: '0',
      params: {
        jgbh: '',
        cwhmcid: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo']),
    organs () {
      if (this.loginInfo.jgalllist === null && this.loginInfo.jgalllist.length > 0) return []
      return this.loginInfo.jgalllist
    }
  },
  methods: {
    selectChange (e) {
      console.log(e)
      this.params.jgbh = this.params.cwhmcid = e
      console.log(this.params)
    },
    handleSelect (key) {
      this.activeIndex = key
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;
  box-sizing: border-box;

  .children-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .children-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .children-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .children-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .children-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .children-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 40px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }
    }
  }

  .children-box {
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 20px;
    background-color: #fff;
    box-sizing: border-box;

    /deep/.el-menu-item {
      font-size: 16px;
    }

    .tab-box {
      width: 100%;
      height: calc(100% - 80px);
      margin-top: 10px;
    }
  }

}

.children-page1 {
  position: relative;
}
</style>
