<template>
  <div class="children">
    <div class="children-title">
      <span>个人基本信息表</span>
    </div>
    <!-- <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
    </el-input> -->
    <div class="children-info">
      <div class="item">
        <span>姓名：{{ info.name }}</span>
      </div>
      <div class="item">编号：{{ info.grjbxxid || '' }}</div>
    </div>
    <div class="children-scroll">
      <div class="children-table children-border-bottom">
        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>性别</span>
          </div>
          <div class="children-td children-flex4 children-text-center">
            <el-radio-group v-model="info.xb" class="margin-left">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
              <el-radio label="9">未说明的性别</el-radio>
              <el-radio label="3">未知的性别</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td children-flex2 children-text-center">
            <span>出生日期</span>
          </div>
          <div class="children-td children-flex4 children-text-center children-border-right-none">
            <el-date-picker
              v-model="info.csrq"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>身份证号</span>
          </div>
          <div class="children-td children-flex4 children-text-center">
            <el-input v-model="info.sfzh"></el-input>
          </div>
          <div class="children-td children-flex2 children-text-center">
            <span>工作单位</span>
          </div>
          <div class="children-td children-flex4 children-text-center children-border-right-none">
            <el-input v-model="info.gzdw"></el-input>
          </div>
        </div>
        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>本人电话</span>
          </div>
          <div class="children-td children-flex2 children-text-center">
            <el-input v-model="info.brdh"></el-input>
          </div>
          <div class="children-td children-flex2 children-text-center">
            <span>联系人</span>
          </div>
          <div class="children-td children-flex2 children-text-center">
            <el-input v-model="info.lxrxm"></el-input>
          </div>
          <div class="children-td children-flex2 children-text-center">
            <span>联系人电话</span>
          </div>
          <div class="children-td children-flex2 children-text-center children-border-right-none">
            <el-input v-model="info.lxrdh"></el-input>
          </div>
        </div>
        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>常住户籍</span>
          </div>
          <div class="children-td children-flex4 children-text-center">
            <el-radio-group v-model="info.czlx">
              <el-radio label="1">户籍</el-radio>
              <el-radio label="2">非户籍</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td children-flex2 children-text-center">
            <span>民族</span>
          </div>
          <div class="children-td children-flex4 children-text-center children-border-right-none">
            <el-radio-group v-model="info.minzu">
              <el-radio label="01">汉族</el-radio>
              <el-radio label="99">少数民族</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>血型</span>
          </div>
          <div class="children-td children-flex4 children-text-center">
            <el-radio-group v-model="info.xuexing">
              <el-radio label="1">A型</el-radio>
              <el-radio label="2">B型</el-radio>
              <el-radio label="3">O型</el-radio>
              <el-radio label="4">AB型</el-radio>
              <el-radio label="5">不详</el-radio>
            </el-radio-group>
          </div>
          <div class="children-td children-flex2 children-text-center">
            <span>RH</span>
          </div>
          <div class="children-td children-flex4 children-text-center children-border-right-none">
            <el-radio-group v-model="info.rh">
              <el-radio label="1">阴性</el-radio>
              <el-radio label="2">阳性</el-radio>
              <el-radio label="3">不详</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>文化程度</span>
          </div>
          <div class="children-td children-flex10 children-text-center children-border-right-none">
            <el-radio-group v-model="info.whcd">
              <el-radio label="1">研究生</el-radio>
              <el-radio label="2">大学本科</el-radio>
              <el-radio label="3">大学专科和专科学校</el-radio>
              <el-radio label="4">中等专业学校</el-radio>
              <el-radio label="5">技工学校</el-radio>
              <el-radio label="6">高中</el-radio>
              <el-radio label="7">初中</el-radio>
              <el-radio label="8">小学</el-radio>
              <el-radio label="9">文盲及半文盲</el-radio>
              <el-radio label="10">不详</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="children-tr children-tr8 children-border">
          <div class="children-td children-flex2">
            <span>职业</span>
          </div>
          <div class="children-td children-flex10 children-border-right-none">
            <el-radio-group v-model="info.zhiye" class="margin-left">
              <el-radio label="0">国家机关、党群组织、企、事业单位负责人</el-radio>
              <el-radio label="1">专业技术人员</el-radio>
              <el-radio label="2">办事人员和有关人员</el-radio>
              <el-radio label="3">商业、服务业人员</el-radio>
              <el-radio label="4">农、林、牧、渔、水利业生产人员</el-radio>
              <el-radio label="5">生产、运输设备操作人员</el-radio>
              <el-radio label="6">军人</el-radio>
              <el-radio label="7">不变分类的其他从业人员</el-radio>
              <el-radio label="8">无职业</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>婚姻状况</span>
          </div>
          <div class="children-td children-flex10 children-border-right-none">
            <el-radio-group v-model="info.hyqk" class="margin-left">
              <el-radio label="1">未婚</el-radio>
              <el-radio label="2">已婚</el-radio>
              <el-radio label="3">丧偶</el-radio>
              <el-radio label="4">离婚</el-radio>
              <el-radio label="5">未说明的婚姻状况</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>医疗费用支付方式</span>
          </div>
          <div class="children-td children-flex10 children-border-right-none">
            <el-radio-group v-model="info.ylfy" class="margin-left">
              <el-radio label="1">城镇职工基本医疗保险</el-radio>
              <el-radio label="2">城镇居民基本医疗保险</el-radio>
              <el-radio label="3">新型合作医疗保险</el-radio>
              <el-radio label="4">贫困救助</el-radio>
              <el-radio label="5">商业医疗保险</el-radio>
              <el-radio label="6">全公费</el-radio>
              <el-radio label="7">全自费</el-radio>
              <el-radio label="8">其他</el-radio>
              <el-input v-if="info.ylfy === '8'" v-model="info.ylfyqt"></el-input>
            </el-radio-group>
          </div>
        </div>

        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>药物过敏史</span>
          </div>
          <div class="children-td children-flex10 children-border-right-none">
            <el-radio-group v-model="info.ywgms" class="margin-left" @change="yhChange">
              <el-radio label="1">无</el-radio>
              <el-radio label="2">青霉素</el-radio>
              <el-radio label="3">磺胺</el-radio>
              <el-radio label="4">链霉素</el-radio>
              <el-radio label="5">其他</el-radio>
            </el-radio-group>
            <el-input v-if="info.ywgms === '5'" v-model="info.ywgmsDes" style="width: 100px; margin-left: 10px;"></el-input>
          </div>
        </div>

        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>暴露史</span>
          </div>
          <div class="children-td children-flex10 children-border-right-none">
            <el-radio-group v-model="info.blshi" class="margin-left">
              <el-radio label="1">无</el-radio>
              <el-radio label="2">化学品</el-radio>
              <el-radio label="3">毒雾</el-radio>
              <el-radio label="4">射线</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="children-tr children-tr10 children-border">
          <div class="children-td children-flex2">
            <span>既往史</span>
          </div>
          <div class="children-td children-flex10 children-td-column children-border-right-none">
            <div class="children-tr children-tr7 children-border-bottom">
              <div class="children-td children-flex1">
                <span>疾病</span>
              </div>
              <div class="children-td children-flex10 children-border-right-none">
                <el-checkbox-group v-model="info.jwsjb" @change="jbChange" class="margin-left el-radio-group1">
                  <el-checkbox label="1">无</el-checkbox>
                  <el-checkbox label="2">高血压</el-checkbox>
                  <el-checkbox label="3">糖尿病</el-checkbox>
                  <el-checkbox label="4">冠心病</el-checkbox>
                  <el-checkbox label="5">慢性阻塞性肺病</el-checkbox>
                  <el-checkbox label="6">恶性肿瘤</el-checkbox>
                  <el-checkbox label="7">脑卒中</el-checkbox>
                  <el-checkbox label="8">严重精神障碍</el-checkbox>
                  <el-checkbox label="9">结核病</el-checkbox>
                  <el-checkbox label="10">肝炎</el-checkbox>
                  <el-checkbox label="11">其他法定传染病</el-checkbox>
                  <el-checkbox label="12">职业病</el-checkbox>
                  <el-checkbox label="13">其他</el-checkbox>
                  <el-input v-show="info.jwsjb.indexOf('13') > -1"></el-input>
                  <div class="checkbox">
                    <el-checkbox class="margin-left"></el-checkbox>
                    <div class="input-text">
                      <span>确诊时间</span>
                      <el-input></el-input>
                      <span>年</span>
                      <el-input></el-input>
                      <span>月</span>
                      <el-input></el-input>
                      <span>日</span>
                    </div>
                    <el-checkbox class="margin-left"></el-checkbox>
                    <div class="input-text">
                      <span class="margin-left">确诊时间</span>
                      <el-input></el-input>
                      <span>年</span>
                      <el-input></el-input>
                      <span>月</span>
                      <el-input></el-input>
                      <span>日</span>
                    </div>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
            <div class="children-tr children-border-bottom">
              <div class="children-td children-flex1">
                <span>手术史</span>
              </div>
              <div class="children-td children-flex10 children-border-right-none">
                <el-radio-group v-model="info.jwssshu" class="margin-left" @change="ssChange">
                  <el-radio label="1">无 </el-radio>
                  <el-radio label="2">有</el-radio>
                  <div class="input-text" v-if="info.jwssshu === '2'">
                    <span class="margin-left">名称</span>
                    <el-input></el-input>
                    <span>时间</span>
                    <el-input></el-input>
                  </div>
                </el-radio-group>
              </div>
            </div>
            <div class="children-tr children-border-bottom">
              <div class="children-td children-flex1">
                <span>外伤</span>
              </div>
              <div class="children-td children-flex10 children-border-right-none">
                <el-radio-group v-model="info.jwsws" class="margin-left">
                  <el-radio label="1">无 </el-radio>
                  <el-radio label="2">有</el-radio>
                  <div class="input-text" v-if="info.jwsws === '2'">
                    <span class="margin-left">名称</span>
                    <el-input></el-input>
                    <span>时间</span>
                    <el-input></el-input>
                  </div>
                </el-radio-group>
              </div>
            </div>
            <div class="children-tr">
              <div class="children-td children-flex1">
                <span>输血</span>
              </div>
              <div class="children-td children-flex10 children-border-right-none">
                <el-radio-group v-model="info.jwssx" class="margin-left">
                  <el-radio label="1">无 </el-radio>
                  <el-radio label="2">有</el-radio>
                  <div class="input-text" v-if="info.jwssx === '2'">
                    <span class="margin-left">名称</span>
                    <el-input></el-input>
                    <span>时间</span>
                    <el-input></el-input>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>

        <div class="children-tr children-tr7 children-border">
          <div class="children-td children-flex2">
            <span>家族史</span>
          </div>
          <div class="children-td children-flex10 children-td-column children-border-right-none">
            <div class="children-tr children-border-bottom">
              <div class="children-td children-flex2">
                <span>父亲</span>
              </div>
              <div class="children-td children-flex4 children-text-center">
                <div class="input-width1">
                  <el-input></el-input>
                </div>
              </div>
              <div class="children-td children-flex2">
                <span>母亲</span>
              </div>
              <div class="children-td children-flex4 children-text-center children-border-right-none">
                <div class="input-width1">
                  <el-input></el-input>
                </div>
              </div>
            </div>
            <div class="children-tr children-border-bottom">
              <div class="children-td children-flex2">
                <span>兄弟姐妹</span>
              </div>
              <div class="children-td children-flex4 children-text-center">
                <div class="input-width1">
                  <el-input></el-input>
                </div>
              </div>
              <div class="children-td children-flex2">
                <span>子女</span>
              </div>
              <div class="children-td children-flex4 children-text-center children-border-right-none">
                <div class="input-width1">
                  <el-input></el-input>
                </div>
              </div>
            </div>
            <div class="children-tr">
              <div class="children-td children-flex10 children-border-right-none">
                <el-radio-group v-model="info.jzsfq" class="margin-left" @change="jzsChange">
                  <el-radio label="1">无</el-radio>
                  <el-radio label="2">高血压</el-radio>
                  <el-radio label="3">糖尿病</el-radio>
                  <el-radio label="4">冠心病</el-radio>
                  <el-radio label="5">慢性阻塞性肺病</el-radio>
                  <el-radio label="6">恶性肿瘤</el-radio>
                  <el-radio label="7">脑卒中</el-radio>
                  <el-radio label="8">严重精神障碍</el-radio>
                  <el-radio label="9">结核病</el-radio>
                  <el-radio label="10">肝炎</el-radio>
                  <el-radio label="11">先天畸形</el-radio>
                  <el-radio label="12">其他</el-radio>
                  <div class="input-text input-text1" v-if="info.jzsfq === '12'">
                    <el-input v-model="info.jzsfqDes"></el-input>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>

        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>遗传病史</span>
          </div>
          <div class="children-td children-flex10 children-border-right-none">
            <el-radio-group v-model="info.ycbs" class="margin-left" @change="ycbsChange">
              <el-radio label="1">无</el-radio>
              <el-radio label="2">有</el-radio>
              <div class="input-text input-text1" v-if="info.ycbs === '2'">
                <el-input v-model="info.ycbDes"></el-input>
              </div>
            </el-radio-group>
          </div>
        </div>

        <div class="children-tr children-border">
          <div class="children-td children-flex2">
            <span>残疾情况</span>
          </div>
          <div class="children-td children-flex10 children-border-right-none">
            <el-radio-group v-model="info.cjqk" class="margin-left" @change="cjqkChange">
              <el-radio label="1">无残疾</el-radio>
              <el-radio label="2">视力残疾</el-radio>
              <el-radio label="3">听力残疾</el-radio>
              <el-radio label="4">言语残疾</el-radio>
              <el-radio label="5">肢体残疾</el-radio>
              <el-radio label="6">智力残疾</el-radio>
              <el-radio label="7">精神残疾</el-radio>
              <el-radio label="8">其他残疾</el-radio>
              <div class="input-text input-text1" v-if="info.cjqk === '8'">
                <el-input v-model="info.cjqkDes"></el-input>
              </div>
            </el-radio-group>
          </div>
        </div>

        <div class="children-tr children-tr5 children-border">
          <div class="children-td children-flex2">
            <span>生活情况</span>
          </div>
          <div class="children-td children-flex10 children-td-column children-border-right-none">
            <div class="children-tr children-border-bottom">
              <div class="children-td children-flex2">
                <span>厨房排风设施：</span>
              </div>
              <div class="children-td children-flex10 children-border-right-none">
                <el-radio-group v-model="info.shhjcfpfcs" class="margin-left">
                  <el-radio label="1">无 </el-radio>
                  <el-radio label="2">油烟机</el-radio>
                  <el-radio label="3">换气扇</el-radio>
                  <el-radio label="4">烟囱</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="children-tr children-border-bottom">
              <div class="children-td children-flex2">
                <span>燃料类型：</span>
              </div>
              <div class="children-td children-flex10 children-border-right-none">
                <el-radio-group v-model="info.shhjrllx" class="margin-left">
                  <el-radio label="1">液化气</el-radio>
                  <el-radio label="2">煤</el-radio>
                  <el-radio label="3">天然气</el-radio>
                  <el-radio label="4">沼气</el-radio>
                  <el-radio label="5">柴火</el-radio>
                  <el-radio label="6">其他</el-radio>
                  <el-input v-if="info.shhjrllx === '6'" v-model="info.shhjrllxqt"></el-input>
                </el-radio-group>
              </div>
            </div>
            <div class="children-tr children-border-bottom">
              <div class="children-td children-flex2">
                <span>饮水</span>
              </div>
              <div class="children-td children-flex10 children-border-right-none">
                <el-radio-group v-model="info.shhjys" class="margin-left">
                  <el-radio label="1">自来水</el-radio>
                  <el-radio label="2">经净化过滤的水</el-radio>
                  <el-radio label="3">井水</el-radio>
                  <el-radio label="4">河湖水</el-radio>
                  <el-radio label="5">糖水</el-radio>
                  <el-radio label="6">其他</el-radio>
                  <el-input v-if="info.shhjys === '6'" v-model="info.shhjysqt"></el-input>
                </el-radio-group>
              </div>
            </div>
            <div class="children-tr children-border-bottom">
              <div class="children-td children-flex2">
                <span>厕所</span>
              </div>
              <div class="children-td children-flex10 children-border-right-none">
                <el-radio-group v-model="info.shhjcs" class="margin-left">
                  <el-radio label="1">卫生厕所</el-radio>
                  <el-radio label="2">一格或二格粪池式</el-radio>
                  <el-radio label="3">马桶</el-radio>
                  <el-radio label="4">露天粪坑</el-radio>
                  <el-radio label="5">简易厕所</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="children-tr">
              <div class="children-td children-flex2">
                <span>禽畜栏</span>
              </div>
              <div class="children-td children-flex10 children-border-right-none">
                <el-radio-group v-model="info.shhjqcl" class="margin-left">
                  <el-radio label="1">无</el-radio>
                  <el-radio label="2">单设</el-radio>
                  <el-radio label="3">室内</el-radio>
                  <el-radio label="4">室外</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="btns">
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectSfzh: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: [],
      info: {
        id: 0,
        createTime: '',
        updateTime: '',
        grjbxxid: '',
        dnbh: '',
        name: '',
        xb: '',
        csrq: '',
        sfzh: '',
        gzdw: '',
        brdh: '',
        lxrxm: '',
        lxrdh: '',
        czlx: '',
        minzu: '',
        xuexing: '',
        rh: '',
        whcd: '',
        zhiye: '',
        hyqk: '',
        ylfy: '',
        ylfyqt: '',
        ywgms: '',
        ywgmsDes: '',
        blshi: '',
        jwsjb: [],
        jwssshu: '',
        jwsws: '',
        jwssx: '',
        jzsfq: '',
        jzsfqDes: '',
        jzsmq: '',
        jzsmqDes: '',
        jzsxdjm: '',
        jzsxdjmDes: '',
        jzszn: '',
        jzsznDes: '',
        ycbs: '',
        ycbDes: '',
        cjqk: '',
        cjqkDes: '',
        shhjcfpfcs: '',
        shhjrllxqt: '',
        shhjysqt: '',
        shhjcs: '',
        shhjqcl: '',
        lrsj: '',
        lrr: '',
        jkdnid: '',
        jkdnxzz: '',
        jkdnhjdz: '',
        lxdh: '',
        xzjdmc: '',
        cwhmc: '',
        jddw: '',
        jdr: '',
        zeys: '',
        jdrq: '',
        lrrq: '',
        sfzhm: '',
        age: '',
        hjdz: '',
        jgbh: '',
        personInfoId: '',
        manageOrgId: ''
      }
    }
  },
  created () {
    console.log('所选身份证号', this.selectSfzh)
    this.getInfo()
  },
  computed: {
    ylfy () {
      return this.info.ylfy
    },
    shhjrllx () {
      return this.info.shhjrllx
    },
    shhjys () {
      return this.info.shhjys
    }
  },
  watch: {
    ylfy (val) {
      if (val !== 8) {
        this.info.ylfyqt = ''
      }
    },
    shhjrllx (val) {
      if (val !== 6) {
        this.info.shhjrllxqt = ''
      }
    },
    shhjys (val) {
      if (val !== 6) {
        this.info.shhjysqt = ''
      }
    }
  },
  methods: {
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97902645
      try {
        const obj = {
          sfzh: this.selectSfzh
        }
        const { data: res } = await this.$http.post('ykdgrjbxxs/findinfo', obj)
        console.log('基本信息', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
        if (!this.info.jwsjb) {
          this.info.jwsjb = []
        } else {
          this.info.jwsjb = this.info.jwsjb.split(',')
        }
      } catch (error) {
        console.log('请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },

    async submit () {
      try {
        this.info.sfzh = this.selectSfzh
        const params = {
          ...this.info,
          jwsjb: this.info.jwsjb.join(',')
        }
        const { data: res } = await this.$http.post('ykdgrjbxxs/updateinfo', params)
        console.log('基本信息', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    yhChange (e) {
      console.log(e)
      if (e !== '5') {
        this.info.ywgmsDes = ''
      }
    },
    ssChange (e) {
      console.log(e)
    },
    jzsChange (e) {
      if (e !== '12') {
        this.info.jzsfqDes = ''
      }
    },
    ycbsChange (e) {
      if (e !== '2') {
        this.info.ycbDes = ''
      }
    },
    cjqkChange (e) {
      if (e !== '8') {
        this.info.cjqkDes = ''
      }
    },
    jbChange (e) {
      if (e.indexOf('1') > -1) {
        this.info.jwsjb = ['1']
      }
      if (e.indexOf('13') === -1) {}
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.children {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .children-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 160px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .el-input {
      width: 200px;
    }

    /deep/.el-input__inner {
      text-align: center;
    }
  }

  .postion {
    position: absolute;
    top: 20px;
    right: 16px;
    width: 246px;
    background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
    border: 1px solid #1F72D3;
    border-radius: 6px;

    /deep/.el-input__inner {
      background-color: transparent;
      color: #000;
      font-size: 18px;
      border: none;

    }

    /deep/ .el-input__suffix {
      color: #000;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54px;
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    .item {
      min-width: 206px;
      color: #000;
      font-size: 22px;

      .white {
        margin-left: 20px;
        color: #000;
      }
    }
  }

  .children-table {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .position {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 251px;
      left: 1px;
      width: 67px;
      height: 198px;
      background-color: #081C38;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
      writing-mode: vertical-lr;
    }

    .children-tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
    }

    .children-tr1 {
      height: 106px;
    }

    .children-tr2 {
      height: 200px;
    }

    .children-tr3 {
      height: 160px;
    }

    .children-tr4 {
      height: 200px;
    }

    .children-tr5 {
      height: 250px;
    }

    .children-tr6 {
      height: 650px;
    }

    .children-tr7 {
      height: 150px;
    }

    .children-tr8 {
      height: 100px;
    }

    .children-tr9 {
      height: 500px;
    }

    .children-tr10 {
      height: 300px;
    }

    .children-td {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      font-size: 14px;

      >span {
        margin-left: 14px;
      }

      .margin-left-none {
        margin-left: 0;
      }

      .color {
        white-space: nowrap;
        color: #000;
      }

      .checkbox {
        display: flex;
        align-items: center;

      }

      .input-text {
        display: flex;
        align-items: center;
        color: #000;
        font-size: 14px;

        .el-input {
          width: 60px;
        }
      }

      .input-text1 {
        .el-input {
          width: 120px;
        }
      }
    }

    .children-td-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 100%;
      color: #000;
      font-size: 14px;
      letter-spacing: 4px;
    }

    .children-td-text {
      writing-mode: vertical-lr;
    }

    .children-td-text1 {
      padding-left: 4px;
      box-sizing: border-box;
    }

    .children-td-right {
      width: calc(100% - 46px);
      height: 100%;
    }

    .children-td-list {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      color: #000;
      font-size: 14px;
    }

    .children-td-list-center {

      justify-content: center;
    }

    .children-td-column {
      flex-direction: column;
    }

    .children-td-title {
      width: 100%;
      height: 45px;
      line-height: 45px;
      padding-left: 14px;
      box-sizing: border-box;
    }

    .checkbox {
      width: 100%;
      height: calc(100% - 50px);
      padding-left: 8px;
      box-sizing: border-box;
    }

    .margin-left {
      margin-left: 14px;
    }

    .margin-bottom {
      margin-bottom: 10px;
    }

    .children-flex1 {
      flex: 1;
    }

    .children-flex2 {
      flex: 2;
    }

    .children-flex3 {
      flex: 3;
    }

    .children-flex4 {
      flex: 4;
    }

    .children-flex5 {
      flex: 5;
    }

    .children-flex6 {
      flex: 6;
    }

    .children-flex7 {
      flex: 7;
    }

    .children-flex8 {
      flex: 8;
    }

    .children-flex10 {
      flex: 10;
    }

    .children-flex12 {
      flex: 12;
    }

    .children-text-center {
      padding-left: 0;
      justify-content: center;

      >span {
        margin-left: 0;
      }
    }

    .children-text-right {
      justify-content: flex-end;

      >span {
        width: calc(100% - 67px);
        text-align: center;
      }
    }

    .children-wrap {
      flex-wrap: wrap;
    }

    .input {
      width: 30px;
    }

    .input1 {
      width: 80px;
    }

    .label {
      width: calc(100% - 14px);
      margin-left: 10px;
    }

    /deep/.el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #000;
      color: #000;
      font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
      color: #000;
    }

    /deep/.el-radio__label,
    /deep/.el-checkbox__label {
      color: #000;
      font-size: 14px;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label,
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #000;
    }

    .el-radio-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .el-radio-group1 {
      display: flex;
      flex-wrap: wrap;
      height: 120px;
      display: flex;
      flex-wrap: wrap;
      align-content: space-evenly;
    }

    .el-checkbox-group {
      align-content: space-between;
    }

    .input-width1 {
      width: 200px;
    }

  }

  .children-border {
    border: 1px solid #000;
    border-bottom: none;
    box-sizing: border-box;
  }

  .children-border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-three {
    border-right: 3px solid transparent !important;
  }

  .btns {
    display: flex;
    align-items: center;
    height: 80px;
  }

}
</style>
