<template>
  <div class="examine">
    <div class="examine-box">
      <div class="examine-title">
        <span class="el-icon-close close" @click="close"></span>
      </div>
      <div class="examine-list">
        <div class="examine-key">
          <span>审核</span>
        </div>
        <el-radio-group v-model="radio">
          <el-radio label="1">通过</el-radio>
          <el-radio label="2">未通过</el-radio>
          <el-input v-show="radio === '2'" placeholder="输入原因"></el-input>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.examine {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);

  .examine-box {
    width: 600px;
    height: 400px;
    padding: 0 10px;
    background-color: #081C38;
    border-radius: 10px;
    box-sizing: border-box;

    .examine-title {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 0 20px;
      box-sizing: border-box;
      color: #fff;
      font-size: 24px;

      .close {
        cursor: pointer;
      }
    }

    .examine-list {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100% - 60px);
      padding-bottom: 80px;
      box-sizing: border-box;
    }

    .examine-key {
      margin-right: 10px;
      color: #fff;
      font-size: 20px;
    }

    .el-radio-group {
      display: flex;
      align-items: center;
    }

    .el-radio {
      display: flex;
      align-items: center;
    }

    /deep/.el-radio__label {
      color: #fff;
      font-size: 18px;
    }
  }
}
</style>
