<template>
  <div class="son">
    <div class="son-box">
      <div class="son-title" @click="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="son-content">
        <div class="son-top">
          <div class="left">
            <div class="item" v-for="(item, index) in tabs" :key="index" :class="{ active: index === tabIndex }"
              @click="tabClick(index)">
              <span>{{ item }}</span>
            </div>
          </div>
          <div class="right">
            <Tab1 v-if="tabIndex === 0"></Tab1>
            <Tab2 v-else-if="tabIndex === 1"></Tab2>
            <Tab3 v-else-if="tabIndex === 2"></Tab3>
            <Tab4 v-else-if="tabIndex === 3"></Tab4>
            <Tab5 v-else-if="tabIndex === 4"></Tab5>
            <Tab6 v-else-if="tabIndex === 5"></Tab6>
            <Tab7 v-else-if="tabIndex === 6"></Tab7>
            <Tab8 v-else-if="tabIndex === 7"></Tab8>
            <Tab9 v-else-if="tabIndex === 8"></Tab9>
            <Tab10 v-else-if="tabIndex === 9"></Tab10>
            <Tab11 v-else-if="tabIndex === 10"></Tab11>
          </div>
        </div>
        <div class="son-bottom">
          <el-button type="primary">留存报告</el-button>
          <el-button type="primary">生化/血常规</el-button>
          <el-button type="primary">下载报告</el-button>
          <el-button type="primary">辅助检查</el-button>
          <el-button type="primary" @click="test">日常体检</el-button>
          <el-button type="primary">点击打印</el-button>
          <el-button type="primary">上传</el-button>
          <el-button type="primary">审核</el-button>
        </div>
      </div>
    </div>
    <Pdf v-if="showPdf" :url="url" @close="closePdf"></Pdf>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tab1 from './tab1/tab1.vue'
import Tab2 from './tab2/tab2.vue'
import Tab3 from './tab3/tab3.vue'
import Tab4 from './tab4/tab4.vue'
import Tab5 from './tab5/tab5.vue'
import Tab6 from './tab6/tab6.vue'
import Tab7 from './tab7/tab7.vue'
import Tab8 from './tab8/tab8.vue'
import Tab9 from './tab9/tab9.vue'
import Tab10 from './tab10/tab10.vue'
import Tab11 from './tab11/tab11.vue'
import Pdf from './pdf.vue'
export default {
  props: {
    params: {
      type: Object,
      default () {
        return {
          jgbh: '',
          sfzh: '',
          tmcode: '',
          tjlx: ''
        }
      }
    }
  },
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5,
    Tab6,
    Tab7,
    Tab8,
    Tab9,
    Tab10,
    Tab11,
    Pdf
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['健康档案', '健康体检表', '自我能力评估表', '中医药健康管理', '中医健康指导', '彩色超声检测报告', '生化检测体检报告单', '血常规体检报告单', '尿常规体检报告单', '心电图', '体检结果评价']),
      info: {
        jgbh: '',
        type: '100',
        jgjb: '',
        sfzh: '',
        tmcode: '',
        tjlx: ''
      },
      showPdf: false,
      url: ''
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    console.log(this.params)
    this.info.jgbh = this.params.jgbh
    this.info.jgjb = this.loginInfo.jgjb
    this.info.sfzh = this.params.sfzh
    this.info.tmcode = this.params.tmcode
    this.info.tjlx = this.params.tjlx
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    async test () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-87813803
      try {
        const { data: res } = await this.$http.post('/exportReport/exportPdftm', this.info)
        console.log('体检报告pdf打印下载', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.url = res.data.pdfurl
        this.showPdf = true
      } catch (error) {
        console.log('体检报告pdf打印下载请求失败', error)
      }
    },
    closePdf () {
      this.url = ''
      this.showPdf = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .69);

  .son-box {
    width: 98%;
    height: 95%;
    background-color: #fff;
    border-radius: 12px;

    .son-title {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      height: 50px;
      padding-right: 20px;
      box-sizing: border-box;
      color: #D1D1D1;
      font-size: 30px;
      cursor: pointer;
    }

    .son-content {
      width: 100%;
      height: calc(100% - 50px);

      .son-top {
        display: flex;
        width: 100%;
        height: calc(100% - 100px);

        .left {
          overflow-y: scroll;
          width: 260px;
          height: 100%;
          background-color: #3782F2;

          &::-webkit-scrollbar {
            width: 0;
          }

          .item {
            width: 100%;
            height: 57px;
            line-height: 57px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
          }

          .active {
            background-color: #3470CB;
          }
        }

        .right {
          overflow-y: scroll;
          width: calc(100% - 300px);
          height: 100%;

          &::-webkit-scrollbar {
            width: 0;
          }
        }
      }

      .son-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;

        .el-button {
          min-width: 120px;
          margin-left: 15px;
          margin-right: 15px;
          padding-top: 6px;
          padding-bottom: 6px;
          background-color: #3782F2;
          border: none;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
