<template>
  <div class="item">
    <div class="item-tab">
      <span class="item-item" :class="{ 'item-active': tabIndex === index }" v-for="(item, index) in tabs" :key="index"
        @click="tabClick(index)">{{ item }}</span>
    </div>
    <div class="item-head">
      <div class="item-line">
        <span class="span">测量统计：31次</span>
        <span class="span">异常：1次</span>
        <span class="span">最后一次测量日期：2020-03-08</span>
      </div>
      <div class="item-line">
        <span class="text">按条数查询：</span>
        <el-radio-group v-model="radio">
          <el-radio label="1">近10条</el-radio>
          <el-radio label="2">近20条</el-radio>
          <el-radio label="3">近50条</el-radio>
        </el-radio-group>
        <el-date-picker type="daterange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" v-model="date">
        </el-date-picker>
        <el-button class="blue">图表</el-button>
        <el-button>列表</el-button>
      </div>
    </div>

    <div class="item-chart" id="echarts"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 1,
      tabs: Object.freeze(['血压', '心率', '血糖', '体温', '身高', '体重', 'BMI']),
      radio: '1',
      date: '',
      myChart: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
      }, 100)
    },
    canvas () {
      var chartDom = document.getElementById('echarts')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['心率'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            color: '#ffffff',
            fontSize: 15
          },
          axisLine: {
            show: true
          }
        },
        yAxis: {
          type: 'value',
          name: '',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ffffff'
            }
          },
          axisLabel: {
            show: true,
            color: '#ffffff',
            fontSize: 15
          }
        },

        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            show: false, // 是否显示滑动条，不影响使用
            type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始
            endValue: 6 // 一次性展示6个
          }
        ],
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            name: '心率',
            itemStyle: {
              color: '#FF8500', // 改变折线点的颜色
              lineStyle: {
                color: '#FF8500' // 改变折线颜色
              }
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  width: 100%;
  height: 100%;

  .item-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 16px);
    height: 50px;
    margin-left: 16px;
    padding-left: 47px;
    border-bottom: 1px solid #00D5EA;
    box-sizing: border-box;

    .item-item {
      margin-right: 44px;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }

    .item-active {
      color: #00D5EA;
    }
  }

  .item-head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: #0C4969;
    box-sizing: border-box;

    .item-line {
      display: flex;
      flex-direction: row;
      align-items: center;

      .span {
        margin-right: 72px;
        color: #FFFFFF;
        font-size: 18px;
      }

      .span:last-child {
        margin-right: 0;
      }

      .text {
        color: #FFFFFF;
        font-size: 18px;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        background-color: #00D5EA;
        border-color: #00D5EA;
      }

      /deep/.el-radio__label {
        color: #fff;
        font-size: 18px;
      }

      .el-radio-group {
        margin-right: 36px;
      }

      .el-button {
        background-color: #fff;
        border: none;
        color: #999999;
      }

      .blue {
        margin-left: 36px;
        background-color: #00D5EA;
        color: #fff;
      }
    }
  }

  .item-chart {
    width: 100%;
    height: calc(100% - 130px);
    margin-top: 10px;
  }
}
</style>
