<template>
  <div class="children-page" :class="{ 'children-page1': showReferral || showUpdate }">
    <div class="head">
      <el-button type="primary">管理档案</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search" v-model="val">
      </el-input>
    </div>
    <div class="input">
      <div class="item">
        <div class="key">
          <span>机构</span>
        </div>
        <el-select v-model="params.cwhmcid" placeholder="我管辖的档案" @change="exorgchange">
          <el-option v-for="item in loginInfo.jgalllist" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>条件</span>
        </div>
        <el-input v-model="params.search" placeholder="姓名/手机号/身份证号"></el-input>
      </div>
      <div class="item">
        <div class="key">
          <span>责任人</span>
        </div>
        <el-select v-model="params.personInfoNo" placeholder="请选择" class="margin">
          <el-option v-for="item in zrrs" :key="item.empid" :label="item.label" :value="item.empid">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>最后一次随访情况</span>
        </div>
        <el-select v-model="value" placeholder="请选择" class="margin">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>建档日期</span>
        </div>
        <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="item">
        <div class="key">
          <span>是否家属签约</span>
        </div>
        <el-select v-model="params.isqy" placeholder="请选择" class="margin">
          <el-option v-for="item in isqys" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>是否纳入三高管理</span>
        </div>
        <el-select v-model="params.issglb" placeholder="请选择" class="margin">
          <el-option v-for="item in issglbs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>高血压级别</span>
        </div>
        <el-select v-model="params.gxyjb" placeholder="请选择" class="margin">
          <el-option v-for="item in jbs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>高血压等级</span>
        </div>
        <el-select v-model="params.gxydj" placeholder="请选择" class="margin">
          <el-option v-for="item in jbs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>糖尿病级别</span>
        </div>
        <el-select v-model="params.tnbjb" placeholder="请选择" class="margin">
          <el-option v-for="item in jbs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>高血糖等级</span>
        </div>
        <el-select v-model="params.gxtdj" placeholder="请选择" class="margin">
          <el-option v-for="item in jbs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>患者类型：</span>
        </div>
        <el-checkbox-group v-model="checkList" @change="select">
          <el-checkbox :label="index + 1" v-for="(item, index) in list" :key="index">{{ item }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="item">
        <el-button type="primary">人群分类</el-button>
        <el-button type="primary" @click="getList">查询</el-button>
        <el-button type="primary" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="add">
      <el-button type="primary" @click="openAdd">新增</el-button>
      <el-button type="primary" @click="showAccess">健康浏览器</el-button>
      <el-button type="primary">重大疾病预警</el-button>
      <el-button type="primary" @click="openClass">人群标记</el-button>
      <el-button type="primary">导出</el-button>
      <el-button v-if="showClearSelect" type="primary" @click="setCurrent">取消选择</el-button>
    </div>
    <div class="table-height">
      <el-table :data="tableData" height="100%" border ref="table" highlight-current-row
      @current-change="currentChange">
        <el-table-column prop="id" label="序号" :width="flexWidth('id', tableData, '序号')">
        </el-table-column>
        <el-table-column label="分级" :width="flexWidth('ycfj', tableData, '分级')">
          <template slot-scope="scope">
            <div class="flex" v-if="scope.row.ycfj === '1'">
              <span class="red">一级</span>
            </div>
            <div class="flex" v-else-if="scope.row.ycfj === '2'">
              <span class="yellow">二级</span>
            </div>
            <div class="flex" v-else-if="scope.row.ycfj === '3'">
              <span class="green">三级</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" :width="flexWidth('name', tableData, '姓名')">
        </el-table-column>
        <el-table-column prop="sfzhm" label="身份证号" :width="flexWidth('sfzhm', tableData, '身份证号')">
        </el-table-column>
        <el-table-column prop="lxdh" label="联系电话" :width="flexWidth('lxdh', tableData, '联系电话')">
        </el-table-column>
        <el-table-column prop="nametage" label="人群分类" :width="flexWidth('nametage', tableData, '人群分类')">
        </el-table-column>
        <el-table-column prop="age" label="年龄" :width="flexWidth('age', tableData, '年龄')">
        </el-table-column>
        <el-table-column prop="xb" label="性别" :width="flexWidth('xb', tableData, '性别')">
          <template slot-scope="scope">
            <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="纳入三高" :width="flexWidth('', tableData, '纳入三高')">
        </el-table-column>
        <el-table-column prop="xzjdmc" label="乡镇/社区" :width="flexWidth('xzjdmc', tableData, '社区')">
        </el-table-column>
        <el-table-column prop="cwhmc" label="村/街道" :width="flexWidth('cwhmc', tableData, '村/街道')">
        </el-table-column>
        <el-table-column prop="jkdnxzz" label="地址" :width="flexWidth('jkdnxzz', tableData, '地址')">
        </el-table-column>
        <el-table-column label="操作" width="270" fixed="right">
          <template slot-scope="scope">
            <div class="flex">
              <el-button type="primary" size="mini" @click="openUpdate(scope.row)">编辑</el-button>
              <el-button type="primary" size="mini" @click="deleteTab(scope.row.id)">删除</el-button>
              <el-button type="primary" size="mini" @click="openBind(scope.row)">绑定</el-button>
              <el-button type="primary" size="mini" @click="openReferral">转诊</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum"
        background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total"
        hide-on-single-page></el-pagination>
    </div>
    <!-- 新增 -->
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <Update v-if="showUpdate" :selectSfzh="selectSfzh" :xzjdmcid="xzjdmcid" :cwhmcid="cwhmcid" @showAccess="showAccess"
      @close="closeUpdate"></Update>
    <!-- 绑定 -->
    <Bind v-if="showBind" @close="closeBind"></Bind>
    <Info v-if="showInfo" @close="closeInfo"></Info>
    <!-- 转诊 -->
    <Referral v-if="showReferral" @close="closeReferral"></Referral>
    <!-- 人群分类 -->
    <Class v-if="showClass" @close="closeClass"></Class>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from './add.vue'
import Update from './update.vue'
import Bind from './bindPopup.vue'
import Info from './info/info.vue'
import Referral from '../pupop/referral.vue'
import Class from './class.vue'
export default {
  components: {
    Add,
    Update,
    Bind,
    Info,
    Referral,
    Class
  },
  data () {
    return {
      val: '',
      tableData: [],
      options: [],
      list: Object.freeze(['正常档案', '高血压', '糖尿病', '冠心病', '脑卒中', '残疾人', '慢阻肺', '高血脂', '肾病综合征', '眼底病突', '周围神经病变', '妇女', '儿童', '精神病', '老年人']),
      checkList: [],
      value: '',
      value1: '',
      radio: '',
      showAdd: false,
      showUpdate: false,
      showBind: false,
      showInfo: false,
      showReferral: false,
      showClass: false,
      selectSfzh: '',
      xzjdmcid: '',
      cwhmcid: '',
      search: '',
      zrrs: [],
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        // 分页起始
        start: '0',
        // 分页长度
        length: '10',
        ssjgbh: '',
        xzjdmcid: '',
        cwhmcid: '',
        flag: '',
        isqy: '',
        issglb: '',
        gxyjb: '',
        gxydj: '',
        tnbjb: '',
        search: '',
        personInfoNo: '',
        gxtdj: '',
        isHypertension: '',
        isDiabetes: '',
        isgxz: '',
        isTuberculosis: '',
        isSmi: '',
        isCa: '',
        isChd: '',
        isDisability: '',
        isDisease: '',
        isszbb: '',
        isydbb: '',
        iszwsjbb: '',
        isWoman: '',
        isChildren: '',
        isMaternal: '',
        isOld: ''
      },
      searchInfo: {
        id: ''
      },
      isqys: Object.freeze([
        { value: '1', label: '签约' },
        { value: '0', label: '未签约' }
      ]),
      issglbs: Object.freeze([
        { value: '1', label: '纳入' },
        { value: '0', label: '未纳入' }
      ]),
      jbs: Object.freeze([
        { value: '1', label: '一级' },
        { value: '2', label: '二级' },
        { value: '3', label: '三级' }
      ]),
      selectInfo: null,
      showClearSelect: false
    }
  },
  created () {
    this.getZrrs()
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
    this.params.ssjgbh = this.params.xzjdmcid = this.params.cwhmcid = this.loginInfo.jgbh
    // this.params.xzjdmcid = this.loginInfo.xzjdmcid
    // if (this.loginInfo.jgjb === '4') {
    //   this.params.cwhmcid = ''
    // } else if (this.loginInfo.jgjb === '5') {
    //   this.params.cwhmcid = this.loginInfo.tjjgcwhmcid
    // }
    this.getList()
  },
  watch: {
    checkList (newVal) {
      if (newVal.indexOf(1) > -1) {
        this.clearSelect()
      }
    }
  },
  methods: {
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) {
        return title.length * 30 + 'px'
      }
      let flexWidth = 0
      let columnContent = ''
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '16px Microsoft YaHei'
      if ((prop === '') && title) {
        columnContent = title
      } else {
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七'
        }
      }
      const width = context.measureText(columnContent)
      flexWidth = width.width + 38 + num
      return flexWidth + 'px'
    },
    currentChange (val) {
      this.selectInfo = val
      this.showClearSelect = true
      this.$refs.table.setCurrentRow(val)
    },
    setCurrent (row) {
      this.selectInfo = null
      this.$refs.table.setCurrentRow(row)

      this.$nextTick(() => {
        this.showClearSelect = false
      })
    },
    exorgchange (e) {
      this.params.ssjgbh = this.params.xzjdmcid = e
    },
    // https://app.apifox.com/project/2726972/apis/api-84415436
    async getList () {
      this.tableData = []
      try {
        this.params.start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + 1 + ''
        this.params.length = this.query1.pageSize + ''
        const { data: res } = await this.$http.get('/ykdjmjkdnjls', {
          params: this.params,
          headers: {
            Token: this.loginInfo.token
          }
        })
        console.log('档案列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
      } catch (error) {
        console.log('档案列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    select (e) {
      console.log(e)
      if (e.indexOf(1) > -1) {
        this.checkList = [1]
        this.clearSelect()
      } else {
        const keys = {
          2: 'isHypertension',
          3: 'isDiabetes',
          4: 'isCa',
          5: 'isChd',
          6: 'isDisability',
          7: 'isDisease',
          8: 'isgxz',
          9: 'isszbb',
          10: 'isydbb',
          11: 'iszwsjbb',
          12: 'isWoman',
          13: 'isChildren',
          14: 'isSmi',
          15: 'isOld',
          16: 'isTuberculosis',
          17: 'isMaternal'
        }
        for (const key in keys) {
          console.log(e.indexOf(parseInt(key)) > -1)
          if (e.indexOf(parseInt(key)) > -1) {
            this.params[keys[key]] = '1'
          }
        }
        console.log(this.params)
      }
    },
    async deleteTab (id) {
      console.log(id)
      this.searchInfo.id = id
      try {
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const { data: res } = await this.$http.post('/ykdjmjkdnjls/ykdgldasc/del', this.searchInfo)
          console.log(res)
          if (res.code !== 0) return this.$message.error(res.msg || res.message)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getList()
        }).catch(() => {})
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error('系统异常，请稍后重试')
      }
    },
    async getZrrs () {
      try {
        this.params.jgbh = this.loginInfo.jgbh
        const { data: res } = await this.$http.post('/users/getretuser', this.params)
        console.log('责任人列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.zrrs = res.data
      } catch (error) {
        console.log('责任人列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    clear () {
      this.checkList = []
      this.params.cwhmcid = ''
      this.value1 = ''
      this.params.isqy = ''
      this.params.issglb = ''
      this.params.gxyjb = ''
      this.params.gxydj = ''
      this.params.tnbjb = ''
      this.params.gxtdj = ''
      this.params.search = ''
      this.params.personInfoNo = ''
      this.clearSelect()
    },

    clearSelect () {
      this.params.isHypertension = ''
      this.params.isDiabetes = ''
      this.params.isCa = ''
      this.params.isChd = ''
      this.params.isgxz = ''
      this.params.isszbb = ''
      this.params.isydbb = ''
      this.params.iszwsjbb = ''
      this.params.isWoman = ''
      this.params.isChildren = ''
      this.params.isSmi = ''
      this.params.isOld = ''
      this.params.isDisability = ''
      this.params.isDisease = ''
    },

    openAdd () {
      this.showAdd = true
    },
    closeAdd (e) {
      if (e === 'loading') {
        this.getList()
      }
      this.showAdd = false
    },
    openUpdate (obj) {
      this.selectSfzh = obj.sfzhm || ''
      this.xzjdmcid = obj.xzjdmcid || ''
      this.cwhmcid = obj.cwhmcid || ''
      this.showUpdate = true
    },
    closeUpdate (type) {
      this.selectSfzh = ''
      this.xzjdmcid = ''
      this.cwhmcid = ''
      this.showUpdate = false
      if (type === 'load') {
        this.getList()
      }
    },
    openBind (info) {
      this.showBind = true
    },
    closeBind () {
      this.showBind = false
    },
    showAccess (e) {
      console.log('打开档案调阅', e)
      this.showInfo = true
    },
    closeInfo () {
      this.showInfo = false
    },
    openReferral () {
      this.showReferral = true
    },
    closeReferral () {
      this.showReferral = false
    },
    openClass () {
      if (!this.selectInfo || !this.selectInfo.id) {
        return this.$message.error('请选择人员')
      }
      this.showClass = true
    },
    closeClass () {
      this.showClass = false
    },
    handleSizeChange (val) {},
    handleCurrentChange (val) {
      this.query1.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: calc(100% - 10px);
  height: 100%;
  padding: 0 20px;
  background-color: #fff;
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      border: 1px solid #c2bfbf;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #000 !important;
        font-size: 18px;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #000;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: space-evenly;
    height: 260px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 20px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #333;
        font-size: 18px;
      }

      .box {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 180px);
      }

      .margin {
        margin-left: 10px;
      }

      .el-select {
        width: 150px;
      }

      /deep/.el-checkbox__label {
        color: #000;
        font-size: 18px;
      }

      .green {
        width: 100px;
        height: 38px;
        line-height: 38px;
        padding: 0;
        margin: 0;
        margin-right: 20px;
        text-align: center;
        background-color: #04B4D0;
        color: #fff;
        font-size: 18px;
      }
    }

    /deep/.el-radio__inner {
      background-color: transparent;
    }

    /deep/.el-radio__label {
      color: #fff;
      font-size: 18px;
    }

    .search {
      width: 300px;
    }
  }

  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

    .el-button {
      margin-right: 30px;
    }
  }

  .table-height {
    width: 100%;
    height: calc(100% - 450px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
      font-size: 16px;
    }

    // /deep/.el-checkbox__inner {
    //   background-color: transparent;
    // }

    .flex {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }

    .green {
      color: #00BB7E;
    }

    .yellow {
      color: #FF8014;
    }

    .red {
      color: #FF0000;
    }

    .purple {
      color: #073F73;
      cursor: pointer;
    }

    .gray {
      color: #D2D2D2;
      cursor: pointer;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
  }
}

.children-page1 {
  position: relative;
}
</style>
